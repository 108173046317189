import type { DatabaseDevices, IrrigationDeviceStateUnflattened } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import FaultMap from '../../components/FaultMap/FaultMap';
import { useMemo } from 'react';

const faultCodes = [
  'General',
  'E-Stop',
  'Valve Mode Conflict',
  'Clean Mode Conflict',
  'Tank High',
  'Tank Low',
  'Sump High',
  'Irrigation Pump',
  'Valve Timeout',
  'Supply Tank Low ',
  'Return Tank High',
  'Return Pressure High',
  'Ozone gas level High',
];

const LatestIrrigationFaults = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<IrrigationDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  return (
    <PaperSection
      mb={2}
      heading='Latest Irrigation Faults'
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <FaultMap labels={faultCodes} faults={Number(latest.faults)} />
    </PaperSection>
  );
};

export default LatestIrrigationFaults;
