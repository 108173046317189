import type { DeviceType, NDSDeviceStateUnflattened } from '@phyllome/common';
import type { AnyObject } from 'yup';

type FieldType = 'number' | 'schedule' | 'array' | 'bool';

type NDSFields = {
  [key in keyof Pick<
    NDSDeviceStateUnflattened,
    'currentDay' | 'schedule' | 'enable'
  >]: FieldType;
};

const ndsFields: NDSFields = {
  currentDay: 'number',
  schedule: 'array',
  enable: 'bool',
};

const fields: { [key in DeviceType]: AnyObject } = {
  nds: ndsFields,
  air: {},
  alert: {},
  camera: {},
  co2: {},
  conveyor: {},
  fertigator: {},
  guard: {},
  harvester: {},
  irrigation: {},
  lift: {},
  liftcore: {},
  mcp: {},
  packager: {},
  seeder: {},
  sentry: {},
  system: {},
  transfer: {},
  tray: {},
  traybot: {},
  traybotcore: {},
  unknown: {},
  lighting: {},
  lightingcore: {},
};

const getFieldsByDeviceType = (deviceType: DeviceType) => {
  const deviceFields = fields[deviceType];

  return Object.keys(deviceFields).map((key) => {
    return {
      name: key,
      type: deviceFields[key] as FieldType,
    };
  });
};

export default getFieldsByDeviceType;
