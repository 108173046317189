import { Stack } from '@mui/system';
import { CheckboxElement, TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, FertigatorDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionFertigatorSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { InputAdornment } from '@mui/material';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormFertigatorSetEc = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<FertigatorDeviceStateUnflattened> ;
}) => {
  const deviceId = deviceState.deviceId;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionFertigatorSet, {
    ec: {
      setpoint: latest.ec?.setpoint || 0,
      enable: latest.ec?.enable,
    },
  });

  return (
    <PaperSection
      heading='EC dosing and setpoint Control'
      mb={2}
      subheading={mqttDefinitionFertigatorSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='fertigator'
        setpoint={latest.ec?.setpoint}
        enable={latest.ec?.enable}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              ec: { setpoint: data?.ec.setpoint, enable: data?.ec.enable },
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Set EC dosing to ${data.ec.enable ? 'enabled' : 'disabled'} and setpoint to ${data.ec.setpoint}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          ec: yup
            .object({
              setpoint: yup.number().min(0).max(4).required(),
              enable: yup.boolean().required(),
            })
            .required(),
        })}
      >
        <Stack spacing={2}>
          <CheckboxElement label='EC Dosing Enabled' name='ec.enable' />
          <TextFieldElement
            label='EC Setpoint' name='ec.setpoint'
            InputProps={{ endAdornment: <InputAdornment position='end'>mS/cm</InputAdornment> }}
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorSetEc;
