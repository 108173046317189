import { Stack } from '@mui/system';
import { CheckboxElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import type { Co2DeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { mqttDefinitionCo2ConfigGet, mqttDefinitionCo2ConfigSet } from '@phyllome/common';
import { Divider } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormCo2ConfigSetAutomation = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<Co2DeviceStateUnflattened>;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;

  const controlLifecycle = useControlSend(mqttDefinitionCo2ConfigSet, deviceId);
  const activityLog = useActivityLog(`device/co2/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionCo2ConfigSet, {
    automation: {
      co2: latest.automation?.co2,
      refresh: latest.automation?.refresh,
    },
  });

  return (
    <PaperSection
      heading='CO2 Dosing Automation'
      mb={2}
      subheading={mqttDefinitionCo2ConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='co2'
        definition={mqttDefinitionCo2ConfigGet}
        co2={latest.automation?.co2}
        refresh={latest.automation?.refresh}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              automation: {
                co2: data.automation.co2,
                refresh: data.automation.refresh,
              },
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            `Set CO2 dosing automation to ${data.automation?.co2} and refresh automation to ${data.automation.refresh}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({})}
      >
        <Stack spacing={2}>
          <CheckboxElement
            label='Automation CO2'
            name='automation.co2'
            required
          />
          <p>Enable the CO2 dosing during the dosing window.</p>
          <Divider />
          <CheckboxElement
            label='Automation Refresh'
            name='automation.refresh'
            required
          />
          <p>
            Enable the refresh fans to be turned on outside of the dosing
            window. Disable to prevent fresh air from being introduced to the
            grow space.
          </p>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormCo2ConfigSetAutomation;
