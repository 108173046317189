import { Stack } from '@mui/system';
import { CheckboxElement, TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import type { DatabaseDevices, IrrigationDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionIrrigationConfigSet,
} from '@phyllome/common';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import useSetDesiredState from '../../../data/hooks/useSetDesiredState';
import SubmitDesiredState from '../../../forms/SubmitDesiredState';
import { useMemo } from 'react';

const FormIrrigationConfigFill = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<IrrigationDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;

  // Replace useControlSend with useSetDesiredState
  const dsLifeCycle = useSetDesiredState({
    deviceId: deviceId,
    deviceType: 'irrigation',
  });

  const activityLog = useActivityLog(`device/irrigation/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionIrrigationConfigSet,
    {
      supply: {
        min: latest.supply?.min || 0,
        max: latest.supply?.max || 0,
      },
      automation: {
        fill: latest.automation.fill || false,
      },
    },
  );

  return (
    <PaperSection
      heading='Supply Tank Automation'
      mb={2}
      subheading={mqttDefinitionIrrigationConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='irrigation'
        min={latest.supply?.min}
        max={latest.supply?.max}
        fill={latest.automation.fill}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          // Replace controlLifecycle.run with dsLifeCycle.run
          dsLifeCycle.run({
            supply: {
              min: data.supply.min,
              max: data.supply.max,
            },
            automation: {
              fill: data.automation.fill,
            },
          }).then(() => {
            activityLog.add(
              `Set data to ${JSON.stringify(data)}`,
              'OPERATION',
            );
          });

          activityLog.add(
            `Set supply tank automation to ${data.automation.fill} and min/max to ${data.supply.min}/${data.supply.max}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          supply: yup.object({
            min: yup
              .number()
              .min(0)
              .max(100)
              .lessThan(yup.ref('max'))
              .required(),
            max: yup
              .number()
              .min(0)
              .max(100)
              .moreThan(yup.ref('min'))
              .required(),
          }),

          automation: yup.object({
            fill: yup.boolean().required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <CheckboxElement label='Automation Fill' name='automation.fill' />
          <Stack spacing={2} direction='row'>
            <TextFieldElement label='Supply MIN' name='supply.min' />
            <TextFieldElement label='Supply MAX' name='supply.max' />
          </Stack>
          <p>
            When fill automation is enabled, mains valve will automatically
            close when supply tank reaches MAX
          </p>
          {/* Replace SubmitControl with SubmitDesiredState */}
          <SubmitDesiredState controlHook={dsLifeCycle}>
            Send to device
          </SubmitDesiredState>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormIrrigationConfigFill;