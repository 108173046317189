import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { AnyObject } from 'yup';

type NavSideParams = {
  path?: string;
  queryString?: Record<string, string | number | boolean>;
};

const parseHash = (hash: string): {
  name: string;
  parameter: string;
  queryParams: URLSearchParams;
} => {
  // Handle empty string
  hash = hash || '';

  // Split path and query parts
  const [path = '', queryString = ''] = hash.split('?');

  // Parse path parts safely
  const pathParts = path.split('/').filter(Boolean);
  const name = pathParts[0] || '';
  const parameter = pathParts[1] || '';

  // Create query parameters object
  const queryParams = new URLSearchParams(queryString);

  return { name, parameter, queryParams };
};

const useNavSide = () => {

  const navigation = useNavigate();

  const { pathname, hash } = useLocation();

  const _newApi = useCallback((sidebarPath: NavSideParams) => {

    const { queryString } = sidebarPath;

    console.log(pathname, 'is pathname');

    if (queryString) {
      console.log('AQ', queryString, parseHash(hash));
      const existingParams = parseHash(hash).queryParams;
      const newParams = new URLSearchParams(queryString as Record<string, string>);

      for (const [key, value] of newParams.entries()) {
        existingParams.set(key, value);
      }

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _oldApi = useCallback(
    (sidebarPath: string | undefined, queryString: AnyObject | undefined) => {
      if (!sidebarPath) {
        // if you call this without a parameter, it removes all params
        // closing the sidebar
        navigation(pathname);
      } else {
        const query = new URLSearchParams(queryString).toString();

        if (query) {
          sidebarPath += `?${query}`;
        }

        const path = pathname.endsWith('/')
          ? `${pathname.slice(0, -1)}/#/${sidebarPath}`
          : `${pathname}/#/${sidebarPath}`;

        navigation(path);
      }
    },
    [navigation, pathname],
  );

  const _nav = useCallback(
    (sidebarPath?: undefined | string | NavSideParams, queryString?: AnyObject) => {
      if (typeof sidebarPath === 'object') {
        _newApi(sidebarPath);
      } else {
        _oldApi(sidebarPath as string | undefined, queryString);
      }
    },
    [_oldApi, _newApi],
  );

  return _nav;
};

export default useNavSide;
