import { Stack } from '@mui/system';
import {  TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, LightingDeviceStateUnflattened } from '@phyllome/common';
import {  mqttDefinitionLightingSet } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Alert } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { useMemo } from 'react';

function daysIntoYear(date:Date) {
  return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
}

const FormLightingSetStartDay = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LightingDeviceStateUnflattened>;
}) => {

  const deviceId = deviceState.deviceId;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const controlLifecycle = useControlSend(mqttDefinitionLightingSet, deviceId);
  const activityLog = useActivityLog(`device/lighting/${deviceId}/notes`);
  const startDay = daysIntoYear(new Date()) - latest.currentDay;
  const defaultValues = formCreateDefaultValues(mqttDefinitionLightingSet, {
    startDay: startDay || 1,
  });

  return (
    <PaperSection
      heading='Lighting zone start day'
      mb={2}
      subheading={mqttDefinitionLightingSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              startDay: data.startDay,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Set lighting zone ${deviceId} startDay to ${data.startDay }`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({})}
      >
        <Stack spacing={2}>
          <Alert severity='info'>
            Normal operating procedure is to control lighting zone start day state via
            season control
          </Alert>
          <TextFieldElement label='Lightng Zone Start Day' name='startDay' />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormLightingSetStartDay;
