import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { DatabaseDevices, TransferDeviceStateUnflattened } from '@phyllome/common';
import unflatten from '../../helpers/unflatten';
import ObjectToTable from '../../components/ObjectToTable/ObjectToTable';
import { useMemo } from 'react';

const LatestTransfer = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<TransferDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  return (
    <PaperSection
      heading='Latest Device Data'
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ObjectToTable data={unflatten(latest) as any} />
    </PaperSection>
  );
};

export default LatestTransfer;
