import type { Co2DeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import ObjectToTable from '../../components/ObjectToTable/ObjectToTable';
import { useMemo } from 'react';

const LatestCo2 = ({   deviceState }: {
  deviceState: DatabaseDevices<Co2DeviceStateUnflattened>;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  return (
    <PaperSection
      heading='Latest Co2 Device Data'
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ObjectToTable data={latest as any} />

    </PaperSection>
  );
};

export default LatestCo2;
