import type { DatabaseDevices, LightingDeviceStateUnflattened } from '@phyllome/common';
import FormLightingSetSchedule from './forms/FormLightingSetSchedule';
import LatestZoneInfo from './LatestLightingInfo';
import FormLightingEnable from './forms/FormLightingEnable';
import FormLightingOnOff from './forms/FormLightingOnOff';
import FormLightingSetStartDay from './forms/FormLightingSetStartDay';

const ControlLighting = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LightingDeviceStateUnflattened>;
}) => {

  return (
    <>
      <LatestZoneInfo deviceState={deviceState} />
      <FormLightingOnOff  deviceState={deviceState} />
      <FormLightingEnable  deviceState={deviceState} />
      <FormLightingSetStartDay  deviceState={deviceState} />
      <FormLightingSetSchedule  deviceState={deviceState} />
    </>
  );
};

export default ControlLighting;
