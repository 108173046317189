import { LoadingButton } from '@mui/lab';
import type { LoadingButtonProps } from '@mui/lab';
import type { FC } from 'react';

type ExtraProps = {
  isBusy: boolean;
};

const ButtonWait: FC<LoadingButtonProps & ExtraProps> = ({
  isBusy,
  children,
  color,
  ...rest
}) => {
  return (
    <LoadingButton
      {...rest}
      loading={isBusy}
      loadingPosition='start'
      variant='contained'
      color={color}
    >
      {children}
    </LoadingButton>
  );
};

export default ButtonWait;
