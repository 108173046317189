import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';
import type { DatabaseDevices, FertigatorDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionFertigatorConfigSet,
} from '@phyllome/common';
import PhormContainer from '../../../forms/PhormContainer';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import { Typography } from '@mui/material';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormFertigatorConfigSetDosing = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<FertigatorDeviceStateUnflattened> ;
}) => {
  const deviceId = deviceState.deviceId;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorConfigSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionFertigatorConfigSet,
    {
      coefficients: {
        ec: {
          a: latest.coefficients?.ec?.a || 0,
          b: latest.coefficients?.ec?.b || 0,
          c: latest.coefficients?.ec?.c || 0,
        },
        acid: {
          a: latest.coefficients?.acid?.a || 0,
          b: latest.coefficients?.acid?.b || 0,
          c: latest.coefficients?.acid?.c || 0,
        },
        base: {
          a: latest.coefficients?.base?.a || 0,
          b: latest.coefficients?.base?.b || 0,
          c: latest.coefficients?.base?.c || 0,
        },
      },
    },
  );

  return (
    <PaperSection
      heading='Dosing quadratic'
      mb={2}
      subheading={mqttDefinitionFertigatorConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='fertigator'
        dosereca={latest.coefficients?.ec?.a}
        doserecb={latest.coefficients?.ec?.b}
        doserecc={latest.coefficients?.ec?.c}
        doseracida={latest.coefficients?.acid?.a}
        doseracidb={latest.coefficients?.acid?.b}
        doseracidc={latest.coefficients?.acid?.c}
        doserbasea={latest.coefficients?.base?.a}
        doserbaseb={latest.coefficients?.base?.b}
        doserbasec={latest.coefficients?.base?.c}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              dose: {
                ec: {
                  a: data?.coefficients.ec?.a || 0,
                  b: data?.coefficients.ec?.b || 0,
                  c: data?.coefficients.ec?.c || 0,
                },
                acid: {
                  a: data?.coefficients.acid?.a || 0,
                  b: data?.coefficients.acid?.b || 0,
                  c: data?.coefficients.acid?.c || 0,
                },
                base: {
                  a: data?.coefficients.base?.a || 0,
                  b: data?.coefficients.base?.b || 0,
                  c: data?.coefficients.base?.c || 0,
                },
              },
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            'Updated device dosing quadratic configuration',
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          coefficients: yup.object({
            ec: yup.object({
              a: yup.number().required(),
              b: yup.number().required(),
              c: yup.number().required(),
            }),
            acid: yup.object({
              a: yup.number().required(),
              b: yup.number().required(),
              c: yup.number().required(),
            }),
            base: yup.object({
              a: yup.number().required(),
              b: yup.number().required(),
              c: yup.number().required(),
            }),
          }),
        })}
      >
        <Stack spacing={2}>
          <Typography variant='h6'>Dosing EC quadratic</Typography>
          <Typography variant='body1'>ax2 + bx + c</Typography>
          <Stack spacing={2} direction='row'>
            <TextFieldElement label='Dose EC A' name='coefficients.ec.a' />
            <TextFieldElement label='Dose EC B' name='coefficients.ec.b' />
            <TextFieldElement label='Dose EC C' name='coefficients.ec.c' />
          </Stack>
          <Typography variant='h6'>Dosing Acid quadratic</Typography>
          <Typography variant='body1'>ax2 + bx + c</Typography>
          <Stack spacing={2} direction='row'>
            <TextFieldElement label='Dose Acid A' name='coefficients.acid.a' />
            <TextFieldElement label='Dose Acid B' name='coefficients.acid.b' />
            <TextFieldElement label='Dose Acid C' name='coefficients.acid.c' />
          </Stack>
          <Typography variant='h6'>Dosing Base quadratic</Typography>
          <Typography variant='body1'>ax2 + bx + c</Typography>
          <Stack spacing={2} direction='row'>
            <TextFieldElement label='Dose Base A' name='coefficients.base.a' />
            <TextFieldElement label='Dose Base B' name='coefficients.base.b' />
            <TextFieldElement label='Dose Base C' name='coefficients.base.c' />
          </Stack>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorConfigSetDosing;
