import type { DatabaseSeason, WithId } from '@phyllome/common';
import useFetch from '../useFetch';
import { normaliseSeasonData } from '../../../pages/Broker/Seasons/hooks/useSeason';

type SeasonFetchReturn = {
  status: string;
  data: WithId<DatabaseSeason>[];
  pagination: {
    page: number;
    limit: number;
    totalItems: number;
    totalPages: number;
    hasNextPage: boolean;
    hasPrevPage: boolean;
  };
};

const useSeasons = ({
  active = true,
}: {
  active: boolean

})  => {

  const fetchSeasons = useFetch<SeasonFetchReturn>(`/farm/:brokerId/seasons?active=${active}&page=1&limit=100`, {
    default: {
      status: 'loading',
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        totalItems: 0,
        totalPages: 0,
        hasNextPage: false,
        hasPrevPage: false,
      },
    },
    interval: 5000,
  });

  const ret = fetchSeasons.data.data.map((season) => normaliseSeasonData(season));

  return {
    ...fetchSeasons,
    data: ret,
  };
};

export default useSeasons;
