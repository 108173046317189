import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import useUserToken from '../../data/hooks/useUserToken';
import getTabId from './getTabId';
import type WebSocketProvider from './types';
import { useAtom } from 'jotai';
import websocketAtom from '../../data/atoms/websocketAtom';

const useWebSocketProvider = () => {
  const userToken = useUserToken();
  const [websocket, setWebSocketAtom] = useAtom(websocketAtom);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    // Early returns if no authentication or already have a socket instance
    if (!userToken) return;

    // Only create a new socket if one doesn't exist
    if (!websocket) {
      const newSocket = createSocket(userToken);

      setWebSocketAtom(newSocket);
    }

    // Setup event listeners for connection state
    const handleConnect = () => {
      console.log('WEBSOCKET CONNECTED');
      setIsConnected(true);
    };

    const handleDisconnect = () => {
      console.log('WEBSOCKET DISCONNECTED');
      setIsConnected(false);
    };

    const handleConnectError = (error: Error) => {
      console.error('Connection error:', error.message);
      setIsConnected(false);
    };

    // Add event listeners
    websocket?.on('connect', handleConnect);
    websocket?.on('disconnect', handleDisconnect);
    websocket?.on('connect_error', handleConnectError);

    // Set initial connected state
    setIsConnected(websocket?.connected || false);

    // Connect if not already connected
    if (websocket && !websocket.connected) {
      websocket.connect();
    }
    // Cleanup event listeners on unmount
    return () => {
      websocket?.off('connect', handleConnect);
      websocket?.off('disconnect', handleDisconnect);
      websocket?.off('connect_error', handleConnectError);
    };
  }, [userToken, websocket, setWebSocketAtom]);
  return { isConnected };
};

// Helper function to create socket
const createSocket = (token: string): WebSocketProvider => {
  const getWSEndpoint = () => {
    return process.env.NODE_ENV === 'production'
      ? 'wss://websocket.phyllome.io'
      : 'ws://127.0.0.1:8090';
  };

  return io(getWSEndpoint(), {
    // transports: ['websocket'],
    // upgrade: false,
    autoConnect: false,
    auth: {
      tabId: getTabId(),
      token,
    },
    extraHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default useWebSocketProvider;