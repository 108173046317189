import type { FallbackProps } from 'react-error-boundary';
import Logo from './components/Logo/Logo';
import { Button } from '@mui/material';

const ErrorMsg = ({ error }: FallbackProps) => {

  return (
    <div role='alert' >
      <div
        style={{
          background: '#f1f2f4 url(/images/broken.jpg) bottom no-repeat',
          height: '200px',
          backgroundSize: '800px',
          textAlign: 'left',
          padding: '17px',
        }}
      >
        <Logo width='200px'/>
        <h1>
          SOMETHING CRASHED, OOPS
        </h1>
      </div>
      <div style={{ padding: '17px' }}>
        <pre style={{ color: 'red' }}>{error.message}</pre>
        <pre>{error.stack}</pre>
        <Button variant='contained' onClick={() => window.location.href = '/'}>Go to Home</Button>
      </div>
    </div>
  );
};

export default ErrorMsg;