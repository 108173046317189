import { Typography, Stack } from '@mui/material';
import { TextFieldElement, SelectElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import type { AirDeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { mqttDefinitionAirConfigGet, mqttDefinitionAirConfigSet } from '@phyllome/common';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import useActivityLog from '../../../data/hooks/useActivityLog';
import useSetDesiredState from '../../../data/hooks/useSetDesiredState';
import SubmitDesiredState from '../../../forms/SubmitDesiredState';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormAirConfigSetLabel = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<AirDeviceStateUnflattened>;
}) => {
  const { deviceId } = deviceState;
  const state = useMemo(() => deviceState.properties, [deviceState.properties]);

  const dsLifeCycle = useSetDesiredState({
    deviceId: deviceId,
    deviceType: 'air',
  });

  const activityLog = useActivityLog(`device/air/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionAirConfigSet, {
    label: state?.label,
    area: state?.area,
  });

  return (
    <PaperSection
      mb={2}
      heading='Area and Label'
      subheading={mqttDefinitionAirConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='air'
        definition={mqttDefinitionAirConfigGet}
        label={state?.label}
        area={state?.area}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          dsLifeCycle.run({
            label: data.label,
            area: data.area,
          }).then(() => {
            activityLog.add(
              `Set data to ${JSON.stringify(data)}`,
              'OPERATION',
            );
          });

        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          label: yup
            .string()
            .test(
              'len',
              '20 characters max',
              (val) => (val?.length || 0) <= 20,
            ),
          area: yup.number().required(),
        })}
      >
        <Stack spacing={2}>
          <Typography variant='body1'>
            The relevant farm CO2 controller subscribes to all airmonitors where
            area is set to Growroom. Other airmonitor data is ignored.
          </Typography>
          <SelectElement
            label='Area'
            name='area'
            options={[
              {
                id: '0',
                label: 'Default (location not set)',
              },
              {
                id: '1',
                label: 'Growroom (used by co2 system)',
              },
              {
                id: '2',
                label: 'External (outside growroom)',
              },
            ]}
          />
          <Typography variant='body1'>
            Label is used to provide human readable context to the device
          </Typography>
          <TextFieldElement label='Label' name='label' type='text' />
          <SubmitDesiredState controlHook={dsLifeCycle}>
            Send to device
          </SubmitDesiredState>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormAirConfigSetLabel;
