import GenericPing from '../Generic/control/GenericPing';
import type { AirDeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';

const ControlAir = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<AirDeviceStateUnflattened>;
}) => {
  const { deviceId } = deviceState;

  return (
    <div>
      <GenericPing
        deviceId={deviceId}
        deviceType='air'
      />
    </div>
  );
};

export default ControlAir;
