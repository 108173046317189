import type { CloudActionTimeseries } from '@phyllome/common';
import { useContext, useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { ChartContext } from './ChartContext/ChartContext';
import type { FetchResult } from '../../data/hooks/useFetch';
import useFetch from '../../data/hooks/useFetch';

type TimeSeriesQueryParams = Omit<
  CloudActionTimeseries['payload'],
  'beginDate' | 'endDate'
> & {
  dates?:{
    beginDate: Date;
    endDate: Date;
  }
};
export type TimeSeriesData = {
  status: FetchResult<any>['status'];
  isInitiating: boolean;
  isUnsufficientData: boolean;
  isUpdating: boolean;
  data: {
    x: Date;
    y: number;
  }[];
};

const useTimeSeriesPoller = (params: TimeSeriesQueryParams): TimeSeriesData => {
  const {
    state: { beginDate, endDate },
  } = useContext(ChartContext);

  const [data, setData] = useState<TimeSeriesData['data']>([]);

  let realBeginDate;
  let realEndDate;

  if (params.dates) {
    realBeginDate = params.dates?.beginDate;
    realEndDate = params.dates?.endDate;
  } else {
    realBeginDate = beginDate;
    realEndDate = endDate;
  }

  const deviceType = params.deviceType;
  const deviceId = params.deviceId;
  const origin = params.origin;
  const type = params.type;

  const fetch = useFetch<{ status: string, msg: { timestamp: string, value: number }[] }>(`/farm/:brokerId/timeseries/${deviceType}/${deviceId}/${origin}/${type}?beginDate=${realBeginDate.toISOString()}&endDate=${realEndDate.toISOString()}`);

  const rawData = fetch?.data?.msg;

  useEffect(() => {
    if (isArray(rawData)) {
      setData(
        rawData.map((row) => {
          return {
            x: new Date(row.timestamp),
            y: row.value,
          };
        }),
      );
    }
  }, [rawData]);

  const isInitiating = fetch.status !== 'success' && data.length === 0;
  const isUnsufficientData = fetch.status === 'success' && data.length === 0;
  const isUpdating = fetch.status !== 'success' && data.length > 0;

  return {
    data,
    status: fetch.status,
    isInitiating,
    isUnsufficientData,
    isUpdating,
  };
};

export default useTimeSeriesPoller;
