import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import { Box } from '@mui/material';
import type { DatabaseDevices, TransferDeviceStateUnflattened } from '@phyllome/common';
import CachedIcon from '@mui/icons-material/Cached';
import ExpandIcon from '@mui/icons-material/Expand';
import ErrorIcon from '@mui/icons-material/Error';
import { useMemo } from 'react';

const LatestTransferInfo = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<TransferDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  return (
    <PaperSection
      mb={2}
      heading='Latest Transfer Device Info'
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <Box mb={2}>
        <ChipBool
          data={latest.inhibit !== undefined && latest.inhibit === true}
          label='Inhibit'
          color='earth3'
          icon={<ErrorIcon />}
        />
        <ChipBool
          data={latest.safety === undefined || latest.safety === false}
          label='Safety Stop'
          color='earth3'
          icon={<ErrorIcon />}
        />

      </Box>
      <Box mb={2}>
        <ChipBool
          data={latest.conveyors.hatch}
          label='Hatch Conveyor'
          color='primary'
          icon={<CachedIcon />}
        />
        <ChipBool
          data={latest.conveyors.infeed}
          label='Infeed Conveyor'
          color='primary'
          icon={<CachedIcon />}
        />
        <ChipBool
          data={latest.conveyors.outfeed}
          label='Outfeed Conveyor'
          color='primary'
          icon={<CachedIcon />}
        />
      </Box>
      <Box mb={2}>
        <ChipBool
          data={latest.actuators.sweeper_infeed}
          label='Sweeper Infeed'
          color='primary'
          icon={<ExpandIcon />}
        />
        <ChipBool
          data={latest.actuators.sweeper_outfeed}
          label='Sweeper Outfeed'
          color='primary'
          icon={<ExpandIcon />}
        />
        <ChipBool
          data={latest.actuators.roller_infeed}
          label='Roller Infeed'
          color='primary'
          icon={<ExpandIcon />}
        />
        <ChipBool
          data={latest.actuators.roller_outfeed}
          label='Roller Outfeed'
          color='primary'
          icon={<ExpandIcon />}
        />
        <ChipBool
          data={latest.actuators.hatch}
          label='Door Hatch'
          color='primary'
          icon={<ExpandIcon />}
        />
      </Box>
    </PaperSection>
  );
};

export default LatestTransferInfo;
