import type { DatabaseDevices, FertigatorDeviceStateUnflattened } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import type {
  HeroItemType,
} from '../../components/puregui/HeroItem/HeroGroup';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import { Box } from '@mui/material';
import { useMemo } from 'react';

const LatestFertigatorState = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<FertigatorDeviceStateUnflattened> ;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const heroItems: HeroItemType[] = [
    {
      label: 'EC',
      text: (Math.round(Number(latest.sample.ec * 10)) / 10).toString(),
      sup: 'mS/cm',
    },
    {
      label: 'Ph',
      text: (Math.round(Number(latest.sample.ph * 10)) / 10).toString(),
      sup: ' ', // space required to keep vertical alignment
    },
    {
      label: 'Temperature',
      text: (
        Math.round(Number(latest.sample.temperature * 10)) / 10
      ).toString(),
      sup: '\u00b0C',
    },
    {
      label: 'Oxygen',
      text: (Math.round(Number(latest.sample.do * 10)) / 10).toString(),
      sup: 'mg/L',
    },
    {
      label: 'ORP',
      text: (Math.round(Number(latest.sample.orp))).toString(),
      sup: 'mV',
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading='Latest Fertigator Info'
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <Box
        mb={4}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center',
        }}
      >
        <HeroGroup items={heroItems} />
      </Box>
      <Box mb='2'>
        <ChipBool data={latest.ec?.enable} label='EC enabled' color='primary' />
        <ChipBool data={latest.acid?.enable} label='Acid enabled' color='primary' />
        <ChipBool
          data={latest.peroxide?.enable}
          label='Peroxide enabled'
          color='primary'
        /><ChipBool
          data={latest.pump?.enable}
          label='Pump enabled'
          color='primary'
        />
      </Box>
    </PaperSection>
  );
};

export default LatestFertigatorState;
