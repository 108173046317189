import GenericPing from '../Generic/control/GenericPing';
import LatestLiftInfo from './LatestLiftInfo';
import type { DatabaseDevices, LiftDeviceStateUnflattened } from '@phyllome/common';
import FormLiftMove from './forms/FormLiftMove';

const ControlLift = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LiftDeviceStateUnflattened>;
}) => {
  const { deviceId } = deviceState;

  return (
    <>
      <LatestLiftInfo deviceState={deviceState} />
      <FormLiftMove deviceState={deviceState} />
      <div>
        <GenericPing
          deviceId={deviceId}
          deviceType='lift'
        />
      </div>
    </>
  );
};

export default ControlLift;
