import { Stack } from '@mui/system';
import { CheckboxElement, TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, IrrigationDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionIrrigationConfigSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormIrrigationConfigOzone = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<IrrigationDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionIrrigationConfigSet,
    deviceId,
  );

  const activityLog = useActivityLog(`device/irrigation/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionIrrigationConfigSet,
    {
      ozone: {
        min: latest.ozone?.min || 0,
        max: latest.ozone?.max || 0,
      },
      automation: {
        ozone: latest.automation.ozone || false,
      },
    },
  );

  return (
    <PaperSection
      heading='Ozone Automation'
      mb={2}
      subheading={mqttDefinitionIrrigationConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='irrigation'
        min={latest.ozone?.min}
        max={latest.ozone?.max}
        ozone={latest.automation.ozone}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              ozone: {
                min: data.ozone.min,
                max: data.ozone.max,
              },
              automation: {
                ozone: data.automation.ozone,
              },
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Set ozone automation to ${data.automation.ozone} and min/max to ${data.ozone.min}/${data.ozone.max}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          ozone: yup.object({
            min: yup
              .number()
              .min(0)
              .max(100)
              .lessThan(yup.ref('max'))
              .required(),
            max: yup
              .number()
              .min(0)
              .max(100)
              .moreThan(yup.ref('min'))
              .required(),
          }),
          automation: yup.object({
            ozone: yup.boolean().required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <CheckboxElement label='Automation Ozone' name='automation.ozone' />
          <Stack spacing={2} direction='row'>
            <TextFieldElement label='Ozone MIN' name='ozone.min' />
            <TextFieldElement label='Ozone MAX' name='ozone.max' />
          </Stack>
          <p>
            When ozone automation is enabled, ozone system will operate between
            MIN and MAX return tank levels
          </p>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormIrrigationConfigOzone;
