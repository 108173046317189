import type { DatabaseDevices, FertigatorDeviceStateUnflattened } from '@phyllome/common';
import FormFertigatorConfigSetMinimums from './forms/FormFertigatorConfigSetMinimums';
import FormFertigatorConfigSetFlowrates from './forms/FormFertigatorConfigSetFlowrates';
import FormFertigatorConfigSetDosing from './forms/FormFertigatorConfigSetDosing';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import { formatBytes } from '../../helpers/formatBytes';
import { formatSeconds } from '../../helpers/formatSeconds';
import { Alert } from '@mui/material';
import { useMemo } from 'react';

const ConfigFertigator = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<FertigatorDeviceStateUnflattened> ;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;

  if (!latest) return <Alert severity='info'>No latest data</Alert>;
  return (
    <>
      <PaperSection heading='Fertigator Controller Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceId}</LabelValue>
        <LabelValue label='Firmware'>{latest.firmware}</LabelValue>
        <LabelValue label='Build date'>{latest.build}</LabelValue>
        <LabelValue label='Network IP'>{latest.network.ip}</LabelValue>
        <LabelValue label='Free memory'>
          {formatBytes(latest.freememory)}
        </LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormFertigatorConfigSetFlowrates deviceState={deviceState} />
      <FormFertigatorConfigSetMinimums deviceState={deviceState} />
      <FormFertigatorConfigSetDosing deviceState={deviceState} />
      <GenericConfigGet
        deviceId={deviceId}
        deviceType='fertigator'
      />
    </>
  );
};

export default ConfigFertigator;
