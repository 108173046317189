import type { TraybotDeviceStateUnflattened } from '@phyllome/common';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import Graph from '../../components/Favourites/Graph';
import { useCurrentSidebarDeviceData } from '../../data/hooks/useCurrentSidebarDeviceData';

const GraphTraybot  = () => {

  const { brokerId, deviceId } = useCurrentSidebarDeviceData<TraybotDeviceStateUnflattened>();

  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        <Graph name='traybotState' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='traybotBattery' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='traybotNetwork' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='traybotBlower' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='traybotCharger' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='traybotEdge' brokerId={brokerId} deviceId={deviceId} />
        <Graph
          name='traybotLiftRaised'
          brokerId={brokerId}
          deviceId={deviceId}
        />
      </Stacker>
    </ChartProvider>
  );
};

export default GraphTraybot;
