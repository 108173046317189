import { SwitchElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import { Stack } from '@mui/system';
import type { TraybotDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionTraybotScan,
} from '@phyllome/common';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { useCurrentSidebarDeviceData } from '../../../data/hooks/useCurrentSidebarDeviceData';

const FormTraybotControlScan = () => {

  const { properties: latest, deviceId } = useCurrentSidebarDeviceData<TraybotDeviceStateUnflattened>();

  const columnId = latest.columnId;
  const controlLifecycle = useControlSend(
    mqttDefinitionTraybotScan,
    String(columnId),
  );

  const activityLog = useActivityLog(`device/traybot/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTraybotScan,
    {
      enable: latest?.rfid || false,
    },
  );

  return (
    <PaperSection
      heading='Traybot RFID Scanner Control'
      mb={2}
      subheading={mqttDefinitionTraybotScan.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              enable: data.enable,
            },
            topicParams: {
              deviceId: latest?.deviceId,
            },
          });
          activityLog.add(`${data.enable ? 'Enabled' : 'Disabled'} rfid scanner`, 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({

        })}
      >
        <Stack spacing={2}>
          <SwitchElement
            label='RFID Scanner'
            name='enable'
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTraybotControlScan;
