import { Box, CircularProgress } from '@mui/material';
import * as React from 'react';
import { useAuth  } from 'reactfire';
import type { FavouriteName } from '../Favourites/collectionFavourites';
import {
  getFavouriteGraph,
} from '../Favourites/collectionFavourites';
import StarIcon from '@mui/icons-material/Star';
import  useUserFavourite from './useUserFavourite';

type IFavGraphProps = {
  deviceId: string;
  brokerId: string;
  name: FavouriteName;
};

const FavGraph = ({ deviceId, brokerId, name }: IFavGraphProps) => {
  const auth = useAuth();

  const { deviceType, field, subType, type } = getFavouriteGraph(name)({
    brokerId,
    deviceId,
  });

  const favData = useUserFavourite({
    brokerId,
    deviceId,
    deviceType,
    field,
    uid: auth.currentUser?.uid || '',
  });

  if (favData.data) {
    return (

      <Box
        title='Remove from dashboard'
        sx={{
          display: 'inline-block',
          color: 'yellow',
          '&:hover': {
            color: 'white',
          },
          cursor: 'pointer',
        }}
        onClick={() => {
          favData.deleteFavorite();

        }}
      >
        <StarIcon />
      </Box>

    );
  } else {
    return (

      <Box
        sx={{
          display: 'inline-block',

          color: '#888',
          '&:hover': {
            color: 'white',
          },
          cursor: 'pointer',
        }}
        onClick={() => {
          return favData.addFavorite({
            name: name as string,
            type,
            brokerId,
            location: 'general',
            deviceId,
            deviceType,
            subType,
            field,
            order: 999,
          });

        }}
      >
        <StarIcon />
      </Box>

    );
  }
};

export default FavGraph;

export const FavGraphWrap = ({
  children,
  height,
  isUpdating,
}: {
  children?: React.ReactNode;
  isUpdating?: boolean;
  height?: number;
}) => {
  const _height = height || 300;

  if (isUpdating) {
    return (
      <Box
        sx={{
          width: '100%',
          textAlign: 'right',
          position: 'relative',
          top: '-46px',
        }}
      >
        {children}
        <div
          style={{
            position: 'absolute',
            right: '0',
            top: _height / 2 + 80,
            bottom: '0',
            left: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <CircularProgress size='80px' sx={{ opacity: 0.2, color: 'black' }} />
        </div>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: '100%',
          textAlign: 'right',
          position: 'relative',
          top: '-46px',
        }}
      >
        {children}
      </Box>
    );
  }
};

/**
 *
 *
  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "right",
        position: "relative",
        top: "-46px",
      }}
    >
      {children}
    </Box>
  );
 */
