import { Stack } from '@mui/system';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type {  DatabaseDevices, LightingDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionLightingOff,
  mqttDefinitionLightingOn,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { useMemo } from 'react';

const FormLightingOnOff = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LightingDeviceStateUnflattened>;
}) => {

  const deviceId = deviceState.deviceId;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const controlLifecycleOn = useControlSend(mqttDefinitionLightingOn, deviceId);
  const controlLifecycleOff = useControlSend(mqttDefinitionLightingOff, deviceId);
  const activityLog = useActivityLog(`device/lighting/${deviceId}/notes`);

  const defaultValuesZoneOn = formCreateDefaultValues(mqttDefinitionLightingOn, {});
  const defaultValuesZoneOff = formCreateDefaultValues(
    mqttDefinitionLightingOff,
    {},
  );

  return (
    <PaperSection
      heading='Manual zone control'
      mb={2}
      subheading={
        mqttDefinitionLightingOn.topic + ' and ' + mqttDefinitionLightingOff.topic
      }
    >
      <Stack spacing={2} direction='row'>
        {/* ON */}
        <PhormContainer
          defaultValues={defaultValuesZoneOn}
          onSubmit={() => {
            controlLifecycleOn.run({
              payload: {},
              topicParams: {
                deviceId: deviceId,
                zoneId: deviceId,
              },
            });
            activityLog.add('Turned on', 'OPERATION');
          }}
          onError={(error) => console.log(error)}
          schema={yup.object({})}
        >
          <SubmitControl
            controlHook={controlLifecycleOn}
            variant={latest.active ? 'outlined' : 'contained'}
            color={latest.active ? 'secondary' : 'primary'}
          >
            Turn ON
          </SubmitControl>
        </PhormContainer>
        {/* OFF */}
        <PhormContainer
          defaultValues={defaultValuesZoneOff}
          onSubmit={() => {
            controlLifecycleOff.run({
              payload: {},
              topicParams: {
                deviceId: deviceId,
                zoneId: deviceId,
              },
            });
            activityLog.add('Turned off', 'OPERATION');
          }}
          onError={(error) => console.log(error)}
          schema={yup.object({})}
        >
          <SubmitControl
            controlHook={controlLifecycleOff}
            variant={latest.active ? 'contained' : 'outlined'}
            color={latest.active ? 'primary' : 'secondary'}
          >
            Turn OFF
          </SubmitControl>
        </PhormContainer>
      </Stack>
    </PaperSection>
  );
};

export default FormLightingOnOff;
