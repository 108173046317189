import type { FC } from 'react';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import dateToString, { dateFormatTypes } from '../../helpers/dateToString';
import getUIInfoBasedOnAge from '../../pages/Broker/Sprinklers/helpers/getUIInfoBasedOnAge';
import ObjectToTable from '../../components/ObjectToTable/ObjectToTable';
import unflatten from '../../helpers/unflatten';
import type { DatabaseDevices, NDSDeviceStateUnflattened } from '@phyllome/common';

type IProps = {
  latest: DatabaseDevices<NDSDeviceStateUnflattened>;
};

const LatestNDS: FC<IProps> = ({ latest }) => {
  const now = new Date();
  const stuff = getUIInfoBasedOnAge(now, new Date(latest.properties.timestamp));

  return (
    <>
      <PaperSection
        heading='Latest NDS Data'
        subheading={`Last Updated: ${dateToString(
          latest.properties.timestamp,
          dateFormatTypes.TRUNCATED,
        )} (${stuff.age}
        minutes ago)`}
      >
        <ObjectToTable data={unflatten(latest) as any} />
      </PaperSection>
    </>
  );
};

export default LatestNDS;
