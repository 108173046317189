import type { DatabaseDevices, FertigatorDeviceStateUnflattened } from '@phyllome/common';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import FormFertigatorCalibrateEC from './forms/FormFertigatorCalibrateEC';
import FormFertigatorCalibrateECProbe from './forms/FormFertigatorCalibrateECProbe';
import FormFertigatorCalibratePH from './forms/FormFertigatorCalibratePH';
import FormFertigatorCalibrateORP from './forms/FormFertigatorCalibrateORP';
import { Alert } from '@mui/material';
import FormFertigatorCalibrateTemperature from './forms/FormFertigatorCalibrateTemperature';
import FormFertigatorCalibrateDissolvedOxygen from './forms/FormFertigatorCalibrateDissolvedOxygen';
import { useMemo } from 'react';

const CalibrateFertigator = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<FertigatorDeviceStateUnflattened> ;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  if (!latest) return <Alert severity='info'>No latest data</Alert>;
  return (
    <>
      <FormFertigatorCalibrateDissolvedOxygen deviceState={deviceState} />
      <FormFertigatorCalibrateECProbe deviceState={deviceState} />
      <FormFertigatorCalibrateEC deviceState={deviceState} />
      <FormFertigatorCalibratePH deviceState={deviceState} />
      <FormFertigatorCalibrateORP deviceState={deviceState} />
      <FormFertigatorCalibrateTemperature deviceState={deviceState} />
      <GenericConfigGet
        deviceId={latest.deviceId}
        deviceType='fertigator'
      />
    </>
  );
};

export default CalibrateFertigator;
