import { useContext, useReducer, useMemo, useEffect } from 'react';
import * as React from 'react';
import AppDrawer from './AppDrawer';
import { useLocation, useNavigate } from 'react-router-dom';

interface IState {
  isOpen: boolean;
  hash: string;
}
export enum drawActions {
  OPEN,
  CLOSE,
  TOGGLE,
  SET_HASH,
}

export type AppDrawerAction = {
  type: drawActions;
  payload?: string;
};

interface IAppContext {
  state: IState;
  dispatch: React.Dispatch<AppDrawerAction>;
}

const initialState: IState = {
  isOpen: false,
  hash: '',
};

const StoreContext = React.createContext<IAppContext>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (state: IState, action: AppDrawerAction) => {
  const actionHandlers = {
    [drawActions.OPEN]: () => ({ ...state, isOpen: true }),
    [drawActions.CLOSE]: () => ({ ...state, isOpen: false }),
    [drawActions.TOGGLE]: () => ({ ...state, isOpen: !state.isOpen }),
    [drawActions.SET_HASH]: () => ({
      ...state,
      hash: action.payload || state.hash,
    }),
  };

  const actionHandler = actionHandlers[action.type];

  if (actionHandler) {
    return actionHandler();
  }

  throw new Error(`Unhandled action type: ${action.type}`);
};

export const AppDrawerProvider = (props: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    hash: useLocation().hash,
  });
  const { pathname, hash } = useLocation();
  const navigation = useNavigate();

  useEffect(() => {
    dispatch({ type: drawActions.SET_HASH, payload: hash });
  }, [hash]);

  const onClose = useMemo(() => {
    return () => {
      dispatch({ type: drawActions.CLOSE });
      navigation(pathname);
    };
  }, [dispatch, navigation, pathname]);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {props.children}
      <AppDrawer onClose={onClose} />
    </StoreContext.Provider>
  );
};

export const useAppDrawer = () => useContext(StoreContext);

export default AppDrawerProvider;
