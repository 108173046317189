import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { HarvesterDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionHarvesterConfigSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';

const FormHarvesterConfigSet = ({
  latest,
}: {
  latest: HarvesterDeviceStateUnflattened;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionHarvesterConfigSet,
    deviceId,
  );

  return (
    <PaperSection
      heading='Harvester Configuration'
      subheading={mqttDefinitionHarvesterConfigSet.topic}
    >
      <PhormContainer
        defaultValues={{
          speeds: {
            tray: latest?.speeds.tray || 0,
            load: latest?.speeds.load || 0,
            produce: latest?.speeds.produce || 0,
          },
        }}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              speeds: {
                tray: data.speeds?.tray,
                load: data.speeds?.load,
                produce: data.speeds?.produce,
              },
            },
            topicParams: {
              deviceId,
            },
          });
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          speeds: yup.object({
            tray: yup.number().required(),
            load: yup.number().required(),
            produce: yup.number().required(),
          }),
        })}
      >
        <p>Conveyor seepds (percentage of maximum speed)</p>
        <Stack spacing={2}>
          <TextFieldElement
            label='Conveyor Tray Speed'
            name='speeds.tray'
            required
          />
          <TextFieldElement
            label='Conveyor Load Speed'
            name='speeds.load'
            required
          />
          <TextFieldElement
            label='Conveyor Produce Speed'
            name='speeds.produce'
            required
          />
          <SubmitControl controlHook={controlLifecycle}>Submit</SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormHarvesterConfigSet;
