import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, IrrigationDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionIrrigationConfigSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormIrrigationConfigSetNurseryPressures = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<IrrigationDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionIrrigationConfigSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/irrigation/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionIrrigationConfigSet,
    {
      pressureThresholdEngage: latest?.pressureThresholdEngage || 0,
      pressureThresholdMax: latest?.pressureThresholdMax || 0,
    },
  );

  return (
    <PaperSection
      heading='Pressure Settings'
      mb={2}
      subheading={mqttDefinitionIrrigationConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='irrigation'
        pressureThresholdEngage={latest.pressureThresholdEngage}
        pressureThresholdMax={latest.pressureThresholdMax}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              pressureThresholdEngage: data.pressureThresholdEngage,
              pressureThresholdMax: data.pressureThresholdMax,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Set pressure thresholds to ${data.pressureThresholdEngage}/${data.pressureThresholdMax}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          pressureThresholdEngage: yup.number().required(),
          pressureThresholdMax: yup.number().required(),
        })}
      >
        <Stack spacing={2}>
          <p>Supply pump operates between the two pressure thresholds.</p>
          <Stack spacing={2} direction='row'>
            <TextFieldElement
              label='Pressure Engage'
              name='pressureThresholdEngage'
            />
            <TextFieldElement
              label='Pressure Shutoff'
              name='pressureThresholdMax'
            />
          </Stack>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormIrrigationConfigSetNurseryPressures;
