import type { DatabaseDevices, SeederDeviceStateUnflattened } from '@phyllome/common';
import FormSeederSet from './forms/FormSeederSet';
import FormSeederSetCount from './forms/FormSeederSetCount';
import LatestSeederInfo from './LatestSeederInfo';

const ControlSeeder = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<SeederDeviceStateUnflattened>;
}) => {

  return (
    <>
      <LatestSeederInfo deviceState={deviceState} />
      <FormSeederSet deviceState={deviceState} />
      <FormSeederSetCount deviceState={deviceState} />
    </>
  );
};

export default ControlSeeder;
