import GenericPing from '../Generic/control/GenericPing';
import LatestTraybotInfo from './LatestTraybotInfo';
import FormTraybotControlScan from './forms/FormTraybotControlScan';
import FormTraybotControlFan from './forms/FormTraybotControlFan';
import { useCurrentSidebarDeviceData } from '../../data/hooks/useCurrentSidebarDeviceData';
import type { TraybotDeviceStateUnflattened } from '@phyllome/common';

const ControlTraybot  = () => {
  const { deviceId } = useCurrentSidebarDeviceData<TraybotDeviceStateUnflattened>();

  return (
    <>
      <LatestTraybotInfo />
      <FormTraybotControlScan />
      <FormTraybotControlFan />
      <div>
        <GenericPing
          deviceId={deviceId}
          deviceType='traybot'
        />
      </div>
    </>
  );
};

export default ControlTraybot;
