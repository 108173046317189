import { useQuery } from '@tanstack/react-query';
import useUserToken from './useUserToken';
import { useContext } from 'react';
import FarmContext from '../../components/FarmProvider/FarmContext';

export const createFetchEndpoint = (name: string, brokerId: string, token?:string) => {
  if (name.startsWith('/')) {
    name = name.substring(1);
  }

  // replace :brokerId with the actual brokerId
  name = name.replace(':brokerId', brokerId);
  const url = process.env.NODE_ENV === 'production'
    ? `https://websocket.phyllome.io/${name}`
    : `http://127.0.0.1:3025/${name}`;

  return token ? `${url}?token=${token}` : url;
};

export const createFetchEndpointStream = (name: string, brokerId: string, token?:string) => {
  if (name.startsWith('/')) {
    name = name.substring(1);
  }

  // replace :brokerId with the actual brokerId
  name = name.replace(':brokerId', brokerId);
  const url = process.env.NODE_ENV === 'production'
    ? `https://compute.phyllome.io/${name}`
    : `http://127.0.0.1:3025/${name}`;

  return token ? `${url}?token=${token}` : url;
};

export type FetchResult<T> = {
  data: T;
  status: 'pending' | 'error' | 'success';
  isPending: boolean;
  isFetching: boolean;
  error: Error | null;
  refetch: () => Promise<T>; // Added refetch function
};

const useFetch = <T = unknown>(endpoint: string, options?: {
  interval?: number;
  enabled?: boolean;
  default?: T
}):FetchResult<T> => {
  const userToken = useUserToken();
  const { brokerId } = useContext(FarmContext);

  // Create a new options object with defaults
  const finalOptions = {
    enabled: true,
    interval: 0,
    default: undefined as T | undefined,
    ...options, // This spreads your provided options over the defaults
  };

  const {
    data,
    status,     // 'pending' | 'error' | 'success'
    isPending,  // true during first load (replaces isInitialLoading)
    isFetching, // true during any load (including background refetches)
    error,
    refetch: queryRefetch, // Get the refetch function from useQuery
  } = useQuery({
    queryKey: [`${endpoint}`],
    queryFn: () => fetch(createFetchEndpoint(endpoint, brokerId), {
      headers: {
        'Cache-Control': 'no-cache',
        'Authorization': `Bearer ${userToken}`,
      },
    }).then(res => res.json()),
    enabled: userToken !== null && finalOptions.enabled !== false,
    refetchInterval: finalOptions.interval || false,
    staleTime: 0,            // Data becomes stale immediately
    gcTime: 0,

  });

  // Create a wrapped refetch function that returns the expected type
  const refetch = async (): Promise<T> => {
    const result = await queryRefetch();

    return result.data as T;
  };

  return {
    data: data || finalOptions.default as T,
    status,
    isPending,
    isFetching,
    error,
    refetch, // Add the refetch function to the return object
  };
};

export default useFetch;