import type { FC } from 'react';
import * as React from 'react';
import { Box, Icon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type PhyconPropsMUI = {
  icon: React.ElementType<any>;
  size?: number;
  noMargin?: boolean;
};
type PhyconPropsFA = {
  icon: IconDefinition;
  size?: number;
  color?: string;
  noMargin?: boolean;
  opacity?: number;
};
export const PhyconMUI: FC<PhyconPropsMUI> = ({
  icon,
  size = 100,
  noMargin = false,
}) => {
  return (
    <div
      style={{
        display: 'inline-block',
        lineHeight: '1em',
        height: '1em',
        marginRight: noMargin ? '0' : `${size / 200}em`,
      }}
    >
      <Icon
        sx={{
          fontSize: '1.2rem',
          width: '20px',
          top: '-0.1em',
          position: 'relative',
          scale: `${size / 100}`,
        }}
        component={icon}
      />
    </div>
  );
};

export const PhyconFA: FC<PhyconPropsFA> = ({
  icon,
  size = 100,
  color,
  noMargin = false,
  opacity = 1,
}) => {
  return (
    <div
      style={{
        display: 'inline-block',
        height: '1em',
        lineHeight: '1em',
        marginRight: noMargin ? '-3px' : `${size / 200}em`,
        scale: `${size / 100}`,
      }}
    >
      <FontAwesomeIcon
        icon={icon}
        color={color}
        opacity={opacity}
        style={{ width: '20px', boxSizing: 'border-box' }}
      />
    </div>
  );
};

export const PhyconContainer: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        lineHeight: '2em',
        height: '2em',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      {children}
    </Box>
  );
};
