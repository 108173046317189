import { Box } from '@mui/material';
import type { ControlSend } from '../../data/hooks/useControlSend';
import InlineProgress from './InlineProgress';
import InlineProgressFailed from './InlineProgressFailed';

const CCMCP = ({ isComplete, rid }: { isComplete: boolean; rid: number }) => (
  <InlineProgress
    waitingtext='Waiting for MCP'
    completetext={`MCP Responded. (rid: ${rid})`}
    isComplete={isComplete}
  />
);

const CCDevice = ({
  isComplete,
  retries,
}: {
  isComplete: boolean;
  retries: number;
}) => {
  const t = retries
    ? `Waiting for Response From Device (retries: ${retries})`
    : 'Waiting for Response From Device';

  return (
    <InlineProgress
      waitingtext={t}
      completetext='Device Responded'
      isComplete={isComplete}
    />
  );
};

const ControlConnect = ({ data }: { data: ControlSend }) => {
  if (!data) return null;
  if (data.payload?.status === 'done' || data.payload?.status === 'undefined')
    return null;

  const rid = data.rid || 0;

  return (
    <Box>
      <Box p={2}>
        {data.payload?.status === 'started' && (
          <>
            <CCMCP isComplete={false} rid={rid} />
          </>
        )}
        {data.payload?.status === 'mcp' && (
          <>
            <CCMCP isComplete={true} rid={rid} />
            <CCDevice
              isComplete={false}
              retries={data?.payload?.response?.retryCount}
            />
          </>
        )}
        {data.payload?.status === 'timeout' && (
          <>
            <CCMCP isComplete={true} rid={rid} />
            <InlineProgressFailed text='Timed Out' />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ControlConnect;
