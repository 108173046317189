import type { DatabaseDevices, SentryDeviceStateUnflattened } from '@phyllome/common';
import GenericPing from '../Generic/control/GenericPing';
import LatestSentryInfo from './LatestSentryInfo';

const ControlSentry = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<SentryDeviceStateUnflattened>;
}) => {
  const { deviceId } = deviceState;

  return (
    <>
      <LatestSentryInfo deviceState={deviceState} />
      <div>
        <GenericPing
          deviceId={deviceId}
          deviceType='sentry'
        />
      </div>
    </>
  );
};

export default ControlSentry;
