import type { TraybotDeviceStateUnflattened } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import { formatSeconds } from '../../helpers/formatSeconds';
import FormTraybotConfigSetLift from './forms/FormTraybotConfigSetLift';
import FormTraybotConfigSetDrive from './forms/FormTraybotConfigSetDrive';
import FormTraybotConfigSetOperating from './forms/FormTraybotConfigSetOperating';
import FormTraybotConfigSetPositions from './forms/FormTraybotConfigSetPositions';
import FormTraybotConfigSetSpeed from './forms/FormTraybotConfigSetSpeed';
import { useCurrentSidebarDeviceData } from '../../data/hooks/useCurrentSidebarDeviceData';

const ConfigTraybot  = () => {

  const { properties: latest, deviceId } = useCurrentSidebarDeviceData<TraybotDeviceStateUnflattened>();

  return (
    <>
      <PaperSection heading='Traybot Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceId}</LabelValue>
        <LabelValue label='Firmware'>{latest.firmware}</LabelValue>
        <LabelValue label='Build date'>{latest.compiled}</LabelValue>
        <LabelValue label='Network IP'>
          {latest.network?.ip} ({latest.network?.rssi}dB)
        </LabelValue>
        <LabelValue label='Free memory'>
          {/* {formatBytes(latest.freememory)} */}TBD
        </LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormTraybotConfigSetLift  />
      <FormTraybotConfigSetOperating  />
      <FormTraybotConfigSetSpeed />
      <FormTraybotConfigSetPositions  />
      <FormTraybotConfigSetDrive  />

    </>
  );
};

export default ConfigTraybot;
