import GenericPing from '../Generic/control/GenericPing';
import LatestLiftInfo from './LatestLiftCoreInfo';
import type { DatabaseDevices, LiftDeviceStateUnflattened } from '@phyllome/common';

const ControlLiftCore = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LiftDeviceStateUnflattened>;
}) => {
  const { deviceId  } = deviceState;

  return (
    <>
      <LatestLiftInfo deviceState={deviceState} />
      <div>
        <GenericPing
          deviceId={deviceId}
          deviceType='liftcore'
        />
      </div>
    </>
  );
};

export default ControlLiftCore;
