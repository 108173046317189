import { Tabs, Tab, Box, Alert } from '@mui/material';
import { colors } from '@phyllome/common';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import FarmContext from '../../components/FarmProvider/FarmContext';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import PhyTabPanel from '../../components/PhyTabPanel/PhyTabPanel';
import useLatestLightingCore from './hooks/useLatestLightingCore';
import LatestLightingCoreInfo from './LatestLightingCoreInfo';
import LatestLightingCoreFaults from './LatestLightingCoreFaults';
import LatestLightingCore from './LatestLightingCore';
import GraphZoneCore from './GraphLightingCore';
import ControlZoneCore from './ControlLightingCore';
import ConfigZoneCore from './ConfigLightingCore';
import useRoute from '../../data/hooks/useRoute';
import PendingDevice from '../../components/PendingDevice/PendingDevice';

type IProps = {
  deviceId: string;
};

const SideBarEntryLightingCore: FC<IProps> = ({ deviceId }) => {
  const { brokerId } = useContext(FarmContext);
  const router = useRoute();
  const [value, setValue] = useState<any>(Number(router.sidebarQuery.tab) || 0);

  const latestDeviceInfo = useLatestLightingCore(deviceId);
  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    router.navSideQuery({ tab: value });
  }, [value, router]);

  if (!latestDeviceInfo)
    return (
      <Box p={2}>
        <Alert severity='info'>No Latest Data for lightingcore/{deviceId}!</Alert>
      </Box>
    );
  return (
    <>
      <AppDrawerTitle title={`lightingCore/${latestDeviceInfo.deviceId}`} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor='inherit'
        variant='scrollable'
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label='Latest' value={0} />
        <Tab label='Graphs' value={1} />
        <Tab label='Control' value={2} />
        <Tab label='Config' value={3} />
        <Tab label='Activity' value={4} />
        <Tab label='Alerts' value={5} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <LatestLightingCoreInfo deviceState={latestDeviceInfo} />
        <LatestLightingCoreFaults deviceState={latestDeviceInfo} />
        <PendingDevice  deviceState={latestDeviceInfo} />
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='lightingcore'
          count={1}
        />
        <LatestLightingCore deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <GraphZoneCore deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <ControlZoneCore deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <ConfigZoneCore deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <ActivityLog
          identifier={`device/lighting/${deviceId}/notes`}
          heading='Activity Log'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='lightingcore'
        />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryLightingCore;
