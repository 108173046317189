import { Button, Box, Typography } from '@mui/material';
import { type FC, useState } from 'react';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import FormEndSeason from './components/FormEndSeason';
import type {  WithId } from '@phyllome/common';
import { type DatabaseSeason,  colors, mqttDefinitionSeederSet } from '@phyllome/common';
import useAction from '../../../data/hooks/useAction';
import useNavSide from '../../../data/hooks/useNavSide';
import useActivityLog from '../../../data/hooks/useActivityLog';
import useControlSend from '../../../data/hooks/useControlSend';
import useLatestSeeder from '../../../devicespecific/Seeder/hooks/useSeederLatest';
import useFetchManual from '../../../data/hooks/useFetchManual';

type SideBarSeasonStateProps = {
  seasonData: WithId<DatabaseSeason>;
};

// const BtnUpdateStats = ({ seasonId }: { seasonId: string }) => {
//   const action = useAction();
//   return (
//     <Button
//       variant="contained"
//       onClick={() => {
//         action.run({
//           action: "seasonUpdateStats",
//           payload: {
//             seasonId,
//           },
//         });
//       }}
//     >
//       Refresh
//     </Button>
//   );
// };

const BtnConfigureSeeder = ({ seasonData }: { seasonData: WithId<DatabaseSeason> }) => {

  const deviceId = 'growroom'; // need to fix this
  const deviceData = useLatestSeeder(deviceId);
  const controlLifecycle = useControlSend(mqttDefinitionSeederSet, deviceId);

  if (!deviceData) return null;
  return (
    <Button
      disabled={!controlLifecycle.isReady}
      sx={{ mr: 0.5 }}
      variant='outlined'
      color='secondary'
      onClick={() => {
        controlLifecycle.run({
          payload: {
            seedId: seasonData.seedId,
            batch: seasonData.seedBatch[0],
            quantity: seasonData.defaultSeedDensity,
            trays: Number(seasonData.trayNumberTarget),
            seasonId: seasonData.id,
          },
          topicParams: {
            deviceId,
          },
        });
      }}
    >
      Start Seeding
    </Button>
  );
};

const BtnSeasonResume = ({
  seasonId,
  cancel = false,
}: {
  seasonId: string;
  cancel?: boolean;
}) => {

  const activityLog = useActivityLog(`season/${seasonId}/notes`);
  const fetchResume = useFetchManual(`/farm/:brokerId/season/${seasonId}/resume`);

  return (
    <Button
      sx={{ mr: 1 }}
      variant='outlined'
      onClick={() => {
        //seasonContinue.run({ seasonId, brokerId });
        fetchResume.fetch();
        activityLog.add('Resumed Season', 'OPERATION');
      }}
    >
      {cancel ? 'Cancel' : 'Resume'}
    </Button>
  );
};

const BtnSeasonPause = ({
  seasonId,
  cancel = false,
}: {
  seasonId: string;
  cancel?: boolean;
}) => {
  const [btnIsDisabled, setBtnIsDisabled] = useState(false);

  const activityLog = useActivityLog(`season/${seasonId}/notes`);

  const f = useFetchManual(`/farm/:brokerId/season/${seasonId}/pause`);

  return (
    <Button
      disabled={btnIsDisabled}
      sx={{ mr: 0.5 }}
      variant='contained'
      onClick={() => {
        setBtnIsDisabled(true);
        f.fetch();
        activityLog.add('Paused Season', 'OPERATION');
      }}
    >
      {cancel ? 'Cancel' : 'Pause'}
    </Button>
  );
};

const BtnSeasonStart = ({
  seasonId,
  brokerId,
}: {
  seasonId: string;
  brokerId: string;
}) => {
  const seasonStart = useAction('seasonStart');
  const activityLog = useActivityLog(`season/${seasonId}/notes`);

  return (
    <Button
      variant='contained'
      onClick={() => {
        seasonStart.run({ seasonId, brokerId });
        activityLog.add('Started Season', 'OPERATION');
      }}
    >
      Start Season
    </Button>
  );
};

const BtnSeasonStop = ({
  seasonId,
  brokerId,
}: {
  seasonId: string;
  brokerId: string;
}) => {
  const seasonStop = useAction('seasonStop');
  const activityLog = useActivityLog(`season/${seasonId}/notes`);

  return (
    <Button
      variant='outlined'
      color='secondary'
      onClick={() => {
        seasonStop.run({ seasonId, brokerId });
        activityLog.add('Stopped Season', 'OPERATION');
      }}
    >
      Stop Season
    </Button>
  );
};

const StateHeading = ({
  heading,
  text,
  children,
}: {
  heading: string;
  text?: string;
  children?: React.ReactNode;
}) => {
  return (
    <Box sx={{ textAlign: 'center' }} p={1}>
      <Typography variant='h4' fontSize='40px' lineHeight='40px'>
        {heading}
      </Typography>
      {text && (
        <Typography
          variant='body1'
          fontSize='16px'
          lineHeight='24px'
          color='#555'
          maxWidth='400px'
          margin='0px auto'
          paddingTop={1}
        >
          {text}
        </Typography>
      )}
      <Box mt={2}>{children}</Box>
    </Box>
  );
};

// const BtnSeasonHarvest = ({
//   seasonData,
// }: {
//   seasonData: WithId<DatabaseSeason>;
// }) => {
//   const [isOpen, setIsOpened] = useState(false);
//   return (
//     <>
//       <Button variant="contained" onClick={() => setIsOpened(!isOpen)}>
//         Harvest
//       </Button>
//       {isOpen && (
//         <Box pt={3}>
//           <FormEndSeason seasonData={seasonData} />
//         </Box>
//       )}
//     </>
//   );
// };

const SideBarSeasonState: FC<SideBarSeasonStateProps> = ({
  seasonData,
}) => {
  const navSide = useNavSide();
  const seasonId = seasonData.id;

  switch (seasonData.state) {
    case 'created':
      return (
        <Box ml={1} mr={1} mt={2} mb={2}>
          <StateHeading
            heading='New Season'
            text='We need to assign trays to their locations'
          >
            <BtnConfigureSeeder seasonData={seasonData}/>
            <Button
              variant='contained'
              onClick={() => {
                navSide(`season/trays/${seasonId}`);
              }}
            >
              Assign Trays
            </Button>
          </StateHeading>
        </Box>
      );
      break;
    case 'seeded':
      return (
        <Box ml={1} mr={1} mt={2} mb={2}>
          <StateHeading heading='Seeded' text="We're ready to commence growing">
            <BtnSeasonStart
              seasonId={seasonId}
              brokerId={seasonData.brokerId}
            />
          </StateHeading>
        </Box>
      );
      break;
    case 'starting':
      return (
        <Box ml={1} mr={1} mt={2} mb={2}>
          <StateHeading
            heading='Starting'
            text='Enabling lights and sprinklers'
          >
            <BtnSeasonPause
              seasonId={seasonId}
              cancel
            />
          </StateHeading>
        </Box>
      );
      break;

    case 'growing':
      return (
        <Box ml={1} mr={1} mt={2} mb={2}>
          <StateHeading
            heading='Growing'
            text='Stopping the season will disable everything in preparation for harvest'
          >
            <BtnSeasonPause
              seasonId={seasonId}
            />
            <BtnSeasonStop brokerId={seasonData.brokerId} seasonId={seasonId} />

          </StateHeading>
        </Box>
      );
      break;

    case 'stopping':
      return (
        <Box ml={1} mr={1} mt={2} mb={2}>
          <StateHeading
            heading='Stopping'
            text='Plants are grown. Preparing for harvesting by disabling the lights and sprinklers'
          >
            <BtnSeasonPause
              seasonId={seasonId}
            />
            <BtnSeasonStop brokerId={seasonData.brokerId} seasonId={seasonId} />
          </StateHeading>
        </Box>
      );
      break;
    case 'stopped':
      return (
        <Box ml={1} mr={1} mt={2} mb={2}>
          <StateHeading
            heading='Growth Cycle Complete'
            text='Enter in the harvest details below to end the season'
          />
          <Box mb={2} textAlign='center'>
            <BtnSeasonResume
              seasonId={seasonId}
            />
          </Box>
          <PaperSection
            heading='End Season'
            headerSx={{ backgroundColor: colors.earth3 }}
          >
            <FormEndSeason seasonData={seasonData} />
          </PaperSection>
        </Box>
      );
      break;
    case 'pausing':
      return (
        <Box ml={1} mr={1} mt={2} mb={2}>
          <StateHeading
            heading='Pausing'
            text='Disabling lights and sprinklers'
          >
            <BtnSeasonResume
              seasonId={seasonId}
              cancel
            />
          </StateHeading>
        </Box>
      );
      break;
    case 'paused':
      return (
        <Box ml={1} mr={1} mt={2} mb={2}>
          <StateHeading heading='Paused' text='You can safely inspect trays'>
            <BtnSeasonResume
              seasonId={seasonId}
            />
            <BtnSeasonStop brokerId={seasonData.brokerId} seasonId={seasonId} />
          </StateHeading>
        </Box>
      );
      break;
    case 'resuming':
      return (
        <Box ml={1} mr={1} mt={2} mb={2}>
          <StateHeading
            heading='Resuming'
            text='Enabling lights and sprinklers'
          >
            <BtnSeasonPause
              seasonId={seasonId}
              cancel
            />
          </StateHeading>
        </Box>
      );
      break;
    case 'completed':
      return (
        <Box ml={1} mr={1} mt={2} mb={2}>
          <StateHeading heading='Completed' text='This season is complete' />
        </Box>
      );

    default:
      break;
  }
};

export default SideBarSeasonState;
