import type { DatabaseSeason, DeviceType, WithId } from '@phyllome/common';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import type {
  ConsumableDataType,
} from '../../../../components/ConsumablesTable/ConsumablesTable';
import ConsumablesTable from '../../../../components/ConsumablesTable/ConsumablesTable';
import co2LitresToKilos from '../../../../helpers/co2LitresToKilos';
import ConsumableDefaultCosts from '../../../../components/ConsumablesTable/ConsumableDefaultCosts';
import useSeed from '../../../../data/hooks/collections/useSeed';
import Stacker from '../../../../components/Stacker/Stacker';
import RechartVictoryHeading from '../../../../components/Rechart/RechartVictoryHeading';

const SeasonGraph = ({ seasonData, color, heading, field }:{
  seasonData: WithId<DatabaseSeason>;
  color: string;
  heading: string;
  field: string;
}) => {

  return (
    <RechartVictoryHeading
      height={200}
      heading={heading}
      color={color}
      brokerId={seasonData.brokerId}
      deviceId={seasonData.id}
      deviceType={'season' as DeviceType}
      field={field}
      dates={{
        beginDate: seasonData.growthStarted || new Date(),
        endDate: seasonData.growthEnded || new Date(),
      }}
    />
  );

};

const SideBarSeasonConsumables = ({
  seasonData,
}: {
  seasonData?: WithId<DatabaseSeason>;
}) => {
  const seedData = useSeed(seasonData?.seedId);

  if (!seasonData) return null;

  const seedCost =
    (seedData?.pricePerMillion || ConsumableDefaultCosts.seeds) / 1000000;

  const ConsumableData: ConsumableDataType = {
    energy: Number(seasonData?.totalConsumedEnergy) / 1000, // conver to kW
    water: Number(seasonData?.totalConsumedWater),
    nutrientA: Number(seasonData?.totalConsumedNutrientA),
    nutrientB: Number(seasonData?.totalConsumedNutrientB),
    co2: co2LitresToKilos(Number(seasonData?.totalConsumedCO2) / 1000),
    seeds: Number(seasonData?.totalConsumedSeedQuantity),
    nutrientPeroxide: Number(seasonData?.totalConsumedPeroxide),
    nutrientAcid: Number(seasonData?.totalConsumedAcid),
    nutrientBase: Number(seasonData?.totalConsumedBase),
  };

  const ConsumableCosts: ConsumableDataType = {
    nutrientA: ConsumableDefaultCosts.nutrientA,
    nutrientB: ConsumableDefaultCosts.nutrientB,
    nutrientPeroxide: ConsumableDefaultCosts.nutrientPeroxide,
    nutrientAcid: ConsumableDefaultCosts.nutrientAcid,
    nutrientBase: ConsumableDefaultCosts.nutrientBase,
    energy: ConsumableDefaultCosts.energy,
    water: ConsumableDefaultCosts.water,
    co2: ConsumableDefaultCosts.co2,
    seeds: seedCost,
  };

  const productiveTrays =
    (seasonData?.trayNumberActual || 0) -
    (seasonData?.trayNumberRejected || 0) -
    (seasonData?.trayNumberFlagged || 0);

  console.log({
    beginDate: seasonData.created || new Date(),
    endDate: seasonData.growthEnded || new Date(),
  });
  return (
    <>
      <PaperSection heading='Season Consumables' mb={2}>
        <ConsumablesTable
          consumableData={ConsumableData}
          totalTrays={productiveTrays}
          consumableCosts={ConsumableCosts}
        />

      </PaperSection>
      <Stacker>
        <SeasonGraph
          seasonData={seasonData}
          color='green'
          heading='totalConsumedEnergy'
          field='totalConsumedEnergy'
        />
        <SeasonGraph
          seasonData={seasonData}
          color='green'
          heading='totalConsumedNutrientA'
          field='totalConsumedNutrientA'
        />
        <SeasonGraph
          seasonData={seasonData}
          color='green'
          heading='totalConsumedNutrientB'
          field='totalConsumedNutrientB'
        />
        <SeasonGraph
          seasonData={seasonData}
          color='green'
          heading='totalConsumedPeroxide'
          field='totalConsumedPeroxide'
        />
        <SeasonGraph
          seasonData={seasonData}
          color='green'
          heading='totalConsumedAcid'
          field='totalConsumedAcid'
        />
        <SeasonGraph
          seasonData={seasonData}
          color='green'
          heading='totalConsumedCO2'
          field='totalConsumedCO2'
        />
      </Stacker>

    </>

  );
};

export default SideBarSeasonConsumables;
