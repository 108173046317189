import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';
import type { DatabaseDevices, FertigatorDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionFertigatorConfigSet,
} from '@phyllome/common';
import PhormContainer from '../../../forms/PhormContainer';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { InputAdornment, Typography } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormFertigatorConfigSetMinimums = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<FertigatorDeviceStateUnflattened> ;
}) => {
  const deviceId = deviceState.deviceId;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorConfigSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionFertigatorConfigSet,
    {
      minimums: {
        eca: latest.minimums?.eca || 0,
        ecb: latest.minimums?.ecb || 0,
        acid: latest.minimums?.acid || 0,
        base: latest.minimums?.base || 0,
        peroxide: latest.minimums?.peroxide || 0,
        pump: latest.minimums?.pump || 0,
      },
    },
  );

  return (
    <PaperSection
      heading='Minimum dose configuration'
      mb={2}
      subheading={mqttDefinitionFertigatorConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='fertigator'
        eca={latest.minimums?.eca}
        ecb={latest.minimums?.ecb}
        acid={latest.minimums?.acid}
        base={latest.minimums?.base}
        peroxide={latest.minimums?.peroxide}
        pump={latest.minimums?.pump}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              minimums: {
                eca: data.minimums.eca,
                ecb: data.minimums.ecb,
                acid: data.minimums.acid,
                base: data.minimums.base,
                peroxide: data.minimums.peroxide,
                pump: data.minimums.pump,
              },
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add('Updated device minimum calibration', 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          minimums: yup.object({
            eca: yup.number().min(0).required(),
            ecb: yup.number().min(0).required(),
            acid: yup.number().min(0).required(),
            base: yup.number().min(0).required(),
            peroxide: yup.number().min(0).required(),
            pump: yup.number().min(0).required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <Typography variant='body1'>
            Minimum dosing value. The dosers do not open if the target volume is less than these minimums.
          </Typography>
          <Stack spacing={2} direction='row'>
            <TextFieldElement
              label='Minimums EC A' name='minimums.eca'
              InputProps={{ endAdornment: <InputAdornment position='end'>mL</InputAdornment> }}
            />
            <TextFieldElement
              label='Minimums EC B' name='minimums.ecb'
              InputProps={{ endAdornment: <InputAdornment position='end'>mL</InputAdornment> }}
            />
          </Stack>
          <Stack spacing={2} direction='row'>
            <TextFieldElement
              label='Minimums Acid' name='minimums.acid'
              InputProps={{ endAdornment: <InputAdornment position='end'>mL</InputAdornment> }}
            />
            <TextFieldElement
              label='Minimums Base' name='minimums.base'
              InputProps={{ endAdornment: <InputAdornment position='end'>mL</InputAdornment> }}
            />
          </Stack>
          <Stack spacing={2} direction='row'>
            <TextFieldElement
              label='Minimums Peroxide'
              name='minimums.peroxide'
              InputProps={{ endAdornment: <InputAdornment position='end'>mL</InputAdornment> }}
            />
            <TextFieldElement
              label='Minimums Pump'
              name='minimums.pump'
              InputProps={{ endAdornment: <InputAdornment position='end'>mL</InputAdornment> }}
            />
          </Stack>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorConfigSetMinimums;
