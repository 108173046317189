import { useContext } from 'react';
import FarmContext from '../../../../components/FarmProvider/FarmContext';

const useNDSOverlayData = (column: number) => {
  const { brokerId, columnSpec } = useContext(FarmContext);

  const rows = columnSpec[column - 1]?.rows || 0;
  const shelves = columnSpec[column - 1]?.shelves || 0;

  const date = new Date();

  return { brokerId, columnSpec,  rows, shelves, date };

};

export default useNDSOverlayData;

