import type { DatabaseSeason, WithId } from '@phyllome/common';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import ObjectToTable from '../../../../components/ObjectToTable/ObjectToTable';

const SideBarTraySeasonDebug = ({
  seasonData,
}: {
  seasonData?: WithId<DatabaseSeason>;
}) => {
  if (!seasonData) return null;
  return (
    <PaperSection heading='Raw Data' mt={2}>
      <ObjectToTable data={seasonData} />
    </PaperSection>
  );
};

export default SideBarTraySeasonDebug;
