import type { HarvesterDeviceStateUnflattened } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestHarvester = (deviceId: string) => {
  return useDeviceState<HarvesterDeviceStateUnflattened>({
    deviceId,
    deviceType: 'harvester',
  });
};

export default useLatestHarvester;
