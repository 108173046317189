import { useAtomValue } from 'jotai';
import RackBox from '../../../../components/factoryui/components/RackBox';
import { devicesByPrefixAtom } from '../../../../data/atoms/deviceAtomFamily';
import useNDSOverlayData from './useNDSOverlayData';
import type { NDSDeviceStateUnflattened } from '@phyllome/common';

const OverlaySprinklerEnabled = ({ column } :{ column: number }) => {
  const {
    brokerId,
    shelves,
  } = useNDSOverlayData(column);

  const deviceData = useAtomValue(devicesByPrefixAtom(`${brokerId}.nds.${column}`));

  return (
    <>
      {Object.keys(deviceData).map(_deviceRow => {
        const data = deviceData[_deviceRow];

        if (!data) {
          return null;
        }
        const { row, shelf } = (data.properties as NDSDeviceStateUnflattened).location;
        const key = `${column}.${shelf}.${row}`;

        return (
          <RackBox
            key={key}
            zIndex={11}
            row={row}
            shelf={shelf}
            shelves={shelves}
            style={{
              background: data.properties.enable
                ? 'rgba(255,255,255,0)'
                : 'rgba(255,255,255,0.6)',
            }}
          />
        );
      })
      }
    </>
  );
};

export default OverlaySprinklerEnabled;
