import { colors } from '@phyllome/common';
import type { FavouriteDefinition } from './types';

const co2Graph: FavouriteDefinition = {
  co2Mode: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'enum',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/mode`,
    color: colors.earth1,
    field: 'mode',
    brokerId,
    deviceId,
    mapping: {
      '2': 'Manual',
      '1': 'Auto',
      '0': 'Disabled',
    },
  }),
  co2PPM: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/ppm`,
    color: colors.ripening,
    field: 'ppm',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
      max: 1400,
    },
  }),
  co2DoseCumulative: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/doseCumulative`,
    color: colors.earth1,
    field: 'dose',
    brokerId,
    deviceId,
  }),
  co2Dose: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/dose`,
    color: colors.earth1,
    field: 'dose',
    brokerId,
    deviceId,
  }),
  co2FreeMemory: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/freemem`,
    color: colors.earth1,
    field: 'freememory',
    brokerId,
    deviceId,
  }),
  co2Dosing: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/dosing`,
    color: colors.earth1,
    field: 'co2_dosing',
    brokerId,
    deviceId,
  }),
  co2Enabled: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/enabled`,
    color: colors.earth1,
    field: 'co2_enabled',
    brokerId,
    deviceId,
  }),
  co2Heater: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/heater`,
    color: colors.earth1,
    field: 'co2_heater',
    brokerId,
    deviceId,
  }),
  co2FansExhaust: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/fans_exhaust`,
    color: colors.earth1,
    field: 'fans_exaust',
    brokerId,
    deviceId,
  }),
  co2FansIntake: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/fans_intake`,
    color: colors.earth1,
    field: 'fans.intake',
    brokerId,
    deviceId,
  }),
  co2AutomationCo2: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/automation.co2`,
    color: colors.earth1,
    field: 'automation.co2',
    brokerId,
    deviceId,
  }),
  co2AutomationRefresh: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/automation.refresh`,
    color: colors.earth1,
    field: 'automation.refresh',
    brokerId,
    deviceId,
  }),

  co2Control: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'co2',
    subType: 'enum',
    showHeading: ({ deviceId }) => `[CO2] ${deviceId}/control`,
    color: colors.earth1,
    field: 'control',
    brokerId,
    deviceId,
    mapping: {
      '0': 'Disabled',
      '1': 'Local',
      '2': 'Remote',
    },
  }),
};

export default co2Graph;
