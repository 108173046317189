import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';
import type { DatabaseDevices, FertigatorDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionFertigatorTank,
} from '@phyllome/common';
import PhormContainer from '../../../forms/PhormContainer';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import { InputAdornment, Typography } from '@mui/material';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormFertigatorConfigSetTanks = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<FertigatorDeviceStateUnflattened> ;
}) => {
  const deviceId = deviceState.deviceId;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorTank,
    deviceId,
  );
  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionFertigatorTank,
    {
      acid: latest?.tanks.acid / 1000 || 0,
      base: latest?.tanks.acid / 1000 || 0,
      peroxide: latest?.tanks.peroxide / 1000 || 0,
      pump: latest?.tanks.pump / 1000 || 0,
      nutrientA: latest?.tanks.nutrientA / 1000 || 0,
      nutrientB: latest?.tanks.nutrientB / 1000 || 0,
    },
  );

  return (
    <PaperSection
      heading='Doser tank levels'
      mb={2}
      subheading={mqttDefinitionFertigatorTank.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='fertigator'
        nutrientA={latest.tanks.nutrientA}
        nutrientB={latest.tanks.nutrientB}
        acid={latest.tanks.acid}
        base={latest.tanks.base}
        peroxide={latest.tanks.peroxide}
        pump={latest.tanks.pump}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              acid: data.acid * 1000,
              base: data.base * 1000,
              peroxide: data.peroxide * 1000,
              pump: data.pump * 1000,
              nutrientA: data.nutrientA * 1000,
              nutrientB: data.nutrientB * 1000,
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            'Updated device tank levels',
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          acid: yup.number().required().min(0).max(500),
          base: yup.number().required().min(0).max(500),
          peroxide: yup.number().required().min(0).max(500),
          pump: yup.number().required().min(0).max(500),
          nutrientA: yup.number().required().min(0).max(500),
          nutrientB: yup.number().required().min(0).max(500),
        })}
      >
        <Stack spacing={2}>
          <Typography variant='body1'>
            After filling the supply tanks, set the new tank level here so the fertgator can keep track of how much liquid remains. Tank level is in Litres.
          </Typography>
          <TextFieldElement
            label='Nutrient A'
            name='nutrientA'
            InputProps={{ endAdornment: <InputAdornment position='end'>L</InputAdornment> }}
          />
          <TextFieldElement
            label='Nutrient B'
            name='nutrientB'
            InputProps={{ endAdornment: <InputAdornment position='end'>L</InputAdornment> }}
          />
          <TextFieldElement
            label='Acid'
            name='acid'
            InputProps={{ endAdornment: <InputAdornment position='end'>L</InputAdornment> }}
          />
          <TextFieldElement
            label='Base'
            name='base'
            InputProps={{ endAdornment: <InputAdornment position='end'>L</InputAdornment> }}
          />
          <TextFieldElement
            label='Peroxide'
            name='peroxide'
            InputProps={{ endAdornment: <InputAdornment position='end'>L</InputAdornment> }}
          />
          <TextFieldElement
            label='Pump'
            name='pump'
            InputProps={{ endAdornment: <InputAdornment position='end'>L</InputAdornment> }}
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorConfigSetTanks;
