import useFetch from '../../../data/hooks/useFetch';
import type { NDSDeviceFromFetch } from '../../../types/types';

export type UseNDSLatestReturn = ReturnType<typeof useNDSLatestByColumnPoller>;

const useNDSLatestByColumnPoller = (
  brokerId: string,
  column: number,
) => {

  return useFetch<NDSDeviceFromFetch[]>(
    `farm/${brokerId}/devices/nds/column/${column}`,
    {
      default: [],
      interval: 10000,
    },
  );

};

export default useNDSLatestByColumnPoller;
