function generateUniqueColors(
  numberOfColors: number,
  luminosity: number = 50,
  startingHue = 200,
): string[] {
  const colors: string[] = [];
  const hueIncrement = 360 / numberOfColors;

  // Simple approach: just force luminosity to be in a safe range for white text
  for (let i = 0; i < numberOfColors; i++) {
    const hue = (startingHue + i * hueIncrement) % 360;

    // Set safe luminosity levels for different hue ranges
    let safeLuminosity: number;

    if (hue >= 40 && hue <= 80) {
      // Yellow range - needs to be much darker
      safeLuminosity = 40;
    } else if (hue >= 80 && hue <= 160) {
      // Green range - needs to be moderately dark
      safeLuminosity = 45;
    } else {
      // Other colors can be a bit brighter but still dark enough
      safeLuminosity = 50;
    }

    // Use the minimum of our safe value and the requested luminosity
    const adjustedLuminosity = Math.min(safeLuminosity, luminosity);

    // Convert to RGB
    const rgbColor = hslToRgb(hue, 40, adjustedLuminosity);

    // Format the RGB color
    const color = `rgb(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]})`;

    // Push the color to the array
    colors.push(color);
  }
  return colors;
}

// Helper function to convert HSL to RGB
function hslToRgb(h: number, s: number, l: number): number[] {
  h /= 360;
  s /= 100;
  l /= 100;

  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export default generateUniqueColors;