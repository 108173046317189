import type { DatabaseDevices, TransferDeviceStateUnflattened } from '@phyllome/common';
import { Alert } from '@mui/material';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { useMemo } from 'react';

const GraphAir = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<TransferDeviceStateUnflattened>;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  return (
    <PaperSection heading='Transfer Device Graphs' mb={2}>
      <Alert severity='info'>
        Graphs are not yet available for this device
      </Alert>
    </PaperSection>
  );
};

export default GraphAir;
