import { colors } from '@phyllome/common';
import type { FavouriteDefinition } from './types';

const lightingGraph: FavouriteDefinition = {
  lightingEnergy: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'lighting',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[LIGHTING] ${deviceId}/energy`,
    color: colors.earth1,
    field: 'energy',
    brokerId,
    deviceId,
  }),
};

export default lightingGraph;
