import type { FC } from 'react';
import type { NDSSchedule } from '@phyllome/common';
import { compareSchedules } from '@phyllome/common';
import type { StepType } from './ScheduleView';
import ScheduleView from './ScheduleView';

type ContextEnum = 'seed' | 'season' | 'tray' | 'sprinkler';

interface IScheduleViewNDSProps {
  context?: ContextEnum;
  seedSchedule?: NDSSchedule[];
  seasonSchedule?: NDSSchedule[];
  traySchedule?: NDSSchedule[];
  sprinklerSchedule?: NDSSchedule[];
}

const correctNDSSchedule = (ss: any[]) => {
  return ss.map((s) => {
    if (typeof s === 'string') {
      return JSON.parse(s);
    } else {
      return s;
    }
  });
};

const ScheduleViewNDS: FC<IScheduleViewNDSProps> = ({
  seedSchedule = [],
  seasonSchedule = [],
  traySchedule = [],
  sprinklerSchedule = [],
  context = 'seed',
}) => {
  seedSchedule = correctNDSSchedule(seedSchedule);
  seasonSchedule = correctNDSSchedule(seasonSchedule);
  traySchedule = correctNDSSchedule(traySchedule);
  sprinklerSchedule = correctNDSSchedule(sprinklerSchedule);

  const seedEqualsSeason = compareSchedules(seedSchedule, seasonSchedule);
  const seedEqualsTray = compareSchedules(seedSchedule, traySchedule);
  const seasonEqualsTray = compareSchedules(seasonSchedule, traySchedule);
  const trayEqualsSprinkler = compareSchedules(traySchedule, sprinklerSchedule);

  const steps: Array<StepType> = [
    {
      label: 'Seed',
      selected: context === 'seed',
      error: false,
      linkedToPrevious: false, // first element, always false
      completed:
        seedEqualsSeason ||
        (seedEqualsTray && (context === 'tray' || context === 'sprinkler')),
      schedule: seedSchedule,
      showCard: context !== 'seed',
    },
    {
      label: 'Season',
      selected: context === 'season',
      error: false,
      linkedToPrevious: seedEqualsSeason,
      completed: (seedEqualsSeason || seasonEqualsTray) && context !== 'seed',
      schedule: seasonSchedule,
      showCard: context !== 'seed',
    },
    {
      label: 'Tray',
      selected: context === 'tray',
      error: false,
      linkedToPrevious:
        seasonEqualsTray && (context === 'tray' || context === 'sprinkler'),
      completed:
        (seasonEqualsTray || trayEqualsSprinkler) &&
        (context === 'tray' || context === 'sprinkler'),
      schedule: traySchedule,
      showCard: context !== 'seed' && context !== 'season',
    },
    {
      label: 'Sprinkler',
      selected: context === 'sprinkler',
      linkedToPrevious:
        trayEqualsSprinkler && (context === 'tray' || context === 'sprinkler'),
      error: !trayEqualsSprinkler,
      completed:
        trayEqualsSprinkler && (context === 'tray' || context === 'sprinkler'),
      schedule: sprinklerSchedule,
      showCard: context !== 'seed' && context !== 'season',
    },
  ];

  return <ScheduleView steps={steps} type='nds' />;
};

export default ScheduleViewNDS;
