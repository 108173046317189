import { desiredStateCompare, type DatabaseDevices } from '@phyllome/common';
import * as React from 'react';
import ShowDeviceProps from '../../ShowDeviceProps/ShowDeviceProps';

export const DeviceIconToolTipText = ({ data }: { data: DatabaseDevices }) => {
  const deviceId = data?.alias ? data.alias[0] : data.deviceId;
  const enabled =
    (data.deviceType === 'nds' && data.properties?.enable) ||
    (data.deviceType === 'lighting' && data.properties?.enable);

  const isResolved = data.updateStatus === 'COMPLETE';

  const diff = desiredStateCompare({
    currentState: data.properties,
    desiredState: data.desiredProperties,
  });

  return (
    <div>
      {data.deviceType}/{deviceId} ({enabled ? 'enabled' : 'disabled'})
      {!isResolved && (
        <>
          <TTHeading isHappy={false}>Changes Required:</TTHeading>
          <ShowDeviceProps data={diff}/>
        </>
      )}
      {isResolved && (
        <>
          <TTHeading isHappy={true}>Correct Properties:</TTHeading>
          <ShowDeviceProps data={data.desiredProperties}/>

        </>
      )}
    </div>
  );
};

export const TTHeading = ({ children, isHappy }: { children: React.ReactNode, isHappy: boolean }) => {
  return <div
    style={{
      margin: '6px 0',
      textTransform: 'uppercase',
      background: isHappy ? 'green' : 'red',
      padding: '0 4px 0 4px',
      display: 'block',
      color: 'white',
      fontWeight: 'bold',
    }}
  >{children}</div>;
};

export default DeviceIconToolTipText;
