import type { DatabaseDevices, GuardDeviceStateUnflattened } from '@phyllome/common';
import LatestGuardInfo from './LatestGuardInfo';
import FormGuardAlarm from './forms/FormGuardAlarm';
import GenericPing from '../Generic/control/GenericPing';

const ControlGuard = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<GuardDeviceStateUnflattened>;
}) => {

  return (
    <>
      <LatestGuardInfo deviceState={deviceState} />
      <FormGuardAlarm deviceState={deviceState} />
      <div>
        <GenericPing
          deviceId={deviceState.deviceId}
          deviceType='guard'
        />
      </div>
    </>
  );
};

export default ControlGuard;
