import {
  colors,

} from '@phyllome/common';
import RackBox from '../../../../components/factoryui/components/RackBox';
import makeColorSelected from '../../../../helpers/makeColorSelected';
import generateUniqueColors from '../../../../helpers/generateUniqueColours';
import { useMemo } from 'react';
import type { UseNDSLatestLookupReturn } from '../../../../devicespecific/NDS/hooks/useNDSLatestWithLookup';
import type { DatabaseTraySeasonPick } from '../../../../data/hooks/collections/useTraySeasonByBrokerId';

type OverlayTrayIrrigationSchedulesProps = {
  shelves: number;
  selectedTray?: string;
  selectedSeason?: string;
  onClick?: (traySeasonData: DatabaseTraySeasonPick) => void;
  data: DatabaseTraySeasonPick[];
  ndsData: UseNDSLatestLookupReturn;
};

const OverlayTraySeasons = ({
  shelves,
  selectedTray,
  selectedSeason,
  data,
  onClick = () => null,
}: OverlayTrayIrrigationSchedulesProps) => {

  const cMap = useMemo(() => {
    const foo = data.map((row) => {
      return row.seasonId;
    });

    const unique = [...new Set(foo)];
    const colorMap = generateUniqueColors(unique.length, 80);

    return unique.reduce<Record<string, string>>((accumulator, row, index) => {
      accumulator[row] = colorMap[index];
      return accumulator;
    }, {});
  }, [data]);

  return (
    <>
      {data.map((row, index) => {
        const objectHash = row.seasonId;

        const background = row.defaultNDSSchedule?.length
          ? cMap[objectHash]
          : 'red';

        const loc = `${row.locationColumn}/${row.locationShelf}/${row.locationRow}`;

        return (
          <RackBox
            toolTip={<div>{loc}<br/><strong>This is just the seasonId, but will fix this soon:</strong><br/>{row.seasonId}</div>}
            onClick={() => onClick(row)}
            key={index}
            zIndex={10}
            row={row.locationRow}
            shelf={row.locationShelf}
            shelves={shelves}
            isSelected={row.id === selectedTray}
            isGroupSelected={row.seasonId === selectedSeason}
            rackBoxStyleSelected={{
              border: '1px solid black',
              background: colors.ocean,
            }}
            rackBoxStyleHover={{
              background: makeColorSelected(background),
              transition: 'background 0.2s linear',
            }}
            rackBoxStyleGroupSelected={{
              opacity: 1,
              color: '#ffffff',
            }}
            style={{
              border: '1px solid transparent',
              transition: 'background 0.5s linear',
              cursor: 'pointer',
              background: background,
              color: selectedSeason ? '#aaa' : '#ffffff',
              textAlign: 'center',
            }}
          ></RackBox>
        );
      })}
    </>
  );
};

export default OverlayTraySeasons;
