import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import Phorm from '../../../../components/Form/Components/Phorm';
import PhormSubmit from '../../../../components/Form/PhormSubmit/PhormSubmit';
import PhormDateTime from '../../../../components/Form/Components/PhormDateTime';
import type { DatabaseSeason, WithId } from '@phyllome/common';
import PhormBlock from '../../../../components/Form/Components/PhormBlock';
import PhormTextField from '../../../../components/Form/Components/PhormTextField';
import { Box } from '@mui/material';
import * as yup from 'yup';
import PhormErrors from '../../../../components/Form/Components/PhormErrors';
import { useEndpointTraySeasonUpdateAllTrays } from '../../../../data/endpointHooks/endpointHooks';
import useAction from '../../../../data/hooks/useAction';
import FarmContext from '../../../../components/FarmProvider/FarmContext';

interface IProps {
  seasonData: WithId<DatabaseSeason>;
}

const schema = yup.object().shape({
  timestampHarvested: yup.date().required(),
  yield: yup.number().required(),
});

const FormEndSeason: FC<IProps> = ({ seasonData }) => {
  const { brokerId } = useContext(FarmContext);
  const [defaultValues, setDefaultValues] = useState({});

  const seasonHarvest = useAction('seasonHarvest');

  const updateAllTraysInSeason = useEndpointTraySeasonUpdateAllTrays();

  const Submit = (
    <PhormSubmit
      sx={{ background: 'pink' }}
      busy={updateAllTraysInSeason.status === 'RUNNING'}
      color='secondary'
    >
      {seasonData.state === 'stopped' ? 'End Season' : 'Edit Season'}
    </PhormSubmit>
  );

  useEffect(() => {
    const defaultSeasonValue = seasonData.trayHarvestEarliest;

    setDefaultValues({
      timestampHarvested: defaultSeasonValue || new Date(),
      yield: String(seasonData?.totalYieldHarvested || '0'),
      yieldLost: seasonData?.totalYieldLost || '0',
    });
  }, [seasonData]);

  useEffect(() => {
    if (seasonHarvest.status === 'ERROR') console.log(seasonHarvest.msg);
  }, [seasonHarvest.status, seasonHarvest.msg]);

  if (!seasonData) return null;
  return (
    <Phorm
      defaultValues={defaultValues}
      onSubmit={(values: any) => {
        seasonHarvest.run({
          brokerId,
          seasonId: seasonData.id,
          timestampHarvested: values.timestampHarvested.toISOString(),
          totalYield: Number(values.yield),
          totalYieldLost: Number(values.yieldLost),
        });
      }}
      busy={false}
      schema={schema}
    >
      <PhormBlock>
        <PhormDateTime
          name='timestampHarvested'
          label='Time Harvested/Growth Ended'
        />
      </PhormBlock>
      <Box pb={1}>
        The total yield of the season (Based on{' '}
        {(seasonData.trayNumberActual || 0) -
          (seasonData.trayNumberRejected || 0)}{' '}
        trays)
      </Box>
      <PhormBlock>
        <PhormTextField name='yield' label='Yield Packaged (grams)' />
      </PhormBlock>
      <PhormBlock>
        <PhormTextField name='yieldLost' label='Yield Lost (grams)' />
      </PhormBlock>
      <PhormErrors />
      <PhormBlock>{Submit}</PhormBlock>
    </Phorm>
  );
};

export default FormEndSeason;
