import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import { Stack } from '@mui/system';
import type {  TraybotDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionTraybotConfigSet,
} from '@phyllome/common';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { useCurrentSidebarDeviceData } from '../../../data/hooks/useCurrentSidebarDeviceData';

const FormTraybotConfigSetSpeed = () => {

  const { properties: latest, deviceId } = useCurrentSidebarDeviceData<TraybotDeviceStateUnflattened>();

  const columnId = latest.columnId;
  const controlLifecycle = useControlSend(
    mqttDefinitionTraybotConfigSet,
    String(columnId),
  );
  const activityLog = useActivityLog(`device/traybot/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTraybotConfigSet,
    {
      drive: {
        velocityLimit: latest?.drive?.velocityLimit || 0,
        homingLimit: latest?.drive?.homingLimit || 0,
        accelFast: latest?.drive?.accelFast || 0,
        accelSlow: latest?.drive?.accelSlow || 0,
      },
    },
  );

  return (
    <PaperSection
      heading='Traybot Speed and Acceleration'
      mb={2}
      subheading={mqttDefinitionTraybotConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              drive: {
                velocityLimit: data?.drive?.velocityLimit,
                homingLimit: data?.drive?.homingLimit,
                accelFast: data?.drive?.accelFast,
                accelSlow: data?.drive?.accelSlow,
              },
            },
            topicParams: {
              deviceId: latest?.deviceId,
            },
          });
          activityLog.add('Updated traybot speed config}', 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          drive: yup.object({
            velocityLimit: yup.number().min(0).max(100).required(),
            homingLimit: yup.number().min(0).max(10).required(),
            accelFast: yup
              .number()
              .min(0)
              .max(10)
              .moreThan(yup.ref('accelSlow'))
              .required(),
            accelSlow: yup
              .number()
              .min(0)
              .max(10)
              .lessThan(yup.ref('accelFast'))
              .required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <TextFieldElement
            label='Velocity Speed Limit'
            name='drive.velocityLimit'
            required
          />
          <TextFieldElement
            label='Homing Speed Limit'
            name='drive.homingLimit'
            required
          />
          <TextFieldElement
            label='Unloaded acceleration'
            name='drive.accelFast'
            required
          />
          <TextFieldElement
            label='Loaded acceleration'
            name='drive.accelSlow'
            required
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTraybotConfigSetSpeed;
