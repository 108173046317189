import type { DatabaseSeason } from '@phyllome/common';
import {  calculateTrayAgeInDays, colors } from '@phyllome/common';
import type { FC } from 'react';
import { Tooltip, Box, Stack } from '@mui/material';
import dateToString, { dateFormatTypes } from '../../helpers/dateToString';
import getColorByAge from '../../helpers/getColorByAge';

const maxWidth = 100;

export const SeasonTimelineMini: FC<{
  season: DatabaseSeason;
}> = ({ season }) => {

  const isSeeded = season.traySeedEarliest && season.traySeedEarliest !== null;
  const isStarted = season.growthStarted && season.growthStarted !== null;

  const isHarvested = season.trayHarvestLatest && season.trayHarvestLatest !== null;
  const isPaused = season.state === 'paused';
  const isStopped = season.state === 'stopped';

  const age = calculateTrayAgeInDays({
    timestampGrowthStart: season.growthStarted,
    timestampGrowthEnd: season.growthEnded,
  });

  let percentageComplete = season.growthEnded === null ? (age / season.durationDays) : 1;

  if (percentageComplete > 1) percentageComplete = 1;
  if (percentageComplete < 0) percentageComplete = 0;

  const width = (maxWidth * percentageComplete).toFixed(0) + 'px';

  const barColor = isStopped
    ? colors.earth3
    : isPaused
      ? colors.sunlight
      : getColorByAge(age);

  return (
    <>
      <Stack direction='row' spacing={1}>
        <Tooltip
          arrow
          title={
            'Seeded ' +
            dateToString(season.traySeedEarliest, dateFormatTypes.DAYMONTH)
          }
        >
          <Box
            sx={{
              height: '18px',
              width: '18px',
              borderRadius: '9px',
              backgroundColor: isSeeded ? colors.earth1 : colors.fog,
            }}
          />
        </Tooltip>
        <Tooltip
          arrow
          title={
            isStarted &&
            'Started ' +
              dateToString(season.growthStarted, dateFormatTypes.DAYMONTH)
          }
        >
          <Box sx={{ position: 'relative', width: '100px' }}>
            <Box
              sx={{
                height: '18px',
                width: '100px',
                borderRadius: '9px',
                backgroundColor: colors.fog,
                position: 'absolute',
              }}
            />
            <Box
              sx={{
                height: '18px',
                width: width,
                borderRadius: '9px',
                backgroundColor: barColor,
                position: 'absolute',
                textAlign: 'center',
                color: '#fff',
                display: isStarted ? 'block' : 'none',
              }}
            >
              {age}
              {percentageComplete > 0.5 && ' days'}
            </Box>
          </Box>
        </Tooltip>
        <Tooltip
          arrow
          title={
            isHarvested
              ? 'Harvested ' +
                dateToString(
                  season.trayHarvestEarliest,
                  dateFormatTypes.DAYMONTH,
                )
              : ''
          }
        >
          <Box
            sx={{
              height: '18px',
              width: '18px',
              borderRadius: '9px',
              backgroundColor: isHarvested ? colors.ripening : colors.fog,
            }}
          />
        </Tooltip>
      </Stack>
    </>
  );
};

export default SeasonTimelineMini;
