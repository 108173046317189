import { Stack } from '@mui/system';
import {  SelectElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, FertigatorDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionFertigatorCalibrate,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { Typography } from '@mui/material';
import ChipBool from '../../../components/puregui/ChipBool/ChipBool';
import { useMemo } from 'react';

const FormFertigatorCalibratePH = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<FertigatorDeviceStateUnflattened> ;
}) => {
  const deviceId = deviceState.deviceId;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorCalibrate,
    deviceId,
  );
  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = { select: '1' };

  return (
    <PaperSection
      heading='Calibrate pH probe'
      mb={2}
      subheading={mqttDefinitionFertigatorCalibrate.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              ph: data?.select,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Calibrated pH probe to ${data.select}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          ec: yup
            .object({
              do: yup.number().required(),
            })
            .required(),
        })}
      >
        <Stack spacing={2}>
          <Stack spacing={2} direction='row'>
            <ChipBool data={latest.calibration?.ph.low} label='Low' />
            <ChipBool data={latest.calibration?.ph.mid} label='Mid' />
            <ChipBool data={latest.calibration?.ph.high} label='High' />
          </Stack>
          <Typography variant='body1'>Calibrating pH 7.0 will reset the other values, so do this first.</Typography>
          <SelectElement
            fullWidth
            label='Select'
            name='select'
            options={[
              {
                id: '0',
                label: '4.0',
              },
              {
                id: '1',
                label: '7.0',
              },
              {
                id: '2',
                label: '10.0',
              },
            ]}
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorCalibratePH;
