import type { ReactNode } from 'react';
import type { Timestamp } from 'firebase/firestore';
import type { AnyObject } from '@phyllome/common';
import { Box, Table, TableBody  } from '@mui/material';
// Icons
import LinkIcon from '@mui/icons-material/Link';
import dateToString, { dateFormatTypes } from '../../helpers/dateToString';
import type { DataValue, LatestDeviceDataProps, RenderValueOptions } from './types';
import DataRow from './components/DataRow';
import { checkEmptyData, formatAndOrderData } from './helpers/helpers';
import useDeviceType from '../../data/useDeviceType';
import HomeIcon from '@mui/icons-material/Home';
import { CompassCalibration } from '@mui/icons-material';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const ObjectToTable: React.FC<LatestDeviceDataProps> = ({ data, isNested = false }) => {
  const deviceType = useDeviceType();

  return deviceType.isDesktop ? (
    <ObjectToTableDesktop data={data} isNested={isNested} />
  ) : (
    <ObjectToTableMobile data={data} isNested={isNested} />
  );
};

export const renderValue = (
  item: DataValue,
  isNested: boolean = false,
  options: RenderValueOptions,
  isMobile = false,
): ReactNode => {

  const { DataRow, ObjectToTable } = options;
  const key = `${item.type}.${item.key}.${JSON.stringify(item.value)}`;

  switch (item.type) {
    case 'brokerId':
      return (
        <DataRow
          highlighted
          itemKey={item.key}
          key={key}
          isNested={isNested}
          icon={<HomeIcon />}
          isMobile={isMobile}
        >
          {String(item.value)}
        </DataRow>
      );

    case 'deviceId':
      return (
        <DataRow
          highlighted
          itemKey={item.key}
          key={key}
          isNested={isNested}
          icon={<FingerprintIcon />}
          isMobile={isMobile}

        >
          {String(item.value)}
        </DataRow>
      );

    case 'deviceType':
      return (
        <DataRow
          highlighted
          itemKey={item.key}
          key={key}
          isNested={isNested}
          icon={<CompassCalibration/>}
          isMobile={isMobile}

        >
          {String(item.value).toUpperCase()}
        </DataRow>
      );

    case 'alias':
      return (
        <DataRow
          highlighted
          itemKey={item.key}
          key={key}
          isNested={isNested}
          icon={<LinkIcon/>}
          isMobile={isMobile}

        >
          {String(item.value)}
        </DataRow>
      );

    case 'timestamp':
      return (
        <DataRow
          highlighted
          itemKey={item.key}
          key={key}
          isNested={isNested}
          icon={<AccessTimeIcon />}
          isMobile={isMobile}

        >
          {dateToString(item.value as Timestamp, dateFormatTypes.FULL)}
        </DataRow>
      );

    case 'object':
      return (
        <DataRow
          itemKey={item.key}
          key={key}
          isNested={isNested}
          isMobile={isMobile}
          isObject={true}
        >
          <ObjectToTable
            data={item.value as AnyObject}
            isNested={true}
          />
        </DataRow>
      );

    case 'boolean':
      return (
        <DataRow
          itemKey={item.key}
          key={key}
          isNested={isNested}
          isMobile={isMobile}

        >
          <span style={{ color: !item.value ? 'red' : 'green' }}>
            {String(item.value)}
          </span>
        </DataRow>
      );
    case 'undefined':
      return (
        <DataRow
          itemKey={item.key}
          key={key}
          isNested={isNested}
          isMobile={isMobile}

        >
          <span style={{ color: '#aaa' }}>
            {String(item.value)}
          </span>
        </DataRow>
      );
    case 'array':
      return (
        <DataRow
          itemKey={item.key}
          key={key}
          isNested={isNested}
          isMobile={isMobile}
        >
          <ObjectToTable
            data={(item.value as []).reduce((acc: { [key: number]: unknown }, value, index) => {
              acc[index] = value;
              return acc;
            }, {})}
            isNested={true}
          />
        </DataRow>
      );

    default:
      return (
        <DataRow
          itemKey={item.key}
          key={key}
          isNested={isNested}
          isMobile={isMobile}

        >
          {String(item.value)}
        </DataRow>
      );
  }
};

const ObjectToTableDesktop: React.FC<LatestDeviceDataProps> = ({ data, isNested = false }) => {
  const emptyCheck = checkEmptyData(data);

  if (emptyCheck !== null) {
    return <span style={{ color: '#bbb' }}>{emptyCheck}</span>;
  }

  const formattedOrderedByKey = formatAndOrderData(data as AnyObject);

  return (
    <Table
      size='small'
      sx={{
        '& .MuiTableCell-root': {
          py: 0,
          px: isNested ? 0 : undefined,
        },
      }}
    >
      <TableBody>
        {formattedOrderedByKey.map(item =>
          renderValue(item, isNested, { DataRow, ObjectToTable }),
        )}
      </TableBody>
    </Table>
  );
};

const ObjectToTableMobile: React.FC<LatestDeviceDataProps> = ({ data, isNested = false }) => {
  const emptyCheck = checkEmptyData(data);

  if (emptyCheck !== null) {
    return <span style={{ color: '#bbb' }}>{emptyCheck}</span>;
  }

  const formattedOrderedByKey = formatAndOrderData(data as AnyObject);

  return (
    <Box sx={{ marginBottom: '5px' }}>
      <Box>
        {formattedOrderedByKey.map(item =>
          renderValue(item, isNested, { DataRow, ObjectToTable }, true),
        )}
      </Box>
    </Box>
  );
};

export default ObjectToTable;