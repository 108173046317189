import type { FC, PropsWithChildren } from 'react';
import { FormGroup } from '@mui/material';

interface IProps {
  noPad?: boolean;
}

const PhormBlock: FC<PropsWithChildren<IProps>> = ({
  children,
  noPad = false,
}) => {
  return (
    <FormGroup
      row
      sx={{
        marginBottom: `${noPad ? 0 : 16}px`,
      }}
    >
      {children}
    </FormGroup>
  );
};

export default PhormBlock;
