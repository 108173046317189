import Phorm from '../../../components/Form/Components/Phorm';
import PhormErrors from '../../../components/Form/Components/PhormErrors';
import PhormSubmit from '../../../components/Form/PhormSubmit/PhormSubmit';
import AppDrawerTitle from '../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import { Box, Alert, AlertTitle, Stack, Divider, InputAdornment } from '@mui/material';
import PhormTextField from '../../../components/Form/Components/PhormTextField';
import * as yup from 'yup';
import { string, number } from 'yup';
import { useEndpointSeedUpdate } from '../../../data/endpointHooks/endpointHooks';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useAction from '../../../data/hooks/useAction';
import { useSnackbar } from 'notistack';
import ScheduleViewNDS from '../../../components/ScheduleView/ScheduleViewNDS';
import ScheduleViewLighting from '../../../components/ScheduleView/ScheduleViewLighting';
import {
  DosingScheduleEdit,
  LightingScheduleEdit,
} from '../../../components/ScheduleEdit/ScheduleEdit';
import useSeed from '../../../data/hooks/collections/useSeed';
import useActivityLog from '../../../data/hooks/useActivityLog';

const SeedsSidebarEdit = ({ seedId }: { seedId: string }) => {
  const updateSeed = useEndpointSeedUpdate();
  const seedScheduleUpdate = useAction('seedScheduleUpdate');
  const seedLightingScheduleUpdate = useAction('seedLightingScheduleUpdate');

  const activityLog = useActivityLog(`seed/${seedId}/notes`);

  const seedData = useSeed(seedId);

  const { enqueueSnackbar } = useSnackbar();

  if (!seedData) return null;
  return (
    <>
      <AppDrawerTitle

        title={`Edit Seed (${seedData?.name}/${seedData?.variety})`}
      />
      <Box p={2}>
        <PaperSection heading='Seed Details'>
          <Phorm
            schema={yup.object().shape({
              name: string().required(),
              variety: string().required(),
              defaultSeedAmountPerTray: number().required(),
              pricePerMillion: number().required(),
              notes: string(),
              durationDays: number().required(),
            })}
            defaultValues={seedData}
            onSubmit={(values) => {
              updateSeed
                .run({
                  id: seedId,
                  defaultFertigationTargetEC: 0,
                  defaultFertigationTargetPH: 0,
                  defaultSeedAmountPerTray: values.defaultSeedAmountPerTray,
                  name: values.name,
                  notes: values.notes || '',
                  pricePerMillion: values.pricePerMillion,
                  variety: values.variety,
                  durationDays: values.durationDays,
                })
                .then(() => {
                  enqueueSnackbar('Seed profile saved', { variant: 'success' });
                }).then(() => {
                  activityLog.add('Updated seed details', 'OPERATION');
                })
                .catch((e) => {
                  enqueueSnackbar('There was a problem saving, ' + e, {
                    variant: 'error',
                  });
                });
            }}
            busy={false}
          >
            <Box mt={2} p={2}>
              <Stack spacing={2} direction='row' mb={2}>
                <PhormTextField name='name' label='Seed Name' size='medium' />
                <PhormTextField
                  name='variety'
                  label='Seed Variety'
                  size='medium'
                />
              </Stack>
              <Divider />
              <Stack spacing={2} mt={2} mb={2}>
                <PhormTextField
                  name='defaultSeedAmountPerTray'
                  label='Sowing Density'
                  size='medium'
                  InputProps={{ endAdornment: <InputAdornment position='end'>seeds per tray</InputAdornment> }}
                />
                <PhormTextField
                  name='durationDays'
                  label='Season duration'
                  size='medium'
                  InputProps={{ endAdornment: <InputAdornment position='end'>days</InputAdornment> }}
                />
                <PhormTextField
                  name='pricePerMillion'
                  label='Price per million seeds'
                  size='medium'
                  InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                />
                <Alert severity='info'>Note price excludes GST</Alert>
              </Stack>
              <Divider />
              <Stack spacing={2} mt={2}>
                <PhormTextField
                  fullWidth={true}
                  multiline={true}
                  minRows={3}
                  maxRows={10}
                  name='notes'
                  label='Notes'
                />
              </Stack>
              <Stack spacing={2} mt={2}>
                <PhormErrors />
                <PhormSubmit busy={updateSeed.status === 'RUNNING'}>
                  Update Seed
                </PhormSubmit>
              </Stack>
            </Box>
          </Phorm>
          {updateSeed.status === 'ERROR' && (
            <Box mt={2}>
              <Alert severity='error'>Something went wrong</Alert>
            </Box>
          )}
        </PaperSection>
      </Box>
      {!seedData?.defaultIrrigationSchedule ||
        (seedData?.defaultIrrigationSchedule?.length == 0 && (
          <Box p={2}>
            <Alert severity='error' variant='outlined'>
              <AlertTitle>Incomplete or missing irrigation schedule</AlertTitle>
              Seed profile is missing irrigation schedule. Add at least one
              entry in the irrigation schedule to enable the seed profile to be
              displayed in season planning.
            </Alert>
          </Box>
        ))}
      <Box p={2}>

        <PaperSection heading='Irrigation Schedule'>
          <Box mb={2}>
            <ScheduleViewNDS
              seedSchedule={seedData?.defaultIrrigationSchedule}
              context='seed'
            />
          </Box>
          <DosingScheduleEdit
            isSaving={false}
            data={seedData.defaultIrrigationSchedule}
            onSubmit={(payload) => {
              seedScheduleUpdate
                .run({
                  seedId,
                  schedule: payload as any,
                })
                .then(() => {
                  enqueueSnackbar('Irrigation schedule updated', {
                    variant: 'success',
                  });
                });
            }}
          />
        </PaperSection>
        <PaperSection heading='Lighting Schedule' mt={3}>
          <ScheduleViewLighting
            seedSchedule={seedData?.defaultLightingSchedule}
            context='seed'
          />
          <LightingScheduleEdit
            isSaving={seedLightingScheduleUpdate.status === 'RUNNING'}
            data={seedData.defaultLightingSchedule}
            onSubmit={(payload) => {
              seedLightingScheduleUpdate
                .run({
                  seedId,
                  lightingSchedule: payload as any,
                })
                .then(() => {
                  enqueueSnackbar('Lighting schedule updated', {
                    variant: 'success',
                  });
                });
            }}
          />
        </PaperSection>
      </Box>
    </>
  );
};

export default SeedsSidebarEdit;
