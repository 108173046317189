import { Stack, Divider } from '@mui/material';
import type { FC } from 'react';
import HeroItem from './HeroItem';

export type HeroItemType = {
  label: string;
  text?: string;
  sup?: string;
  color?: string;
};

type IHeroGroupProps = {
  items: Array<HeroItemType>;
};

const HeroGroup: FC<IHeroGroupProps> = ({ items }) => {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      flexWrap='wrap'
      mb={2}
      justifyContent='center'
      alignItems='center'
      sx={{
        gap: { xs: 1, sm: 2, md: 3 },
      }}
    >
      {items.map((item, index) => (
        <>
          <HeroItem
            key={`item-${index}`}
            label={item.label}
            text={item.text}
            sup={item.sup}
            color={item.color}
          />
          {index < items.length - 1 && (
            <>
              <Divider
                orientation='horizontal'
                flexItem
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  alignSelf: 'stretch',
                }}
              />
              <Divider
                orientation='vertical'
                flexItem
                sx={{
                  display: { xs: 'none', sm: 'block' },
                  alignSelf: 'stretch',
                }}
              />
            </>
          )}
        </>
      ))}
    </Stack>
  );
};

export default HeroGroup;