import { ToggleButtonGroup, ToggleButton, styled } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { SprinklerContext } from './SprinkerContext';
import { colors } from '@phyllome/common';
import useRoute from '../../../data/hooks/useRoute';

const CustomToggle = styled(ToggleButton)({
  '&.Mui-selected': {
    background: colors.ripening,
    color: 'white',
  },
  '&.Mui-selected:hover': {
    background: colors.ripening,
    color: 'white',
  },
  '&.MuiToggleButton-root': {
    border: '1px solid #aaa',
  },
});

const Filters = () => {
  const router = useRoute();
  const tabValue = router.mainQuery.tab;
  const [primaryTab, setPrimaryTab] = useState('SIMPLE');
  const [secondaryTab, setSecondaryTab] = useState(() => []);
  const sContext = useContext(SprinklerContext);

  const handlePrimaryFormat = (event: any, newFormats: any) => {
    setPrimaryTab(newFormats);
    sContext.dispatch({ type: 'SET_PRIMARY_LAYER', payload: newFormats });
    router.navMainQuery({ tab: newFormats });
  };

  const handleSecondaryFormat = (event: any, newFormats: any) => {
    setSecondaryTab(newFormats);

    sContext.dispatch({ type: 'SET_SECONDARY_LAYER', payload: newFormats });
  };

  useEffect(() => {
    setPrimaryTab(tabValue);
  }, [tabValue]);
  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={primaryTab}
        onChange={handlePrimaryFormat}
        aria-label='text formatting'
        color='primary'
        size='small'
        sx={{ marginRight: '4px' }}
      >
        <CustomToggle value='SIMPLE' aria-label='bold'>
          Standard
        </CustomToggle>
        <CustomToggle value='FIRMWARE' aria-label='italic'>
          Firmware
        </CustomToggle>
        <CustomToggle value='WATERRATE' aria-label='italic'>
          Water Rates
        </CustomToggle>
        <CustomToggle value='CURRENTDAY' aria-label='italic'>
          Current Day
        </CustomToggle>

      </ToggleButtonGroup>
      <ToggleButtonGroup
        value={secondaryTab}
        onChange={handleSecondaryFormat}
        aria-label='text formatting'
        color='primary'
        size='small'
      >
        <CustomToggle value='VALVES' aria-label='bold'>
          Valves
        </CustomToggle>
        <CustomToggle value='ENABLED' aria-label='italic'>
          Enabled
        </CustomToggle>

      </ToggleButtonGroup>
    </>
  );
};

export default Filters;
