import { Tabs, Tab, Box, Alert } from '@mui/material';
import { colors } from '@phyllome/common';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import FarmContext from '../../components/FarmProvider/FarmContext';
import useLatestCo2 from './hooks/useLatestCo2';
import LatestCo2 from './LatestCo2';
import ControlCo2 from './ControlCo2';
import GraphCo2 from './GraphCo2';
import ConfigCo2 from './ConfigCo2';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import LatestCo2Info from './LatestCo2Info';
import LatestCo2Levels from './ControlLevelsCo2';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import PhyTabPanel from '../../components/PhyTabPanel/PhyTabPanel';
import useRoute from '../../data/hooks/useRoute';
import PendingDevice from '../../components/PendingDevice/PendingDevice';

type IProps = {
  deviceId: string;
};

const SideBarEntryCo2: FC<IProps> = ({ deviceId }) => {
  const { brokerId } = useContext(FarmContext);
  const router = useRoute();
  const [value, setValue] = useState<any>(Number(router.sidebarQuery.tab) || 0);

  const latestDeviceInfo = useLatestCo2(deviceId);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    router.navSideQuery({ tab: value });
  }, [value, router]);

  if (!latestDeviceInfo)
    return (
      <Box p={2}>
        <Alert severity='info'>No Latest Data!</Alert>
      </Box>
    );
  return (
    <>
      <AppDrawerTitle title={`co2/${latestDeviceInfo.deviceId}`} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor='inherit'
        variant='scrollable'
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label='Latest' value={0} />
        <Tab label='Graphs' value={1} />
        <Tab label='Control' value={2} />
        <Tab label='Config' value={3} />
        <Tab label='Levels' value={4} />
        <Tab label='Activity' value={5} />
        <Tab label='Alerts' value={6} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <LatestCo2Info deviceState={latestDeviceInfo} />
        <PendingDevice deviceState={latestDeviceInfo} />
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='co2'
          count={1}
        />
        <LatestCo2 deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <GraphCo2 deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <ControlCo2 deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <ConfigCo2 deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <LatestCo2Levels deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <ActivityLog
          identifier={`device/co2/${deviceId}/notes`}
          heading='Activity Log'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={6}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='co2'
        />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryCo2;
