import GenericPing from '../Generic/control/GenericPing';
import FormIrrigationSetFillValve from './forms/FormIrrigationSetFillValve';
import LatestIrrigationInfo from './LatestIrrigationInfo';
import type { DatabaseDevices, IrrigationDeviceStateUnflattened } from '@phyllome/common';
import FormIrrigationSetModesDischarge from './forms/FormIrrigationSetModesDischarge';
import FormIrrigationSetModesTransfer from './forms/FormIrrigationSetModesTransfer';

const ControlIrrigation = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<IrrigationDeviceStateUnflattened> ;
}) => {

  const { brokerId } = deviceState;

  return (
    <>
      <LatestIrrigationInfo deviceState={deviceState} />
      <div>
        <GenericPing
          deviceId={deviceState.deviceId}
          deviceType='irrigation'
        />
      </div>
      {brokerId !== 'mcp-nursery' && (
        <>
          <FormIrrigationSetFillValve deviceState={deviceState} />
          <FormIrrigationSetModesDischarge deviceState={deviceState} />
          <FormIrrigationSetModesTransfer deviceState={deviceState} />
        </>
      )}
    </>
  );
};

export default ControlIrrigation;
