import type { AirDeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import useAction from '../../data/hooks/useAction';
import Markdown from 'react-markdown';
import Phorm from '../../components/Form/Components/Phorm';
import PhormTextField from '../../components/Form/Components/PhormTextField';
import PhormBlock from '../../components/Form/Components/PhormBlock';
import PhormSubmit from '../../components/Form/PhormSubmit/PhormSubmit';
import { Alert, AlertTitle } from '@mui/material';
import { useMemo } from 'react';

const AnalysisAir = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<AirDeviceStateUnflattened>;
}) => {
  const { deviceId, brokerId } = deviceState;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const getAction = useAction('deviceAnalyse');

  if (getAction.status === 'ERROR') {
    return <Alert severity='error'><AlertTitle>Error</AlertTitle>There was a problem analysing this using AI</Alert>;
  }

  const prompt = `The location of this sensor is at ${latest.label}.
  Analyse the timeseries data from this sensor. 

  - Ignore WiFi signal strength
  - Don't analyse the VPD or dew point
  - Look for any potential events.
  - Talk about temperature, humidity, co2, with headings for each.
  - Talk about any correlations.
  - Talk about how we could improve the yield of crops planted in this environment
 
  
 
  `;

  return (
    <div style={{ width: '100%' }}>
      <Phorm
        onSubmit={(values) => {
          getAction.run({
            brokerId: brokerId,
            deviceId: deviceId,
            deviceType: 'air',
            text: values.prompt,
          });
        }}
        defaultValues={{
          prompt: prompt,
        }}
      >
        <PhormBlock>
          <PhormTextField name='prompt' label='Prompt' multiline />
        </PhormBlock>
        <PhormBlock>
          <PhormSubmit>Fetch Analysis Using AI</PhormSubmit>
        </PhormBlock>
      </Phorm>
      {getAction.status === 'RUNNING' && <div>Loading...</div>}
      {getAction.status === 'DONE' && (
        <div>
          <Markdown>{String(getAction.msg)}</Markdown>
        </div>
      )}
    </div>
  );
};

export default AnalysisAir;
