import { Tabs, Tab, Box, Alert } from '@mui/material';
import { colors } from '@phyllome/common';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import TabPanel from '../../components/TabPanel/TabPanel';
import FarmContext from '../../components/FarmProvider/FarmContext';
import ConfigHarvester from './ConfigHarvester';
import GraphHarvester from './GraphHarvester';
import LatestHarvester from './LatestHarvester';
import useLatestHarvester from './hooks/useLatestHarvester';
import LatestHarvesterInfo from './LatestHarvesterInfo';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import useRoute from '../../data/hooks/useRoute';
import PendingDevice from '../../components/PendingDevice/PendingDevice';

type IProps = {
  deviceId: string;
};

const SideBarEntryHarvester: FC<IProps> = ({ deviceId }) => {
  const { brokerId } = useContext(FarmContext);
  const router = useRoute();
  const [value, setValue] = useState<any>(Number(router.sidebarQuery.tab) || 0);

  const latestDeviceInfo = useLatestHarvester(deviceId);
  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    router.navSideQuery({ tab: value });
  }, [value, router]);

  if (!latestDeviceInfo?.lastAccessed)
    return (
      <Box p={2}>
        <Alert severity='info'>No Latest Data for harvester/{deviceId}!</Alert>
      </Box>
    );
  return (
    <>
      <AppDrawerTitle title={`harvester/${latestDeviceInfo.deviceId}`} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor='inherit'
        variant='scrollable'
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label='Latest' value={0} />
        <Tab label='Graphs' value={1} />
        <Tab label='Config' value={2} />
        <Tab label='Activity' value={3} />
        <Tab label='Alerts' value={4} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <LatestHarvesterInfo latest={latestDeviceInfo.properties} />
        <PendingDevice deviceState={latestDeviceInfo} />
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='harvester'
          count={1}
        />
        <LatestHarvester latest={latestDeviceInfo.properties} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GraphHarvester latest={latestDeviceInfo.properties} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ConfigHarvester latest={latestDeviceInfo.properties} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ActivityLog
          identifier={`device/harvester/${deviceId}/notes`}
          heading='Activity Log'
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='sentry'
        />
      </TabPanel>
    </>
  );
};

export default SideBarEntryHarvester;
