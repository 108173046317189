import type { FC } from 'react';
import {
  faCrosshairs,
  faDatabase,
  faDesktop,
  faElevator,
  faInbox,
  faLayerGroup,
  faScissors,
  faSeedling,
  faThermometerHalf,
  faTint,
  faTractor,
  faWater,
  faWeight,
  faLightbulb,
  faFlask,
  faWind,
  faCamera,
  faBolt,
  faMicrochip,
  faShield,
  faCube,
  faRoute,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { DeviceType } from '@phyllome/common';
import { PhyconFA } from '../puregui/Phycon/Phycon';

interface IProps {
  deviceType: DeviceType;
  color?: string;
  noMargin?: boolean;
  opacity?: number;
}
export const deviceTypeMap: { [key: string]: IconDefinition } = {
  air: faThermometerHalf,
  camera: faCamera,
  cloud: faDatabase,
  co2: faWind,
  consoleUI: faDesktop,
  conveyor: faRoute,
  fertigator: faFlask,
  guard: faShield,
  harvester: faScissors,
  irrigation: faWater,
  lift: faElevator,
  liftcore: faMicrochip,
  lighting: faLightbulb,
  lightingcore: faBolt,
  nds: faTint,
  packager: faCube,
  register: faLayerGroup,
  seeder: faSeedling,
  sentry: faCrosshairs,
  transfer: faRoute,
  tray: faInbox,
  traybot: faTractor,
  weightPacking: faWeight,
};

const DeviceTypeToIcon: FC<IProps> = ({
  deviceType,
  color,
  noMargin = false,
  opacity = 1,
}) => {
  if (deviceTypeMap[deviceType]) {
    return (
      <PhyconFA
        icon={deviceTypeMap[deviceType]}
        color={color}
        noMargin={noMargin}
        opacity={opacity}
      />
    );
  } else {
    return <PhyconFA icon={faQuestion} color={color} noMargin={noMargin} />;
  }
};

export default DeviceTypeToIcon;
