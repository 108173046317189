import type { DatabaseSeed, WithId } from '@phyllome/common';
import {
  type DatabaseSeason,
  type DatabaseTraySeason,
  calculateSeasonAgeInDays,
  compareSchedules,
  mqttDefinitionNDSSet,
} from '@phyllome/common';
import { type FC, useEffect, useState } from 'react';
import useSeed from '../../../../data/hooks/collections/useSeed';
import { useEndpointTraySeasonUpdate } from '../../../../data/endpointHooks/endpointHooks';
import { Box, Button } from '@mui/material';
import useControlSend from '../../../../data/hooks/useControlSend';
import ControlConnect from '../../../../components/ControlConnect/ControlConnect';
import useNDSByLocation from '../../../../devicespecific/NDS/hooks/useNDSByLocation';
import ScheduleViewNDS from '../../../../components/ScheduleView/ScheduleViewNDS';
import { DosingScheduleEdit } from '../../../../components/ScheduleEdit/ScheduleEdit';
import useActivityLog from '../../../../data/hooks/useActivityLog';
import { useSnackbar } from 'notistack';

type IProps = {
  traySeasonId: string;
  seasonData: WithId<DatabaseSeason>;
  locationColumn: number;
  locationShelf: number;
  locationRow: number;
  traySeasonData: DatabaseTraySeason;
};

const TraySeasonNDSSchedule: FC<IProps> = ({
  traySeasonId,
  seasonData,
  traySeasonData,
}) => {
  const lifeCycleRequest = useControlSend(
    mqttDefinitionNDSSet,
    `${traySeasonId}`,
  );
  const [sched, setSched] = useState<DatabaseSeed['defaultIrrigationSchedule']>(
    [],
  );
  const [needsToBeUpdated, setNeedsToBeUpdated] = useState<boolean>(false);
  const seedData = useSeed(seasonData.seedId || 'nothing');
  const trayUpdate = useEndpointTraySeasonUpdate();
  const { enqueueSnackbar } = useSnackbar();
  const activityLog = useActivityLog(`traySeason/${traySeasonId}/notes`);

  const { data }  = useNDSByLocation({
    column: traySeasonData.locationColumn,
    shelf: traySeasonData.locationShelf,
    row: traySeasonData.locationRow,
  });

  const sprinklerData = data?.properties;

  useEffect(() => {
    // there's no default schedule
    let s4;

    if (!traySeasonData?.defaultNDSSchedule?.length) {
      s4 = compareSchedules(
        seedData?.defaultIrrigationSchedule,
        sprinklerData?.schedule as any,
      );
    } else {
      s4 = compareSchedules(
        sprinklerData?.schedule as any,
        traySeasonData?.defaultNDSSchedule,
      );
    }

    setNeedsToBeUpdated(!s4);
    if (traySeasonData?.defaultNDSSchedule?.length) {
      setSched(traySeasonData.defaultNDSSchedule);
    } else {
      setSched(seedData?.defaultIrrigationSchedule || []);
    }
  }, [
    seedData?.defaultIrrigationSchedule,
    sprinklerData?.schedule,
    traySeasonData?.defaultNDSSchedule,
  ]);
  return (
    <>
      <Box mb={2}>
        <ScheduleViewNDS
          seedSchedule={seedData?.defaultIrrigationSchedule}
          seasonSchedule={seasonData?.defaultNDSSchedule}
          traySchedule={traySeasonData?.defaultNDSSchedule}
          sprinklerSchedule={sprinklerData?.schedule}
          context='tray'
        />
      </Box>
      {needsToBeUpdated && (
        <Box mb={4} textAlign='center'>
          <Button
            disabled={
              lifeCycleRequest.status === 'started' ||
              lifeCycleRequest.status === 'mcp'
            }
            variant='contained'
            color='secondary'
            onClick={() => {

              lifeCycleRequest.run({
                payload: {
                  schedule: sched,
                  currentDay: calculateSeasonAgeInDays({
                    growthStarted: seasonData.growthStarted,
                    growthEnded: seasonData.growthEnded,
                  }),
                },
                topicParams: {
                  column: String(traySeasonData.locationColumn),
                  shelf: String(traySeasonData.locationShelf),
                  row: String(traySeasonData.locationRow),
                },
              }).then(() => {
                activityLog.add('Pushed irrigation schedule to NDS', 'OPERATION');
              });
            }}
          >
            send schedule to single sprinkler
          </Button>
          <ControlConnect data={lifeCycleRequest} />
        </Box>
      )}
      <DosingScheduleEdit
        isSaving={trayUpdate.status === 'RUNNING'}
        data={sched}
        onSubmit={(data) => {
          trayUpdate.run({
            brokerId: traySeasonData.brokerId,
            id: traySeasonId,
            defaultNDSSchedule: data as any,
          }) .then(() => {
            enqueueSnackbar('Irrigation schedule updated', {
              variant: 'success',
            });
          }).then(() => {
            activityLog.add('Updated irrigation schedule', 'OPERATION');
          });
        }}
      />
    </>
  );
};

export default TraySeasonNDSSchedule;
