import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, IrrigationDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionIrrigationConfigSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Alert, AlertTitle } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormIrrigationConfigSetNurserySump = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<IrrigationDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionIrrigationConfigSet,
    deviceId,
  );

  const activityLog = useActivityLog(`device/irrigation/${deviceId}/notes`);
  const defaultValues = formCreateDefaultValues(
    mqttDefinitionIrrigationConfigSet,
    {
      sumpFlushDelayMinutes: latest?.sumpFlushDelayMinutes || 0,
      sumpLowLevelTimeoutMs: latest?.sumpLowLevelTimeoutMs || 0,
    },
  );

  return (
    <PaperSection
      heading='Sump Pump Settings'
      mb={2}
      subheading={mqttDefinitionIrrigationConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='irrigation'
        sumpFlushDelayMinutes={latest.sumpFlushDelayMinutes}
        sumpLowLevelTimeoutMs={latest.sumpLowLevelTimeoutMs}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              sumpFlushDelayMinutes: data.sumpFlushDelayMinutes,
              sumpLowLevelTimeoutMs: data.sumpLowLevelTimeoutMs,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });

          activityLog.add(
            `Set sump pump settings to flush interval: ${data.sumpFlushDelayMinutes} and timeout: ${data.sumpLowLevelTimeoutMs}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          sumpFlushDelayMinutes: yup.number().required(),
          sumpLowLevelTimeoutMs: yup.number().required(),
        })}
      >
        <Stack spacing={2}>
          <Alert severity='warning'>
            <AlertTitle>Requires restart</AlertTitle>These settings require a
            device restart to take effect
          </Alert>
          <p>Set the time between interval sump flushes.</p>
          <TextFieldElement
            label='Sump flush interval (minutes)'
            name='sumpFlushDelayMinutes'
          />
          <p>
            Set the duration the sump pump runs for when it is engaged (both the
            interval flush and the low level flush)
          </p>
          <TextFieldElement
            label='Sump timeout (ms)'
            name='sumpLowLevelTimeoutMs'
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormIrrigationConfigSetNurserySump;
