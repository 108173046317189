import type { FC } from 'react';
import { useContext, useEffect } from 'react';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import DeviceTypeToIcon from '../../../components/DeviceTypeToIcon/DeviceTypeToIcon';
import { SprinklerProvider } from './SprinkerContext';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import ScaffoldRack from '../../../components/factoryui/ScaffoldRack/ScaffoldRack';
import OverLaySprinklerEntry from './overlays/OverLaySprinklerEntry';
import Filters from './Filters';
import type { ColumnSpec } from '@phyllome/common';
import DeviceWorker from '../../../components/DevicesProvider/DeviceWorker';
import { brokerIdAtom } from '../../../data/atoms/websocketFilters';
import { useAtomValue } from 'jotai';

const Sprinklers: FC<{ brokerId: string }> = () => {
  const { columnSpec } = useContext(FarmContext);
  const brokerId = useAtomValue(brokerIdAtom);

  useEffect(() => {

    if (brokerId) {
      console.log('Starting NDS fetching for brokerId:', brokerId);
      DeviceWorker.startFetchingNDS(brokerId);
    } else {
      console.log('No brokerId provided, not starting NDS fetching.');
    }
    return () => {

      if (brokerId) {
        console.log('Stopping NDS fetching for brokerId:', brokerId);
        DeviceWorker.stopFetchingNDS();
      }
    };

  }, [brokerId]);
  return (
    <ContainerPrimary
      title='Sprinklers'
      icon={<DeviceTypeToIcon deviceType='nds' />}
    >

      <SprinklerProvider>
        <Filters />
        <Scaffolds columnSpec={columnSpec} />
      </SprinklerProvider>

    </ContainerPrimary>
  );
};

const Scaffolds = ({
  columnSpec,
}: {
  columnSpec: ColumnSpec[];
}) => {

  return columnSpec.map((_column, index) => {
    return (
      <div style={{ paddingTop: '8px' }} key={index}>
        <ScaffoldRack column={index + 1}>
          <OverLaySprinklerEntry
            column={index + 1}
          />
        </ScaffoldRack>
      </div>
    );
  });
};

export default Sprinklers;
