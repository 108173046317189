import type { DatabaseDevices, SentryDeviceStateUnflattened } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import dateToString, { dateFormatTypes } from '../../helpers/dateToString';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import { useMemo } from 'react';

const LatestSentryInfo = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<SentryDeviceStateUnflattened>;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const heroItems = [
    {
      label: 'Last scanned tray',
      text: (latest?.tray || '-').toString() || 'N/A',
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading='Latest Sentry Info'
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <HeroGroup items={heroItems} />
    </PaperSection>
  );
};

export default LatestSentryInfo;
