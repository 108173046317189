import { useAtomValue } from 'jotai';
import { brokerIdAtom } from '../../../data/atoms/brokerIdAtom';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import { PhyconMUI } from '../../../components/puregui/Phycon/Phycon';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import GraphSelect from './GraphSelect';

const Grapher = () => {
  const brokerId = useAtomValue(brokerIdAtom);

  return (
    <ContainerPrimary
      title={`Devices (${brokerId})`}
      fullWidth={true}
      icon={<PhyconMUI icon={WbSunnyIcon} />}
    >
      <GraphSelect/>
    </ContainerPrimary>
  );
};

export default Grapher;