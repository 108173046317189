import type { BoxProps, ButtonProps } from '@mui/material';
import { Box, CircularProgress } from '@mui/material';
import { Button } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { formHasErrors } from '../helpers';

type IProps = {
  onClick?: () => void;
  busy?: boolean;
  inline?: boolean;
  height?: string;
  color?: ButtonProps['color'];
  disabled?: boolean;
  variant?: ButtonProps['variant'];
} & BoxProps;

const PhormSubmit: FC<PropsWithChildren<IProps>> = ({
  busy = false,
  disabled = false,
  children,
  onClick = () => null,
  inline = false,
  height = 'auto',
  color = 'primary',
  variant = 'contained',
  ...boxProps
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const hasErrors = formHasErrors(errors);

  return (
    <Box sx={{ ...boxProps.sx, display: inline ? 'inline' : 'block' }}>
      <Button
        sx={{ height }}
        onClick={onClick}
        color={color}
        variant={variant}
        type='submit'
        disabled={busy || hasErrors || disabled}
      >
        {children}
        {busy && (
          <span style={{ position: 'relative', top: '3px', left: '6px' }}>
            <CircularProgress size={15} />
          </span>
        )}
      </Button>
    </Box>
  );
};

export default PhormSubmit;
