import type { DatabaseTraySeason } from '@phyllome/common';
import useFetch from '../useFetch';
import { useMemo } from 'react';

export type DatabaseTraySeasonPick = Pick<DatabaseTraySeason,
'id' |
 'seasonId' |
 'mostRecentIrrigationTimeStamp' |
 'lightingZone' |
 'locationColumn' |
  'locationRow' |
  'locationShelf' |
  'defaultNDSSchedule' |
  'isRejected' |
  'isFlagged' |
  'timestampGrowthStart' |
  'timestampGrowthEnd'
>;

const useTraySeasonByBrokerId = () => {
  const fetch = useFetch<DatabaseTraySeasonPick[]>('/farm/:brokerId/trayseasons', {
    default: [],
    interval: 10000,
  });

  const transformedData = useMemo(() => {
    return fetch.data.map((data) => ({
      ...data,
      timestampGrowthStart: data.timestampGrowthStart ? new Date(data.timestampGrowthStart) : undefined,
      timestampGrowthEnd: data.timestampGrowthEnd ? new Date(data.timestampGrowthEnd) : undefined,
    }));
  }, [fetch.data]);

  return {
    data: transformedData,
    refetch: fetch.refetch,
  };
};

export default useTraySeasonByBrokerId;
