import type { DatabaseSeason, WithId } from '@phyllome/common';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import FormSetSeasonDetails from './FormSetSeasonDetails';
import { Box } from '@mui/material';

const SideBarSeasonEditSeasonDetails = ({
  seasonData,
}: {
  seasonData?: WithId<DatabaseSeason>;
}) => {
  if (!seasonData) return null;
  return (
    <PaperSection
      mb={2}
      heading='Edit Season Details'
      collapsable={seasonData?.state === 'completed'}
    >
      <Box>
        <FormSetSeasonDetails
          seasonData={seasonData}
          seasonId={seasonData.id}
        />
      </Box>
    </PaperSection>
  );
};

export default SideBarSeasonEditSeasonDetails;
