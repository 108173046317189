import { Alert, AlertTitle, Box } from '@mui/material';
import GenericConfigGet from '../GenericConfigGet/GenericConfigGet';
import type { DeviceType } from '@phyllome/common';

const StaleDataAlert = ({
  deviceId,
  deviceType,
  ...restProps
}: {
  deviceId: string;
  deviceType: DeviceType;
  [key: string]: any;
}) => {

  const hasUndefinedProps = Object.values({ deviceId,  ...restProps }).some(
    value => value === undefined,
  );

  if (hasUndefinedProps == false)
    return null;
  else
    return (
      <Box mb={2}>
        <Alert
          severity='warning'
        >
          <AlertTitle>Warning</AlertTitle>
          Form data is stale, press the refresh button to get the latest device state, or enter in all fields to set and update.
          <Box mt={2}>
            <GenericConfigGet
              deviceId={deviceId}
              deviceType={deviceType}
              showResponse={false}
              color='secondary'
            />
          </Box>
        </Alert>
      </Box>
    );
};

export default StaleDataAlert;