import type { DatabaseDevices, GuardDeviceStateUnflattened } from '@phyllome/common';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import { formatBytes } from '../../helpers/formatBytes';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import { useMemo } from 'react';

const ConfigGuard = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<GuardDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const { deviceId } = deviceState;

  return (
    <>
      <PaperSection heading='Air Monitor Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceId}</LabelValue>
        <LabelValue label='Firmware'>{latest.firmware}</LabelValue>
        <LabelValue label='Build date'>{latest.compiled}</LabelValue>
        <LabelValue label='Network IP'>{latest.network.ip}</LabelValue>
        <LabelValue label='Free memory'>
          {formatBytes(latest.freememory)}
        </LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <GenericConfigGet
        deviceId={deviceId}
        deviceType='guard'
      />
    </>
  );
};

export default ConfigGuard;
