import type { DatabaseTraySeason, WithId } from '@phyllome/common';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import ObjectToTable from '../../../../components/ObjectToTable/ObjectToTable';

const SideBarTraySeasonDebug = ({
  traySeasonData,
}: {
  traySeasonData: WithId<DatabaseTraySeason>;
}) => {
  return (
    <>
      <PaperSection heading='Debug (trayseason data)' mt={2}>
        <ObjectToTable data={traySeasonData} />
      </PaperSection>
    </>
  );
};

export default SideBarTraySeasonDebug;
