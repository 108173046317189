import { useLocation, useNavigate } from 'react-router-dom';

// Parse hash fragments into structured components
const parseHash = (hashString: string) => {
  // Remove the leading '#' if it exists
  const cleanHash = hashString.startsWith('#') ? hashString.substring(1) : hashString;

  // Split into path and query parts
  const [path, queryString] = cleanHash.split('?');

  // Split the path by '/' and filter out empty strings
  const pathParts = path.split('/').filter((part: string) => part);

  // Extract name and uid
  const name = pathParts[0] || null;
  const uid = pathParts[1] || null;

  // Parse query parameters
  const query: { [key: string]: string } = {};

  if (queryString) {
    queryString.split('&').forEach((param: string) => {
      const [key, value] = param.split('=');

      query[key] = value;
    });
  }

  // Construct a clean path without hash symbol or query params
  const cleanPath = pathParts.length > 0 ? pathParts.join('/') : '';

  return { name, uid, query, cleanPath };
};

// Parse search string into query parameters
const parseSearch = (searchString: string) => {
  // Parse query parameters from search string
  const query: { [key: string]: string } = {};

  // Remove the leading '?' if it exists
  const cleanSearch = searchString.startsWith('?') ? searchString.substring(1) : searchString;

  if (cleanSearch) {
    cleanSearch.split('&').forEach((param: string) => {
      const [key, value] = param.split('=');

      if (key) { // Only add if key exists
        query[key] = value || ''; // Handle cases where there's a key but no value
      }
    });
  }
  return query;
};

// Convert query object to query string
const buildQueryString = (query: { [key: string]: string | number | boolean }): string => {
  if (!query || Object.keys(query).length === 0) {
    return '';
  }
  return '?' + Object.entries(query)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value || '')}`)
    .join('&');
};

// Route state and changes interfaces
interface RouteState {
  mainPath: string;
  mainQuery: { [key: string]: string };
  sidebarPath: string;
  sidebarQuery: { [key: string]: string };
}

interface RouteChanges {
  mainPath?: string;
  mainQuery?: { [key: string]: string | number | boolean };
  sidebarPath?: string;
  sidebarQuery?: { [key: string]: string | number | boolean };
}

const useRoute = () => {
  const { pathname, hash, search } = useLocation();
  const navigation = useNavigate();

  // Parse the hash for sidebar navigation
  const sidebar = parseHash(hash);

  // Parse the search string for query parameters
  const query = parseSearch(search);

  // Current route state
  const currentState: RouteState = {
    mainPath: pathname,
    mainQuery: query,
    sidebarPath: `/${sidebar.cleanPath}`,
    sidebarQuery: sidebar.query,
  };

  // Individual navigation methods (kept for backward compatibility)
  const navMainPath = (newPath: string) => {
    // Ensure the path starts with a slash
    const normalizedPath = newPath.startsWith('/') ? newPath : `/${newPath}`;

    navigation(normalizedPath + buildQueryString(query) + hash);
  };

  const navMainQuery = (newQuery: { [key: string]: string | number | boolean }) => {
    // Merge with existing query, overwriting any duplicate keys
    const mergedQuery = { ...query, ...newQuery };

    navigation(pathname + buildQueryString(mergedQuery) + hash);
  };

  const navSidePath = (newSidePath: string): Promise<void> => {
    return new Promise((resolve) => {
      // Remove leading slash if present for consistency in hash
      const normalizedPath = newSidePath.startsWith('/') ? newSidePath.substring(1) : newSidePath;

      // Build the new hash with existing query params
      const newHash = normalizedPath ?
        `#/${normalizedPath}${buildQueryString(sidebar.query).replace('?', '?')}` :
        '';

      navigation(pathname + search + newHash, { replace: true });

      // Resolve the promise after navigation is complete
      resolve();
    });
  };

  const navSideQuery = (
    newSideQuery: { [key: string]: string | number | boolean },
    useNavigate = false,
  ) => {
    // Merge with existing sidebar query
    const mergedSideQuery = { ...sidebar.query, ...newSideQuery };

    // Build the new hash with updated query params
    const queryPart = Object.keys(mergedSideQuery).length > 0 ?
      `?${Object.entries(mergedSideQuery)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value || '')}`)
        .join('&')}` :
      '';

    const newHash = sidebar.cleanPath ?
      `#${sidebar.cleanPath}${queryPart}` :
      '';

    if (useNavigate) {
      navigation(pathname + search + newHash);
    } else {
      window.history.pushState({}, '', pathname + search + newHash);
    }
  };

  // Route reducer - apply multiple changes in a single navigation
  const navReducer = (changes: RouteChanges) => {
    // Start with current state
    const newState = { ...currentState };

    // Apply changes
    if (changes.mainPath !== undefined) {
      newState.mainPath = changes.mainPath.startsWith('/')
        ? changes.mainPath
        : `/${changes.mainPath}`;
    }

    if (changes.mainQuery !== undefined) {
      newState.mainQuery = {
        ...newState.mainQuery,
        ...changes.mainQuery as Record<string, string>,
      };
    }

    if (changes.sidebarPath !== undefined) {
      // Normalize path by removing leading slash for hash
      const normalizedPath = changes.sidebarPath.startsWith('/')
        ? changes.sidebarPath.substring(1)
        : changes.sidebarPath;

      newState.sidebarPath = `/${normalizedPath}`;
    }

    if (changes.sidebarQuery !== undefined) {
      newState.sidebarQuery = {
        ...newState.sidebarQuery,
        ...changes.sidebarQuery as Record<string, string>,
      };
    }

    // Build the full URL from the new state
    const mainQueryString = buildQueryString(newState.mainQuery);
    const sidebarPathClean = newState.sidebarPath.startsWith('/')
      ? newState.sidebarPath.substring(1)
      : newState.sidebarPath;

    // Only add # if we have a sidebar path
    const hashPrefix = sidebarPathClean ? '#/' : '';

    const sidebarQueryString = buildQueryString(newState.sidebarQuery).replace('?', '?');
    const newHash = sidebarPathClean
      ? `${hashPrefix}${sidebarPathClean}${sidebarQueryString}`
      : '';

    // Navigate to the new URL
    navigation(newState.mainPath + mainQueryString + newHash);
  };

  return {
    // Route state
    mainPath: pathname,
    mainQuery: query,
    sidebarPath: `/${sidebar.cleanPath}`,
    sidebarQuery: sidebar.query,

    // Individual navigation methods
    navMainPath,
    navMainQuery,
    navSidePath,
    navSideQuery,

    // Combined reducer navigation
    navReducer,
  };
};

export default useRoute;