import { useFormContext, Controller } from 'react-hook-form';
import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import type { SxProps, Theme } from '@mui/material/styles';

type Width = 'xs' | 'sm' | 'md' | 'lg' | 'full';

interface PhormTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
  name: string;
  busy?: boolean;
  onModify?: (value: string) => void;
  width?: Width;
  noLabel?: boolean;
}

const widthMap: Record<Width, string> = {
  xs: '120px',
  sm: '200px',
  md: '300px',
  lg: '400px',
  full: '100%',
};

const PhormTextField = ({
  name,
  busy = false,
  onModify,
  variant = 'outlined',
  size = 'small',
  noLabel = false,
  width = 'full',
  sx,
  ...props
}: PhormTextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const getErrorMessage = (fieldName: string): string | undefined => {
    const error = errors[fieldName];

    return error ? (error.message as string) : undefined;
  };

  const baseStyles: SxProps<Theme> = {
    width: widthMap[width],
    '& .MuiOutlinedInput-root': {
      width: '100%',
    },
    ...(noLabel && {
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 },
    }),
    ...sx,
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...props}
          {...field}
          disabled={busy}
          variant={variant}
          size={size}
          value={field.value ?? ''}
          onChange={(e) => {
            field.onChange(e);
            onModify?.(e.target.value);
          }}
          label={noLabel ? '' : props.label}
          error={!!errors[name]}
          helperText={getErrorMessage(name)}
          sx={baseStyles}
          InputLabelProps={{
            shrink: true,
            ...props.InputLabelProps,
          }}
        />
      )}
    />
  );
};

export default PhormTextField;
