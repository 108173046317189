import { Box } from '@mui/material';
import type { FC } from 'react';
import * as React from 'react';

interface IProps {
  children: React.ReactNode;
}

const LayoutCenter: FC<IProps> = ({ children }) => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      minHeight='100vh'
    >
      {children}
    </Box>
  );
};

export default LayoutCenter;
