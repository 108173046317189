import type { DatabaseDevices, FertigatorDeviceStateUnflattened } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { Box, Grid } from '@mui/material';
import type tankDefinition from '../../components/TankLevel/TankDefinition';
import calculatePercentageDifference from '../../helpers/calculatePercentageDifference';
import { Constants } from '../../types/constants';
import FormFertigatorConfigSetTanks from './forms/FormFertigatorConfigSetTanks';
import TankLevel from '../../components/TankLevel/TankLevel';
import { useMemo } from 'react';

const ControlLevelsFertigator = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<FertigatorDeviceStateUnflattened> ;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const tanks: Array<tankDefinition> = [
    {
      label: 'Acid',
      consumed: latest?.tanks?.acid || 0,
      payload: { acid: true },
    },
    {
      label: 'Nutrient A',
      consumed: latest?.tanks?.nutrientA || 0,
      payload: { nutrientA: true },
    },
    {
      label: 'Nutrient B',
      consumed: latest?.tanks?.nutrientB || 0,
      payload: { nutrientB: true },
    },
    {
      label: 'Peroxide',
      consumed: latest?.tanks?.peroxide || 0,
      payload: { peroxide: true },
    },
    {
      label: 'Pump',
      consumed: latest?.tanks?.pump || 0,
      payload: { pump: true },
    },
  ];

  const tankCapacity = (latest.brokerId === 'mcp-nursery') ? Constants.NURSERY_FERTIGATOR_TANK_CAPACITY : Constants.ALEXANDRIA_FERTIGATOR_TANK_CAPACITY;

  return (
    <>
      <PaperSection
        mb={2}
        heading='Fertigator Tank Levels'
        //    subheading={mqttDefinitionFertigatorClear.topic}
      >
        <Box mb={4} sx={{ textAlign: 'center' }}>
          <Grid container spacing={8} justifyContent='center'>
            {tanks.map((tank, index) => (
              <Grid item key={index}>
                <TankLevel
                  label={tank.label}
                  level={calculatePercentageDifference(
                    tankCapacity,
                    tankCapacity - tank.consumed,
                  )}
                  // subLabel={`${(tank.consumed / 1000).toLocaleString(
                  //   undefined,
                  //   {
                  //     maximumFractionDigits: 0,
                  //   },
                  // )} L`}
                  //payload={tank.payload}
                  //deviceId={latest.deviceId}
                  // mqttDefinitionDeviceClear={mqttDefinitionFertigatorClear}
                  //addActivityLog={addActivityLog}
                  key={index}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </PaperSection>
      <FormFertigatorConfigSetTanks deviceState={deviceState} />
    </>
  );
};

export default ControlLevelsFertigator;
