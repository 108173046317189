import type { HarvesterDeviceStateUnflattened } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import unflatten from '../../helpers/unflatten';
import ObjectToTable from '../../components/ObjectToTable/ObjectToTable';

const LatestHarvester = ({
  latest,
}: {
  latest: HarvesterDeviceStateUnflattened;
}) => {
  return (
    <PaperSection
      heading='Latest Harvester Device Data'
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ObjectToTable data={unflatten(latest) as any} />
    </PaperSection>
  );
};

export default LatestHarvester;
