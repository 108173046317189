import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, TransferDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionTransferConfigSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Typography } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { useMemo } from 'react';

const FormTransferConfigSetSpeeds = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<TransferDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const { deviceId } = deviceState;

  const controlLifecycle = useControlSend(
    mqttDefinitionTransferConfigSet,
    `${deviceId}`,
  );

  const activityLog = useActivityLog(`device/transfer/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTransferConfigSet,
    {
      speeds: {
        infeed: latest?.speeds.infeed || 0,
        outfeed: latest?.speeds.outfeed || 0,
        hatch: latest?.speeds.hatch || 0,
      },
    },
  );

  return (
    <PaperSection
      mb={2}
      heading='Transfer Conveyor Speeds'
      subheading={mqttDefinitionTransferConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              speeds: {
                infeed: data?.speeds?.infeed,
                outfeed: data?.speeds?.outfeed,
                hatch: data?.speeds?.hatch,
              },
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            `Set transfer speeds to hatch: ${data.speeds.infeed}%, infeed: ${data.speeds.infeed}% and outfeed: ${data.speeds.outfeed}%`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          speeds: yup.object({
            infeed: yup.number().min(0).max(100).required(),
            outfeed: yup.number().min(0).max(100).required(),
            hatch: yup.number().min(0).max(100).required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <Typography variant='body1'>
            Set the default conveyor speeds for the transfer system. Infeed is
            from the hatch, outfeed is to the harvester. Speeds are in
            percentage of maximum speed.
          </Typography>
          <TextFieldElement
            label='Hatch Conveyor Speed'
            name='speeds.hatch'
            type='text'
          />
          <TextFieldElement
            label='Infeed Conveyor Speed'
            name='speeds.infeed'
            type='text'
          />
          <TextFieldElement
            label='Outfeed Conveyor Speed'
            name='speeds.outfeed'
            type='text'
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTransferConfigSetSpeeds;
