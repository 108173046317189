import type { LiftDeviceStateUnflattened } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestLift = (deviceId: string) => {
  const data = useDeviceState<LiftDeviceStateUnflattened>({
    deviceId,
    deviceType: 'lift',
  });

  if (!data) return undefined;
  return data;

};

export default useLatestLift;
