import { useState, useRef, useCallback, useEffect } from 'react';
import * as React from 'react';
import {
  TransformWrapper,
  TransformComponent,
} from 'react-zoom-pan-pinch';
import {
  Box,
  Stack,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TrayImage from './TrayImage';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SaveIcon from '@mui/icons-material/Save';

// Interface for Box selection
interface TaggedArea {
  x: number;
  y: number;
  width: number;
  height: number;
  reason: string;
  trayPhotoId: string;
}
interface TaggableTrayImageProps {
  src: string;
  trayPhotoId: string;
  onBoxAdded?: (box: TaggedArea) => void;
}

// TODO MOVE TO common / Database Definitino
const TAG_REASONS = [
  'Weed',
  'Mould',
  'Algae',
  'Nutrient Deficiency',
  'Yellow Leaves',
  'Foreign Object',
  'Other',
];

const TaggableTrayImage = ({
  src,
  trayPhotoId,
  onBoxAdded,
}: TaggableTrayImageProps) => {
  const [isTaggingMode, setisTaggingMode] = useState(false);
  const [tags, setTags] = useState<TaggedArea[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  // For box selection
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState<{ x: number, y: number } | null>(null);
  const [currentBox, setCurrentBox] = useState<Partial<TaggedArea> | null>(null);

  // For reason dropdown
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [temporarytags, setTemporarytags] = useState<Partial<TaggedArea>[]>([]);

  // Keep track of image dimensions
  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      setImageSize({
        width: img.width,
        height: img.height,
      });
    };
    img.src = src;
  }, [src]);

  const toggleBoxingMode = () => {
    // If we're exiting boxing mode and we have a current box, ask for reason
    if (isTaggingMode && currentBox && currentBox.width && currentBox.height && currentBox.width > 5 && currentBox.height > 5) {
      setTemporarytags(prev => [...prev, currentBox]);
      setCurrentBox(null);
    } else {
      setisTaggingMode(!isTaggingMode);
      if (isDrawing) {
        setIsDrawing(false);
        setStartPoint(null);
        setCurrentBox(null);
      }
    }
  };

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!isTaggingMode || !containerRef.current) return;

    // Prevent default behavior
    e.preventDefault();

    // Get the container's bounding box
    const rect = containerRef.current.getBoundingClientRect();

    // Get the click position relative to the container
    const clickX = e.clientX - rect.left;
    const clickY = e.clientY - rect.top;

    // Calculate the actual position on the image considering the transformation
    const imageX = (clickX - positionX) / scale;
    const imageY = (clickY - positionY) / scale;

    // Check if the click is within the image boundaries
    if (imageX < 0 || imageY < 0 || imageX > imageSize.width || imageY > imageSize.height) {
      return; // Outside the image, don't start drawing
    }

    // Set the start point for drawing
    setStartPoint({ x: imageX, y: imageY });
    setIsDrawing(true);

    // Initialize the current box
    setCurrentBox({
      x: imageX,
      y: imageY,
      width: 0,
      height: 0,
      reason: '',
      trayPhotoId: '',
    });
  }, [isTaggingMode, scale, positionX, positionY, imageSize]);

  const handleMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!isTaggingMode || !isDrawing || !startPoint || !containerRef.current) return;

    // Get the container's bounding box
    const rect = containerRef.current.getBoundingClientRect();

    // Get the current mouse position relative to the container
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    // Calculate the actual position on the image considering the transformation
    let imageX = (mouseX - positionX) / scale;
    let imageY = (mouseY - positionY) / scale;

    // Constrain to image boundaries
    imageX = Math.max(0, Math.min(imageX, imageSize.width));
    imageY = Math.max(0, Math.min(imageY, imageSize.height));

    // Calculate the width and height of the box
    const width = imageX - startPoint.x;
    const height = imageY - startPoint.y;

    setCurrentBox({
      x: width >= 0 ? startPoint.x : imageX,
      y: height >= 0 ? startPoint.y : imageY,
      width: Math.abs(width),
      height: Math.abs(height),
      reason: '',
      trayPhotoId: '',
    });
  }, [isTaggingMode, isDrawing, startPoint, scale, positionX, positionY, imageSize]);

  const handleMouseUp = useCallback(() => {
    if (!isTaggingMode || !isDrawing || !currentBox) return;

    // Only add tags with actual dimensions
    if (currentBox.width && currentBox.height && currentBox.width > 5 && currentBox.height > 5) {
      // Ensure the box is within image boundaries
      const constrainedBox = {
        x: Math.max(0, currentBox.x || 0),
        y: Math.max(0, currentBox.y || 0),
        width: Math.min(currentBox.width, imageSize.width - (currentBox.x || 0)),
        height: Math.min(currentBox.height, imageSize.height - (currentBox.y || 0)),
        reason: '',
        trayPhotoId: '',
      };

      setCurrentBox(constrainedBox);
    } else {
      // Reset if box is too small
      setCurrentBox(null);
    }

    // Reset drawing state
    setIsDrawing(false);
    setStartPoint(null);
  }, [isTaggingMode, isDrawing, currentBox, imageSize]);

  // Add document-level mouse up handler to handle cases where mouse is released outside the component
  useEffect(() => {
    if (isDrawing) {
      const handleGlobalMouseUp = () => {
        handleMouseUp();
      };

      document.addEventListener('mouseup', handleGlobalMouseUp);
      return () => {
        document.removeEventListener('mouseup', handleGlobalMouseUp);
      };
    }
  }, [isDrawing, handleMouseUp]);

  const getVisibleBoxStyle = (box: Partial<TaggedArea>): React.CSSProperties => {
    // Calculate box position in screen space
    const boxLeft = (box.x || 0) * scale + positionX;
    const boxTop = (box.y || 0) * scale + positionY;
    const boxWidth = (box.width || 0) * scale;
    const boxHeight = (box.height || 0) * scale;

    // Get the image boundaries in screen space
    const imageWidth = imageSize.width * scale;
    const imageHeight = imageSize.height * scale;

    // If the box is completely outside the image, don't render it
    if (
      boxLeft + boxWidth < 0 ||
      boxTop + boxHeight < 0 ||
      boxLeft > imageWidth ||
      boxTop > imageHeight
    ) {
      return { display: 'none' };
    }

    // Calculate the visible part of the box
    const visibleLeft = Math.max(0, boxLeft);
    const visibleTop = Math.max(0, boxTop);
    const visibleRight = Math.min(boxLeft + boxWidth, imageWidth);
    const visibleBottom = Math.min(boxTop + boxHeight, imageHeight);

    // Determine which borders to show (hide borders that touch the image boundary)
    const borderTop = visibleTop > 0 ? '2px solid red' : 'none';
    const borderRight = visibleRight < imageWidth ? '2px solid red' : 'none';
    const borderBottom = visibleBottom < imageHeight ? '2px solid red' : 'none';
    const borderLeft = visibleLeft > 0 ? '2px solid red' : 'none';

    return {
      position: 'absolute',
      left: `${visibleLeft}px`,
      top: `${visibleTop}px`,
      width: `${visibleRight - visibleLeft}px`,
      height: `${visibleBottom - visibleTop}px`,
      borderTop,
      borderRight,
      borderBottom,
      borderLeft,
      backgroundColor: 'rgba(255, 0, 0, 0.2)',
      pointerEvents: 'none',
    };
  };

  const handleReasonSelect = (reason: string) => {
    setMenuAnchorEl(null);

    // If we have a temporary box, add it with the reason
    if (temporarytags.length > 0) {
      const lastBox = temporarytags[temporarytags.length - 1];
      const boxWithReason = {
        ...lastBox,
        reason,
        trayPhotoId,
      };

      setTags(prev => [...prev, boxWithReason as TaggedArea]);
      setTemporarytags(prev => prev.slice(0, -1));

      if (onBoxAdded) {
        onBoxAdded(boxWithReason as TaggedArea);
      }

      setisTaggingMode(false);
    }
  };

  // Handle cancel option selection
  const handleCancelTag = () => {
    setMenuAnchorEl(null);

    // Remove the temporary tag without saving
    if (temporarytags.length > 0) {
      setTemporarytags(prev => prev.slice(0, -1));
    }

    // Continue tagging mode
    setisTaggingMode(false);
  };

  // Show reason dropdown
  const handleShowReasonMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (currentBox && currentBox.width && currentBox.height && currentBox.width > 5 && currentBox.height > 5) {
      setTemporarytags(prev => [...prev, currentBox]);
      setCurrentBox(null);
    }

    setMenuAnchorEl(event.currentTarget);
  };

  // Handle menu close without selection
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  // Calculate label position for a box
  const getLabelPosition = (box: TaggedArea): React.CSSProperties => {
    const boxLeft = box.x * scale + positionX;
    const boxTop = box.y * scale + positionY;
    const boxHeight = box.height * scale;

    return {
      position: 'absolute',
      left: `${boxLeft}px`,
      top: `${boxTop + boxHeight + 5}px`,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      padding: '2px 5px',
      borderRadius: '3px',
      fontSize: '12px',
      zIndex: 1001,
      pointerEvents: 'auto', // Changed to allow interaction
      display: 'flex',
      alignItems: 'center',
    };
  };

  // Handle box deletion
  const handleDeleteTaggedArea = (index: number) => {
    setTags(prevtags => {
      const newtags = [...prevtags];

      newtags.splice(index, 1);
      return newtags;
    });
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <TaggingControls
        isTaggingMode={isTaggingMode}
        menuAnchorEl={menuAnchorEl}
        tagReasons={TAG_REASONS}
        onTagAreaClick={toggleBoxingMode}
        onShowReasonMenu={handleShowReasonMenu}
        onMenuClose={handleMenuClose}
        onReasonSelect={handleReasonSelect}
        onCancelTag={handleCancelTag}
      />
      <Box
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        sx={{
          position: 'relative',
          width: '100%',
          cursor: isTaggingMode ? 'crosshair' : 'default',
        }}
      >
        <TransformWrapper
          disabled={isTaggingMode}
          onTransformed={(ref) => {
            setScale(ref.state.scale);
            setPositionX(ref.state.positionX);
            setPositionY(ref.state.positionY);
          }}
        >
          <TransformComponent
            wrapperStyle={{
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          >
            <TrayImage src={src} />
          </TransformComponent>
        </TransformWrapper>
        {/* Overlay for tags */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            zIndex: 1000,
          }}
        >
          {/* Render the completed box selections */}
          {tags.map((box, index) => (
            <React.Fragment key={`box-fragment-${index}`}>
              <div
                key={`box-${index}`}
                style={getVisibleBoxStyle(box)}
              />
              {box.reason && (
                <div style={getLabelPosition(box)}>
                  <span>{box.reason}</span>
                  <IconButton
                    size='small'
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteTaggedArea(index);
                    }}
                    sx={{
                      ml: 1,
                      p: 0.5,
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      },
                    }}
                  >
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </div>
              )}
            </React.Fragment>
          ))}
          {/* Render the temporary tags (waiting for reason) */}
          {temporarytags.map((box, index) => (
            <div
              key={`temp-box-${index}`}
              style={getVisibleBoxStyle(box)}
            />
          ))}
          {/* Render the current box being drawn */}
          {currentBox && (
            <div
              style={getVisibleBoxStyle(currentBox)}
            />
          )}
        </div>
      </Box>
    </Box>
  );
};

export default TaggableTrayImage;

interface TaggingControlsProps {
  isTaggingMode: boolean;
  menuAnchorEl: HTMLElement | null;
  tagReasons: string[];
  onTagAreaClick: () => void;
  onShowReasonMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMenuClose: () => void;
  onReasonSelect: (reason: string) => void;
  onCancelTag: () => void;
}

const TaggingControls: React.FC<TaggingControlsProps> = ({
  isTaggingMode,
  menuAnchorEl,
  tagReasons,
  onTagAreaClick,
  onShowReasonMenu,
  onMenuClose,
  onReasonSelect,
  onCancelTag,
}) => {
  return (
    <> <Box
      sx={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 1200,
      }}
    >
      <Stack direction='row' justifyContent='flex-end' mb={1}>
        <IconButton
          size='medium'
          color={isTaggingMode ? 'primary' : 'secondary'}
          onClick={isTaggingMode ? onShowReasonMenu : onTagAreaClick}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
            },
          }}
        >
          {isTaggingMode ? <SaveIcon /> : <LocalOfferIcon />}
        </IconButton>
        {/* Reason selection menu */}
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={onMenuClose}
        >
          <Typography variant='subtitle2' sx={{ px: 2, py: 1 }}>
            Select tag reason:
          </Typography>
          {tagReasons.map((reason) => (
            <MenuItem
              key={reason}
              onClick={() => onReasonSelect(reason)}
            >
              {reason}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem
            onClick={onCancelTag}
            sx={{ color: 'error.main' }}
          >
            Cancel
          </MenuItem>
        </Menu>
      </Stack>
    </Box>

    </>
  );
};