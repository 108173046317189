import type { DatabaseSeason, WithId } from '@phyllome/common';
import {
  Box,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { OpenInNew } from '@mui/icons-material';
import TrayImage from '../../../components/TrayImage/TrayImage';
import dateToString, { dateFormatTypes } from '../../../helpers/dateToString';
import { howLongAgoInEnglish } from '../../../helpers/FStimeAgo';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import usePhotoLatest from './hooks/usePhotoLatest';
import useNavSide from '../../../data/hooks/useNavSide';

const SideBarSeasonPhotoLatest = ({
  season,
}: {
  season?: WithId<DatabaseSeason>;
}) => {
  const nav = useNavSide();
  const currentPhoto = usePhotoLatest({
    seasonId: season?.id,
    type: 'visible',
  });

  if (!currentPhoto) return null;
  return (
    <PaperSection
      mb={2}
      heading='Latest Image'
      subheading={`Taken ${dateToString(
        currentPhoto?.timestamp?.toDate(),
        dateFormatTypes.TRUNCATEDNOSECONDS2,
      )} (${howLongAgoInEnglish(currentPhoto?.timestamp)} ago)`}
    >

      <TransformWrapper>
        <TransformComponent>
          <TrayImage src={currentPhoto.url} />
        </TransformComponent>
      </TransformWrapper>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <div
          style={{
            marginTop: '-37px',
            zIndex: 101,
          }}
        >
          <a
            href={currentPhoto.url}
            download='download'
            target='_blank'
            rel='noreferrer'
          >
            <IconButton color='fog'>
              <OpenInNew />
            </IconButton>
          </a>
        </div>
      </div>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant='caption' color='textSecondary'>ISO: {currentPhoto?.camera?.iso}
          - Shutter: {currentPhoto?.camera?.shutter_speed}
          - AWB gains: {currentPhoto?.camera?.awb_gains}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center' }} >
        <Button onClick={() => nav(`tray/${currentPhoto.traySeasonId}`)} variant='outlined' color='primary' sx={{ mt: 2 }}>View Tray</Button>
      </Box>
    </PaperSection>
  );
};

export default SideBarSeasonPhotoLatest;