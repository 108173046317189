import { useContext, useEffect } from 'react';
import DeviceTypeToIcon from '../../../components/DeviceTypeToIcon/DeviceTypeToIcon';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import ScaffoldRack from '../../../components/factoryui/ScaffoldRack/ScaffoldRack';
import { Box } from '@mui/material';
import type { ColumnSpec } from '@phyllome/common';
import { TraysContext } from './TraysContext';
import TraysFilters from './TraysFilters';
import OverlayEntryPointTraysCurrent from './OverylayEntryPoints/OverlayEntryPointTraysCurrent';
import OverlayEntryPointLighting from './OverylayEntryPoints/OverlayEntryPointLighting';
import OverlayEntryPointValves from './OverylayEntryPoints/OverlayEntryPointValves';
import OverlayEntryPointIrrigationSchedules from './OverylayEntryPoints/OverlayEntryPointIrrigationSchedules';
import OverlayLift from './Overlays/OverlayLift';
import OverlayEntrySeasons from './OverylayEntryPoints/OverlayEntrySeasons';
import useSideBar from '../../../sidebar/useSideBar';
import DeviceWorker from '../../../components/DevicesProvider/DeviceWorker';
import { useAtomValue } from 'jotai';
import { brokerIdAtom } from '../../../data/atoms/websocketFilters';

const Trays = ({
  columnSpec,

  children = null,
  seasonId = undefined,
}: {
  columnSpec: ColumnSpec[];
  children?: React.ReactNode;
  seasonId?: string;
}) => {
  const trayFilterContext = useContext(TraysContext);
  const dispatch = trayFilterContext.dispatch;
  const sidebarInfo = useSideBar();
  const brokerId = useAtomValue(brokerIdAtom);

  useEffect(() => {

    if (brokerId) {
      DeviceWorker.startFetchingNDS(brokerId);
    }
    return () => {
      DeviceWorker.stopFetchingNDS();
    };

  }, [brokerId]);

  useEffect(() => {

    if (sidebarInfo.parsedHash.name === 'tray') {
      dispatch({ type: 'SET_SELECTED_TRAY', payload: sidebarInfo.parsedHash.parameter });
    }
    dispatch({
      type: 'SET_SELECTED_SEASON',
      payload: sidebarInfo.parsedHash.queryParams.get('seasonId') || undefined,
    });

  }, [sidebarInfo, dispatch]);

  useEffect(() => {
    // Only access dispatch function from context
    const { dispatch } = trayFilterContext;
    const { displayedSeason } = trayFilterContext.state;

    if (seasonId && displayedSeason !== seasonId) {
      dispatch({
        type: 'SET_DISPLAYED_SEASON',
        payload: seasonId,
      });
    } else if (!seasonId && displayedSeason !== undefined) {
      dispatch({
        type: 'SET_DISPLAYED_SEASON',
        payload: undefined,
      });
    }
    // Only include stable values in dependency array
  }, [seasonId, trayFilterContext]);
  return (
    <ContainerPrimary
      title='TRAYS'
      icon={<DeviceTypeToIcon deviceType='tray' />}
    >
      {children}
      <Box sx={{ marginBottom: '16px' }}>
        <TraysFilters />
      </Box>
      {columnSpec.map((column, index) => {
        return (

          <Box sx={{ pt: 1 }} key={index}>
            <ScaffoldRack column={index + 1}>
              {trayFilterContext.state.layers.primaryLayer === 'TRAYS' && (
                <OverlayEntryPointTraysCurrent column={index + 1} />
              )}
              {trayFilterContext.state.layers.primaryLayer ===
                'IRRIGSCHEDULE' && (
                <OverlayEntryPointIrrigationSchedules column={index + 1} />
              )}
              {trayFilterContext.state.layers.secondaryLayer.includes(
                'LIGHTING',
              ) && <OverlayEntryPointLighting column={index + 1} />}
              {trayFilterContext.state.layers.secondaryLayer.includes(
                'SPRINKLERS',
              ) && <OverlayEntryPointValves column={index + 1} />}
              {trayFilterContext.state.layers.primaryLayer === 'SEASONS' && (
                <OverlayEntrySeasons column={index + 1} />
              )}
              <OverlayLift columnId={index + 1} />

            </ScaffoldRack>
          </Box>
        );
      })}
    </ContainerPrimary>
  );
};

export default Trays;
