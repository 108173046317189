import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  Alert,
} from '@mui/material';
import showNumber from '../../helpers/showNumber';
import { formatThousands } from '../../helpers/showNumber';
import type { LightingSchedule, LightingScheduleElement } from '@phyllome/common';

type LightingScheduleProps = {
  scheduleDataDB: LightingSchedule;
};

const ScheduleTableLighting = ({ scheduleDataDB }: LightingScheduleProps) => {
  if (Object.keys(scheduleDataDB).length === 0)
    return <Alert severity='warning'>No data available.</Alert>;
  return (
    <TableContainer>
      <Table aria-label='Lighting Schedule' size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Day</TableCell>
            <TableCell>Duration&nbsp;(min)</TableCell>
            <TableCell>Period&nbsp;(min)</TableCell>
            {/* <TableCell></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {scheduleDataDB.map((data) => {
            return <ScheduleRow key={data.day} data={data} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScheduleTableLighting;

type Nullable<T> = { [P in keyof T]: T[P] | null };

const ScheduleRow = ({
  data,
  // onDelete,
}: {
  data: Nullable<LightingScheduleElement>;
  // onDelete?: (args: number | undefined) => void;
}) => {
  return (
    <TableRow
      hover
      key={data.day}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component='th' scope='row'>
        {data.day}
      </TableCell>
      <TableCell>{showNumber(data.on, formatThousands)}</TableCell>
      <TableCell>{showNumber(data.period, formatThousands)}</TableCell>
      {/* <TableCell>
        {onDelete && (
          <IconButton
            aria-label="delete"
            onClick={() => {
              onDelete(data.day as number);
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell> */}
    </TableRow>
  );
};
