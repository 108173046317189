import type { GuardDeviceStateUnflattened } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestGuard = (deviceId: string) => {
  return useDeviceState<GuardDeviceStateUnflattened>({
    deviceId,
    deviceType: 'guard',
  });
};

export default useLatestGuard;
