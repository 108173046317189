import OverlaySprinklerValves from '../../Sprinklers/overlays/OverlaySprinklerValves';

const OverlayEntryPointValues = ({ column }: { column: number }) => {

  return (
    <>
      <OverlaySprinklerValves
        column={column}
      />
    </>
  );
};

export default OverlayEntryPointValues;
