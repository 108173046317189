import type { Co2DeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import Graph from '../../components/Favourites/Graph';
import { useMemo } from 'react';

//import ReChart from "../../components/Rechart/Rechart";
//import ReChartArea from "../../components/Rechart/RechartArea";

const GraphAir = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<Co2DeviceStateUnflattened>;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const { deviceId, brokerId } = deviceState;

  //freememory, mode, control, ppm
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        <Graph name='co2Mode' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='co2PPM' brokerId={brokerId} deviceId={deviceId} target={latest.setpoint}/>
        <Graph name='co2Dose' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='co2DoseCumulative' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='co2FreeMemory' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='co2Dosing' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='co2Enabled' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='co2Heater' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='co2FansExhaust' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='co2FansIntake' brokerId={brokerId} deviceId={deviceId} />
        <Graph
          name='co2AutomationCo2'
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph
          name='co2AutomationRefresh'
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph name='co2Control' brokerId={brokerId} deviceId={deviceId} />
      </Stacker>
    </ChartProvider>
  );
};

export default GraphAir;
