import type { Co2DeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import FormCo2SetEnableTime from './forms/FormCo2SetEnableTime';
import FormCo2SetSetpoint from './forms/FormCo2SetSetpoint';
import FormCo2ConfigSetAutomation from './forms/FormCo2ConfigSetAutomation';
import LatestCo2Info from './LatestCo2Info';
import GenericPing from '../Generic/control/GenericPing';

const ControlCo2 = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<Co2DeviceStateUnflattened>;
}) => {
  const { deviceId } = deviceState;

  return (
    <>
      <LatestCo2Info deviceState={deviceState} />
      <FormCo2SetSetpoint deviceState={deviceState} />
      <FormCo2SetEnableTime deviceState={deviceState} />
      <FormCo2ConfigSetAutomation deviceState={deviceState} />
      <div>
        <GenericPing
          deviceId={deviceId}
          deviceType='co2'
        />
      </div>
    </>
  );
};

export default ControlCo2;
