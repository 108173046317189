import { Tabs, Tab, Box, Alert } from '@mui/material';
import { colors } from '@phyllome/common';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import LatestFertigator from './LatestFertigator';
import FarmContext from '../../components/FarmProvider/FarmContext';
import useLatestFertigator from './hooks/useLatestFertigator';
import ControlFerigator from './ControlFertigator';
import ControlLevelsFertigator from './ControlLevelsFertigator';
import GraphFertigator from './GraphFertigator';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import LatestFertigatorInfo from './LatestFertigatorInfo';
import ConfigFertigator from './ConfigFertigator';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import CalibrateFertigator from './CalibrateFertigator';
import AnalysisFertigator from './AnalysisFertigator';
import PhyTabPanel from '../../components/PhyTabPanel/PhyTabPanel';
import useRoute from '../../data/hooks/useRoute';
import PendingDevice from '../../components/PendingDevice/PendingDevice';

type ISideBarEntryFertigatorProps = {
  deviceId: string;
};

const SideBarEntryFertigator: FC<ISideBarEntryFertigatorProps> = ({
  deviceId,
}) => {
  const { brokerId } = useContext(FarmContext);
  const router = useRoute();
  const [value, setValue] = useState<any>(Number(router.sidebarQuery.tab) || 0);

  const latestDeviceInfo = useLatestFertigator(deviceId);

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    router.navSideQuery({ tab: value });
  }, [value, router]);

  if (!latestDeviceInfo)
    return (
      <Box p={2}>
        <Alert severity='info'>No Latest Data!</Alert>
      </Box>
    );
  return (
    <>
      <AppDrawerTitle title={`fertigator/${latestDeviceInfo.deviceId}`} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor='inherit'
        variant='scrollable'
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label='Latest' value={0} />
        <Tab label='Graphs' value={1} />
        <Tab label='Control' value={2} />
        <Tab label='Config' value={3} />
        <Tab label='Calibrate' value={4} />
        <Tab label='Levels' value={5} />
        <Tab label='Activity' value={6} />
        <Tab label='Alerts' value={7} />
        <Tab label='Analysis' value={8} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <LatestFertigatorInfo deviceState={latestDeviceInfo} />
        <PendingDevice deviceState={latestDeviceInfo} />
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='fertigator'
          count={1}
        />
        <LatestFertigator deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <GraphFertigator deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <ControlFerigator deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <ConfigFertigator deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <CalibrateFertigator deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <ControlLevelsFertigator deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={6}>
        <ActivityLog
          identifier={`device/fertigator/${deviceId}/notes`}
          heading='Activity Log'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={7}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='fertigator'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={8}>
        <AnalysisFertigator deviceState={latestDeviceInfo} />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryFertigator;
