import type { DatabaseTraySeasonPick } from '../data/hooks/collections/useTraySeasonByBrokerId';

const createHashedListFromTraySeason = (
  traySeasons: DatabaseTraySeasonPick[],
) => {
  return traySeasons.reduce<Record<string, boolean>>((obj, tray) => {
    const key = `${tray.locationShelf}.${tray.locationRow}`;

    obj[key] = true;
    return obj;
  }, {});
};

export default createHashedListFromTraySeason;
