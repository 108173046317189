import type { Co2DeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import FormCo2ConfigSetDosing from './forms/FormCo2ConfigSetDosing';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import FormCo2ConfigSetCutOff from './forms/FormCo2ConfigSetCutOff';
import { formatBytes } from '../../helpers/formatBytes';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import { formatSeconds } from '../../helpers/formatSeconds';
import { useMemo } from 'react';

const ConfigCo2 = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<Co2DeviceStateUnflattened>;
}) => {
  const { deviceId } = deviceState;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  return (
    <>
      <PaperSection heading='CO2 Controller Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceId}</LabelValue>
        <LabelValue label='Firmware'>{latest.firmware}</LabelValue>
        <LabelValue label='Build date'>{latest.build}</LabelValue>
        <LabelValue label='Network IP'>{latest.network.ip}</LabelValue>
        <LabelValue label='Free memory'>
          {formatBytes(latest.freememory)}
        </LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormCo2ConfigSetCutOff deviceState={deviceState} />
      <FormCo2ConfigSetDosing deviceState={deviceState} />
      <GenericConfigGet
        deviceId={deviceId}
        deviceType='co2'
      />
    </>
  );
};

export default ConfigCo2;
