import type { FC } from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value: number) {
  return `${value}`;
}

type IProps = {
  min?: number;
  max?: number;
  step?: number;
  minDistance?: number;
};

const RangeSlider: FC<IProps> = ({
  min = 0,
  max = 100,
  step = 20,
  minDistance = 10,
}) => {
  const [value, setValue] = useState<number[]>([1100, 1800]);

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };

  return (
    <Box sx={{ width: 300 }}>
      <Slider
        getAriaLabel={() => 'Minimum distance'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay='auto'
        getAriaValueText={valuetext}
        disableSwap
        min={min}
        step={step}
        max={max}
      />
    </Box>
  );
};

export default RangeSlider;
