import type { DatabaseDevices, TransferDeviceStateUnflattened } from '@phyllome/common';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import FormTransferConfigSetSpeeds from './forms/FormTransferConfigSetSpeeds';
import { useMemo } from 'react';

const ConfigTransfer = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<TransferDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;

  return (
    <>
      <PaperSection heading='Transfer Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceId}</LabelValue>
        <LabelValue label='Firmware'>{latest.firmware}</LabelValue>
        <LabelValue label='Build date'>{latest.compiled}</LabelValue>
        <LabelValue label='Network IP'>TBD</LabelValue>
        <LabelValue label='Free memory'>TBD</LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormTransferConfigSetSpeeds deviceState={deviceState} />
      <GenericConfigGet
        deviceId={deviceId}
        deviceType='transfer'
      />
    </>
  );
};

export default ConfigTransfer;
