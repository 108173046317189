import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import useUserToken from './useUserToken';
import FarmContext from '../../components/FarmProvider/FarmContext';

export const createFetchEndpoint = (name: string, brokerId: string, token?:string) => {
  if (name.startsWith('/')) {
    name = name.substring(1);
  }

  // replace :brokerId with the actual brokerId
  name = name.replace(':brokerId', brokerId);
  const url = process.env.NODE_ENV === 'production'
    ? `https://websocket.phyllome.io/${name}`
    : `http://127.0.0.1:3025/${name}`;

  return token ? `${url}?token=${token}` : url;
};

export type FetchResult<T> = {
  data: T | undefined;
  status: 'idle' | 'pending' | 'error' | 'success';
  isFetching: boolean;
  error: Error | null;
  time: number | null; // Time in milliseconds it took to complete the fetch
  fetch: () => Promise<T>; // Main function to trigger the fetch
  isReady: boolean,
  isFetchedAfterMount: boolean;
};

const useFetchManual = <T = unknown>(
  endpoint: string,
  options?: { default?: T },
): FetchResult<T> => {

  const { brokerId } = useContext(FarmContext);
  const userToken = useUserToken();
  const [fetchTime, setFetchTime] = useState<number | null>(null);

  const fetchData = async () => {
    if (!userToken) throw new Error('User is not authenticated');

    const fullEndpoint = createFetchEndpoint(endpoint, brokerId); // Ensure this function correctly builds the full URL
    const response = await fetch(fullEndpoint, {
      headers: {
        'Authorization': `Bearer ${userToken}`,
      },
    });

    if (!response.ok) throw new Error('Network response was not ok');
    return response.json() as Promise<T>;
  };

  const { data, status, error, refetch, isFetching, isFetchedAfterMount } = useQuery<T>({
    queryKey: ['data', endpoint, brokerId], // Ensure query key includes dynamic dependencies
    queryFn: fetchData,
    enabled: false, // Prevent auto-fetching
    initialData: options?.default,
  });

  return {
    data,
    status,
    isFetching,
    error,
    time: fetchTime && Math.floor(fetchTime),
    fetch: async () => {
      const startTime = performance.now();

      try {
        const result = await refetch();
        const endTime = performance.now();

        setFetchTime(endTime - startTime);

        if (result.data) return result.data;
        throw result.error ?? new Error('Failed to fetch data');
      } catch (error) {
        const endTime = performance.now();

        setFetchTime(endTime - startTime);
        throw error;
      }
    },
    isReady: !!userToken, // Ensures fetch only works if the user is authenticated
    isFetchedAfterMount: isFetchedAfterMount,
  };
};

export default useFetchManual;