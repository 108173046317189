import OverlaySprinklerSimple from './OverlaySprinklerSimple';
import OverlaySprinklerValves from './OverlaySprinklerValves';
import OverlaySprinklerSelect from './OverlaySprinklerSelect';
import { useContext } from 'react';
import { SprinklerContext } from '../SprinkerContext';
import OverlaySprinklerEnabled from './OverlaySprinklerEnabled';
import useRoute from '../../../../data/hooks/useRoute';
import OverlaySprinklerValues from './OverlaySprinklerValues';
import type { DatabaseDevices } from '@phyllome/common';

const OverLaySprinklerEntry = ({
  column,
}: {
  column: number;
}) => {
  const sprinklerContext = useContext(SprinklerContext);
  const router = useRoute();

  const clickHandler = (data: DatabaseDevices) => {
    const { column, shelf, row } = data.properties.location;

    router.navSidePath(`sprinklers/${column}.${shelf}.${row}`);

  };

  const layer = sprinklerContext?.state?.layers?.primaryLayer || 'SIMPLE';
  const secondaryLayer = sprinklerContext?.state?.layers?.secondaryLayer;

  return (
    <>
      <OverlaySprinklerSelect column={column} onClick={clickHandler} />
      {layer === 'SIMPLE' && <OverlaySprinklerSimple column={column} />}
      {layer === 'FIRMWARE' && <OverlaySprinklerValues column={column} propertyToDisplay='firmware' method='unique'/>}
      {layer === 'WATERRATE' &&  <OverlaySprinklerValues column={column} propertyToDisplay='waterRate' method='gradient'/>}
      {layer === 'CURRENTDAY' && (
        <OverlaySprinklerValues column={column} propertyToDisplay='currentDay' method='unique'/>
      )}
      {secondaryLayer.includes('ENABLED') && (
        <OverlaySprinklerEnabled column={column} />
      )}
      {secondaryLayer.includes('VALVES') && (
        <OverlaySprinklerValves column={column} />
      )}
    </>
  );
};

export default OverLaySprinklerEntry;
