import type { WritableAtom } from 'jotai/vanilla';
import { getDefaultStore } from 'jotai/vanilla';

function setAtomValue<Value>(atom: WritableAtom<Value, [Value], unknown>, value: Value) {
  const store = getDefaultStore();

  store.set(atom, value);

}

export default setAtomValue;