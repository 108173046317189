import type { DatabaseDevices, GuardDeviceStateUnflattened } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import { Box } from '@mui/material';
import ShieldIcon from '@mui/icons-material/Shield';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useMemo } from 'react';

const LatestGuardInfo = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<GuardDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  return (
    <PaperSection
      mb={2}
      heading='Latest Guard Info'
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      {/* <Box mb={2}>
        <HeroGroup items={heroItems} />
      </Box> */}
      <Box mb={2}>
        {latest.move && latest.automate ? (
          <ShieldOutlinedIcon
            sx={{ height: 80 }}
            fontSize='large'
            color='primary'
          />
        ) : latest.move ? (
          <ShieldOutlinedIcon
            sx={{ height: 80 }}
            fontSize='large'
            color='earth3'
          />
        ) : (
          <ShieldIcon sx={{ height: 80 }} fontSize='large' color='earth3' />
        )}
      </Box>
      <Box mb={2}>
        <ChipBool data={latest.move} label='Move' color='primary' />
        <ChipBool data={latest.automate} label='Automate' color='primary' />
        <ChipBool
          data={latest.alarm}
          label='Alarm'
          color='primary'
          icon={<NotificationsActiveIcon />}
        />
        <ChipBool
          data={latest.flash}
          label='Flash'
          color='primary'
          icon={<WbTwilightIcon />}
        />
      </Box>
    </PaperSection>
  );
};

export default LatestGuardInfo;
