import { sub } from 'date-fns';
import type { Dispatch, FC, ReactNode } from 'react';
import { createContext, useReducer } from 'react';
import { getChartConfigs } from './chartContexthelpers';
import type { ChartContextState } from './chartContextTypes';
import { GraphCursorProvider } from './GraphCursorContext';

const initialValues: ChartContextState = {
  beginDate: sub(new Date(), { days: 1 }),
  endDate: new Date(),
  ...getChartConfigs(sub(new Date(), { days: 1 }), new Date()),
};

export const ChartContext = createContext<{
  state: ChartContextState;
  dispatch: Dispatch<any>;
}>({ state: initialValues, dispatch: () => null });

// TODO: these are not type safe
function chartContextReducer(
  state: ChartContextState,
  action: any,
): ChartContextState {
  switch (action.type) {
    case 'SET_WINDOW_TYPE': {
      return { ...state, windowType: action.payload };
    }
    case 'SET_DATES': {
      const configs = getChartConfigs(
        action.payload.beginDate,
        action.payload.endDate,
      );

      return {
        ...state,
        beginDate: action.payload.beginDate,
        endDate: action.payload.endDate,
        ...configs,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
export const ChartProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(chartContextReducer, initialValues);

  return (
    <ChartContext.Provider value={{ state, dispatch }}>
      <GraphCursorProvider>{children}</GraphCursorProvider>
    </ChartContext.Provider>
  );
};
