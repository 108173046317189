import type { DatabaseSeason, WithId } from '@phyllome/common';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import FormEndSeason from './FormEndSeason';
import { Box } from '@mui/material';

const SideBarSeasonCompletedSeasonEdit = ({
  seasonData,
}: {
  seasonData?: WithId<DatabaseSeason>;
}) => {
  if (!seasonData) return null;
  if (seasonData.state !== 'completed') return null;
  return (
    <PaperSection collapsable={true} heading='Edit Completed Season' mt={2}>
      <Box pt={1}>
        <FormEndSeason seasonData={seasonData} />
      </Box>
    </PaperSection>
  );
};

export default SideBarSeasonCompletedSeasonEdit;
