import type { DeviceType } from '@phyllome/common';
import { Alert, Box, Fade, Stack } from '@mui/material';
import { colors } from '@phyllome/common';
import useFetchManual from '../../data/hooks/useFetchManual';
import ButtonWait from '../puregui/ButtonWait/ButtonWait';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import PaperSection from '../puregui/PaperSection/PaperSection';
import ObjectToTable from '../ObjectToTable/ObjectToTable';

type IFormAnyDeviceConfigGetProps = {
  deviceId: string;
  deviceType: DeviceType;
  color?: 'primary' | 'secondary';
  showResponse?: boolean;
};

const GenericConfigGet = ({
  deviceId,
  deviceType,
  color = 'primary',
  showResponse = true,
}: IFormAnyDeviceConfigGetProps) => {
  const fetchStuff = useFetchManual<any>(`/farm/:brokerId/device/${deviceType}/${deviceId}/config/get`);

  const isFetching = fetchStuff.isFetching;
  const isSuccess = fetchStuff.status === 'success';
  const psHeading = isFetching ? `Fetching config for ${deviceType}/${deviceId}` : `Device Configuration: ${deviceType}/${deviceId}`;

  return (
    <Box>
      <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
        <ButtonWait
          color={color}
          isBusy={isFetching}
          onClick={() => {
            fetchStuff.fetch();
          }}
          startIcon={<BrowserUpdatedIcon />}
        >
          Get Device Config
        </ButtonWait>
        {isSuccess && (
          <Alert
            severity='success'
            variant='standard'
            sx={{ paddingTop: '0', paddingBottom: '0' }}
          >
            Success ({fetchStuff.time}ms)
          </Alert>
        )}
      </Stack>
      {showResponse && (isSuccess || isFetching) && (
        <PaperSection
          mt={2}
          mb={2}
          headerSx={{ background: colors.ripening, color: '#fff' }}
          spinner={isFetching}
          heading={psHeading}
        >
          {isSuccess ? (
            <Fade
              in={true}
              timeout={800}
            >
              <div>
                <ObjectToTable data={fetchStuff?.data?.data || {}} />
              </div>
            </Fade>
          ) : (
            <div style={{ minHeight: '50px' }}></div>
          )}
        </PaperSection>
      )}
    </Box>
  );
};

export default GenericConfigGet;