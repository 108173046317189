import type { NDSDeviceStateUnflattened } from '@phyllome/common';
import FormNDSEnable from './forms/FormNDSEnable';

const ControlNDS = ({ deviceState }: { deviceState: NDSDeviceStateUnflattened }) => {
  return (
    <>
      <FormNDSEnable deviceState={deviceState} />
    </>
  );
};

export default ControlNDS;
