import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type {   DatabaseDevices, LightingCoreDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionLightingCoreConfigSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormLightingCoreConfigSet = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LightingCoreDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionLightingCoreConfigSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/lighting/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionLightingCoreConfigSet,
    {
      calibration: latest?.calibration || 0,
      firstZoneID: latest?.firstZoneID || 0,
    },
  );

  return (
    <PaperSection
      heading='Zone Core Config'
      mb={2}
      subheading={mqttDefinitionLightingCoreConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='lighting'
        calibration={latest.calibration}
        firstZoneID={latest.firstZoneID}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              calibration: data.calibration,
              firstZoneID: data.firstZoneID,
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            `Set lighting core ${deviceId} calibration to ${data.calibration} and first zone ID to ${data.firstZoneID}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          calibration: yup.number().min(0).required(),
          firstZoneID: yup.number().required(),
        })}
      >
        <Stack spacing={2}>
          <TextFieldElement label='Calibration' name='calibration' required />
          <TextFieldElement label='First Zone ID' name='firstZoneID' required />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormLightingCoreConfigSet;
