import type { DatabaseTraySeason, WithId } from '@phyllome/common';
import useSeasons from '../../../../data/hooks/collections/useSeasons';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import DropDownMenu from '../../../../components/puregui/DropDownMenu/DropDownMenu';
import useAction from '../../../../data/hooks/useAction';

const SideBarTraySeasonReassign = ({
  traySeasonData,
  brokerId,
}: {
  traySeasonData: WithId<DatabaseTraySeason>;
  brokerId: string;
}) => {
  const allSeasons = useSeasons({ active: true });
  const editTraySeason = useAction('traySeasonUpdateTray');

  return (
    <PaperSection heading='Reassign Season'>
      <DropDownMenu
        label='Select Season'
        menuItems={allSeasons.data.map((seasonItem) => {
          return {
            label: seasonItem.name,
            onClick: () => {
              editTraySeason.run({
                update: {
                  brokerId: brokerId,
                  id: traySeasonData.id,
                  seasonId: seasonItem.id,
                },
              });
            },
          };
        })}
      ></DropDownMenu>
    </PaperSection>
  );
};

export default SideBarTraySeasonReassign;
