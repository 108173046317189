import { ToggleButtonGroup, ToggleButton, styled } from '@mui/material';
import { useContext, useState } from 'react';
import { TraysContext } from './TraysContext';
import { colors } from '@phyllome/common';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import WaterDropIcon from '@mui/icons-material/WaterDrop';

const CustomToggle = styled(ToggleButton)({
  '&.Mui-selected': {
    background: colors.ocean,
    color: 'white',
  },
  '&.Mui-selected:hover': {
    background: colors.ocean,
    color: 'white',
  },
  '&.MuiToggleButton-root': {
    border: '1px solid #aaa',
  },
});

const Filters = () => {
  const [secondaryFormats, setSecondaryFormats] = useState(() => []);
  const [formats, setFormats] = useState(() => ['TRAYS']);
  const sContext = useContext(TraysContext);

  const handlePrimaryFormat = (_: any, payload: any) => {
    if (!payload) return;
    setFormats(payload);
    sContext.dispatch({ type: 'SET_PRIMARY_LAYER', payload });
  };

  const handleSecondaryFormat = (event: any, newFormats: any) => {
    setSecondaryFormats(newFormats);
    sContext.dispatch({ type: 'SET_SECONDARY_LAYER', payload: newFormats });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <ToggleButtonGroup
        size='small'
        value={formats}
        exclusive
        onChange={handlePrimaryFormat}
        aria-label='text formatting'
        color='primary'
        sx={{ marginRight: '4px' }}
      >
        <CustomToggle value='TRAYS' aria-label='bold'>
          Trays
        </CustomToggle>
        <CustomToggle value='IRRIGSCHEDULE' aria-label='italic'>
          Irrigation Schedule
        </CustomToggle>
        <CustomToggle value='SEASONS' aria-label='italic'>
          SEASONS
        </CustomToggle>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        size='small'
        value={secondaryFormats}
        onChange={handleSecondaryFormat}
        aria-label='text formatting'
        color='primary'
      >
        <CustomToggle value='SPRINKLERS' aria-label='italic'>
          Sprinklers
          <WaterDropIcon
            sx={{
              textAlign: 'left',
              fontSize: '11px',
              marginLeft: '4px',
              color: colors.river,
            }}
          />
        </CustomToggle>
        <CustomToggle value='LIGHTING' aria-label='bold'>
          Lighting
          <LightbulbIcon
            sx={{
              textAlign: 'left',
              fontSize: '11px',
              marginLeft: '4px',
              color: colors.sunlight,
            }}
          />
        </CustomToggle>
      </ToggleButtonGroup>
    </div>
  );
};

export default Filters;