import { useContext } from 'react';
import { GraphCursorContext } from './ChartContext/GraphCursorContext';

const GraphCursor = () => {
  const {
    state: { cursorPosition },
  } = useContext(GraphCursorContext);

  if (cursorPosition === undefined || cursorPosition === null) return null;
  return (
    <g>
      <line
        x1={cursorPosition}
        y1={28}
        x2={cursorPosition}
        y2={155}
        stroke='#777'
        strokeWidth='2'
        strokeOpacity={0.5}
      />
    </g>
  );
};

export default GraphCursor;
