import { useMemo, type FC } from 'react';
import { ReportProblem } from '@mui/icons-material';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, tableCellClasses, styled } from '@mui/material';
import { colors } from '@phyllome/common';
import useNavSide from '../../../../data/hooks/useNavSide';
import useSeasons from '../../../../data/hooks/collections/useSeasons';
import useDevicesUpdating from '../../../../data/hooks/useDevicesUpdating';
import ContainerPrimary from '../../../../components/containers/ContainerPrimary/ContainerPrimary';
import { PhyconMUI } from '../../../../components/puregui/Phycon/Phycon';
import Logs from '../Logs';
import DeviceTypeToIcon from '../../../../components/DeviceTypeToIcon/DeviceTypeToIcon';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.night,
    color: colors.fog, //
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Updates: FC<{ brokerId: string }> = ({ brokerId }) => {

  const queue = useDevicesUpdating();
  const seasons = useSeasons({
    active: true,
  });

  const navSide = useNavSide();

  const seasonLookup = useMemo<{ [key: string]: typeof seasons.data[0] }>(() => {

    return seasons.data.reduce((acc, season) => ({
      ...acc,
      [season.id]: season,
    }), {});
  }, [seasons]);

  const filteredMap = queue.data?.filter(x => Object.keys(x.diff).length > 0);

  console.log(queue);
  return (
    <ContainerPrimary
      title='Logs'
      icon={<PhyconMUI icon={ReportProblem} size={120} />}
    >
      <Logs brokerId={brokerId} selected={2}>

        <TableContainer component={Paper}>

          <Table sx={{ minWidth: 650 }} aria-label='updates' size='small'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Device</StyledTableCell>
                <StyledTableCell>Update Required</StyledTableCell>
                <StyledTableCell>Season</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMap.map((device) => {

                return (
                  <TableRow key={device.id}>
                    <TableCell>
                      <Button
                        onClick={() => { navSide(`${device.deviceType}/${device.deviceId}`); }}
                        startIcon={<DeviceTypeToIcon deviceType={device.deviceType}/>}
                      >
                        {device.deviceType}/{device.deviceId}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {JSON.stringify(device.diff)}</TableCell>
                    <TableCell>{device.seasonId && device.seasonId.map(y => {
                      const season = seasonLookup[y];
                      const seasonId = season ? season.id : y;

                      return <Button onClick={() => navSide(`season/${seasonId}`)}>{season ? season.name : y}</Button>;
                    })}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Logs>
    </ContainerPrimary>
  );
};

export default Updates;