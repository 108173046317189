import useControlSend from '../../../data/hooks/useControlSend';
import type {
  DatabaseDevices,
  LightingDeviceStateUnflattened,

} from '@phyllome/common';
import {
  mqttDefinitionLightingSet,
} from '@phyllome/common';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { useSnackbar } from 'notistack';
import ScheduleViewLighting from '../../../components/ScheduleView/ScheduleViewLighting';
import useSeed from '../../../data/hooks/collections/useSeed';
import useFindSeasonWithZone from '../hooks/useFindSeasonWithZone';
import { LightingScheduleEdit } from '../../../components/ScheduleEdit/ScheduleEdit';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { Alert } from '@mui/material';
import { useMemo } from 'react';

const FormLightingSetSchedule = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LightingDeviceStateUnflattened>;
}) => {

  const { deviceId, brokerId } = deviceState;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const controlLifecycle = useControlSend(mqttDefinitionLightingSet, deviceId);
  const season = useFindSeasonWithZone({
    zoneId: parseInt(deviceId),
    brokerId: brokerId,
  });
  const seed = useSeed(season?.seedId);
  const { enqueueSnackbar } = useSnackbar();
  const activityLog = useActivityLog(`device/lighting/${deviceId}/notes`);

  return (
    <PaperSection
      heading='Zone Schedule'
      mb={2}
      subheading={mqttDefinitionLightingSet.topic}
    >
      <Alert severity='error'>
        Bug in the way schedules are saved (not as an array). Bug logged 2/march
      </Alert>
      <ScheduleViewLighting
        seedSchedule={seed?.defaultLightingSchedule}
        seasonSchedule={season?.defaultLightingSchedule}
        zoneSchedule={latest.schedule}
        context='zone'
      />
      <LightingScheduleEdit
        isSaving={controlLifecycle.status === 'started'}
        data={latest.schedule}
        onSubmit={(payload) => {
          controlLifecycle
            .run({
              payload: {
                schedule: payload,
              },
              topicParams: {
                deviceId,
              },
            })
            .then(() => {
              enqueueSnackbar(`lightong zone ${deviceId} schedule updated`, {
                variant: 'success',
              });
            })
            .then(() => {
              activityLog.add('Updated lighting zone schedule', 'OPERATION');
            });
        }}
      />
    </PaperSection>
  );
};

export default FormLightingSetSchedule;
