/** @jsxImportSource @emotion/react */
import type { FC } from 'react';
import { css, Global, keyframes } from '@emotion/react';

type PulseProps = {
  children?: React.ReactNode;
  disabled?: boolean;
};

const Pulse: FC<PulseProps> = ({ children = null, disabled = false }) => {
  const oscillateOpacity = keyframes`
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
`;

  const animationStyleOn = css`
    animation: ${oscillateOpacity} 1s infinite;
  `;

  const animationStyleOff = css`
    animation: none;
  `;

  const animationStyle = disabled ? animationStyleOff : animationStyleOn;

  return (
    <>
      <Global
        styles={css`
          @keyframes oscillateOpacity {
            0%,
            100% {
              opacity: 0.5;
            }
            50% {
              opacity: 1;
            }
          }
        `}
      />
      <span css={animationStyle}>
        {children}
      </span>
    </>
  );
};

export default Pulse;
