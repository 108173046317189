import { colors } from '@phyllome/common';
import type { FavouriteDefinition } from './types';

const lightingCoreGraph: FavouriteDefinition = {
  lightingCoreCurrentA: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'lightingcore',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[LIGHTINGCORE] ${deviceId}/current.A`,
    color: colors.earth1,
    field: 'current.A',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  lightingCoreCurrentB: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'lightingcore',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[LIGHTINGCORE] ${deviceId}/current.B`,
    color: colors.earth1,
    field: 'current.B',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  lightingCoreCurrentC: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'lightingcore',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[LIGHTINGCORE] ${deviceId}/current.C`,
    color: colors.earth1,
    field: 'current.C',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  lightingCoreMode: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'lightingcore',
    subType: 'enum',
    showHeading: ({ deviceId }) => `[LIGHTINGCORE] ${deviceId}/mode`,
    color: colors.earth1,
    field: 'mode',
    brokerId,
    deviceId,
    mapping: {
      '0': 'Off',
      '1': 'Auto',
      '2': 'Manual',
    },
  }),
};

export default lightingCoreGraph;
