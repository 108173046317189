import { Tabs, Tab, Box, Alert } from '@mui/material';
import { colors } from '@phyllome/common';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
//import { appStateActions, useAppState } from "../../data/AppState/useAppState";
import FarmContext from '../../components/FarmProvider/FarmContext';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import useLatestTransfer from './hooks/useLatestTransfer';
import LatestTransferInfo from './LatestTransferInfo';
import LatestTransfer from './LatestTransfer';
import GraphTransfer from './GraphTransfer';
import ControlTransfer from './ControlTransfer';
import ConfigTransfer from './ConfigTransfer';
import PhyTabPanel from '../../components/PhyTabPanel/PhyTabPanel';
import useRoute from '../../data/hooks/useRoute';
import PendingDevice from '../../components/PendingDevice/PendingDevice';

const SideBarEntryTransfer = ({ deviceId }: { deviceId: string }) => {
  const { brokerId } = useContext(FarmContext);

  const router = useRoute();
  const [value, setValue] = useState<any>(Number(router.sidebarQuery.tab) || 0);

  const latestDeviceInfo = useLatestTransfer(deviceId);
  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    router.navSideQuery({ tab: value });
  }, [value, router]);

  if (!latestDeviceInfo)
    return (
      <Box p={2}>
        <Alert severity='info'>No Latest Data!</Alert>
      </Box>
    );
  return (
    <>
      <AppDrawerTitle title={latestDeviceInfo.deviceId} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor='inherit'
        variant='scrollable'
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label='Latest' value={0} />
        <Tab label='Graphs' value={1} />
        <Tab label='Control' value={2} />
        <Tab label='Config' value={3} />
        <Tab label='Activity' value={4} />
        <Tab label='Alerts' value={5} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <LatestTransferInfo deviceState={latestDeviceInfo} />
        <PendingDevice deviceState={latestDeviceInfo} />
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='transfer'
          count={1}
        />
        <LatestTransfer deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <GraphTransfer deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <ControlTransfer deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <ConfigTransfer deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <ActivityLog
          identifier={`device/transfer/${deviceId}/notes`}
          heading='Activity Log'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='transfer'
        />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryTransfer;
