import type { PackagerDeviceStateUnflattened } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestPackager = (deviceId: string) => {
  return useDeviceState<PackagerDeviceStateUnflattened>({
    deviceId,
    deviceType: 'packager',
  });
};

export default useLatestPackager;
