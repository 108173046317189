import CloseIcon from '@mui/icons-material/Close';

const InlineProgressFailed = ({ text }: { text: string }) => {
  const iconStyle = {
    lineHeight: '30px',
    width: '26px',
    display: 'inline-block',
    lineHight: '30px',
  };

  return (
    <div>
      <div style={{ ...iconStyle, top: '3px', position: 'relative' }}>
        <CloseIcon fontSize='small' color='secondary' />
      </div>
      <span style={{ lineHeight: '30px' }}>{text}</span>
    </div>
  );
};

export default InlineProgressFailed;
