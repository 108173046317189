import { useEffect, useState } from 'react';
import * as React from 'react';

export const DevicesParent = ({ children }: { children: React.ReactNode }) => {
  const [gridColumns, setGridColumns] = useState('1fr');

  useEffect(() => {
    const updateGridColumns = () => {
      if (window.matchMedia('(min-width: 1300px)').matches) {
        setGridColumns('repeat(4, 1fr)');
      } else if (window.matchMedia('(min-width: 1024px)').matches) {
        setGridColumns('repeat(3, 1fr)');
      } else {
        setGridColumns('1fr');
      }
    };

    updateGridColumns(); // Initial check

    const mediaQuery2 = window.matchMedia('(min-width: 1300px)');
    const mediaQuery1 = window.matchMedia('(min-width: 1024px)');

    mediaQuery2.addEventListener('change', updateGridColumns);
    mediaQuery1.addEventListener('change', updateGridColumns);
    // Cleanup listeners on component unmount
    return () => {
      mediaQuery2.removeEventListener('change', updateGridColumns);
      mediaQuery1.removeEventListener('change', updateGridColumns);
    };
  }, []);

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: gridColumns,
    gap: '8px',
  };

  return <div style={containerStyle}>{children}</div>;
};
