import type { FC } from 'react';
import {  useState } from 'react';
import { howManyDaysAgo } from '../../../helpers/FStimeAgo';
import type {
  DatabaseDevices,
  DeviceType,
  GuardDeviceStateUnflattened,
  HarvesterDeviceStateUnflattened,
  PackagerDeviceStateUnflattened,
  IrrigationDeviceStateUnflattened,
  LiftDeviceStateUnflattened,
  LightingCoreDeviceStateUnflattened,
  LightingDeviceStateUnflattened,
  SeederDeviceStateUnflattened,
  SentryDeviceStateUnflattened,
  TransferDeviceStateUnflattened,
  TraybotDeviceStateUnflattened,
  FertigatorDeviceStateUnflattened,
  ConveyorDeviceStateUnflattened,
} from '@phyllome/common';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import DeviceTemplate from './DeviceTemplate';
import { DevicesParent } from './DevicesParent';
import { Box, useTheme } from '@mui/material';
import HorizontalData from './HorizontalData';
import ChipBool from '../../../components/puregui/ChipBool/ChipBool';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import getDeviceErrorState from '../../../helpers/getDeviceErrorState';

interface IRowProps {
  deviceData: DatabaseDevices[];
  onClick: (deviceData: DatabaseDevices) => void;
  devicesSelected: DeviceType[];
}

const DeviceListPrototype: FC<IRowProps> = ({
  deviceData,
  onClick,
  devicesSelected,
}) => {
  const [selectedRow, setSelectedRow] = useState({});
  const theme = useTheme();

  return (
    <Box mt={2.5}>
      <DevicesParent>
        {deviceData.map((_deviceData) => {

          const _selectedKey = `${_deviceData.deviceType}.${_deviceData.deviceId}`;
          const isComplete = _deviceData.updateStatus === 'COMPLETE';
          const key = `${_deviceData.deviceType}.${_deviceData.deviceId}`;

          if (!devicesSelected.includes(_deviceData.deviceType)) return null;
          if (howManyDaysAgo(_deviceData.lastAccessed) >= 12) return null;
          const label =
            _deviceData.properties.label === undefined
              ? `${_deviceData.deviceType}/${_deviceData.deviceId}`
              : `${_deviceData.deviceType}/${_deviceData.deviceId} (${_deviceData.properties.label})`;

          const commonProps = {
            isSelected: selectedRow === _selectedKey,
            onClick: () => {
              setSelectedRow(_selectedKey);
              onClick(_deviceData);
            },

            lastAccessed: _deviceData?.lastAccessed
              ? new Date(_deviceData.lastAccessed)
              : new Date(),
            deviceType: _deviceData.deviceType,
            deviceId: _deviceData.deviceId,
            aliases: _deviceData.alias,
          };

          switch (_deviceData.deviceType) {

            case 'air':
              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  isError={getDeviceErrorState(_deviceData)}
                  labelRow={`${label}`}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {_deviceData.properties.temperature.toFixed(1)}°C /{' '}
                    {_deviceData.properties.co2?.toFixed(0)}

                  </Box>
                </DeviceTemplate>
              );
            case 'camera':
              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  isError={getDeviceErrorState(_deviceData)}
                  labelRow={`${label}`}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {_deviceData.properties.images_pending} images
                  </Box>
                </DeviceTemplate>
              );
            case 'co2':
              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  isError={getDeviceErrorState(_deviceData)}
                  labelRow={`${label}`}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {_deviceData.properties.ppm}ppm
                  </Box>
                </DeviceTemplate>
              );
            case 'fertigator': {
              const data =
                _deviceData.properties as unknown as FertigatorDeviceStateUnflattened;

              const heroItems = [
                {
                  label: 'EC',
                  text: (
                    Math.round(Number(data.sample.ec * 10)) / 10
                  ).toString(),
                },
                {
                  label: 'Ph',
                  text: (
                    Math.round(Number(data.sample.ph * 10)) / 10
                  ).toString(),
                },
                {
                  label: 'Temp',
                  text:
                    Math.round(Number(data.sample.temperature * 10)) / 10 +
                    '\u00b0',
                },
                {
                  label: 'Oxygen',
                  text: (
                    Math.round(Number(data.sample.do * 10)) / 10
                  ).toString(),
                },
                {
                  label: 'ORP',
                  text: (
                    Math.round(Number(data.sample.orp))
                  ).toString(),
                },
              ];

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <HorizontalData data={heroItems} />
                </DeviceTemplate>
              );
            }

            case 'guard': {
              const data =
                _deviceData.properties as unknown as GuardDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    <ChipBool size='tiny' data={data.move} label='Move' />
                    <ChipBool
                      size='tiny'
                      data={data.automate}
                      label='Automate'
                    />
                    <ChipBool
                      size='tiny'
                      data={data.alarm}
                      label='Alarm'
                      icon={<NotificationsActiveIcon />}
                    />
                    <ChipBool
                      size='tiny'
                      data={data.flash}
                      label='Flash'
                      icon={<WbTwilightIcon />}
                    />
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'harvester': {
              const data =
                _deviceData.properties as unknown as HarvesterDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {data.platform.height}
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'packager': {
              const data =
                _deviceData.properties as unknown as PackagerDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {data.count} bags
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'irrigation': {
              const data =
                _deviceData.properties as unknown as IrrigationDeviceStateUnflattened;
              const heroItems = [
                {
                  label: 'Supply Lvl',
                  text: `${String(data.levels.supply)}%`,
                },
                {
                  label: 'Return Lvl',
                  text: `${String(data.levels.return)}%`,
                },
                {
                  label: 'Ret.Pressure',
                  text: `${String(data.pressures.return)}kpa`,
                },
              ];

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <HorizontalData data={heroItems} />
                </DeviceTemplate>
              );
            }
            case 'lightingcore': {
              const data =
                _deviceData.properties as unknown as LightingCoreDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {`${(data.current.A + data.current.B + data.current.C).toFixed(1)}A`}
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'lift': {
              const data = _deviceData.properties as unknown as LiftDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    shelf: {data.shelf}
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'liftcore': {
              const data = _deviceData.properties as unknown as LiftDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    shelf: {data.shelf}
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'traybot': {
              const data =
                _deviceData.properties as unknown as TraybotDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {data.location?.column}/{data.location?.shelf}/
                    {data.location?.row}
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'lighting': {
              const data = _deviceData.properties as unknown as LightingDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    <ChipBool size='tiny' data={data.enable} label='Enabled' />
                    <ChipBool size='tiny' data={data.active} label='Active' />
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'sentry': {
              const data =
                _deviceData.properties as unknown as SentryDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {data.tray}
                  </Box>
                </DeviceTemplate>
              );
            }

            case 'seeder': {
              const data =
                _deviceData.properties as unknown as SeederDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {data.count}/{data.trays}
                  </Box>
                </DeviceTemplate>
              );
            }

            case 'transfer': {
              const data =
                _deviceData.properties as unknown as TransferDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    <ChipBool
                      size='tiny'
                      data={data.conveyors.infeed}
                      label='Infeed'
                    />
                    <ChipBool
                      size='tiny'
                      data={data.conveyors.outfeed}
                      label='Outfeed'
                    />
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'conveyor': {
              const data =
                _deviceData.properties as unknown as ConveyorDeviceStateUnflattened;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                  isLoading={!isComplete}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    <ChipBool
                      size='tiny'
                      data={data.busy}
                      label='busy'
                    />
                  </Box>
                </DeviceTemplate>
              );
            }

            default:
              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isLoading={!isComplete}
                />
              );
          }
        })}
      </DevicesParent>
    </Box>
  );
};

export default DeviceListPrototype;
