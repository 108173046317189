import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import type { TraybotDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionTraybotConfigSet,
} from '@phyllome/common';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { InputAdornment } from '@mui/material';
import { useCurrentSidebarDeviceData } from '../../../data/hooks/useCurrentSidebarDeviceData';

const FormTraybotConfigSetPositions = () => {

  const { properties: latest, deviceId } = useCurrentSidebarDeviceData<TraybotDeviceStateUnflattened>();

  const columnId = latest.columnId;
  const controlLifecycle = useControlSend(
    mqttDefinitionTraybotConfigSet,
    String(columnId),
  );
  const activityLog = useActivityLog(`device/traybot/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTraybotConfigSet,
    {
      drive: {
        positionWidth: latest?.drive?.positionWidth || 0,
        liftOffset: latest?.drive?.liftOffset || 0,
        firstTrayPosition: latest?.drive?.firstTrayPosition || 0,
        chargerOffset: latest?.drive?.chargerOffset || 0,
      },
    },
  );

  return (
    <PaperSection
      heading='Traybot Drive Positions'
      mb={2}
      subheading={mqttDefinitionTraybotConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              drive: {
                positionWidth: data?.drive?.positionWidth,
                liftOffset: data?.drive?.liftOffset,
                firstTrayPosition: data?.drive?.firstTrayPosition,
                chargerOffset: data?.drive?.chargerOffset,
              },
            },
            topicParams: {
              deviceId: latest?.deviceId,
            },
          });

          activityLog.add('Updated traybot position config}', 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          drive: yup.object({
            positionWidth: yup.number().min(100).max(2000).required(),
            liftOffset: yup.number().min(0).required(),
            firstTrayPosition: yup.number().min(0).required(),
            chargerOffset: yup.number().required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <TextFieldElement
            label='Position Width'
            name='drive.positionWidth'
            required
            InputProps={{ endAdornment: <InputAdornment position='end'>mm</InputAdornment> }}
          />
          <TextFieldElement
            label='Lift Offset'
            name='drive.liftOffset'
            required
            InputProps={{ endAdornment: <InputAdornment position='end'>mm</InputAdornment> }}
          />
          <TextFieldElement
            label='First Tray Position'
            name='drive.firstTrayPosition'
            required
            InputProps={{ endAdornment: <InputAdornment position='end'>mm</InputAdornment> }}
          />
          <TextFieldElement label='Charger Offset' name='drive.chargerOffset' InputProps={{ endAdornment: <InputAdornment position='end'>mm</InputAdornment> }} />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTraybotConfigSetPositions;
