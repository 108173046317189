import type { LiftDeviceStateUnflattened } from '@phyllome/common';

const getLiftColumnData = (liftState: LiftDeviceStateUnflattened) => {
  const columns: Record<string, {
    traybot: number | undefined;
    tray: boolean;
    blocked: boolean;
  }> = {};

  if (!liftState) {
    return undefined;
  }
  if (!liftState.traybots || !liftState.trays || !liftState.blocked) {
    return undefined;
  }

  const allKeys = new Set([
    ...Object.keys(liftState.traybots),
    ...Object.keys(liftState.trays),
    ...Object.keys(liftState.blocked),
  ]);

  liftState.blocked[3] = liftState.blocked[3] || false;

  allKeys.forEach(key => {

    const columnId = Number(key);

    if (isNaN(columnId)) {
      return; // handle "infeed" key on tray object, we only want the numerics
    }

    columns[key] = {
      traybot: liftState.traybots[columnId],
      tray: liftState.trays[columnId],
      blocked: liftState.blocked[columnId],
    };
  });
  return columns;
};

export default getLiftColumnData;