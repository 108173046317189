import { max, min } from 'lodash';
import type { FC } from 'react';
import { useMemo } from 'react';
import {
  VictoryArea,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import chartTheme from './chartTheme';
import FlyOut from './FlyOut';
import GraphCursor from './GraphCursor';
import type { TimeSeriesData } from './useTimeSeriesPoller';
import type { GraphConstraints, GraphLimits } from '../Favourites/types';
import { colors } from '@phyllome/common';

export type IVictoryChatProps = {
  color: string;
  timeSeries: TimeSeriesData;
  height?: number;
  constraints?: GraphConstraints;
  limits?: GraphLimits;
  target?: number;
};

const ReChartVictoryLine: FC<IVictoryChatProps> = ({
  color,
  timeSeries,
  height,
  constraints,
  limits,
  target,
}) => {
  const minValue = useMemo(() => {
    const vals =
      constraints?.min !== undefined
        ? [constraints.min]
        : timeSeries.data.map((t) => t.y).filter((v) => v !== null);

    return Math.floor(min(vals) || 0);
  }, [timeSeries, constraints]);

  const maxValue = useMemo(() => {
    const vals =
      constraints?.max !== undefined
        ? [constraints.max]
        : timeSeries.data.map((t) => t.y).filter((v) => v !== null);

    return Math.ceil(max(vals) || 0);
  }, [timeSeries, constraints]);

  const minDate = useMemo(() => {
    return timeSeries.data.length > 0 ? timeSeries.data[0].x : null;
  }, [timeSeries]);

  const maxDate = useMemo(() => {
    return timeSeries.data.length > 0 ? timeSeries.data[timeSeries.data.length - 1].x : null;
  }, [timeSeries]);

  return (
    <VictoryChart
      height={height || 170}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension='x'
          labels={() => ' '}
          labelComponent={
            <VictoryTooltip
              pointerLength={0}
              flyoutComponent={<FlyOut color={color} />}
            />
          }
        />
      }
      theme={chartTheme}
      scale={{ x: 'time', y: 'linear' }}
      minDomain={{ y: minValue || 0 }}
      maxDomain={{ y: maxValue || 1 }}
    >
      <VictoryGroup>
        <VictoryLine
          style={{
            data: {
              stroke: color,
              strokeWidth: 1.3,
              strokeLinecap: 'round',
              strokeOpacity: 1,
            },
          }}
          data={timeSeries.data}
        />
        {target && (
          <VictoryLine
            style={{
              data: {
                stroke: colors.budding,
                strokeWidth: 1.3,
                strokeLinecap: 'round',
                strokeOpacity: 0.5,
              },
            }}
            data={[
              { x: minDate, y: target },
              { x: maxDate, y: target },
            ]}
          />
        )}
        {limits && (
          <VictoryArea
            style={{
              data: {
                fill: color,
                fillOpacity: 0.1,
                stroke: 'transparent',
              },
            }}
            data={[
              { x: minDate, y: limits?.max, y0: limits?.min },
              { x: maxDate, y: limits?.max, y0: limits?.min },
            ]}
          />
        )}
      </VictoryGroup>
      <GraphCursor />
    </VictoryChart>
  );
};

export default ReChartVictoryLine;
