import { Stack, Box } from '@mui/material';

type IHorizontalData = {
  data: { label: string; text: string }[];
};

const HorizontalData = ({ data }: IHorizontalData) => {
  return (
    <Box style={{ fontSize: '15px', color: '#999' }}>
      <Stack mt={1} direction='row' spacing={4} alignSelf='center' useFlexGap>
        {data.map((item, key) => {
          return (
            <div key={key} style={{ textAlign: 'center' }}>
              <div style={{ lineHeight: '10px', fontSize: '11px' }}>
                {item.label}
              </div>
              <div>{item.text}</div>
            </div>
          );
        })}
      </Stack>
    </Box>
  );
};

export default HorizontalData;
