import { useEffect, type FC } from 'react';
import { useSnackbar } from 'notistack';
import { useAtomValue } from 'jotai';
import websocketAtom from '../../data/atoms/websocketAtom';
import { Button } from '@mui/material';

export type ConsoleStreamItem = {
  brokerId: string,
  messageText: string,
  thumbnail?: string,
  user?: string,
  deviceIdentifier?: string,
  seasonId?: string
};

// Separate the Dismiss button into its own component for clarity
const DismissButton = ({ snackbarId, closeSnackbar }: any) => {
  return (
    <Button
      style={{ position: 'relative', top: '0px' }}
      onClick={() => closeSnackbar(snackbarId)}
      variant='contained'
    >
      <span style={{ color: 'white' }}>dismiss</span>
    </Button>
  );
};

const getSnackVariation = (consoleStreamData: ConsoleStreamItem) => {
  if (consoleStreamData.user) {
    return <SnackVariationWithEmail data={consoleStreamData} />;
  } else {
    return <SnackVariationWithOutEmail data={consoleStreamData} />;
  }
};

const SnackVariationWithEmail = (props:{ data: ConsoleStreamItem }) => {
  const { user, messageText } = props.data;

  return (<div><strong>{user}</strong><br/>{messageText}</div>);
};

const SnackVariationWithOutEmail = (props:{ data: ConsoleStreamItem }) => {
  const { messageText } = props.data;

  return (<div>{messageText}</div>);
};

const StreamMessageProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const websocket = useAtomValue(websocketAtom);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    // Skip if no websocket is available
    if (!websocket) return;

    // Define the event handler
    const handleConsoleMessage = (data: ConsoleStreamItem) => {
      enqueueSnackbar(getSnackVariation(data), {
        variant: 'info',
        autoHideDuration: 5000, // Auto-hide after 5 seconds
        preventDuplicate: true, // Prevent duplicate messages
        action: (key) => (
          <DismissButton snackbarId={key} closeSnackbar={closeSnackbar} />
        ),
      });
    };

    // Register the event listener
    websocket.on('console:message', handleConsoleMessage);
    // Cleanup function
    return () => {
      websocket.off('console:message', handleConsoleMessage);
    };
  }, [enqueueSnackbar, closeSnackbar, websocket]); // Include closeSnackbar in dependencies
  return <>{children}</>;
};

export default StreamMessageProvider;