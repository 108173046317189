import RackBox from '../../../../components/factoryui/components/RackBox';
import getUIInfoBasedOnAge from '../helpers/getUIInfoBasedOnAge';
import useNDSOverlayData from './useNDSOverlayData';
import type { NDSDeviceStateUnflattened } from '@phyllome/common';
import useNDSByColumn from './useNDSByColumn';

const OverlaySpinklerSimple = ({ column }: { column: number }) => {
  const {
    shelves,
    date,
  } = useNDSOverlayData(column);

  const deviceData = useNDSByColumn(column);

  return (
    <>
      {Object.keys(deviceData).map(_deviceRow => {

        const data = deviceData[_deviceRow];

        if (!data) {
          return null;
        }
        const { row, shelf } = (data.properties as NDSDeviceStateUnflattened).location;
        const key = `${column}.${shelf}.${row}`;
        const uiInfo = getUIInfoBasedOnAge(date, new Date(data.lastAccessed || 'now'));
        const isUpdating = data.updateStatus !== 'COMPLETE';

        return (
          <RackBox
            key={key}
            zIndex={10}
            row={row}
            shelf={shelf}
            shelves={shelves}
            style={{
              textAlign: 'center',
              color: 'white',
              background: uiInfo.color,
            }}
            isPulsing={isUpdating}

          >

            {column}/{shelf}/{row}
          </RackBox>
        );
      })
      }
    </>
  );
};

export default OverlaySpinklerSimple;
