import type {
  DeviceType,
  AirDeviceStateUnflattened,
  FertigatorDeviceStateUnflattened,
} from '@phyllome/common';
import type { FC } from 'react';
import PaperSection from '../puregui/PaperSection/PaperSection';
import { Alert, AlertTitle } from '@mui/material';

export type AverageInfoItems = {
  field: string;
  label?: string;
  sup?: string;
  decimals?: number;
};

type IAverageInfoProps = {
  latest: AirDeviceStateUnflattened  | FertigatorDeviceStateUnflattened;
  deviceType: DeviceType;
  items: AverageInfoItems[];
};
export const AverageInfo: FC<IAverageInfoProps> = ({
  latest,
  deviceType,
  items,
}) => {
  console.log('AverageInfo', latest, deviceType, items);
  return (
    <PaperSection heading='Averages'>
      <Alert severity='info'>
        <AlertTitle>Mick disabled this for some reason</AlertTitle>
      </Alert>
    </PaperSection>
  );
  // return (
  //   <PaperSection heading="Averages">
  //     <Grid container spacing={2} mb={2} justifyContent="center">
  //       {items.map((item, index) => (
  //         <Grid item key={index}>
  //           <AverageTimeSeriesHeroItem
  //             latest={latest}
  //             deviceType={deviceType}
  //             field={item.field}
  //             sup={item.sup}
  //             label={item.label}
  //             decimals={item.decimals}
  //           />
  //           {items.length > 0 && index < items.length - 1 && (
  //             <Divider orientation="vertical" flexItem />
  //           )}
  //         </Grid>
  //       ))}
  //     </Grid>
  //   </PaperSection>
  // );
};
