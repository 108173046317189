import { Stack } from '@mui/system';
import { SwitchElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, LightingDeviceStateUnflattened } from '@phyllome/common';
import { mqttDefinitionLightingEnable } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Alert } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { useMemo } from 'react';

const FormLightingEnable = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LightingDeviceStateUnflattened>;
}) => {

  const deviceId = deviceState.deviceId;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const controlLifecycle = useControlSend(mqttDefinitionLightingEnable, deviceId);
  const activityLog = useActivityLog(`device/lighting/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionLightingEnable, {
    enable: latest?.enable || false,
  });

  return (
    <PaperSection
      heading='Lighting Zone control'
      mb={2}
      subheading={mqttDefinitionLightingEnable.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              enable: data.enable,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Set lighting ${deviceId} to ${data.enable ? 'enable' : 'disable'}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({})}
      >
        <Stack spacing={2}>
          <Alert severity='info'>
            Normal operating procedure is to control lighting zone enable state via
            season control
          </Alert>
          <SwitchElement label='Enable Lighting Zone' name='enable' />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormLightingEnable;
