import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import websocketAtom from './websocketAtom';

export type WebsocketFilters = {
  brokerId: string;
  fetchDevices: boolean;
  fetchNDSes: boolean;
};

// Create the base atom with localStorage persistence
// The first parameter 'websocket-filters' is the key used in localStorage
const baseWebsocketFiltersAtom = atomWithStorage<WebsocketFilters>('websocket-filters', {
  brokerId: '',
  fetchDevices: false,
  fetchNDSes: false,
});

// Create the derived atom with partial update support
export const websocketFiltersAtom = atom(
  // Getter - just return the current value
  (get) => get(baseWebsocketFiltersAtom),

  // Setter - support partial updates
  (get, set, update: Partial<WebsocketFilters>) => {
    const currentFilters = get(baseWebsocketFiltersAtom);

    const mergedData = {
      ...currentFilters,
      ...update,
    };

    set(baseWebsocketFiltersAtom, mergedData);

    const websocket = get(websocketAtom);

    // this should always be true
    if (websocket?.connected) {
      websocket.emit('filters', mergedData);
    };

  },
);

// If you need just the brokerId, you can also create a focused atom
export const brokerIdAtom = atom(
  (get) => get(websocketFiltersAtom).brokerId,
  (get, set, newBrokerId: string) => {
    set(websocketFiltersAtom, { brokerId: newBrokerId });
  },
);