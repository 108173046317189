import type { Co2DeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { Box } from '@mui/material';
import { Stack } from '@mui/material';
import { mqttDefinitionCo2Clear } from '@phyllome/common';
import TankLevelWithControl from '../../components/TankLevel/TankLevelWithControl';
import FormCo2ConfigSetTanks from './forms/FormCo2ConfigSetTanks';
import useActivityLog from '../../data/hooks/useActivityLog';
import { useMemo } from 'react';

const LatestCo2Levels = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<Co2DeviceStateUnflattened>;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;
  const level = (latest.tank / latest.tankCapacity) * 100;
  const consumed = (latest.tankCapacity - latest.tank).toLocaleString() + ' L';

  const activityLog = useActivityLog(`device/co2/${deviceId}/notes`);

  const addActivityLog = (text: string) => {
    activityLog.add(text, 'OPERATION');
  };

  return (
    <>
      <PaperSection
        mb={2}
        heading='CO2 Gas tank levels'
        subheading={mqttDefinitionCo2Clear.topic}
      >
        <Box mb={4} sx={{ textAlign: 'center' }}>
          <Stack direction='row' spacing={8} justifyContent='center'>
            <TankLevelWithControl
              label='CO2 Tank'
              level={level}
              subLabel={consumed}
              payload={{}}
              deviceId={deviceId}
              mqttDefinitionDeviceClear={mqttDefinitionCo2Clear}
              addActivityLog={addActivityLog}
            />
          </Stack>
        </Box>
      </PaperSection>
      <FormCo2ConfigSetTanks deviceState={deviceState} />
    </>
  );
};

export default LatestCo2Levels;
