import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import type { NDSDeviceStateUnflattened } from '@phyllome/common';
import { mqttDefinitionNDSConfigSet } from '@phyllome/common';
import parseNDSDeviceId from '../../../helpers/parseNDSDeviceId';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { InputAdornment } from '@mui/material';

const FormNDSConfigSetWaterRate = ({
  deviceState,
}: {
  deviceState: NDSDeviceStateUnflattened;
}) => {
  const controlLifecycle = useControlSend(
    mqttDefinitionNDSConfigSet,
    `${deviceState.deviceId}`,
  );

  const defaultValues = formCreateDefaultValues(mqttDefinitionNDSConfigSet, {
    waterRate: deviceState?.waterRate || 0,
  });

  const { column, row, shelf } = parseNDSDeviceId(deviceState.deviceId);

  return (
    <PaperSection
      heading='NDS Water Rate Configuration'
      mb={2}
      subheading={mqttDefinitionNDSConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              waterRate: data.waterRate,
            },
            topicParams: {
              column,
              row,
              shelf,
            },
          });
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          waterRate: yup.number().required(),
        })}
      >
        <Stack spacing={2}>
          <p>
            Water flow rate (in mL per minute) used to calculate valve duration
          </p>
          <TextFieldElement
            label='Water Rate'
            name='waterRate'
            required
            InputProps={{ endAdornment: <InputAdornment position='end'>mL</InputAdornment> }}
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormNDSConfigSetWaterRate;
