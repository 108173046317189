import { parseDevicePath, type DatabaseDevices } from '@phyllome/common';
import { atom, useAtomValue } from 'jotai';
import { deviceAtomFamily } from '../atoms/deviceAtomFamily';

export const currentSidebarDeviceDataAtom = atom<DatabaseDevices>();

export const currentSideBarDeviceIdAtom = atom<string>('unknown');

export const useCurrentSidebarDeviceData = <T>() => {
  const currentDeviceId = useAtomValue(currentSideBarDeviceIdAtom);
  const parsedDeviceStuff = parseDevicePath(currentDeviceId);
  const deviceData = useAtomValue(deviceAtomFamily({
    brokerId: parsedDeviceStuff?.brokerId || '',
    deviceId: parsedDeviceStuff?.deviceId || 'unknown',
    deviceType: parsedDeviceStuff?.deviceType || 'unknown',
  })) as DatabaseDevices<T>;

  return deviceData;

};

