import Phorm from '../../../components/Form/Components/Phorm';
import PhormBlock from '../../../components/Form/Components/PhormBlock';
import PhormErrors from '../../../components/Form/Components/PhormErrors';
import PhormSubmit from '../../../components/Form/PhormSubmit/PhormSubmit';
import AppDrawerTitle from '../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import { Box, InputAdornment, Typography } from '@mui/material';
import { useContext, useRef } from 'react';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import useNavSide from '../../../data/hooks/useNavSide';
import PhormTextField from '../../../components/Form/Components/PhormTextField';
import useSeeds from '../../../data/hooks/collections/useSeeds';
import PhormSelect from '../../../components/Form/Components/PhormSelect';
import type { PhormDropDownData } from '../../../components/Form/types';
import * as yup from 'yup';
import { string, number } from 'yup';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useAction from '../../../data/hooks/useAction';
import PhormActionErrors from '../../../components/Form/Components/PhormActionErrors.';
import type { UseFormReturn } from 'react-hook-form';
import type { CloudActionSeasonCreate } from '@phyllome/common';
import { useSnackbar } from 'notistack';

const SeasonsSidebar = () => {
  const { brokerId } = useContext(FarmContext);
  const seasonCreate = useAction('seasonCreate');
  const { enqueueSnackbar } = useSnackbar();
  const seeds = useSeeds({ validatedOnly: true });
  const navSide = useNavSide();
  const formMethodsRef = useRef<
    UseFormReturn<
      CloudActionSeasonCreate['payload'] & { seedBatchFirst: string }
    >
  >(null!);

  const ddData: PhormDropDownData[] = [
    {
      label: '-- Select Seed --',
      value: '0',
    },
    ...seeds.map((seed) => ({
      label: `${seed.name}/${seed.variety}`,
      value: seed.id || '',
    })),
  ];

  return (
    <>
      <AppDrawerTitle title='Create a Season' />
      <Box p={2}>
        <PaperSection heading='Create a Season'>
          <Phorm
            methodsRef={formMethodsRef}
            schema={yup.object().shape({
              brokerId: string(),
              name: string().required(),
              notes: string(),
              defaultSeedDensity: number().required(),
              durationDays: number().required(),
              trayNumberTarget: number().required(),
              seedId: string().required(),
              defaultSeedCost: number().required(),
              seedBatch: string(),
            })}
            defaultValues={{
              brokerId: '',
              defaultSeedCost: 0,
              name: '',
              notes: '',
              defaultSeedDensity: 0,
              durationDays: 0,
              trayNumberTarget: 0,
              seedId: '0',
              seedBatchFirst: '',
            }}
            onSubmit={(values) => {
              seasonCreate
                .run({
                  brokerId,
                  name: values.name,
                  notes: values.notes || '',
                  seedId: values.seedId,
                  trayNumberTarget: values.trayNumberTarget,
                  defaultSeedDensity: values.defaultSeedDensity,
                  durationDays: values.durationDays,
                  defaultSeedCost: values.defaultSeedCost,
                  seedBatch: [values.seedBatchFirst],
                })
                .then(() => {
                  enqueueSnackbar('Season Created', { variant: 'success' });
                  navSide();
                });
            }}
            busy={false}
          >
            <PhormBlock>
              <PhormTextField
                name='name' label='Season Name'
                size='medium'
              />
            </PhormBlock>
            <PhormBlock>
              <PhormSelect
                id='seedId'
                name='seedId'
                label='Seed Type'
                size='medium'
                options={ddData}
                onModify={(value) => {
                  const seedItem = seeds.find((item) => {
                    return item.id === value;
                  });

                  formMethodsRef.current.setValue(
                    'defaultSeedDensity',
                    seedItem?.defaultSeedAmountPerTray || 0,
                  );
                  formMethodsRef.current.setValue(
                    'durationDays',
                    seedItem?.durationDays || 0,
                  );
                  formMethodsRef.current.setValue(
                    'defaultSeedCost',
                    seedItem?.pricePerMillion || 0,
                  );
                }}
              />
            </PhormBlock>
            <PhormBlock>
              <PhormTextField
                name='trayNumberTarget'
                label='Target Number of Trays'
                size='medium'
              />
            </PhormBlock>
            <PhormBlock>
              <PhormTextField
                fullWidth={true}
                name='notes'
                label='Notes'
                multiline={true}
                minRows={4}
                maxRows={10}
              />
            </PhormBlock>
            <PhormBlock>
              <Typography variant='h6'>Seed Info</Typography>
            </PhormBlock>
            <PhormBlock>
              <PhormTextField
                name='defaultSeedDensity'
                label='Seed Amount (Per Tray)'
                size='medium'
                InputProps={{ endAdornment: <InputAdornment position='end'>seeds per tray</InputAdornment> }}
              />
            </PhormBlock>
            <PhormBlock>
              <PhormTextField
                name='durationDays'
                label='Length of Season'
                size='medium'
                InputProps={{ endAdornment: <InputAdornment position='end'>days</InputAdornment> }}
              />
            </PhormBlock>
            <PhormBlock>
              <PhormTextField
                name='defaultSeedCost'
                label='Seed Cost (per million)'
                size='medium'
                InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
              />
            </PhormBlock>
            <PhormBlock>
              <PhormTextField
                name='seedBatchFirst'
                label='Seed Batch Number'
                size='medium'
              />
            </PhormBlock>
            <PhormErrors />
            <PhormActionErrors actionObject={seasonCreate} />
            <PhormSubmit busy={seasonCreate.status === 'RUNNING'}>
              Create Season
            </PhormSubmit>
          </Phorm>
        </PaperSection>
      </Box>
    </>
  );
};

export default SeasonsSidebar;
