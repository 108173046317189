import type { CameraDeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import { Box } from '@mui/material';
import { formatBytes } from '../../helpers/formatBytes';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useMemo } from 'react';

const LatestCameraInfo = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<CameraDeviceStateUnflattened>;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const heroItems = [
    {
      label: 'Pending Images',
      text: latest.images.pending.toString(),
    },
    {
      label: 'Total Images',
      text: latest.images.total.toString(),
    },
    {
      label: 'Free space',
      text: formatBytes(latest.freespace),
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading='Latest Camera Info'
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <HeroGroup items={heroItems} />
      <Box mt={2}>
        <ChipBool
          label='Pending Upload'
          data={Number(latest.images.pending) > 0}
          color='sunlight'
          icon={<CloudUploadIcon />}
        />
      </Box>
    </PaperSection>
  );
};

export default LatestCameraInfo;
