// import type { DatabaseDevices, DeviceType } from '@phyllome/common';
// import useDevicesByType from './useDevicesByType';

import type { DeviceType } from '@phyllome/common';
import { useAtomValue } from 'jotai';
import { devicesAtom } from '../../atoms/devicesAtom';

// // NOTE: This is the order devices appear in the devices table
export const includedDeviceTypes: DeviceType[] = [
  'air',
  'camera',
  'co2',
  'conveyor',
  'fertigator',
  'guard',
  'harvester',
  'irrigation',
  'lift',
  'liftcore',
  'lighting',
  'lightingcore',
  'packager',
  'seeder',
  'sentry',
  'transfer',
  'traybot',
];

const useDevices = () => {
  const value = useAtomValue(devicesAtom);

  // Return empty array if no values yet
  if (!value || Object.keys(value).length === 0) {
    return [];
  }
  return Object.keys(value).map((key) => value[key]);
};

export default useDevices;