import RadarIcon from '@mui/icons-material/Radar';
import { Alert, Fade } from '@mui/material';
import { colors, type DeviceType } from '@phyllome/common';
import { type FC } from 'react';
import ButtonWait from '../../../components/puregui/ButtonWait/ButtonWait';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import ObjectToTable from '../../../components/ObjectToTable/ObjectToTable';
import useFetchManual from '../../../data/hooks/useFetchManual';
import { Stack } from '@mui/system';

type IProps = {
  deviceId: string;
  deviceType: DeviceType;
};

const GenericPing: FC<IProps> = ({ deviceId, deviceType }) => {
  const pingFetch = useFetchManual<any>(`/farm/:brokerId/device/${deviceType}/${deviceId}/ping`);

  const isFetching = pingFetch.isFetching;
  const isSuccess = pingFetch.status === 'success';
  const psHeading = isFetching ? `Pinging ${deviceId}` : 'Received from MCP';

  return (
    <div>
      <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
        <ButtonWait
          isBusy={isFetching}
          onClick={() => {
            pingFetch.fetch();
          }}
          startIcon={<RadarIcon />}
        >
          Ping Device
        </ButtonWait>
        {isSuccess && pingFetch.isFetchedAfterMount && (
          <Alert
            severity='success'
            variant='standard'
            sx={{ paddingTop: '0', paddingBottom: '0' }}
          >
            Success ({pingFetch.time}ms)
          </Alert>
        )}
      </Stack>
      {(isSuccess || isFetching) && (
        <PaperSection
          mt={2}
          mb={2}
          headerSx={{ background: colors.ripening, color: '#fff' }}
          spinner={isFetching}
          heading={psHeading}
        >
          {isSuccess ? (
            <Fade
              in={true}
              timeout={800}
            >
              <div>
                <ObjectToTable data={pingFetch?.data?.data || {}} />
              </div>
            </Fade>
          ) : (
            <div style={{ minHeight: '50px' }}></div>
          )}
        </PaperSection>
      )}
    </div>
  );
};

export default GenericPing;