import type { WithId } from '@phyllome/common';
import {  type DatabaseSeason, type NDSSchedule } from '@phyllome/common';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import useSeed from '../../../../data/hooks/collections/useSeed';
import { useEndpointSeasonUpdate } from '../../../../data/endpointHooks/endpointHooks';
import { Box } from '@mui/material';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import ScheduleViewNDS from '../../../../components/ScheduleView/ScheduleViewNDS';
import ScheduleTableNDS from '../../../../components/ScheduleTable/ScheduleTableNDS';
import { DosingScheduleEdit } from '../../../../components/ScheduleEdit/ScheduleEdit';
import { useSnackbar } from 'notistack';
import useActivityLog from '../../../../data/hooks/useActivityLog';
import usePost from '../../../../data/hooks/usePost';

type IProps = {
  seasonData: WithId<DatabaseSeason>;
};

const SideBarSeasonNDSSchedule: FC<IProps> = ({ seasonData }) => {
  const [sched, setSched] = useState<NDSSchedule[]>([]);

  const seedData = useSeed(seasonData.seedId || 'nothing');
  const trayUpdate = useEndpointSeasonUpdate();
  const { enqueueSnackbar } = useSnackbar();
  const updateNDSScheduleInSeason = usePost(`farm/:brokerId/season/${seasonData.id}/ndsSchedule`);

  const activityLog = useActivityLog(`season/${seasonData.id}/notes`);

  useEffect(() => {
    setSched(seasonData?.defaultNDSSchedule || []);
  }, [seasonData?.defaultNDSSchedule]);
  if (seasonData?.state === 'completed') {
    return (
      <PaperSection
        heading='Irrigation Schedule (completed)'
        mb={2}
      >
        <ScheduleTableNDS scheduleDataDB={seasonData?.defaultNDSSchedule || []} />
      </PaperSection>);
  } else {

    return (
      <PaperSection
        heading='Irrigation Schedule'
        mb={2}
      >
        <Box mb={2}>
          <ScheduleViewNDS
            seedSchedule={seedData?.defaultIrrigationSchedule}
            seasonSchedule={seasonData?.defaultNDSSchedule}
            context='season'
          />
        </Box>
        <DosingScheduleEdit
          isSaving={trayUpdate.status === 'RUNNING'}
          data={sched}
          onSubmit={(data) => {

            updateNDSScheduleInSeason.run({
              defaultNDSSchedule: data as any,
            }).then(() => {
              enqueueSnackbar('Irrigation schedule updated', {
                variant: 'success',
              });
            }).then(() => {
              activityLog.add('Updated irrigation schedule', 'OPERATION');
            }).catch(() => {
              enqueueSnackbar('ERROR WRITING SCHEDULE', {
                variant: 'error',
              });
            });

          }}
        />

      </PaperSection>
    );
  }
};

export default SideBarSeasonNDSSchedule;
