import type { FC } from 'react';
import * as React from 'react';
import { useSigninCheck } from 'reactfire';
import Login from './Login/Login';

interface IProps {
  children: React.ReactNode;
}

const AuthCheck: FC<IProps> = ({ children }) => {

  const { status, data: signInCheckResult  } = useSigninCheck();

  if (status === 'loading') {
    return <div>auth</div>;
  }

  if (signInCheckResult.signedIn) {
    return <>{children}</>;
  } else {
    return <Login />;
  }
};

export default AuthCheck;
