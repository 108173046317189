import { useContext, useEffect, useState } from 'react';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import { TraysContext } from '../TraysContext';
import useNavSide from '../../../../data/hooks/useNavSide';
import useNDSLatestWithLookup from '../../../../devicespecific/NDS/hooks/useNDSLatestWithLookup';
import OverlayTraySeasons from '../Overlays/OverlayTraySeasons';
import type { DatabaseTraySeasonPick } from '../../../../data/hooks/collections/useTraySeasonByBrokerId';

const OverlayEntrySeasons = ({
  column,
}: {
  column: number;
}) => {
  const { columnSpec, brokerId } = useContext(FarmContext);

  const shelves = columnSpec[column - 1].shelves;
  const traysContext = useContext(TraysContext);
  const navSide = useNavSide();
  const ndsLookup = useNDSLatestWithLookup(brokerId, column);
  const [trayData, setTrayData] = useState<DatabaseTraySeasonPick[]>([]);

  useEffect(() => {
    const filter = traysContext.state.trays.filter((tray) => {
      return tray.locationColumn === column;
    });

    setTrayData(filter);
  }, [traysContext.state.trays, column]);
  return (
    <>
      <OverlayTraySeasons
        data={trayData}
        ndsData={ndsLookup}
        shelves={shelves}
        onClick={(trayRowData) => {

          navSide(`season/${trayRowData.seasonId}`);
        }}
      />
    </>
  );
};

export default OverlayEntrySeasons;
