import { Box, Button  } from '@mui/material';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import type { DatabaseSeason, WithId } from '@phyllome/common';
import {  useState } from 'react';
import SafeDelete from '../../../../components/SafeDelete/SafeDelete';
import useAction from '../../../../data/hooks/useAction';
import useNavSide from '../../../../data/hooks/useNavSide';
import { useNavigate } from 'react-router';

const alertRed = '#ab3434';
const SideBarSeasonDangerZone = ({
  seasonData,
}: {
  seasonData: WithId<DatabaseSeason>;
}) => {

  const navSide = useNavSide();
  const nav = useNavigate();

  const deleteSeason = useAction('seasonDelete');

  const [delSeasonClicked, setDelSeasonClicked] = useState(false);
  const isSafeToDelete = seasonData.state === 'created' ||
            seasonData.state === 'seeded' ||
            seasonData.state === 'stopped' ||
            seasonData.state === 'paused' ||
            seasonData.state === 'completed';

  if (!isSafeToDelete) {
    return (
      <PaperSection heading='Danger Zone' mt={2} headerSx={{ background: alertRed }}>
        <Box>Operations of this nature are not possible until this season has successfully stopped, paused or completed.</Box>
      </PaperSection>
    );
  }
  return (
    <PaperSection heading='Danger Zone' mt={2} headerSx={{ background: alertRed }}>

      <Box pb={2}>All operations here are very destructive and <strong>cannot be undone</strong>.
        Please be sure you know what you are doing.</Box>
      {!delSeasonClicked && (
        <Button
          sx={{ background: alertRed }}
          variant='contained'
          onClick={() => setDelSeasonClicked(true)}
        >
          Delete This Season
        </Button>
      )}
      {delSeasonClicked && (
        <SafeDelete
          actionText='Confirm Season Deletion'
          deleteButtonText='Delete This Season Now' onComplete={() => {
            deleteSeason.run({ seasonId: seasonData.id }).then(() => {
              navSide('');
              nav(`/broker/${seasonData.brokerId}/seasons`);
            });
          }}
        />
      )}

    </PaperSection>
  );
};

export default SideBarSeasonDangerZone;