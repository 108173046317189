import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import * as React from 'react';
import type {
  DefaultValues,
  UseFormReturn,
  FieldValues,
} from 'react-hook-form';
import {
  useForm,
  useWatch,
} from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import type * as yup from 'yup';
import { updatedDiff } from 'deep-object-diff';
import { usePrevious } from 'react-use';
import { isEqual } from 'lodash';
import { Box } from '@mui/material';
import ObjectToTable from '../components/ObjectToTable/ObjectToTable';

// Utility function to convert all number values in an object to strings
function hydrateNumbersToStrings(obj: any): any {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (typeof obj === 'number') {
    return obj.toString();
  }

  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      return obj.map(hydrateNumbersToStrings);
    } else {
      return Object.keys(obj).reduce((acc, key) => {
        acc[key] = hydrateNumbersToStrings(obj[key]);
        return acc;
      }, {} as any);
    }
  }
  return obj;
}

function useDeepCompareEffect(callback: () => void, dependencies: any[]) {
  const currentDependenciesRef = useRef<undefined | any[]>(undefined);

  if (!isEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, [currentDependenciesRef.current]);
}

type PhormContainerProps<T extends FieldValues = FieldValues> = {
  debug?: boolean;
  children: React.ReactNode;
  schema: yup.ObjectSchema<any>;
  defaultValues: DefaultValues<T>;
  onSubmit?: (data: T) => void;
  onError?: (error: any) => void;
  onChange?: (field: string, value: any) => void;
  ref?: React.RefObject<any>;
};

interface PhormContainerHandle<T extends FieldValues = FieldValues> {
  reset: () => void;
  context: UseFormReturn<T>;
}

const PhormContainer = <T extends FieldValues>(
  props: PhormContainerProps<T>,
  ref: React.Ref<PhormContainerHandle<T>>,
) => {
  const [debugState, setDebugState] = React.useState<T>();

  const {
    debug = false,
    children,
    schema,
    defaultValues,
    onSubmit = (data: T) => console.log('[FORM] SUBMITTED', data),
    onError = (error: any) => console.log('[FORM] ERROR', error),
    onChange = undefined,
  } = props;

  // Hydrate defaultValues to convert all numbers to strings
  const hydratedDefaultValues = hydrateNumbersToStrings(
    defaultValues,
  ) as DefaultValues<T>;

  const context = useForm<T>({
    resolver: yupResolver(schema),
    defaultValues: hydratedDefaultValues, // Use the hydrated values
  });

  const reset = context.reset;

  const watcher = useWatch({
    control: context.control,
  });
  const prevWatcher = usePrevious(watcher);

  useDeepCompareEffect(() => {
    reset(hydratedDefaultValues);
  }, [hydratedDefaultValues, reset]);

  useImperativeHandle(ref, () => ({
    reset: () => reset(hydratedDefaultValues),
    context,
  }));

  useEffect(() => {
    if (!onChange) return;

    if (prevWatcher && watcher) {
      const diff: { [key: string]: any } = updatedDiff(prevWatcher, watcher);

      const diffArray = Object.keys(diff).map((key) => {
        return { key, value: diff[key] };
      });

      diffArray.forEach((item) => {
        onChange(item.key, item.value);
      });
    }
  }, [watcher, prevWatcher, onChange]);

  const onSubmitWrapper = (data: T) => {
    setDebugState(data);
    onSubmit(data);
  };

  return (
    <FormContainer
      formContext={context}
      onSuccess={onSubmitWrapper}
      onError={onError}
    >
      {debug && (
        <DebugMode defaultValues={defaultValues} submitData={debugState} />
      )}
      {children}
    </FormContainer>
  );
};

const ForwardedPhormContainer = forwardRef(PhormContainer) as <
  T extends FieldValues,
>(
  props: PhormContainerProps<T> & { ref?: React.Ref<PhormContainerHandle<T>> },
) => ReturnType<typeof PhormContainer>;

const DebugMode = ({
  defaultValues,
  submitData,
}: {
  defaultValues: any;
  submitData: any;
}) => {
  return (
    <div style={{ border: '2px solid red' }}>
      <Box>
        <div style={{ background: 'red', color: 'white' }}>Debug Mode</div>
        <div style={{ padding: '2px' }}>
          <div style={{ fontSize: '120%' }}>Default Values</div>
          <ObjectToTable  data={defaultValues} />
          <div style={{ fontSize: '120%' }}>Submit Data</div>
          <ObjectToTable  data={submitData} />
        </div>
      </Box>
    </div>
  );
};

export default ForwardedPhormContainer;
