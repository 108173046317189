import { useEffect, useRef, useState } from 'react';
import './flash.css';

type FlashProps = {
  children: React.ReactNode;
  value: string | number;
  style?: React.CSSProperties;
};

const Flash = ({ children, value, style }: FlashProps) => {
  const prevValueRef = useRef<string | number | undefined>(undefined);
  const [flash, setFlash] = useState(false);

  useEffect(() => {
    if (prevValueRef.current !== undefined && prevValueRef.current !== value) {
      setFlash(true);
      setTimeout(() => setFlash(false), 3000); // Duration of the flash animation
    }
    prevValueRef.current = value;
  }, [value]);
  return (
    <div style={style} className={flash ? 'flash-animation' : ''}>
      {children}
    </div>
  );
};

export default Flash;
