import type { DeviceType, WithId, DatabaseDevices } from '@phyllome/common';
import { atom, getDefaultStore } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { deviceSubscriberAtom } from '../../components/DevicesProvider/DeviceWorker';
import { devicesAtom } from './devicesAtom';

// Create an atom family for individual device selection
export const deviceAtomFamily = atomFamily(
  (params: { brokerId: string; deviceType: DeviceType; deviceId: string }) => {
    return atom(
      (get) => {
        const devices = get(devicesAtom);
        const key = `${params.brokerId}.${params.deviceType}.${params.deviceId}`;

        return devices[key];
      },
      (get, set, updatedDevice: WithId<DatabaseDevices> | undefined) => {
        const devices = get(devicesAtom);
        const key = `${params.brokerId}.${params.deviceType}.${params.deviceId}`;

        set(devicesAtom, {
          ...devices,
          [key]: updatedDevice as WithId<DatabaseDevices>,
        });
      },
    );
  },
);

export const deviceAtomUpdate = ({
  brokerId, deviceType, deviceId, data,
}:{
  brokerId: string,
  deviceType: DeviceType,
  deviceId: string,
  data: WithId<DatabaseDevices>
},
): void => {
  const store = getDefaultStore();

  const key = `${brokerId}.${deviceType}.${deviceId}`;

  store.set(devicesAtom, (prevDevices) => {

    return {
      ...prevDevices,
      [key]: {
        ...prevDevices[key],
        ...data,
      },
    };
  });

  store.set(deviceSubscriberAtom, (count) => count + 1);
};

export const devicesByPrefixAtom = atomFamily((prefix: string) =>
  atom((get) => {
    const devices = get(devicesAtom);

    // console.log('[devicesByPrefix] Recomputing for prefix:', prefix);
    // console.log('IN', Object.entries(devices).map(([key]) => key));
    const filteredDevices = Object.entries(devices)
      .filter(([key]) => key.startsWith(prefix))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as Record<string, WithId<DatabaseDevices>>);

    // console.log('OUT', JSON.stringify(filteredDevices));
    return filteredDevices;
  }),
);
