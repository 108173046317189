import muiTheme from './styles/muiTheme';
import {
  AuthProvider,
  DatabaseProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  useFirebaseApp,
} from 'reactfire';
import firebaseConfig from './helpers/firebaseConfig';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import { AppStateProvider } from './data/AppState/useAppState';
import BrokerEntry from './pages/Broker/BrokerEntry';
import Sus from './components/Sus/Sus';
import AuthCheck from './pages/auth/AuthCheck';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import Seeds from './pages/Broker/Seeds/Seeds';
import AppDrawerProvider from './sidebar/AppDrawer/useAppDrawer';
import FarmProvider from './components/FarmProvider/FarmProvider';
import { PollerCacheProvider } from './data/hooks/PollerCacheProvider';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorMsg from './ErrorMsg';
import useWebsocketProvider from './components/WebSocketProvider/useWebSocketProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

const MainRoutes = () => {
  const websocketProvider = useWebsocketProvider();

  if (!websocketProvider.isConnected)
    return (
      <div style={{ margin: '20px' }}>
        <CircularProgress size={20} />
        <span style={{ position: 'relative', top: '-4px', left: '10px' }}>Waiting patiently for the data server to be ready...</span>
      </div>
    );
  return (
    <ErrorBoundary FallbackComponent={ErrorMsg}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <FarmProvider>
            <AppDrawerProvider>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/22' element={<Home />} />
                <Route path='/broker/:brokerId/*' element={<BrokerEntry />} />
                <Route path='seeds/' element={<Seeds />} />
              </Routes>
            </AppDrawerProvider>
          </FarmProvider>
        </Router>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

function AppRun() {
  const app = useFirebaseApp();
  const database = getDatabase(app);
  const auth = getAuth(app);

  const firestoreInstance = getFirestore(useFirebaseApp());

  return (
    <AuthProvider sdk={auth}>
      <DatabaseProvider sdk={database}>
        <SnackbarProvider>
          <FunctionsProvider sdk={getFunctions(app)}>
            <AppStateProvider>
              <FirestoreProvider sdk={firestoreInstance}>
                <PollerCacheProvider>
                  <CssBaseline />
                  <ThemeProvider theme={muiTheme}>
                    <Sus>
                      <AuthCheck>
                        <MainRoutes />
                      </AuthCheck>
                    </Sus>
                  </ThemeProvider>
                </PollerCacheProvider>
              </FirestoreProvider>
            </AppStateProvider>
          </FunctionsProvider>
        </SnackbarProvider>
      </DatabaseProvider>
    </AuthProvider>
  );
}

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
      <AppRun />
    </FirebaseAppProvider>
  );
}
export default App;
