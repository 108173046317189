import * as React from 'react';
import type { SxProps } from '@mui/material';
import { Grid, Typography } from '@mui/material';

interface LabelValueProps {
  leftColWidth?: number;
  label: string;
  children: React.ReactNode;
  hide?: boolean;
  sxLabel?: SxProps;
  sxContent?: SxProps;
}

const LabelValue: React.FC<LabelValueProps> = ({
  label,
  children,
  hide = false,
  sxLabel = {},
  sxContent = {},
}) => {
  if (hide) return null;
  return (
    <Grid container spacing={0}>
      <Grid item xs={2.8} paddingBottom={0.2} sx={{ textAlign: 'right', paddingRight: 2, ...sxLabel }}>
        <Typography color='textSecondary'>{label}</Typography>
      </Grid>
      <Grid item xs={8} paddingBottom={0.2} sx={sxContent}>
        {children}
      </Grid>
    </Grid>
  );
};

export default LabelValue;
