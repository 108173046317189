import type { DatabaseSeason, DatabaseTraySeason, WithId } from '@phyllome/common';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import TraySeasonNDSSchedule from './TraySeasonNDSSchedule';

const SideBarTraySeasonIrrigationSchedule = ({
  traySeasonData,
  seasonData,
}: {
  traySeasonData: WithId<DatabaseTraySeason>;
  seasonData: WithId<DatabaseSeason>;
}) => {
  return (
    <PaperSection
      heading='Irrigation Schedule'
      mb={2}
      hidden={seasonData?.state === 'completed'}
    >
      <TraySeasonNDSSchedule
        traySeasonData={traySeasonData}
        seasonData={seasonData}
        traySeasonId={traySeasonData.id}
        locationColumn={traySeasonData.locationColumn}
        locationRow={traySeasonData.locationRow}
        locationShelf={traySeasonData.locationShelf}
      />
    </PaperSection>
  );
};

export default SideBarTraySeasonIrrigationSchedule;
