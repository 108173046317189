import type { DatabaseDevices } from '@phyllome/common';
import {
  type DatabaseSeasonsDevices,
  type NDSDeviceStateUnflattened,
  type LightingDeviceState,
} from '@phyllome/common';
import useFetch from '../useFetch';

type ThisType = Pick<
  DatabaseSeasonsDevices,
  'seasonId' | 'desiredState' | 'brokerId'
> & {
  deviceId: string;
  alias: string[];
};

type NDSDeviceType = ThisType & {
  deviceType: 'nds';
  desiredProperties: Partial<NDSDeviceStateUnflattened>;
  properties: NDSDeviceStateUnflattened;
};

type LightingDeviceType = ThisType & {
  deviceType: 'lighting';
  desiredProperties: Partial<NDSDeviceStateUnflattened>;
  properties: LightingDeviceState;
};
export type DeviceFromSeason = NDSDeviceType | LightingDeviceType;

export const useDevicesInSeason = (seasonId: string) => {
  const data = useFetch<DatabaseDevices[]>(`/farm/:brokerId/devices/season/${seasonId}`, {
    default: [],
    interval: 5000,
  });

  return data;
};
