import type { DatabaseDevices, LightingCoreDeviceStateUnflattened } from '@phyllome/common';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import Graph from '../../components/Favourites/Graph';

const GraphZoneCore = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LightingCoreDeviceStateUnflattened>;
}) => {

  const { deviceId, brokerId } = deviceState;

  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        <Graph
          name='lightingCoreCurrentA'
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph
          name='lightingCoreCurrentB'
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph
          name='lightingCoreCurrentC'
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph name='lightingCoreMode' brokerId={brokerId} deviceId={deviceId} />
      </Stacker>
    </ChartProvider>
  );
};

export default GraphZoneCore;
