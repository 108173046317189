import ObjectToTable from '../../components/ObjectToTable/ObjectToTable';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { DatabaseDevices, IrrigationDeviceStateUnflattened } from '@phyllome/common';

const LatestIrrigation = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<IrrigationDeviceStateUnflattened>;
}) => {

  return (
    <PaperSection
      heading='Latest Device Data'
      subheading={`Last Updated: ${dateToString(
        deviceState.lastAccessed,
        dateFormatTypes.TRUNCATED,
      )}`}
    >

      <ObjectToTable data={deviceState as any} />

    </PaperSection>
  );
};

export default LatestIrrigation;
