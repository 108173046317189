import { useContext } from 'react';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import Trays from './Trays';
import { TraysProvider } from './TraysContext';
import { useParams } from 'react-router-dom';
import { SprinklerProvider } from '../Sprinklers/SprinkerContext';

const TraysEntryPoint = () => {
  const { columnSpec } = useContext(FarmContext);
  const { seasonId } = useParams();

  return (
    <>
      <SprinklerProvider>

        <TraysProvider>
          <Trays columnSpec={columnSpec} seasonId={seasonId} />
        </TraysProvider>

      </SprinklerProvider>
    </>
  );
};

export default TraysEntryPoint;
