import type { Co2DeviceStateUnflattened } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestCo2 = (deviceId: string) => {
  const data = useDeviceState<Co2DeviceStateUnflattened>({ deviceId, deviceType: 'co2' });

  if (!data) return undefined;
  return data;
};

export default useLatestCo2;
