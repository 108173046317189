import { calculateTrayZone, type DatabaseTraySeason } from '@phyllome/common';
import RackBox from '../../../../components/factoryui/components/RackBox';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useContext } from 'react';
import FarmContext from '../../../../components/FarmProvider/FarmContext';

type OverlayTrayZonesProps = {
  shelves: number;
  selectedZone?: number;
  onClick?: (traySeasonData: DatabaseTraySeason) => void;
  data: DatabaseTraySeason[];
};

const OverlayTrayZones = ({ shelves, data }: OverlayTrayZonesProps) => {

  const farmConfig = useContext(FarmContext);

  return (
    <>
      {data.map((row, index) => {

        const location = {
          column: row.locationColumn,
          row: row.locationRow,
          shelf: row.locationShelf,
        };

        const zone = calculateTrayZone(location, farmConfig);

        return (
          <RackBox
            key={index}
            zIndex={10}
            row={row.locationRow}
            shelf={row.locationShelf}
            shelves={shelves}
            style={{
              border: '1px solid transparent',
              background: 'transparent',
              textAlign: 'right',
            }}
          >
            {zone}
            <LightbulbIcon
              sx={{ textAlign: 'left', fontSize: '11px', color: 'yellow' }}
            />
          </RackBox>
        );
      })}
    </>
  );
};

export default OverlayTrayZones;
