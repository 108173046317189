import { useEffect, useState } from 'react';
import type { DatabaseTraySeasonPhotos } from '@phyllome/common';
import type { QueryConstraint } from 'firebase/firestore';
import {
  query,
  collection,
  where,
  orderBy,
  limit,
  getFirestore,
  getDocs,
} from 'firebase/firestore';
import type { FromFirebase } from '../../../../types/types';

type DatabasePhotoType = 'visible' | 'ir';

const usePhotoLatest = ({
  seasonId,
  type = 'visible',
}: {
  seasonId?: string;
  type: DatabasePhotoType;
}): FromFirebase<DatabaseTraySeasonPhotos> | undefined => {
  const [data, setData] = useState<FromFirebase<DatabaseTraySeasonPhotos> | undefined>(undefined);
  const fs = getFirestore();

  useEffect(() => {
    const fetchTrayPhotos = async () => {
      const constraints: QueryConstraint[] = [
        where('type', '==', type),
        orderBy('timestamp', 'desc'),
        limit(1),
      ];

      if (seasonId !== undefined) {
        constraints.push(where('seasonId', '==', seasonId));
      }
      const documentReference = query(
        collection(fs, 'traySeasonPhotos'),
        ...constraints,
      );

      try {
        const querySnapshot = await getDocs(documentReference);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          const trayPhoto = {
            id: doc.id,
            ...doc.data(),
          } as FromFirebase<DatabaseTraySeasonPhotos>;

          setData(trayPhoto);
        } else {
          setData(undefined);
        }
      } catch (error) {
        console.error('Error fetching tray photos:', error);
        setData(undefined);
      }
    };

    fetchTrayPhotos();
  }, [fs, seasonId, type]);
  return data;
};

export default usePhotoLatest;