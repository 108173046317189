import type { DatabaseDevices, LightingDeviceStateUnflattened } from '@phyllome/common';
import ChipBool from '../puregui/ChipBool/ChipBool';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const LightingZoneChipBool = ({
  deviceState,
}: {
  deviceState?: DatabaseDevices<LightingDeviceStateUnflattened> | undefined;
}) => {

  const latest = deviceState?.properties;

  if (latest?.active) {
    return (
      <ChipBool
        data={latest?.enable}
        label={`Lighting zone ${deviceState?.deviceId}`}
        color='sunlight'
        icon={<LightbulbIcon />}
      />);
  } else
    return (
      <ChipBool
        data={latest?.enable}
        label={`Lighting zone ${deviceState?.deviceId}`}
        color='primary'
        icon={<LightbulbIcon />}
      />);
};

export default LightingZoneChipBool;