import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import FormNDSConfigSetWaterRate from './forms/FormNDSConfigSetWaterRate';
import FormNDSConfigSetWithDeviceColours from './forms/FormNDSConfigSetColours';
import FormNDSConfigSetWithDeviceHeartBeat from './forms/FormNDSConfigSetHeartBeat';
import type { NDSDeviceStateUnflattened } from '@phyllome/common';
import { mqttDefinitionNDSConfigGet } from '@phyllome/common';
import FormNDSDeviceConfigGet from '../../components/FormNDSDeviceConfigGet/FormNDSDeviceConfigGet';

const ConfigNDS = ({ deviceState }: { deviceState: NDSDeviceStateUnflattened }) => {
  const firmware = deviceState.firmware || 'Unknown';

  return (
    <>
      <PaperSection heading='NDS Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceState.id}</LabelValue>
        <LabelValue label='Firmware'>{firmware}</LabelValue>
        <LabelValue label='Build date'>TBD</LabelValue>
        <LabelValue label='Network IP'>TBD</LabelValue>
      </PaperSection>
      <FormNDSConfigSetWaterRate deviceState={deviceState} />
      {/* <FormNDSConfigSetWithDeviceNightFactor deviceState={deviceState} /> */}
      <FormNDSConfigSetWithDeviceHeartBeat deviceState={deviceState} />
      <FormNDSConfigSetWithDeviceColours deviceState={deviceState} />
      <FormNDSDeviceConfigGet
        location={{
          column: deviceState.location.column,
          shelf: deviceState.location.shelf,
          row: deviceState.location.row,
        }}
        definition={mqttDefinitionNDSConfigGet}
      />
    </>
  );
};

export default ConfigNDS;
