import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
// import { MuiColorInput } from "mui-color-input";
// import { useState, useRef } from "react";
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import type { NDSDeviceStateUnflattened } from '@phyllome/common';
import { mqttDefinitionNDSConfigSet } from '@phyllome/common';
import parseNDSDeviceId from '../../../helpers/parseNDSDeviceId';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { InputAdornment } from '@mui/material';

// const convertToHex = (number: number): string => {
//   return number.toString(16).padStart(6, "0");
// };

// const convertToInt = (hex: string): number => {
//   return parseInt(stripLeadingHash(hex), 16);
// };

// const stripLeadingHash = (hex: string): string => {
//   if (hex.startsWith("#")) {
//     return hex.substring(1);
//   }
//   return hex;
// };

const FormNDSConfigSetWithDeviceColours = ({
  deviceState,
}: {
  deviceState: NDSDeviceStateUnflattened;
}) => {
  const controlLifecycle = useControlSend(
    mqttDefinitionNDSConfigSet,
    `${deviceState.deviceId}`,
  );

  const {
    ledHeartbeat,
    pingActiveInterval,
    timeBackupInterval,
    pingEnColor,
    pingDisColor,
    pingRelayEnColor,
    pingRelayDisColor,
  } = deviceState;

  const defaultValues = formCreateDefaultValues(mqttDefinitionNDSConfigSet, {
    ledHeartbeat: ledHeartbeat || 0,
    pingActiveInterval: pingActiveInterval || 0,
    timeBackupInterval: timeBackupInterval || 0,
    pingEnColor: pingEnColor || 0,
    pingDisColor: pingDisColor || 0,
    pingRelayEnColor: pingRelayEnColor || 0,
    pingRelayDisColor: pingRelayDisColor || 0,
  });

  const { column, row, shelf } = parseNDSDeviceId(deviceState.deviceId);

  return (
    <PaperSection
      heading='NDS LED Configuration'
      mb={2}
      subheading={mqttDefinitionNDSConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              ledHeartbeat: data.ledHeartbeat,
              pingActiveInterval: data.pingActiveInterval,
              timeBackupInterval: data.timeBackupInterval,
              pingEnColor: data.pingEnColor,
              pingDisColor: data.pingDisColor,
              pingRelayEnColor: data.pingRelayEnColor,
              pingRelayDisColor: data.pingRelayDisColor,
            },
            topicParams: {
              column,
              row,
              shelf,
            },
          });
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          ledHeartbeat: yup.number().min(1000).required(),
          pingActiveInterval: yup.number().required(),
          timeBackupInterval: yup.number().required(),
          pingEnColor: yup.number().required(),
          pingDisColor: yup.number().required(),
          pingRelayEnColor: yup.number().required(),
          pingRelayDisColor: yup.number().required(),
        })}
      >
        <Stack spacing={2}>
          <h4>Intervals</h4>
          <p>Interval (in milliseconds) between led pulses.</p>
          <TextFieldElement
            label='LED Heartbeat'
            name='ledHeartbeat'
            required
            InputProps={{ endAdornment: <InputAdornment position='end'>milliseconds</InputAdornment> }}

          />
          <p>
            Duration (in seconds) of the led response after a ping message is
            received.
          </p>
          <TextFieldElement
            label='Ping Active Interval'
            name='pingActiveInterval'
            required
            InputProps={{ endAdornment: <InputAdornment position='end'>seconds</InputAdornment> }}

          />
          {/* <MuiColorInput
            value={color}
            onChange={handleChange}
            name="seb"
            format="hex"
          /> */}
          <TextFieldElement
            label='Time Backup Interval'
            name='timeBackupInterval'
            required
            InputProps={{ endAdornment: <InputAdornment position='end'>seconds</InputAdornment> }}

          />
          <h4>LED Colours</h4>
          <TextFieldElement
            label='Ping Enabled state colour'
            name='pingEnColor'
            required
          />
          <TextFieldElement
            label='Ping Disabled state colour'
            name='pingDisColor'
            required
          />
          <TextFieldElement
            label='Ping Relay Enabled Colour'
            name='pingRelayEnColor'
            required
          />
          <TextFieldElement
            label='Ping Relay Disabled Colour'
            name='pingRelayDisColor'
            required
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormNDSConfigSetWithDeviceColours;
