import { CircularProgress } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

const InlineProgress = ({
  waitingtext,
  completetext,
  isComplete,
}: {
  waitingtext: string;
  completetext: string;
  isComplete: boolean;
}) => {
  const iconStyle = {
    lineHeight: '30px',
    width: '26px',
    display: 'inline-block',
    lineHight: '30px',
  };

  if (isComplete === false) {
    return (
      <div>
        <span style={iconStyle}>
          <CircularProgress size='0.75rem' />
        </span>
        <span style={{ lineHeight: '30px' }}>{waitingtext}</span>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ ...iconStyle, top: '3px', position: 'relative' }}>
          <DoneIcon fontSize='small' color='primary' />
        </div>
        <span style={{ lineHeight: '30px' }}>{completetext}</span>
      </div>
    );
  }
};

export default InlineProgress;
