import type { FC } from 'react';
import * as React from 'react';
import {
  Badge,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { colors } from '@phyllome/common';

interface IProps {
  onClick: () => void;
  text: string;
  icon: React.ReactNode;
  highlight?: boolean;
}

const ListCustom: FC<IProps> = ({ onClick, text, icon, highlight = false }) => {
  return (
    <ListItemButton
      onClick={() => onClick()}
      sx={{
        color: colors.fog,
      }}
    >
      <ListItemIcon
        sx={{
          color: colors.fog,
        }}
      >
        <BadgeVisible visible={highlight}>{icon}</BadgeVisible>
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

export default ListCustom;

const BadgeVisible: FC<{ visible?: boolean; children?: React.ReactNode }> = ({
  visible = false,
  children,
}) => {
  return visible ? (
    <Badge color='secondary' variant='dot'>
      {children}
    </Badge>
  ) : (
    <>{children}</>
  );
};
