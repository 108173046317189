import RackBox from '../../../../components/factoryui/components/RackBox';
import { useEffect, useState } from 'react';
import useNDSOverlayData from './useNDSOverlayData';
import type { NDSDeviceStateUnflattened } from '@phyllome/common';
import { useAtomValue } from 'jotai';
import { devicesByPrefixAtom  } from '../../../../data/atoms/deviceAtomFamily';
import generateUniqueColoursFromSet from '../../../../helpers/generateUniqueColoursFromSet';
import generateUniqueColoursFromSetValues from '../../../../helpers/generateUniqueColoursFromSetValues';
import { devicesAtom } from '../../../../data/atoms/devicesAtom';

const OverlaySprinklerValues = ({
  column,
  propertyToDisplay,
  method,
}: {
  column: number;
  propertyToDisplay: 'firmware' | 'currentDay' | 'waterRate';
  method: 'unique' | 'gradient'
}) => {
  const {
    brokerId,
    shelves,
  } = useNDSOverlayData(column);
  const [colourLookup, setColourLookup] = useState<{
    [key: string]: string;
  }>({});

  const deviceData = useAtomValue(devicesByPrefixAtom(`${brokerId}.nds.${column}`));
  const allDeviceData = useAtomValue(devicesAtom);

  useEffect(() => {
    if (Object.keys(colourLookup).length > 0) return;

    const uniqueValueLookup = new Set<any>();

    Object.values(allDeviceData).forEach((device) => {
      if (device) {
        const deviceProps = device.properties as NDSDeviceStateUnflattened;
        const value = deviceProps[propertyToDisplay];

        if (value !== undefined) {
          uniqueValueLookup.add(value);
        }
      }
    });

    const uniqueCols = method === 'unique' ?
      generateUniqueColoursFromSet(uniqueValueLookup) :
      generateUniqueColoursFromSetValues(uniqueValueLookup, '#950000', '#039dbd');

    setColourLookup(uniqueCols);
  }, [allDeviceData, colourLookup, propertyToDisplay, method]);

  if (Object.keys(colourLookup).length === 0) return null;
  return (
    <>
      {Object.keys(deviceData).map(_deviceRow => {
        const data = deviceData[_deviceRow];

        if (!data) {
          return null;
        }

        const { row, shelf } = (data.properties as NDSDeviceStateUnflattened).location;
        const key = `${column}.${shelf}.${row}`;

        const displayValue = data.properties[propertyToDisplay];

        return (
          <RackBox
            key={key}
            zIndex={10}
            row={row}
            shelf={shelf}
            shelves={shelves}
            style={{
              textAlign: 'center',
              color: 'white',
              background: colourLookup[displayValue] || 'white',
            }}

          >
            {displayValue}
          </RackBox>
        );
      })}
    </>
  );
};

export default OverlaySprinklerValues;