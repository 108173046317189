import type { HarvesterDeviceStateUnflattened } from '@phyllome/common';
import { Box, Alert, AlertTitle } from '@mui/material';
import FormHarvesterConfigSet from './forms/FormHarvesterConfigSet';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';

const ConfigHarvester = ({
  latest,
}: {
  latest: HarvesterDeviceStateUnflattened;
}) => {
  return (
    <>
      <PaperSection heading='Harvester Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{latest.deviceId}</LabelValue>
        <LabelValue label='Firmware'>{latest.firmware}</LabelValue>
        <LabelValue label='Build date'>{latest.compiled}</LabelValue>
        <LabelValue label='Network IP'>TBD</LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <Box mb={2}>
        <Alert severity='warning'>
          <AlertTitle>Form not connected</AlertTitle>Do not use these forms
          until they have been whitelisted on the MCP
        </Alert>
      </Box>
      <FormHarvesterConfigSet latest={latest} />
      <GenericConfigGet deviceId={latest.deviceId} deviceType='harvester' />
    </>
  );
};

export default ConfigHarvester;
