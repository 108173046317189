import type { DatabaseSeason, WithId } from '@phyllome/common';
import type { FC } from 'react';
import useAction from '../../../../data/hooks/useAction';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import ScheduleViewLighting from '../../../../components/ScheduleView/ScheduleViewLighting';
import useSeeds from '../../../../data/hooks/collections/useSeeds';
import { LightingScheduleEdit } from '../../../../components/ScheduleEdit/ScheduleEdit';
import ScheduleTableLighting from '../../../../components/ScheduleTable/ScheduleTableLighting';
import useActivityLog from '../../../../data/hooks/useActivityLog';
import { useSnackbar } from 'notistack';

type IProps = {
  seasonData: WithId<DatabaseSeason>;
};

const SideBarSeasonLightingSchedule: FC<IProps> = ({ seasonData }) => {
  const updateSeason = useAction('seasonUpdate');

  const { enqueueSnackbar } = useSnackbar();
  const activityLog = useActivityLog(`season/${seasonData.id}/notes`);

  const seedData = useSeeds(); // MICK THIS GRABS ALL THE SEEDS FYI
  const selectedSeedData = seedData.find(
    (seed) => seed.id === seasonData.seedId,
  );

  if (seasonData?.state === 'completed') {
    return    (
      <PaperSection
        heading='Lighting Schedule'
        mt={2}
      >
        <ScheduleTableLighting scheduleDataDB={seasonData?.defaultLightingSchedule || []} />
      </PaperSection>);
  } else {
    return (
      <PaperSection
        heading='Lighting Schedule'
        mb={2}
      >
        <ScheduleViewLighting
          seasonSchedule={seasonData?.defaultLightingSchedule}
          seedSchedule={selectedSeedData?.defaultLightingSchedule}
          context='season'
        />
        <LightingScheduleEdit
          isSaving={updateSeason.status === 'RUNNING'}
          data={seasonData?.defaultLightingSchedule}
          onSubmit={(data) => {
            updateSeason.run({
              brokerId: seasonData.brokerId,
              id: seasonData.id,
              defaultLightingSchedule: data as any,
            })
              .then(() => {
                enqueueSnackbar('lighting schedule updated', {
                  variant: 'success',
                });
              })
              .then(() => {
                activityLog.add('Updated lighting schedule', 'OPERATION');
              });

          }}
        />
      </PaperSection>
    );
  };
};

export default SideBarSeasonLightingSchedule;
