import { Stack } from '@mui/system';
import { CheckboxElement, TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import type { Co2DeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import {  mqttDefinitionCo2ConfigSet } from '@phyllome/common';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormCo2ConfigSetCutOff = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<Co2DeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;
  const controlLifecycle = useControlSend(mqttDefinitionCo2ConfigSet, deviceId);
  const activityLog = useActivityLog(`device/co2/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionCo2ConfigSet, {
    automation: {
      lowLevelCutoff: latest?.automation?.lowLevelCutoff || false,
    },
    lowLevelCutoff: latest?.lowLevelCutoff || 0,
  });

  return (
    <PaperSection
      heading='CO2 Cutoff Automation'
      mb={2}
      subheading={mqttDefinitionCo2ConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='camera'
        co2={latest.automation?.co2}
        refresh={latest.automation?.refresh}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              automation: {
                lowLevelCutoff: data.automation.lowLevelCutoff,
              },
              lowLevelCutoff: data.lowLevelCutoff,
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            `Set CO2 dosing automation to ${data.automation.lowLevelCutoff} and low level cutoff to ${data.lowLevelCutoff}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          lowLevelCutoff: yup.number().min(0).max(1000).required(),
        })}
      >
        <Stack spacing={2}>
          <CheckboxElement
            label='Automation Fall Level Cutoff'
            name='automation.lowLevelCutoff'
            required
          />
          <TextFieldElement
            label='CO2 fall level threshold'
            name='lowLevelCutoff'
            required
          />
          <p>
            When enabled, CO2 system will automatically shut off if the CO2
            level <strong>falls</strong> by this amount within any hour during
            the dosing window.
          </p>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormCo2ConfigSetCutOff;
