import { Stack } from '@mui/system';
import { SwitchElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Alert } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import useControlSend from '../../../data/hooks/useControlSend';
import parseNDSDeviceId from '../../../helpers/parseNDSDeviceId';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import type { NDSDeviceStateUnflattened } from '@phyllome/common';
import { mqttDefinitionNDSEnable } from '@phyllome/common';

const FormNDSEnable = ({
  deviceState,
}: {
  deviceState: NDSDeviceStateUnflattened;
}) => {
  const controlLifecycle = useControlSend(
    mqttDefinitionNDSEnable,
    `${deviceState.deviceId}`,
  );

  const activityLog = useActivityLog(
    `device/nds/${deviceState.deviceId}/notes`,
  );

  const { column, shelf, row } = parseNDSDeviceId(deviceState.deviceId);

  const defaultValues = formCreateDefaultValues(mqttDefinitionNDSEnable, {
    enable: deviceState.enable || false,
  });

  return (
    <PaperSection
      heading='NDS control'
      mb={2}
      subheading={mqttDefinitionNDSEnable.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          console.log({
            payload: {
              enable: data.enable,
            },
            topicParams: {
              column,
              shelf,
              row,
            },
          });
          controlLifecycle.run({
            payload: {
              enable: data.enable,
            },
            topicParams: {
              column,
              shelf,
              row,
            },
          });
          activityLog.add(
            `Set NDS ${deviceState.deviceId} to ${data.enable ? 'enable' : 'disable'}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({})}
      >
        <Stack spacing={2}>
          <Alert severity='info'>
            Normal operating procedure is to control NDS enable state via season
            control
          </Alert>
          <SwitchElement label='Enable NDS' name='enable' />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormNDSEnable;
