import type {  TraybotDeviceStateUnflattened } from '@phyllome/common';
import {
  MQTTPayloadTraybotState,
  colors,
} from '@phyllome/common';
import { useContext } from 'react';
import FarmContext from '../../components/FarmProvider/FarmContext';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import HourglassTopSharpIcon from '@mui/icons-material/HourglassTopSharp';
import BatteryChargingFullSharpIcon from '@mui/icons-material/BatteryChargingFullSharp';
import ErrorIcon from '@mui/icons-material/Error';
import AirSharpIcon from '@mui/icons-material/AirSharp';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { Box } from '@mui/material';
import TraybotBlockGroup from './components/TraybotBlockGroup';
import { useCurrentSidebarDeviceData } from '../../data/hooks/useCurrentSidebarDeviceData';

const LatestTraybotInfo = () => {

  const { properties: latest } = useCurrentSidebarDeviceData<TraybotDeviceStateUnflattened>();

  const farmConfig = useContext(FarmContext);

  const rows =
    latest.location.column !== undefined &&
    latest.location.column > 0 &&
    latest.location.column <= farmConfig.columnSpec.length
      ? farmConfig.columnSpec[latest.location.column - 1]?.rows || 0
      : 0;

  const heroRowColor =
    Number(latest.location?.row) > rows || Number(latest.location?.row) < 0
      ? colors.earth3
      : 'black';

  const paperSectionColor =
    latest.inhibit !== undefined && latest.inhibit === true
      ? colors.earth3
      : colors.night;

  const heroItems = [
    {
      label: 'Column',
      text: (latest.location?.column === undefined
        ? '-'
        : latest.location?.column || '-'
      ).toString(),
    },
    {
      label: 'Shelf',
      text: (latest.location?.shelf === undefined
        ? '-'
        : latest.location.shelf || '-'
      ).toString(),
    },
    {
      label: 'Row',
      text: (latest.location?.row === undefined
        ? '-'
        : latest.location.row || '-'
      ).toString(),
      color: heroRowColor,
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading='Latest Traybot Info'
      headerSx={{
        background: paperSectionColor,
      }}
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${
        latest.timestamp &&
        dateToString(latest.timestamp, dateFormatTypes.TRUNCATED)
      }`}
    >
      <HeroGroup items={heroItems} />
      <Box mb={2}>
        {rows > 0 && <TraybotBlockGroup rows={rows} />}
      </Box>
      <Box mb={2}>
        <ChipBool
          data={latest.busy}
          label='Busy'
          color='earth3'
          icon={<HourglassTopSharpIcon />}
        />
        <ChipBool
          data={Number(latest.state) === MQTTPayloadTraybotState.MOVING}
          label='Moving'
          color='primary'
        />
        <ChipBool
          data={Number(latest.state) === MQTTPayloadTraybotState.HOMING}
          label='Homing'
          color='sunlight'
        />
      </Box>
      <Box mb={2}>
        <ChipBool
          data={Number(latest.state) === MQTTPayloadTraybotState.TAKING_PHOTO}
          label='Photo'
          icon={<PhotoCameraIcon />}
        />
        <ChipBool data={latest.lift?.raised} label='Raised' color='primary' />
        <ChipBool
          data={latest.charger}
          label='Charger'
          color='primary'
          icon={<BatteryChargingFullSharpIcon />}
        />
        <ChipBool data={!latest.edge} label='Edge' color='earth3' />
        <ChipBool
          data={latest.blower}
          label='Fan'
          color='primary'
          icon={<AirSharpIcon />}
        />
        <ChipBool data={latest.rfid} label='RFID' color='primary' icon={<LocationSearchingIcon />}/>
        <ChipBool
          data={latest.inhibit !== undefined && latest.inhibit === true}
          label='Inhibit'
          color='earth3'
          icon={<ErrorIcon />}
        />
        {/* <ChipBool
          data={
            Number(latest.location.row) > rows ||
            Number(latest.location.row) < 0
          }
          label="Location"
          color="earth3"
          icon={<ErrorIcon />}
        /> */}
      </Box>
    </PaperSection>
  );
};

export default LatestTraybotInfo;
