import type { AirDeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import Graph from '../../components/Favourites/Graph';
import type { AverageInfoItems } from '../../components/AverageTimeSeries/AverageInfo';
import {
  AverageInfo,
} from '../../components/AverageTimeSeries/AverageInfo';
import { useMemo } from 'react';

const GraphAir = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<AirDeviceStateUnflattened>;
}) => {

  const { deviceId, brokerId } = deviceState;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const averageItems: AverageInfoItems[] = [
    {
      field: 'temperature',
      label: 'Temperature',
      sup: '\u00b0C',
    },
    {
      field: 'humidity',
      label: 'Humidity',
      sup: '%',
    },
    {
      field: 'co2',
      label: 'CO2',
      sup: 'ppm',
      decimals: 0,
    },
    {
      field: 'vpd',
      label: 'VPD',
      sup: 'kPa',
    },
    {
      field: 'dewPoint',
      label: 'Dew Point',
      sup: '\u00b0C',
    },
  ];

  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        <AverageInfo latest={latest} deviceType='air' items={averageItems} />
        <Graph name='airTemperature' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='airHumidity' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='airVPD' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='airDewPoint' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='airCo2' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='airRssi' brokerId={brokerId} deviceId={deviceId} />
      </Stacker>
    </ChartProvider>
  );
};

export default GraphAir;
