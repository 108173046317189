import { Stack } from '@mui/system';
import { SwitchElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, TransferDeviceStateUnflattened } from '@phyllome/common';
import {  mqttDefinitionTransferManual } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Typography } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { useMemo } from 'react';

const FormTransferManualConveyors = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<TransferDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const { deviceId } = deviceState;

  const controlLifecycle = useControlSend(
    mqttDefinitionTransferManual,
    `${deviceId}`,
  );

  const activityLog = useActivityLog(`device/transfer/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTransferManual,
    {
      conveyors: {
        hatch: latest?.conveyors.hatch || false,
        infeed: latest?.conveyors.infeed || false,
        outfeed: latest?.conveyors.outfeed || false,
      },
    },
  );

  return (
    <PaperSection
      mb={2}
      heading='Conveyor Manual Control'
      subheading={mqttDefinitionTransferManual.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              conveyors: {
                hatch: data?.conveyors?.hatch,
                infeed: data?.conveyors?.infeed,
                outfeed: data?.conveyors?.outfeed,
              },
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            `Manually set conveyors hatch ${data.conveyors.hatch} infeed: ${data.conveyors.infeed} and outfeed: ${data.conveyors.outfeed}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          conveyors: yup.object({
          }),
        })}
      >
        <Stack spacing={2}>
          <Typography variant='body1'>
            Start / Stop the conveyors
          </Typography>
          <SwitchElement
            label='Hatch Conveyor'
            name='conveyors.hatch'
          />
          <SwitchElement
            label='Infeed Conveyor'
            name='conveyors.infeed'
          />
          <SwitchElement
            label='Outfeed Conveyor'
            name='conveyors.outfeed'
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTransferManualConveyors;
