import type { IrrigationDeviceStateUnflattened } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestIrrigation = (deviceId: string) => {
  const data = useDeviceState<IrrigationDeviceStateUnflattened>({
    deviceId,
    deviceType: 'irrigation',
  });

  if (!data) return undefined;
  return data;

};

export default useLatestIrrigation;
