import { colors } from '@phyllome/common';
import type { FC } from 'react';
import RackElementContainer from '../../components/RackElementContainer';
import { Box } from '@mui/material';
import type { NDSDeviceFromFetch } from '../../../../types/types';

type NDSRackElementProps = {
  data: NDSDeviceFromFetch;
};

const NDSRackElement: FC<NDSRackElementProps> = () => {
  return (
    <RackElementContainer>
      <Box
        sx={{
          background: colors.budding,
          display: 'block',
        }}
      >
        &nbsp;
      </Box>
    </RackElementContainer>
  );
};

export default NDSRackElement;
