import { useEffect } from 'react';
import { appStateActions, useAppState } from '../../data/AppState/useAppState';
import { Route, Routes, useParams } from 'react-router-dom';
import Devices from './Devices/Devices';
import Messaging from './Messaging/Messaging';
import Sprinklers from './Sprinklers/Sprinklers';
import Satellite from './Satellite/Satellite';
import Alerts from './Logs/Alerts/Alerts';
import FactoryUI from './factoryui/FactoryUI';
import Seasons from './Seasons/Seasons';
import TraysEntryPoint from './Trays/TraysEntryPoint';
import CustomConsole from './CustomConsole/CustomConsole';
import CustomConsoleEdit from './CustomConsole/CustomConsoleEdit';
import Activity from './Logs/Activity/Activity';
import DesiredToMQTT from './DesiredToMQTT/DesiredToMQTT';
import Search from './Search/Search';
import DeviceWebsocketRun from './Devices/DeviceWebsocket';
import DevicesUpdating from './Devices/DevicesUpdating';
import StreamMessageProvider from '../../components/StreamMessageProvider/StreamMessageProvider';
import Updates from './Logs/Updates/Updates';
import { useAtomValue, useSetAtom } from 'jotai';
import { brokerIdAtom } from '../../data/atoms/brokerIdAtom';
import { websocketFiltersAtom } from '../../data/atoms/websocketFilters';
import DeviceWorker from '../../components/DevicesProvider/DeviceWorker';
import Grapher from './Grapher/Grapher';

const BrokerEntry = () => {
  const { brokerId } = useParams();
  const { dispatch: appDispatch } = useAppState();
  const setBrokerId = useSetAtom(brokerIdAtom);
  const setWebsocketFilters = useSetAtom(websocketFiltersAtom);
  const computedBrokerId = useAtomValue(brokerIdAtom);

  useEffect(() => {

    if (!brokerId) {
      console.error('Broker ID is undefined');
      return;
    };

    appDispatch({ type: appStateActions.SETBROKER, payload: brokerId });
    setBrokerId(brokerId);

    DeviceWorker.commenceSyncing(brokerId);
    // Return a cleanup function that will run when the component unmounts
    return () => {
      console.log('UNMOUNT');
      // Reset broker ID when component unmounts
      appDispatch({ type: appStateActions.SETBROKER, payload: '' });
      setBrokerId('');
      DeviceWorker.stopSyncing();
    // Add any other cleanup code here
    };
  }, [appDispatch, brokerId, setBrokerId, setWebsocketFilters]);

  if (!brokerId || computedBrokerId !== brokerId) return null;
  return (

    <StreamMessageProvider>
      <Routes>
        <Route
          path='/'
          element={<CustomConsole />}
        />
        <Route
          path='/dashboard'
          element={<CustomConsole />}
        />
        <Route
          path='/dashboard/edit'
          element={<CustomConsoleEdit brokerId={brokerId} />}
        />
        <Route
          path='/devices/test'
          element={<DeviceWebsocketRun brokerId={brokerId} />}
        />
        <Route
          path='/devices/updating'
          element={<DevicesUpdating brokerId={brokerId} />}
        />
        <Route
          path='/devices'
          element={<Devices />}
        />
        <Route
          path='messaging'
          element={<Messaging />}
        />
        <Route
          path='sprinklers'
          element={<Sprinklers brokerId={brokerId} />}
        />
        <Route
          path='trays'
          element={<TraysEntryPoint />}
        />
        <Route
          path='factoryui'
          element={<FactoryUI />}
        />
        <Route
          path='seasons'
          element={<Seasons />}
        />
        <Route
          path='satellite/:seasonId?'
          element={<Satellite />}
        />
        <Route
          path='logs/updates'
          element={<Updates brokerId={brokerId} />}
        />
        <Route
          path='logs/alerts'
          element={<Alerts brokerId={brokerId} />}
        />
        <Route
          path='logs/activity'
          element={<Activity brokerId={brokerId} />}
        />
        <Route path='grapher' element={<Grapher />} />
        {/* <Route path="mqtt" element={<LocalMQTT brokerId={brokerId} />} />

      <Route path="mqtt/:mqttId" element={<LocalMQTT brokerId={brokerId} />} /> */}
        <Route
          path='desired'
          element={<DesiredToMQTT />}
        />
        <Route
          path='search'
          element={<Search />}
        />
      </Routes>
    </StreamMessageProvider>

  );
};

export default BrokerEntry;
