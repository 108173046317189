import type { DatabaseDevices, LightingCoreDeviceStateUnflattened } from '@phyllome/common';
import GenericPing from '../Generic/control/GenericPing';
import LatestLightingCoreInfo from './LatestLightingCoreInfo';

const ControlZoneCore = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LightingCoreDeviceStateUnflattened>;
}) => {

  const { deviceId } = deviceState;

  return (
    <>
      <LatestLightingCoreInfo deviceState={deviceState} />
      <div>
        <GenericPing
          deviceId={deviceId}
          deviceType='lightingcore'
        />
      </div>
    </>
  );
};

export default ControlZoneCore;
