import type { ConveyorDeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { colors } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import HourglassTopSharpIcon from '@mui/icons-material/HourglassTopSharp';
import Box from '@mui/material/Box';
import {  Divider } from '@mui/material';
import { useMemo } from 'react';

const LatestConveyorInfo = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<ConveyorDeviceStateUnflattened>;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const paperSectionColor =
    latest.inhibit !== undefined && latest.inhibit === true
      ? colors.earth3
      : colors.night;

  return (
    <PaperSection
      mb={2}
      heading='Latest Conveyor Info'
      headerSx={{
        background: paperSectionColor,
      }}
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <Box
        mb={2}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center',
        }}
      >
        {Object.entries(latest.trays).map(([trayId, isActive]) => (
          <ChipBool
            data={isActive}
            label={`Tray ${trayId}`}
            color='primary'
            tooltip='Tray present in column'
          />
        ))}
      </Box>
      <Divider sx={{ marginBottom: 2 }} />
      <Box mb={2}>
        <ChipBool
          data={latest.busy}
          label='Busy'
          color='earth3'
          icon={<HourglassTopSharpIcon />}
          tooltip='state of conveyor busy flag'
        />
        <ChipBool
          data={latest.blocked}
          label='Blocked'
          color='earth3'
          icon={<HourglassTopSharpIcon />}
          tooltip='state of conveyor blocked flag'
        />
      </Box>
    </PaperSection>
  );
};

export default LatestConveyorInfo;
