import { useEffect } from 'react';
import airGraph from '../../../components/Favourites/airGraph';
import fertGraph from '../../../components/Favourites/fertGraph';

const airGraphArray = Object.keys(airGraph).map((key) => ({
  ...airGraph[key],
}));

const fertGraphArray = Object.keys(fertGraph).map((key) => (fertGraph[key]));

const allGraphs = [
  ...airGraphArray,
  ...fertGraphArray,
];

const GraphSelect  = () => {

  useEffect(() => {

  });
  console.log(airGraphArray, allGraphs);
  return <div>Hello</div>;
};

export default GraphSelect;