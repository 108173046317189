import type { HarvesterDeviceStateUnflattened } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import { Box } from '@mui/material';
import DangerousIcon from '@mui/icons-material/Dangerous';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';

const LatestHarvesterInfo = ({
  latest,
}: {
  latest: HarvesterDeviceStateUnflattened;
}) => {
  const heroItems = [
    {
      label: 'Platform Height',
      text: latest.platform.height.toString(),
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading='Latest Harvester Info'
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <HeroGroup items={heroItems} />
      <Box mb={2}>
        <ChipBool
          data={latest.estop}
          label='Emergency Stop'
          color='earth3'
          icon={<DangerousIcon />}
          tooltip='State of emergency stop button, red if pressed'
        />
        <ChipBool
          data={latest.conveyors.tray}
          label='Conveyor Tray'
          color='primary'
        />
        <ChipBool
          data={latest.conveyors.load}
          label='Conveyor Load'
          color='primary'
        />
        <ChipBool
          data={latest.conveyors.produce}
          label='Conveyor Produce'
          color='primary'
        />
      </Box>
    </PaperSection>
  );
};

export default LatestHarvesterInfo;
