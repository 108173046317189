import type { CameraDeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import { useMemo } from 'react';

//import ReChart from "../../components/Rechart/Rechart";
//import ReChartArea from "../../components/Rechart/RechartArea";

const GraphCamera = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<CameraDeviceStateUnflattened>;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { deviceId, brokerId } = deviceState;

  //freememory, mode, control, ppm
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker></Stacker>
    </ChartProvider>
  );
};

export default GraphCamera;
