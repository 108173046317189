import { colors } from '@phyllome/common';
import type { FavouriteDefinition } from './types';

const irrigGraph: FavouriteDefinition = {
  irrigPressuresSupply: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pressures.supply`,
    color: colors.earth1,
    field: 'pressures.supply',
    brokerId,
    deviceId,
  }),
  irrigPressuresReturn: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pressures.return`,
    color: colors.earth3,
    field: 'pressures.return',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  irrigLevelsSupply: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/levels.supply`,
    color: colors.ocean,
    field: 'levels.supply',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  irrigLevelsSump: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/levels.sump`,
    color: colors.earth1,
    field: 'levels.sump',
    brokerId,
    deviceId,
  }),
  irrigLevelsReturn: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/levels.return`,
    color: colors.river,
    field: 'levels.return',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  irrigPumpsFertigation: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pumps.fertigation`,
    color: colors.earth1,
    field: 'pumps.fertigation',
    brokerId,
    deviceId,
  }),
  irrigValvesOzone: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves.ozone`,
    color: colors.earth1,
    field: 'valves.ozone',
    brokerId,
    deviceId,
  }),
  irrigOzoneGenerator: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/sterilisation.generator`,
    color: colors.earth1,
    field: 'sterilisation.generator',
    brokerId,
    deviceId,
  }),
  irrigPumpsSump: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pumps.sump`,
    color: colors.earth1,
    field: 'pumps.sump',
    brokerId,
    deviceId,
  }),
  irrigPumpsSupply: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pumps.supply`,
    color: colors.earth1,
    field: 'pumps.supply',
    brokerId,
    deviceId,
  }),
  irrigPumpsTransfer: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pumps.transfer`,
    color: colors.earth1,
    field: 'pumps.transfer',
    brokerId,
    deviceId,
  }),
  irrigValvesDischarge: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves.discharge`,
    color: colors.earth1,
    field: 'valves.discharge',
    brokerId,
    deviceId,
  }),
  irrigValvesTransfer: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves.transfer`,
    color: colors.earth1,
    field: 'valves.transfer',
    brokerId,
    deviceId,
  }),
  irrigValvesMains: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves.mains`,
    color: colors.earth1,
    field: 'valves.mains',
    brokerId,
    deviceId,
  }),
  irrigValvesReturn: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves.return`,
    color: colors.earth1,
    field: 'valves.return',
    brokerId,
    deviceId,
  }),
  irrigValvesSupply: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves.supply`,
    color: colors.earth1,
    field: 'valves.supply',
    brokerId,
    deviceId,
  }),
  irrigMode: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'enum',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/mode`,
    color: colors.earth1,
    field: 'mode',
    brokerId,
    deviceId,
    mapping: {
      '0': 'Off',
      '1': 'Auto',
      '2': 'Manual',
    },
  }),
};

export default irrigGraph;
