import { useContext, useEffect, useState } from 'react';
import OverlayTraySimple from '../Overlays/OverlayTraySimple';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import { TraysContext } from '../TraysContext';
import type { DatabaseTraySeasonPick } from '../../../../data/hooks/collections/useTraySeasonByBrokerId';
import useRoute from '../../../../data/hooks/useRoute';

const OverlayEntryPointTraysCurrent = ({ column }: { column: number }) => {
  const { columnSpec } = useContext(FarmContext);
  const traysContext = useContext(TraysContext);

  const route = useRoute();
  const [trayData, setTrayData] = useState<DatabaseTraySeasonPick[]>([]);

  useEffect(() => {
    const filter = traysContext.state.trays.filter((tray) => {
      return tray.locationColumn === column;
    });

    setTrayData(filter);
  }, [traysContext.state.trays, column]);

  const numberOfShelves = columnSpec[column - 1].shelves;

  return (
    <>
      <OverlayTraySimple
        data={trayData}
        shelves={numberOfShelves}
        selectedSeason={
          traysContext.state.selectedSeason
        }
        selectedTray={traysContext.state.selectedTray}
        onClick={(trayRowData) => {
          traysContext.dispatch({
            type: 'SET_SELECTED_TRAY',
            payload: trayRowData.id,
          });

          traysContext.dispatch({
            type: 'SET_SELECTED_SEASON',
            payload: trayRowData.seasonId,
          });

          route.navReducer({
            mainQuery: { seasonId: trayRowData.seasonId },
            sidebarPath: `/tray/${trayRowData.id}`,
          });

        }}
      />
    </>
  );
};

export default OverlayEntryPointTraysCurrent;
