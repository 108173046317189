import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import ChipEnum from '../../components/puregui/ChipEnum/ChipEnum';
import AutoModeSharpIcon from '@mui/icons-material/AutoModeSharp';
import ModeStandbySharpIcon from '@mui/icons-material/ModeStandbySharp';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AirIcon from '@mui/icons-material/Air';
import { Box } from '@mui/material';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import type { ChipEnumData } from '../../components/puregui/ChipEnum/types';
import type { Co2DeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { useMemo } from 'react';

const modeStates: ChipEnumData = [
  {
    label: 'Off',
    value: 0,
    color: 'earth3',
    icon: <ModeStandbySharpIcon />,
  },
  { label: 'Auto', value: 1, color: 'primary', icon: <AutoModeSharpIcon /> },
  {
    label: 'Manual',
    value: 2,
    color: 'sunlight',
    icon: <PlayCircleOutlineIcon />,
  },
];

const controlStates: ChipEnumData = [
  {
    label: 'Off',
    value: 0,
    color: 'earth3',
    icon: <ModeStandbySharpIcon />,
  },
  {
    label: 'Local',
    value: 1,
    color: 'sunlight',
    icon: <PlayCircleOutlineIcon />,
  },
  {
    label: 'Remote',
    value: 2,
    color: 'primary',
    icon: <PlayCircleOutlineIcon />,
  },
];

const LatestCo2Info = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<Co2DeviceStateUnflattened>;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const heroItems = [
    {
      label: 'CO2 Level',
      text: (Math.round(Number(latest.ppm * 10)) / 10).toString(),
      sup: 'ppm',
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading='Latest CO2 Info'
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <HeroGroup items={heroItems} />
      <Box mb={2}>
        <ChipEnum
          data={modeStates}
          value={Number(latest.mode)}
          tooltip='Operating mode'
        />
        <ChipEnum
          data={controlStates}
          value={Number(latest.control)}
          tooltip='State of front panel remote switch and control mode'
        />
        <ChipBool
          data={latest.co2?.enabled}
          label='Dosing Window Active'
          tooltip='True when CO2 automation is on, enabled and during dosing window'
        />
      </Box>
      <Box mb={2}>
        <ChipBool
          data={latest.automation?.co2}
          label='Co2'
          icon={<AutoModeSharpIcon />}
          tooltip='State of CO2 automation'
        />
        <ChipBool
          data={latest.co2?.dosing}
          label='Dosing'
          tooltip='State of CO2 gas valve'
        />
        <ChipBool
          data={latest.co2?.heater}
          label='Heater'
          tooltip='State of heater element'
        />
        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
        <ChipBool
          data={latest.automation?.refresh}
          label='Fans'
          icon={<AutoModeSharpIcon />}
          tooltip='State of FAN refresh automation'
        />
        <ChipBool
          data={latest.fans?.exhaust}
          label='Exhaust'
          icon={<AirIcon />}
          tooltip='State of exhaust fans'
        />
        <ChipBool
          data={latest.fans?.intake}
          label='Intake'
          icon={<AirIcon />}
          tooltip='State of intake fans'
        />
      </Box>
    </PaperSection>
  );
};

export default LatestCo2Info;
