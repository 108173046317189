/**
 * Generates a mapping of values from a Set to colors along a gradient
 * Lower values in the set will be closer to colourLow
 * Higher values will be closer to colourHigh
 *
 * @param set - A Set of unique values to map to colors
 * @param colourLow - The color for the lowest value (hex or rgb)
 * @param colourHigh - The color for the highest value (hex or rgb)
 * @returns An object mapping each value from the set to a color in hex format
 */
function generateUniqueColoursFromSetValues(
  set: Set<any>,
  colourLow: string,
  colourHigh: string,
): { [key: string]: string } {
  // Convert set to array for easier manipulation
  const values = Array.from(set);

  if (values.length === 0) {
    return {};
  }

  // Parse the colors to RGB
  const lowRGB = parseColor(colourLow);
  const highRGB = parseColor(colourHigh);

  // For numeric values, sort them to create a proper gradient
  const sortedValues = [...values];
  const valueMap: { [key: string]: number } = {};

  if (sortedValues.every(val => typeof val === 'number')) {
    // Create a map of original values to their indices before sorting
    sortedValues.forEach((val, idx) => {
      valueMap[String(val)] = idx;
    });
    sortedValues.sort((a, b) => a - b);
  } else {
    // For non-numeric values, just use their original order
    sortedValues.forEach((val, idx) => {
      valueMap[String(val)] = idx;
    });
  }

  // Map each value to a color
  const result: { [key: string]: string } = {};
  const maxIndex = sortedValues.length - 1;

  // Pre-calculate step sizes for RGB channels to improve performance
  const rStep = (highRGB.r - lowRGB.r) / Math.max(1, maxIndex);
  const gStep = (highRGB.g - lowRGB.g) / Math.max(1, maxIndex);
  const bStep = (highRGB.b - lowRGB.b) / Math.max(1, maxIndex);

  sortedValues.forEach((value, index) => {
    const key = String(value);

    // Interpolate between colors using steps
    const r = Math.round(lowRGB.r + rStep * index);
    const g = Math.round(lowRGB.g + gStep * index);
    const b = Math.round(lowRGB.b + bStep * index);

    // Convert back to hex for consistency in output
    result[key] = rgbToHex(r, g, b);
  });
  return result;
}

/**
 * Parses a color string (hex or rgb) into RGB components
 */
function parseColor(color: string): { r: number, g: number, b: number } {
  // Check if the color is in hex format
  if (color.startsWith('#')) {
    return hexToRgb(color);
  }

  // Check if the color is in rgb format
  const rgbMatch = color.match(/rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/i);

  if (rgbMatch) {
    return {
      r: parseInt(rgbMatch[1], 10),
      g: parseInt(rgbMatch[2], 10),
      b: parseInt(rgbMatch[3], 10),
    };
  }

  // Default to black if format is unrecognized
  console.warn('Unrecognized color format:', color);
  return { r: 0, g: 0, b: 0 };
}

/**
 * Converts a hex color string to RGB components
 */
function hexToRgb(hex: string): { r: number, g: number, b: number } {
  // Remove the hash if present
  hex = hex.replace(/^#/, '');

  // Handle shorthand hex format (e.g., #FFF)
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Parse the hex values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b };
}

/**
 * Converts RGB components to a hex color string
 */
function rgbToHex(r: number, g: number, b: number): string {
  // Ensure values are in the valid range
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  // Convert to hex and pad with zeros if needed
  const hex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

  return `#${hex}`;
}

export default generateUniqueColoursFromSetValues;