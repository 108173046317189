import { colors } from '@phyllome/common';
import type { FavouriteDefinition } from './types';

const fertGraph: FavouriteDefinition = {
  fertSampleEC: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/sample.ec`,
    color: colors.budding,
    field: 'sample.ec',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
      max: 3,
    },
  }),

  fertSamplePH: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/sample.ph`,
    color: colors.earth3,
    field: 'sample.ph',
    brokerId,
    deviceId,
    constraints: {
      min: 3,
      max: 8,
    },
    limits: {
      min: 5,
      max: 6.5,
    },
  }),

  fertSampleORP: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/sample.orp`,
    color: colors.ocean,
    field: 'sample.orp',
    brokerId,
    deviceId,
  }),

  fertSampleTemperature: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/sample.temperature`,
    color: colors.earth1,
    field: 'sample.temperature',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
      max: 40,
    },
  }),

  fertSampleDO: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/sample.do`,
    color: colors.river,
    field: 'sample.do',
    brokerId,
    deviceId,
  }),

  fertDispensedNutrientA: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed.nutrientA`,
    color: colors.earth1,
    field: 'dispensed.nutrientA',
    brokerId,
    deviceId,
  }),
  fertDispensedNutrientB: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed.nutrientB`,
    color: colors.earth1,
    field: 'dispensed.nutrientB',
    brokerId,
    deviceId,
  }),
  fertDispensedAcid: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed.acid`,
    color: colors.earth1,
    field: 'dispensed.acid',
    brokerId,
    deviceId,
  }),
  fertDispensedBase: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed.base`,
    color: colors.earth1,
    field: 'dispensed.base',
    brokerId,
    deviceId,
  }),
  fertDispensedPeroxide: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed.peroxide`,
    color: colors.earth1,
    field: 'dispensed.peroxide',
    brokerId,
    deviceId,
  }),

  fertDispensedPump: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed.pump`,
    color: colors.earth1,
    field: 'dispensed.pump',
    brokerId,
    deviceId,
  }),
};

export default fertGraph;
