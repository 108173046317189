import { useContext } from 'react';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import useTraySeasonsCurrentByColID from '../../../../data/hooks/collections/useTraySeasonsCurrentByColID';
import OverlayTrayZones from '../Overlays/OverlayTrayZones';

const OverlayEntryPointLighting = ({ column }: { column: number }) => {
  const { columnSpec } = useContext(FarmContext);
  const trayData = useTraySeasonsCurrentByColID({ columnId: column });
  const shelves = columnSpec[column - 1].shelves;

  return (
    <>
      <OverlayTrayZones data={trayData.data} shelves={shelves} />
    </>
  );
};

export default OverlayEntryPointLighting;
