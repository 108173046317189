import { Tabs, Tab } from '@mui/material';
import type { NDSDeviceStateUnflattened } from '@phyllome/common';
import {  colors } from '@phyllome/common';
import { useEffect, useState } from 'react';
import * as React from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import ControlNDS from './ControlNDS';
import ConfigNDS from './ConfigNDS';
import LatestNDS from './LatestNDS';
import LatestNDSInfo from './LatestNDSInfo';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import GraphNDS from './GraphNDS';
import PhyTabPanel from '../../components/PhyTabPanel/PhyTabPanel';
import useRoute from '../../data/hooks/useRoute';
import PendingDevice from '../../components/PendingDevice/PendingDevice';
import { useAtomValue, useSetAtom } from 'jotai';
import { brokerIdAtom } from '../../data/atoms/websocketFilters';
import { currentSideBarDeviceIdAtom, useCurrentSidebarDeviceData } from '../../data/hooks/useCurrentSidebarDeviceData';

type IProps = {
  locationString: string;
  brokerId: string;
};

const SideBarEntryNDS: FC<IProps> = ({ locationString }) => {
  const brokerId = useAtomValue(brokerIdAtom);
  const setSideBarDeviceId = useSetAtom(currentSideBarDeviceIdAtom);

  const router = useRoute();
  const [value, setValue] = useState<any>(Number(router.sidebarQuery.tab) || 0);

  const latestDeviceInfo = useCurrentSidebarDeviceData<NDSDeviceStateUnflattened>();

  useEffect(() => {
    setSideBarDeviceId(`${brokerId}.nds.${locationString}`);
  }, [brokerId, locationString, setSideBarDeviceId]);

  useEffect(() => {
    router.navSideQuery({ tab: value });
  }, [value, router]);

  if (!latestDeviceInfo) return <div>Initialising ..</div>;

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  return (
    <>
      <AppDrawerTitle title={`nds/${locationString}`} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor='inherit'
        variant='scrollable'
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label='Latest' value={0} />
        <Tab label='Graph' value={1} />
        <Tab label='Control' value={2} />
        <Tab label='Config' value={3} />
        <Tab label='Activity' value={4} />
        <Tab label='Alerts' value={5} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <LatestNDSInfo latest={latestDeviceInfo} />
        <PendingDevice deviceState={latestDeviceInfo} />
        <AlertsDevice
          brokerId={brokerId}
          deviceId={latestDeviceInfo.deviceId}
          deviceType='nds'
          count={1}
        />
        <LatestNDS latest={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <GraphNDS deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <ControlNDS deviceState={latestDeviceInfo.properties} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <ConfigNDS deviceState={latestDeviceInfo.properties} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <ActivityLog
          identifier={`device/nds/${locationString}/notes`}
          heading='Activity Log'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={latestDeviceInfo.deviceId}
          deviceType='nds'
        />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryNDS;
