import type { DatabaseDevices, PackagerDeviceStateUnflattened } from '@phyllome/common';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';

//import ReChart from "../../components/Rechart/Rechart";
//import ReChartArea from "../../components/Rechart/RechartArea";

const GraphPackager = ({

  deviceState,
}: {
  deviceState: DatabaseDevices<PackagerDeviceStateUnflattened>;
}) => {

  const { deviceId, brokerId } = deviceState;

  console.log(deviceId, brokerId);
  //freememory, mode, control, ppm
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker></Stacker>
    </ChartProvider>
  );
};

export default GraphPackager;
