import type { DatabaseDevices, IrrigationDeviceStateUnflattened } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import { formatSeconds } from '../../helpers/formatSeconds';
import FormIrrigationConfigFill from './forms/FormIrrigationConfigFill';
import FormIrrigationConfigOzone from './forms/FormIrrigationConfigOzone';
import FormIrrigationConfigTransfer from './forms/FormIrrigationConfigTransfer';
import FormIrrigationConfigPressure from './forms/FormIrrigationConfigPressure';
import FormIrrigationConfigSetNurseryTank from './forms/FormIrrigationConfigSetNurseryTank';
import FormIrrigationConfigSetNurseryPressures from './forms/FormIrrigationConfigSetNurseryPressures';
import FormIrrigationConfigSetNurserySump from './forms/FormIrrigationConfigSetNurserySump';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import { useMemo } from 'react';

const ConfigIrrigation = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<IrrigationDeviceStateUnflattened> ;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const brokerId = deviceState.brokerId;

  return (
    <>
      <PaperSection heading='Irrigation Controller Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceState.deviceId}</LabelValue>
        <LabelValue label='Firmware'>{latest.firmware}</LabelValue>
        <LabelValue label='Build date'>{latest.compiled}</LabelValue>
        <LabelValue label='Network IP'>{latest.network.ip}</LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      {brokerId !== 'mcp-nursery' && (
        <>
          <FormIrrigationConfigFill deviceState={deviceState} />
          <FormIrrigationConfigOzone deviceState={deviceState} />
          <FormIrrigationConfigTransfer deviceState={deviceState} />
          <FormIrrigationConfigPressure deviceState={deviceState} />
          <GenericConfigGet
            deviceId={deviceState.deviceId}
            deviceType='irrigation'
          />
        </>
      )}
      {brokerId === 'mcp-nursery' && (
        <>
          <FormIrrigationConfigSetNurseryTank deviceState={deviceState} />
          <FormIrrigationConfigSetNurseryPressures deviceState={deviceState} />
          <FormIrrigationConfigSetNurserySump deviceState={deviceState} />
          <GenericConfigGet
            deviceId={deviceState.deviceId}
            deviceType='irrigation'
          />
        </>
      )}
    </>
  );
};

export default ConfigIrrigation;
