import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import type { Co2DeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { mqttDefinitionCo2Set } from '@phyllome/common';
import { Alert } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormCo2SetEnableTime = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<Co2DeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;
  const controlLifecycle = useControlSend(mqttDefinitionCo2Set, deviceId);
  const activityLog = useActivityLog(`device/co2/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionCo2Set, {
    start: {
      hour: latest.start?.hour,
      minute: latest.start?.minute,
    },
    end: {
      hour: latest.end?.hour,
      minute: latest.end?.minute,
    },
  });

  return (
    <PaperSection
      heading='CO2 Dosing window'
      mb={2}
      subheading={mqttDefinitionCo2Set.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='co2'
        starthour={latest.start?.hour}
        startminute={latest.start?.minute}
        endhour={latest.end?.hour}
        endminute={latest.end?.minute}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle
            .run({
              payload: {
                start: { hour: data.start.hour, minute: data.start.minute },
                end: { hour: data.end.hour, minute: data.end.minute },
              },
              topicParams: {
                deviceId,
              },
            })
            .then(() => {
              activityLog.add(
                `Set CO2 dosing window to ${data.start.hour}:${data.start.minute} - ${data.end.hour}:${data.end.minute}`,
                'OPERATION',
              );
            });
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          start: yup
            .object({
              hour: yup.number().required(),
              minute: yup.number().required(),
            })
            .required(),
          end: yup
            .object({
              hour: yup.number().required(),
              minute: yup.number().required(),
            })
            .required(),
        })}
      >
        <Stack spacing={2}>
          <Alert severity='info'>Note times are GMT</Alert>
          <Stack direction='row' spacing={2}>
            <TextFieldElement label='Start Hour' name='start.hour' required />
            <TextFieldElement
              label='Start Minute'
              name='start.minute'
              required
            />
          </Stack>
          <Stack direction='row' spacing={2}>
            <TextFieldElement label='End Hour' name='end.hour' required />
            <TextFieldElement label='End Minute' name='end.minute' required />
          </Stack>
          <p>
            When enabled, the CO2 controller will dispense CO2 gas between these
            times.
          </p>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormCo2SetEnableTime;
