import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import Graph from '../../components/Favourites/Graph';
import type { DatabaseDevices, IrrigationDeviceStateUnflattened } from '@phyllome/common';
import { useMemo } from 'react';

const GraphIrrigation = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<IrrigationDeviceStateUnflattened> ;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const brokerId = deviceState.brokerId;
  const deviceId = deviceState.deviceId;

  if (!latest) {
    return null;
  }
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        {/* Only display a graph when there is recent data.
        (latest.X != undefined check)
        As the nursery and growroom have different datasets, this will
        nsure that only the graphs that are relevant to each controller are loaded */}

        {latest.pressures?.supply !== undefined && (
          <Graph
            name='irrigPressuresSupply'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.pressures?.return !== undefined && (
          <Graph
            name='irrigPressuresReturn'
            brokerId={brokerId}
            deviceId={deviceId}
            limits={{ min: latest?.pressure?.min, max: latest?.pressure?.max }}
          />
        )}
        {latest.levels?.supply !== undefined && (
          <Graph
            name='irrigLevelsSupply'
            brokerId={brokerId}
            deviceId={deviceId}
            limits={{ min: latest?.supply?.min, max: latest?.supply?.max }}
          />
        )}
        {latest.levels?.sump !== undefined && (
          <Graph
            name='irrigLevelsSump'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.levels?.return !== undefined && (
          <Graph
            name='irrigLevelsReturn'
            brokerId={brokerId}
            deviceId={deviceId}
            limits={{ min: latest?.return?.min, max: latest?.return?.max }}
          />
        )}
        {latest.sterilisation !== undefined && (
          <Graph
            name='irrigOzoneGenerator'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.pumps?.fertigation !== undefined && (
          <Graph
            name='irrigPumpsFertigation'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.pumps?.sump !== undefined && (
          <Graph
            name='irrigPumpsSump'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.pumps?.supply !== undefined && (
          <Graph
            name='irrigPumpsSupply'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.pumps?.transfer !== undefined && (
          <Graph
            name='irrigPumpsTransfer'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves?.ozone !== undefined && (
          <Graph
            name='irrigValvesOzone'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves?.discharge !== undefined && (
          <Graph
            name='irrigValvesDischarge'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves?.transfer !== undefined && (
          <Graph
            name='irrigValvesTransfer'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves?.mains !== undefined && (
          <Graph
            name='irrigValvesMains'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves?.return !== undefined && (
          <Graph
            name='irrigValvesReturn'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves?.supply !== undefined && (
          <Graph
            name='irrigValvesSupply'
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.mode !== undefined && (
          <Graph name='irrigMode' brokerId={brokerId} deviceId={deviceId} />
        )}
      </Stacker>
    </ChartProvider>
  );
};

export default GraphIrrigation;
