import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { useDebounce } from 'react-use';
import { ChartContext } from '../Rechart/ChartContext/ChartContext';

const vals: Array<[string, number]> = [
  ['1 hour', 1],
  ['3 hours', 3],
  ['6 hours', 6],
  ['1 day', 24],
  ['3 days', 24 * 3],
  ['1 week', 24 * 7],
  ['2 weeks', 24 * 14],
  ['3 weeks', 24 * 21],
  ['1 month', 24 * 30],
  ['2 months', 24 * 60],
  ['3 months', 24 * 90],
  ['6 months', 24 * 180],
  ['9 months', 24 * 270],
  ['1 year', 24 * 365],
];

function convertValueToString(value: number) {
  return vals[value][0];
}

function subtractHoursFromDate(date: Date, hours: number): Date {
  const updatedDate = new Date(date.getTime());

  updatedDate.setHours(updatedDate.getHours() - hours);
  return updatedDate;
}

type IProps = {
  defaultSlot?: number;
};
export default function TimeSlider({ defaultSlot = 3 }: IProps) {
  const [value, setValue] = React.useState<number>(defaultSlot);
  const chartContext = React.useContext(ChartContext);

  useDebounce(
    () => {
      const hours = vals[value][1];
      const now = new Date();

      chartContext.dispatch({
        type: 'SET_DATES',
        payload: {
          beginDate: subtractHoursFromDate(now, hours),
          endDate: now,
        },
      });
    },
    400,
    [value],
  );

  const handleChange = (_event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }
  };

  return (
    <Box sx={{ maxWidth: '600px' }}>
      <Typography id='non-linear-slider' gutterBottom>
        Duration: {convertValueToString(value)}
      </Typography>
      <Slider
        value={value}
        min={0}
        max={8}
        valueLabelFormat={convertValueToString}
        onChange={handleChange}
        valueLabelDisplay='off'
        aria-labelledby='non-linear-slider'
      />
    </Box>
  );
}
