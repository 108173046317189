import generateUniqueColours from './generateUniqueColours';

function generateUniqueColoursFromSet(set: Set<any>) {
  const cols = generateUniqueColours(set.size, 80);
  const x = Array.from(set).reduce((acc, f, i) => {
    acc[f] = cols[i];
    return acc;
  }, {});

  return x as { [key: string]: string };
}
export default generateUniqueColoursFromSet;
