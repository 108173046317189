import { Tabs, Tab, Box, Alert } from '@mui/material';
import type { DatabaseDevices, LiftDeviceStateUnflattened } from '@phyllome/common';
import { colors } from '@phyllome/common';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import FarmContext from '../../components/FarmProvider/FarmContext';
import useLatestLiftCore from './hooks/useLatestLiftCore';
import LatestLift from './LatestLiftCore';
import GraphLift from './GraphLiftCore';
import ControlLift from './ControlLiftCore';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import LatestLiftInfo from './LatestLiftCoreInfo';
import ConfigLift from './ConfigLiftCore';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import PhyTabPanel from '../../components/PhyTabPanel/PhyTabPanel';
import useRoute from '../../data/hooks/useRoute';
import PendingDevice from '../../components/PendingDevice/PendingDevice';

type IProps = {
  deviceId: string;
};

const SideBarEntryLift: FC<IProps> = ({ deviceId }) => {
  const { brokerId } = useContext(FarmContext);
  const [deviceState, setDeviceState] = React.useState<DatabaseDevices<LiftDeviceStateUnflattened>>();
  const router = useRoute();
  const [value, setValue] = useState<any>(Number(router.sidebarQuery.tab) || 0);

  const latestDeviceInfo = useLatestLiftCore(deviceId);

  useEffect(() => {
    if (latestDeviceInfo) {
      setDeviceState(latestDeviceInfo);
    }

  }, [latestDeviceInfo]);

  useEffect(() => {
    router.navSideQuery({ tab: value });
  }, [value, router]);

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  if (!deviceState) {
    return (
      <Box p={2}>
        <Alert severity='info'>No Latest Data for {deviceId}!</Alert>
      </Box>
    );
  }
  return (
    <>
      <AppDrawerTitle
        title={`liftcore/${deviceState.deviceId} `}
      />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor='inherit'
        variant='scrollable'
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label='Latest' value={0} />
        <Tab label='Graphs' value={1} />
        <Tab label='Control' value={2} />
        <Tab label='Config' value={3} />
        <Tab label='Activity' value={4} />
        <Tab label='Alerts' value={5} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <LatestLiftInfo deviceState={deviceState} />
        <PendingDevice  deviceState={deviceState} />
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='liftcore'
          count={1}
        />
        <LatestLift deviceState={deviceState} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <GraphLift deviceState={deviceState} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <ControlLift deviceState={deviceState} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <ConfigLift deviceState={deviceState} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <ActivityLog
          identifier={`device/lift/${deviceId}/notes`}
          heading='Activity Log'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='liftcore'
        />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryLift;
