import type { AnyObject } from '@phyllome/common';
import type { DataValue } from '../types';

export const checkEmptyData = (data: unknown) => {
  if (!data && data !== false) {
    return '(empty)';
  }

  if (typeof data !== 'object') {
    return String(data);
  }

  if (Object.keys(data as object).length === 0) {
    return '(empty)';
  }
  return null;
};

export const formatAndOrderData = (data: AnyObject) => {
  const formattedData: DataValue[] = Object.entries(data).map(([key, value]) => ({
    key,
    value,
    type: getType(key, value),
  }));

  const priorityKeys = ['brokerId', 'timestamp', 'deviceId', 'alias', 'deviceType'];

  return formattedData.sort((a, b) => {
    const indexA = priorityKeys.indexOf(a.key);
    const indexB = priorityKeys.indexOf(b.key);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    const numA = parseInt(a.key.replace(/\D/g, ''));
    const numB = parseInt(b.key.replace(/\D/g, ''));

    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB;
    }
    return a.key.localeCompare(b.key);
  });
};

export type TypeResult =
  | 'brokerId'
  | 'deviceId'
  | 'deviceType'
  | 'alias'
  | 'null'
  | 'date'
  | 'timestamp'
  | 'array'
  | 'object'
  | 'string'
  | 'number'
  | 'boolean'
  | 'undefined'
  | 'function'
  | 'symbol'
  | 'bigint';

export const getType = (key: string, value: unknown): TypeResult => {
  // Handle specific key-based types
  if (key === 'brokerId') return 'brokerId';
  if (key === 'deviceId') return 'deviceId';
  if (key === 'deviceType') return 'deviceType';
  if (key === 'alias') return 'alias';

  // Handle null values
  if (value === null) return 'null';

  // Handle Date objects
  if (value instanceof Date) return 'date';

  // Handle Firestore timestamp objects
  if (typeof value === 'object' && value !== null && 'nanoseconds' in value) return 'timestamp';

  // Handle arrays
  if (Array.isArray(value)) return 'array';

  // Handle regular objects
  if (typeof value === 'object') return 'object';

  // For primitive types, use JavaScript's typeof but ensure it matches our union type
  const jsType = typeof value;

  // TypeScript knows all possible return values from typeof
  if (jsType === 'string' ||
      jsType === 'number' ||
      jsType === 'boolean' ||
      jsType === 'undefined' ||
      jsType === 'function' ||
      jsType === 'symbol' ||
      jsType === 'bigint') {
    return jsType;
  }
  // This should never happen with proper TypeScript, but for safety:
  return 'object' as TypeResult;
};