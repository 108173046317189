import type { FC } from 'react';
import { Stepper, Step, StepLabel, Tooltip } from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import dateToString from '../../helpers/dateToString';
import { dateFormatTypes } from '../../helpers/dateToString';
import CircleIcon from '@mui/icons-material/Circle';
import { calculateTrayAgeInDays, colors } from '@phyllome/common';
import getColorByAge from '../../helpers/getColorByAge';

const TimelineConnectorDynamic: FC<{
  percentage?: number;
  paused?: boolean;
  label?: string;
  color?: string;
}> = ({
  percentage = 100,
  paused = false,
  label = '',
  color = colors.vegetative,
}) => {
  if (percentage < 30) percentage = 30;
  if (percentage > 100) percentage = 100;
  return (
    <div
      style={{
        position: 'absolute',
        width: 'calc(200% + 24px)',
        height: '20px',
        top: '1px',
        left: '41%',
        zIndex: 2,
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: percentage.toString() + '%',
          height: '20px',
          backgroundColor: paused ? colors.sunlight : color,
          top: '0px',
          left: '0px',
          borderRadius: '10px',
          textAlign: 'center',
          fontSize: '12px',
          lineHeight: '20px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {label}
      </div>
    </div>
  );
};

const TimelineConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const TimelineConnectorDouble = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% - 16px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const TimelineConnectorLinked = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colors.budding,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

interface ITimelineViewProps {
  duration?: number;
  harvested?: Date | null;
  sowed?: Date | null;
  started?: Date | null;
  ended?: Date | null;
  paused?: boolean;
}

const TimelineView: FC<ITimelineViewProps> = ({
  duration = 16,
  harvested = null,
  sowed = null,
  started = null,
  ended = null,
  paused = false,
}) => {

  const isSowed = sowed !== null;
  const isStarted = started !== null;
  const isEnded = ended !== null;
  const isHarvested = harvested !== null;
  const isPaused = paused;

  const predictedEndDate = started !== null
    ? new Date(started.getTime() + (duration * 24 * 60 * 60 * 1000))
    : null;

  const age = calculateTrayAgeInDays({
    timestampGrowthStart: started,
    timestampGrowthEnd: ended,
  });

  let percentageComplete = ended === null ? (age / duration) * 100 : 100;

  if (percentageComplete > 100) percentageComplete = 100;
  if (percentageComplete < 0) percentageComplete = 0;
  return (
    <Stepper alternativeLabel nonLinear connector={null}>
      <Step key='seeded' completed={isSowed} active={false}>
        <Tooltip
          title={
            'Seeded ' +
            dateToString(sowed, dateFormatTypes.DAYMONTHHOURSSECONDS)
          }
          arrow
        >
          <StepLabel error={false} icon={<CircleIcon color='earth1' />}>
            {isSowed
              ? dateToString(sowed, dateFormatTypes.DAYMONTH)
              : 'Seeded'}
          </StepLabel>
        </Tooltip>
      </Step>
      <Step key='started' completed={isStarted} active={false}>
        <Tooltip
          title={
            'Started ' +
            dateToString(
              started,
              dateFormatTypes.DAYMONTHHOURSSECONDS,
            )
          }
          arrow
        >
          <StepLabel error={false} icon={<CircleIcon color='fog' />}>
            {isStarted
              ? dateToString(started, dateFormatTypes.DAYMONTH)
              : 'Started'}
          </StepLabel>
        </Tooltip>
        {isStarted ? (
          <TimelineConnectorLinked key='0' />
        ) : (
          <TimelineConnector key='0' />
        )}
        {isStarted && (
          <TimelineConnectorDynamic
            percentage={percentageComplete}
            paused={isPaused}
            label={age.toString() + ' days'}
            color={getColorByAge(age)}
          />
        )}
      </Step>
      <Step key='intermediate' completed={false} active={false}>
        <TimelineConnectorDouble key='1' />
      </Step>
      <Step key='ended' completed={isEnded} active={false}>
        <Tooltip
          title={
            ended
              ? 'Ended ' +
                dateToString(
                  ended,
                  dateFormatTypes.DAYMONTHHOURSSECONDS,
                )
              : 'Predicted End Date'
          }
          arrow
        >
          <StepLabel error={false} icon={<CircleIcon color='fog' />}>
            {isEnded
              ? dateToString(ended, dateFormatTypes.DAYMONTH)
              : isStarted
                ? dateToString(predictedEndDate, dateFormatTypes.DAYMONTH)
                : 'Ended'}
          </StepLabel>
        </Tooltip>
        <TimelineConnector key='2' />
      </Step>
      <Step key='harvested' completed={isHarvested} active={false}>
        <Tooltip
          title={
            'Harvested ' +
            dateToString(
              harvested,
              dateFormatTypes.DAYMONTHHOURSSECONDS,
            )
          }
          arrow
        >
          <StepLabel
            error={false}
            icon={<CircleIcon color={isHarvested ? 'ripening' : 'fog'} />}
          >
            {isHarvested
              ? dateToString(harvested, dateFormatTypes.DAYMONTH)
              : 'Harvested'}
          </StepLabel>
        </Tooltip>
        {isHarvested ? (
          <TimelineConnectorLinked key='3' />
        ) : (
          <TimelineConnector key='3' />
        )}
      </Step>
    </Stepper>
  );
};

export default TimelineView;
