import type { DeviceType } from '@phyllome/common';
import { useEffect, useState } from 'react';
import useUserToken from './useUserToken';
import { useAppState } from '../AppState/useAppState';

type IGetDSEndPoint = {
  token: string;
  brokerId: string;
  deviceId: string;
  deviceType: DeviceType;
};

type Status = 'IDLE' | 'PENDING' | 'COMPLETE' | 'ERROR';

const getDSEndpoint = ({ brokerId, deviceId, deviceType }:IGetDSEndPoint) => {

  const req = `farm/${brokerId}/device/${deviceType}/${deviceId}`;

  return process.env.NODE_ENV === 'production'
    ? `https://websocket.phyllome.io/${req}`  // Changed to wss://
    : `http://127.0.0.1:3025/${req}`;            // Changed to ws://
};

export type SetDesiredState = ReturnType<typeof useSetDesiredState>;

const useSetDesiredState = ({ deviceId, deviceType }:{ deviceId: string, deviceType: DeviceType }) => {
  const [endpoint, setEndPoint] = useState('');
  const { state: { brokerId } } = useAppState();
  const [status, setStatus] = useState<Status>('IDLE');

  const token = useUserToken();

  useEffect(() => {
    if (!token) return;
    if (!brokerId) return;

    setEndPoint(getDSEndpoint({
      token,
      brokerId,
      deviceId,
      deviceType,
    }));
    setStatus('IDLE');
  }, [token, brokerId, deviceId, deviceType]);

  const run = async (payload: any) => {
    if (endpoint.length === 0) return;
    setStatus('PENDING');
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      setStatus('ERROR');
      throw new Error('Failed to set desired state');
    }
    setStatus('COMPLETE');
    return response.json();

  };

  return { run, status };

};

export default useSetDesiredState;