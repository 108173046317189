import { Box, LinearProgress } from '@mui/material';
import type { FC, JSX, PropsWithChildren } from 'react';
import { Suspense } from 'react';

interface IProps {
  text?: string | JSX.Element;
}

const DefaultLoading = () => {
  return (
    <Box m={0}>
      <LinearProgress sx={{ height: '20px' }}/>
      <Box m={1}>Initialising ...</Box>

    </Box>
  );
};

const Sus: FC<PropsWithChildren<IProps>> = ({
  children,
  text = <DefaultLoading />,
}) => {
  return (
    <Suspense fallback={<div>{text}</div>}>
      <>{children}</>
    </Suspense>
  );
};

export default Sus;
