import { useAtomValue } from 'jotai';
import type { DeviceType, WithId, DatabaseDevices } from '@phyllome/common';
import type { AnyObject } from 'yup';
import { useContext } from 'react';
import FarmContext from '../../components/FarmProvider/FarmContext';
import { deviceAtomFamily } from '../atoms/deviceAtomFamily';

// Hook to access an individual device
const useDeviceState = <T extends AnyObject>({
  deviceId,
  deviceType,
}: {
  deviceId: string;
  deviceType: DeviceType;
}) => {

  const { brokerId } = useContext(FarmContext);

  // Get the specific device atom
  const deviceAtom = deviceAtomFamily({ brokerId, deviceType, deviceId });

  // Subscribe only to this specific device
  return useAtomValue(deviceAtom) as WithId<DatabaseDevices<T>> | undefined;
};

export default useDeviceState;