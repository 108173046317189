import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { drawActions, useAppDrawer } from './AppDrawer/useAppDrawer';

const parseHash = (hash1: string, hash2: string): {
  name: string;
  parameter: string;
  queryParams: URLSearchParams;
} => {
  // Handle empty strings
  hash1 = hash1 || '';
  hash2 = hash2 || '';

  // Split path and query parts
  const [path1 = '', queryString1 = ''] = hash1.split('?');
  const [, queryString2 = ''] = hash2.split('?');

  // Parse path parts safely
  const pathParts1 = path1.split('/').filter(Boolean);
  const name = pathParts1[0] || '';
  const parameter = pathParts1[1] || '';

  // Create query parameters object
  const queryParams = new URLSearchParams(queryString1);

  // Add second hash query parameters
  const queryParams2 = new URLSearchParams(queryString2);

  queryParams2.forEach((value, key) => {
    // You can decide whether to replace or append
    // Currently it appends, which creates duplicate keys if they already exist
    queryParams.append(key, value);
  });
  return { name, parameter, queryParams };
};

const useSideBar = () => {
  const { dispatch } = useAppDrawer();
  const currentLocation = useLocation();
  const hash = currentLocation.hash;

  useEffect(() => {
    if (hash) {
      dispatch({
        type: drawActions.OPEN,
      });
    } else {
      dispatch({
        type: drawActions.CLOSE,
      });
    }
  }, [dispatch, hash]);

  const parsedHash = parseHash(hash, currentLocation.search);

  return { currentLocation, hash, parsedHash };

};

export default useSideBar;
