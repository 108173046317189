import { Stack } from '@mui/system';
import { SelectElement, SliderElement, TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import type { CameraDeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { mqttDefinitionCameraConfigGet, mqttDefinitionCameraConfigSet } from '@phyllome/common';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { Box } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';

const mqttDefinitionCameraConfigSetExtended = {
  ... mqttDefinitionCameraConfigSet,
  payload: {
    ...mqttDefinitionCameraConfigSet.payload,
    ir: {
      ...mqttDefinitionCameraConfigSet.payload.ir,
      gain_blue: 0,
      gain_red: 0,
    },
  },
};

const CameraResolutionOptions = [
  { id: '3280,2464', label: '3280x2464 (8MP)' },
  { id: '2560,1920', label: '2560x1920 (5MP)' },
  { id: '2048,1536', label: '2048x1536 (3MP default)' },
  { id: '1920,1080', label: '1920x1080 (2MP)' },
  { id: '1640,1232', label: '1640x1232 (2MP wide)' },
  { id: '1280,720', label: '1280x720 (1MP)' },
];

const processGainSliderValue = (value:any) => {
  if (value === 'auto') {
    return 0;
  }
  return value || 0;
};

const formatAWBGains = (red:number, blue:number): string => {
  const val = `${red || 0},${blue || 0}`;

  return (val === '0,0') ? 'auto' : val;
};

const FormCameraConfigCameraSettingsVisible = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<CameraDeviceStateUnflattened>;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;

  const controlLifecycle = useControlSend(mqttDefinitionCameraConfigSet, deviceId);
  const activityLog = useActivityLog(`device/camera/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionCameraConfigSetExtended, {
    ir: {
      flash_mode: latest?.ir?.flash_mode || 'auto',
      iso: latest?.ir?.iso || 100,
      shutter_speed: latest?.ir?.shutter_speed || 'auto',
      exposure_mode: latest?.ir?.exposure_mode || 'auto',
      awb_gains: latest?.ir?.awb_gains || 'auto',
      awb_mode: latest?.ir?.awb_mode || 'auto',
      resolution: latest?.ir?.resolution || '2048,1536',
      gain_red: processGainSliderValue(latest?.ir?.awb_gains?.split(',')[0]),
      gain_blue: processGainSliderValue(latest?.ir?.awb_gains?.split(',')[1]),
    },
  });

  const formRef = useRef<any>(null);
  const [awbGain, setAwbGain] = useState('auto');

  useEffect(() => {
    const context = formRef?.current.context;

    if (!context) return;

    context.watch((formData:any) => {
      setAwbGain(formatAWBGains(formData.gain_red, formData.gain_blue));
    });
  }, [formRef?.current?.context, defaultValues]);

  useEffect(() => {
    if (formRef?.current) {
      formRef.current.context.setValue('ir.awb_gains', awbGain);
    }
  }
  , [awbGain]);
  return (
    <PaperSection
      heading='IR Camera Settings'
      mb={2}
      subheading={mqttDefinitionCameraConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='camera'
        definition={mqttDefinitionCameraConfigGet}
        flash_mode={latest.ir?.flash_mode}
        iso={latest.ir?.iso}
        shutter_speed={latest.ir?.shutter_speed}
        exposure_mode={latest.ir?.exposure_mode}
        awb_gains={latest.ir?.awb_gains}
        awb_mode={latest.ir?.awb_mode}
        resolution={latest.ir?.resolution}
      />
      <PhormContainer
        ref={formRef}
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              ir: {
                flash_mode: data?.ir.flash_mode,
                iso: data?.ir.iso,
                shutter_speed: data?.ir.shutter_speed,
                exposure_mode: data?.ir.exposure_mode,
                awb_gains: data?.ir.awb_gains,
                awb_mode: data?.ir.awb_mode,
                resolution: data?.ir.resolution,
              },
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add('Updated IR camera settings', 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
        })}
      >
        <Stack spacing={2}>
          <SelectElement
            name='ir.resolution'
            label='Resolution'
            options={CameraResolutionOptions}
          />
          <SelectElement
            name='ir.flash_mode'
            label='Flash Mode'
            options={[
              { id: 'off', label: 'Off' },
              { id: 'auto', label: 'Auto' },
              { id: 'on', label: 'On' },
              { id: 'redeye', label: 'Redeye' },
              { id: 'fillin', label: 'Fillin' },
              { id: 'torch', label: 'Torch' },
            ]}
          />
          <Box pl={2} pr={2}>
            <SliderElement
              label='ISO'
              marks
              max={800}
              min={100}
              step={100}
              name='ir.iso'
            />
          </Box>
          <TextFieldElement
            name='ir.shutter_speed'
            label='Shutter Speed'
            type='text'
          />
          <SelectElement
            name='ir.exposure_mode'
            label='Exposure Mode'
            options={[
              { id: 'off', label: 'Off' },
              { id: 'auto', label: 'Auto' },
              { id: 'night', label: 'Night' },
              { id: 'nightpreview', label: 'Night Preview' },
              { id: 'backlight', label: 'Backlight' },
              { id: 'spotlight', label: 'Spotlight' },
              { id: 'sports', label: 'Sports' },
              { id: 'snow', label: 'Snow' },
              { id: 'beach', label: 'Beach' },
              { id: 'verylong', label: 'Very Long' },
              { id: 'fixedfps', label: 'Fixed FPS' },
              { id: 'antishake', label: 'Anti Shake' },
              { id: 'fireworks', label: 'Fireworks' },
            ]}
          />
          <SelectElement
            name='ir.awb_mode'
            label='AWB Mode'
            options={[
              { id: 'off', label: 'Off' },
              { id: 'auto', label: 'Auto' },
              { id: 'sunlight', label: 'Sunlight' },
              { id: 'cloudy', label: 'Cloudy' },
              { id: 'shade', label: 'Shade' },
              { id: 'tungsten', label: 'Tungsten' },
              { id: 'fluorescent', label: 'Fluorescent' },
              { id: 'incandescent', label: 'Incandescent' },
              { id: 'flash', label: 'Flash' },
              { id: 'horizon', label: 'Horizon' },
            ]}
          />
          <TextFieldElement
            name='ir.awb_gains'
            label='AWB Gains'
            inputProps={{ readOnly: true }}
          />
          <Box pl={2} pr={2}>
            <SliderElement
              label='AWB gain - RED'
              marks
              max={3}
              min={0}
              step={.1}
              name='gain_red'
            />
          </Box>
          <Box pl={2} pr={2}>
            <SliderElement
              label='AWB gain - BLUE'
              marks
              max={3}
              min={0}
              step={.1}
              name='gain_blue'
            />
          </Box>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormCameraConfigCameraSettingsVisible;
