// Helper function to set a nested property path
export function setValueByPath<T extends Record<string, any>, V>(
  obj: T,
  path: string,
  value: V,
): T {
  const keys = path.split('/');
  let current: any = obj;

  // Navigate to the parent object of the property to set
  for (let i = 0; i < keys.length - 1; i++) {
    // Create the property if it doesn't exist
    if (!current[keys[i]]) {
      current[keys[i]] = {};
    }
    current = current[keys[i]];
  }

  // Set the value at the final key
  current[keys[keys.length - 1]] = value;
  return obj;
}