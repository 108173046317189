import type {  DatabaseDevices, LightingCoreDeviceStateUnflattened } from '@phyllome/common';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import FormLightingCoreConfigSet from './forms/FormLightingCoreConfigSet';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import { useMemo } from 'react';

const ConfigZoneCore = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LightingCoreDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;

  return (
    <>
      <PaperSection heading='Lighting Controller Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceId}</LabelValue>
        <LabelValue label='Firmware'>{latest.firmware}</LabelValue>
        <LabelValue label='Build date'>{latest.compiled}</LabelValue>
        <LabelValue label='Network IP'>{latest.network.ip}</LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormLightingCoreConfigSet deviceState={deviceState} />
      <GenericConfigGet
        deviceId={deviceId}
        deviceType='lightingcore'
      />
    </>
  );
};

export default ConfigZoneCore;
