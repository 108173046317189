import type { DatabaseSeason, WithId } from '@phyllome/common';
import useFetch from '../../../../data/hooks/useFetch';

const toDate = (value: any): Date | null => {
  if (!value) return null;
  // If it's already a Date object, return it as is
  if (value instanceof Date) return value;
  // Otherwise create a new Date
  return new Date(value);
};

export const normaliseSeasonData = (data: WithId<DatabaseSeason>):WithId<DatabaseSeason> => {
  return {
    ...data,
    created: toDate(data.created) || new Date(), // Fallback for created
    growthStarted: toDate(data.growthStarted),
    growthEnded: toDate(data.growthEnded),
    traySeedEarliest: toDate(data.traySeedEarliest),
    traySeedLatest: toDate(data.traySeedLatest),
    trayHarvestEarliest: toDate(data.trayHarvestEarliest),
    trayHarvestLatest: toDate(data.trayHarvestLatest),
  };
};

const useSeason = (
  seasonId?: string,
) => {
  const fetch = useFetch<WithId<DatabaseSeason>>(`/farm/:brokerId/season/${seasonId}`, {
    enabled: !!seasonId,
    default: undefined,
    interval: 5000,
  });

  if (!fetch.data) {
    return undefined;
  }

  const unhydrated = normaliseSeasonData(fetch.data);

  return unhydrated;
};

export default useSeason;
