import type { DatabaseDevices, PackagerDeviceStateUnflattened } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import { formatSeconds } from '../../helpers/formatSeconds';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import { useMemo } from 'react';

const ConfigPackager = ({

  deviceState,
}: {
  deviceState: DatabaseDevices<PackagerDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;

  return (
    <>
      <PaperSection heading='Packager Device Configuration' mb={2}>
        <LabelValue label='DeviceId'>{deviceId}</LabelValue>
        <LabelValue label='Firmware'>{latest.firmware}</LabelValue>
        <LabelValue label='Build date'>TBD</LabelValue>
        <LabelValue label='Network'>
          {latest.wifi.ip} ({latest.wifi.rssi}dB)
        </LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <GenericConfigGet
        deviceId={deviceId}
        deviceType='packager'
      />
    </>
  );
};

export default ConfigPackager;
