import { Alert, AlertTitle, Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';

interface SafeDeleteProps {
  deleteButtonText: string;
  actionText?: string;
  onComplete: () => void;
}

const TOTAL_NUMBERS = 6;

const getRandomizedArray = () => {
  const array = Array.from({ length: TOTAL_NUMBERS }, (_, i) => i + 1);

  // Fisher-Yates shuffle algorithm
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// Forces a new line in the 3x3 grid
const CR = ({ shouldBreak }: { shouldBreak: boolean }) => {
  return shouldBreak ? <div /> : null;
};

const SafeDelete = ({ deleteButtonText, actionText, onComplete }: SafeDeleteProps) => {
  const [buttonIds, setButtonIds] = useState<number[]>([]);
  const [currentNumber, setCurrentNumber] = useState<number>(0);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  useEffect(() => {
    setButtonIds(getRandomizedArray());
  }, []);

  const handleNumberClick = (clickedNumber: number) => {
    if (clickedNumber === currentNumber + 1) {
      setCurrentNumber(clickedNumber);
    } else {
      setCurrentNumber(0);
    }
  };

  return (

    <>
      <Alert severity='warning'>
        {actionText && <AlertTitle>{actionText}</AlertTitle>}
        <Box>To ensure this is not an accident, click each number in numerical order.</Box>
        {currentNumber !== TOTAL_NUMBERS && <>

          {buttonIds.map((number, index) => {
            const isNextNumber = currentNumber === number - 1;
            const shouldBreak = (index + 1) % 3 === 0;

            return (
              <>
                <Button
                  key={number}
                  sx={{ margin: '2px', color: 'white' }}
                  color={isNextNumber ? 'vegetative' : 'budding'}
                  variant='contained'
                  size='small'
                  onClick={() => handleNumberClick(number)}
                >
                  {number}
                </Button>
                <CR shouldBreak={shouldBreak} />
              </>
            );
          })}
        </>
        }
        <>
          {currentNumber === TOTAL_NUMBERS && (
            <Button
              disabled={isComplete}
              color='secondary'
              variant='contained'
              sx={{ margin: '8px 2px 2px 2px' }}
              onClick={() => {
                setIsComplete(true);
                onComplete();
              }}
            >
              {deleteButtonText}
            </Button>
          )}
        </>
      </Alert>
    </>
  );
};

export default SafeDelete;