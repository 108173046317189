import type {
  DatabaseDevices,
  IrrigationDeviceStateUnflattened,
  FertigatorDeviceStateUnflattened,
  GuardDeviceStateUnflattened,
  LiftDeviceStateUnflattened,
  AirDeviceStateUnflattened,
  LightingCoreDeviceStateUnflattened,
  TraybotDeviceStateUnflattened,
} from '@phyllome/common';

export function getDeviceErrorState(
  deviceData: DatabaseDevices,
): boolean {
  switch (deviceData.deviceType) {
    case 'air': {
      const _deviceData = deviceData.properties as unknown as AirDeviceStateUnflattened;

      if (_deviceData.co2 === undefined) return false;
      return _deviceData.co2 > 1200;
    }

    case 'fertigator': {
      const _deviceData =
        deviceData.properties as unknown as FertigatorDeviceStateUnflattened;

      return _deviceData.sample.ec > 2.5 || _deviceData.sample.ph < 5;
    }

    case 'guard': {
      const _deviceData = deviceData.properties as unknown as GuardDeviceStateUnflattened;

      return _deviceData.alarm;
    }

    case 'irrigation': {
      const _deviceData =
        deviceData.properties as unknown as IrrigationDeviceStateUnflattened;

      const faults = _deviceData.faults === undefined || _deviceData.faults > 0;

      const levelsSupply = _deviceData.levels.supply
        ? _deviceData.levels.supply <= _deviceData.supply?.min
        : false;
      const pressures_return = _deviceData.pressures.return
        ? _deviceData.pressures.return > _deviceData.pressure?.max
        : false;

      return faults || levelsSupply || pressures_return;
    }

    case 'lift': {
      const _deviceData = deviceData.properties as unknown as LiftDeviceStateUnflattened;

      return _deviceData.inhibit || _deviceData.shelf === -1;
    }

    case 'liftcore': {
      const _deviceData = deviceData.properties as unknown as LiftDeviceStateUnflattened;

      return _deviceData.inhibit || _deviceData.shelf === -1;
    }

    case 'conveyor': {
      const _deviceData = deviceData.properties as unknown as LiftDeviceStateUnflattened;

      return _deviceData.inhibit;
    }

    case 'traybot': {
      const _deviceData =
        deviceData.properties as unknown as TraybotDeviceStateUnflattened;

      return _deviceData.battery < 20 || _deviceData.inhibit === true;
    }

    case 'lightingcore': {
      const _deviceData =
        deviceData.properties as unknown as LightingCoreDeviceStateUnflattened;

      return _deviceData.faults > 0 || _deviceData.mode === 0;
    }

    case 'lighting': {
      // const _deviceData = deviceData.properties as DatabaseZoneLatest;
      return false;
    }

    default:
      return false;
  }
}

export default getDeviceErrorState;
