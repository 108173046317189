import type { AnyObject } from '@phyllome/common';
import type { FC } from 'react';

type ShowDevicePropsProps = {
  data: AnyObject;
  primaryColor?: string;
  secondaryColor?: string;
};

const ShowDeviceProps: FC<ShowDevicePropsProps> = ({ data, primaryColor = 'white', secondaryColor = '#f5c02f' }) => {

  const MorePretty = Object.keys(data).map((key) => {
    return <div style={{  padding: '1px', color: primaryColor }} key={key}>
      <strong style={{ color: secondaryColor }}>{key}</strong>: {JSON.stringify(data[key])}
    </div>;
  });

  return <div>{MorePretty}</div>;

};

export default ShowDeviceProps;