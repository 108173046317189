import GenericPing from '../Generic/control/GenericPing';
import LatestLiftInfo from './LatestConveyorInfo';
import type { DatabaseDevices, ConveyorDeviceStateUnflattened } from '@phyllome/common';

const ControlConveyor = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<ConveyorDeviceStateUnflattened>;
}) => {
  const { deviceId } = deviceState;

  return (
    <>
      <LatestLiftInfo deviceState={deviceState} />
      <div>
        <GenericPing
          deviceId={deviceId}
          deviceType='conveyor'
        />
      </div>
    </>
  );
};

export default ControlConveyor;
