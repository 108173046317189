import { useMemo } from 'react';
import ObjectToTable from '../../components/ObjectToTable/ObjectToTable';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { DatabaseDevices, LightingDeviceStateUnflattened } from '@phyllome/common';

const LatestLighting = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LightingDeviceStateUnflattened>;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  return (
    <PaperSection
      heading='Latest Lighting Zone Data'
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ObjectToTable data={deviceState as any} />
    </PaperSection>
  );
};

export default LatestLighting;
