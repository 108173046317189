import { useEffect, useState, type FC } from 'react';
import { useUser } from 'reactfire';
import ContainerPrimary from '../../components/containers/ContainerPrimary/ContainerPrimary';
import {
  Box,
  ListItemAvatar,
  List,
  Paper,
  Typography,
  Avatar,
  ListItemText,
  ListItemButton,
  FormControl,
  Select,
  MenuItem,
  Alert,
} from '@mui/material';
import Logo from '../../components/Logo/Logo';
import { useNavigate } from 'react-router-dom';
import useFarms from '../../data/hooks/collections/useFarms';
import BusinessIcon from '@mui/icons-material/Business';
import type { Farm, MCPConfig } from '@phyllome/common';
import IsDeveloper from '../../components/IsDeveloper/IsDeveloper';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import ObjectToTable from '../../components/ObjectToTable/ObjectToTable';
import { useAppState } from '../../data/AppState/useAppState';
import { howLongAgoInEnglish } from '../../helpers/FStimeAgo';

const Home: FC<unknown> = () => {
  const navigate = useNavigate();
  const { data: user } = useUser();
  const farmList = useFarms();
  const [selectedFarm, setSelectedFarm] = useState<string>('mcp-alexandria');
  const [selectedFarmData, setSelectedFarmData] = useState<MCPConfig>();
  const { state } = useAppState();

  useEffect(() => {
    setSelectedFarmData(farmList.find((farm: Farm) => farm.brokerId === selectedFarm));
  }, [farmList, selectedFarm]);
  return (
    <ContainerPrimary title='Phyllome Console'>

      <Box
        width='300px'
        mb={2}
      >
        <Logo />
      </Box>
      <Box mb={2}>
        <Paper elevation={0}>
          <Alert>

            You are logged in as <strong>{user?.displayName}</strong> ({user?.email}).<br/>
            This version of the console was last updated <strong>{howLongAgoInEnglish(new Date(Number(state.version)))}</strong> ago.

          </Alert>
        </Paper>
      </Box>
      <Box mb={2}>
        <Paper elevation={0}>
          <Box p={2}>
            <Typography variant='h5'>Farms</Typography>
            <List>
              {farmList.map((item: Farm, key: number) => {
                return (
                  <ListItemButton
                    key={key}
                    onClick={() => navigate(`/broker/${item.brokerId}`)}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <BusinessIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.name}
                      secondary={item.address}
                    />
                  </ListItemButton>
                );
              })}
            </List>
          </Box>
        </Paper>
        <IsDeveloper>
          <PaperSection heading='Configurations'>
            <FormControl fullWidth>
              <Select
                defaultValue='mcp-alexandria'
                onChange={(v) => setSelectedFarm(v.target.value)}
              >

                {farmList.map((item: Farm, key: number) => {
                  return  <MenuItem key={key} value={item.brokerId}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <Box sx={{ pt: 2 }}>
              <LabelValue label='brokerId'>{selectedFarmData?.brokerId}</LabelValue>
              <LabelValue label='address'>{selectedFarmData?.address}</LabelValue>
              <LabelValue label='versions'><ObjectToTable data={selectedFarmData?.versions as any}/></LabelValue>
              <LabelValue label='liftGroups'><ObjectToTable data={selectedFarmData?.liftGroups as any}/></LabelValue>

            </Box>

          </PaperSection>
        </IsDeveloper>
      </Box>

    </ContainerPrimary>

  );
};

export default Home;
