import { Tabs, Tab, Box, Alert } from '@mui/material';
import { colors } from '@phyllome/common';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import ControlAir from './ControlAir';
import ConfigAir from './ConfigAir';
import GraphAir from './GraphAir';
import useLatestAir from './hooks/useLatestAir';
import LatestAir from './LatestAir';
import FarmContext from '../../components/FarmProvider/FarmContext';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import LatestAirInfo from './LatestAirInfo';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import AnalysisAir from './AnalysisAir';
import PhyTabPanel from '../../components/PhyTabPanel/PhyTabPanel';
import useRoute from '../../data/hooks/useRoute';
import PendingDevice from '../../components/PendingDevice/PendingDevice';

type IProps = {
  deviceId: string;
};

const SideBarEntryAir: FC<IProps> = ({ deviceId }) => {
  const { brokerId } = useContext(FarmContext);
  const router = useRoute();
  const [value, setValue] = useState<any>(Number(router.sidebarQuery.tab) || 0);

  const latestDeviceInfo = useLatestAir(deviceId);
  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    router.navSideQuery({ tab: value });
  }, [value, router]);

  if (!latestDeviceInfo)
    return (
      <Box p={2}>
        <Alert severity='info'>No Latest Data!</Alert>
      </Box>
    );

  const title = latestDeviceInfo.properties.label
    ? `air/${deviceId} (${latestDeviceInfo.properties.label})`
    : `air/${deviceId}`;

  return (
    <>
      <AppDrawerTitle title={title} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
          padding: '0px',
        }}
        textColor='inherit'
        variant='scrollable'
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label='Latest' value={0} />
        <Tab label='Graphs' value={1} />
        <Tab label='Control' value={2} />
        <Tab label='Config' value={3} />
        <Tab label='Activity' value={4} />
        <Tab label='Alerts' value={5} />
        <Tab label='Analysis' value={6} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <LatestAirInfo deviceState={latestDeviceInfo} />
        <PendingDevice deviceState={latestDeviceInfo} />
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='air'
          count={1}
        />
        <LatestAir deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <GraphAir deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <ControlAir deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <ConfigAir deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <ActivityLog
          identifier={`device/air/${deviceId}/notes`}
          heading='Activity Log'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='air'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={6}>
        <AnalysisAir deviceState={latestDeviceInfo} />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryAir;
