import type { Dispatch, FC, Reducer } from 'react';
import { createContext, useReducer, useMemo } from 'react';
import * as React from 'react';
import type { NDSAllFetch } from '../../../devicespecific/NDS/hooks/useNDSLatestByBrokerId';

// Original context type
type SprinkerContextType = {
  layers: {
    primaryLayer:
      | 'SIMPLE'
      | 'FIRMWARE'
      | 'VALVES'
      | 'WATERRATE'
      | 'CURRENTDAY';
    secondaryLayer: string[];
  };

};

const initialValues: SprinkerContextType = {

  layers: {
    primaryLayer: 'SIMPLE',
    secondaryLayer: [],
  },

};

type SprinklerContextState = {
  state: SprinkerContextType;
  dispatch: Dispatch<SprinklerContextAction>;

};

type SprinklerContextAction =
  | {
    type: 'SET_PRIMARY_LAYER';
    payload:
        | 'SIMPLE'
        | 'FIRMWARE'
        | 'VALVES'
        | 'WATERRATE'
        | 'CURRENTDAY'

  }

  | { type: 'SET_SECONDARY_LAYER'; payload: string[] }
  // New actions for individual NDS management
  | { type: 'UPDATE_NDS_DEVICE'; payload: NDSAllFetch }
  | { type: 'SET_ALL_NDS'; payload: NDSAllFetch[] };

const sprinklerContextReducer: Reducer<
  SprinkerContextType,
  SprinklerContextAction
> = (_state, action) => {
  switch (action.type) {
    case 'SET_PRIMARY_LAYER': {
      return {
        ..._state,
        layers: {
          ..._state.layers,
          primaryLayer: action.payload,
        },
      };
    }
    case 'SET_SECONDARY_LAYER': {
      return {
        ..._state,
        layers: {
          ..._state.layers,
          secondaryLayer: action.payload,
        },
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};

// Create a default context with empty implementations
export const SprinklerContext = createContext<SprinklerContextState>({
  state: initialValues,
  dispatch: () => null,
});

export const SprinklerProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {

  const [state, dispatch] = useReducer(
    sprinklerContextReducer,
    initialValues,
  );

  // Create a memoized context value to prevent unnecessary renders
  const contextValue = useMemo(() => ({
    state,
    dispatch,
  }), [state]);

  return (
    <SprinklerContext.Provider value={contextValue}>
      {children}
    </SprinklerContext.Provider>
  );
};
