import type { AirDeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import { formatBytes } from '../../helpers/formatBytes';
import FormAirConfigSetLabel from './forms/FormAirConfigSetLabel';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import FormAirConfigSetTemperatureOffset from './forms/FormAirConfigSetTemperatureOffset';
import FormAirConfigSetHumidityOffset from './forms/FormAirConfigSetHumidityOffset';
import { useMemo } from 'react';

const ConfigAir = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<AirDeviceStateUnflattened>;
}) => {
  const { deviceId } = deviceState;
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  return (
    <>
      <PaperSection heading='Air Monitor Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceId}</LabelValue>
        <LabelValue label='Label'>{latest.label}</LabelValue>
        <LabelValue label='Firmware'>{latest.firmware}</LabelValue>
        <LabelValue label='Build date'>{latest.compiled}</LabelValue>
        <LabelValue label='Network IP'>
          {latest.wifi.ip} ({latest.wifi.rssi}dB)
        </LabelValue>
        <LabelValue label='Free memory'>
          {formatBytes(latest.freememory)}
        </LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormAirConfigSetLabel deviceState={deviceState} />
      <FormAirConfigSetTemperatureOffset deviceState={deviceState} />
      <FormAirConfigSetHumidityOffset deviceState={deviceState} />
      <GenericConfigGet
        deviceId={deviceId}
        deviceType='air'
      />
    </>
  );
};

export default ConfigAir;
