import ModalCustom from '../../../../components/ModalCustom/ModalCustom';
import { useContext } from 'react';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import Phorm from '../../../../components/Form/Components/Phorm';
import PhormBlock from '../../../../components/Form/Components/PhormBlock';
import PhormDateTime from '../../../../components/Form/Components/PhormDateTime';
import PhormErrors from '../../../../components/Form/Components/PhormErrors';
import PhormSubmit from '../../../../components/Form/PhormSubmit/PhormSubmit';
import * as yup from 'yup';
import type { Coordinate } from '../../../../components/factoryui/useRackSelect';
import usePost from '../../../../data/hooks/usePost';

type ModalAddSeasonProps = {
  trays: Coordinate[];
  columnId: number;
  isOpened: boolean;
  seasonId: string;
  onClose: () => void;
};

const schema = yup.object({});
const ModalAddSeason = ({
  isOpened,
  trays,
  columnId,
  seasonId,
  onClose,
}: ModalAddSeasonProps) => {
  const farmData = useContext(FarmContext);

  //const traySeasonAddTrays = useAction('traySeasonAddTrays');
  const traySeasonAddTrays = usePost(`farm/:brokerId/trayseasons/${seasonId}`);

  return (
    <ModalCustom
      title={`Add ${trays.length} Trays in Column ${columnId}`}
      isOpened={isOpened}
      onClose={onClose}
    >
      <Phorm
        defaultValues={{
          timestampSeeded: new Date(),
        }}
        onSubmit={(values: any) => {
          traySeasonAddTrays
            .run({
              brokerId: farmData.brokerId,
              seasonId: seasonId,
              trays: trays.map((tray) => {
                return {
                  timestampSeeded: values.timestampSeeded,
                  locationColumn: Number(columnId),
                  locationRow: tray.x,
                  locationShelf: tray.y,
                  trayId: `C${columnId}S${tray.y}R${tray.x}`,
                };
              }),
            })
            .then(() => {
              onClose();
            });
        }}
        busy={traySeasonAddTrays.status === 'pending'}
        schema={schema}
      >
        <PhormBlock>
          <PhormDateTime name='timestampSeeded' label='Time Seeded' />
        </PhormBlock>
        <PhormErrors />
        <PhormSubmit busy={traySeasonAddTrays.status === 'pending'}>
          Submit
        </PhormSubmit>
      </Phorm>
    </ModalCustom>
  );
};

export default ModalAddSeason;
