import type {
  DatabaseSeason,
  DatabaseSeed,
  LightingDeviceStateUnflattened,
  WithId,
} from '@phyllome/common';
import type { FromFirebase } from '../../../../types/types';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import ScheduleViewLighting from '../../../../components/ScheduleView/ScheduleViewLighting';
import ScheduleTableLighting from '../../../../components/ScheduleTable/ScheduleTableLighting';

const SideBarTraySeasonLightingSchedule = ({
  seasonData,
  seedData,
  zoneData,
}: {
  seasonData: WithId<DatabaseSeason>;
  seedData: FromFirebase<DatabaseSeed>;
  zoneData: LightingDeviceStateUnflattened;
}) => {
  return (
    <PaperSection
      heading='Lighting Schedule'
      mb={2}
      hidden={seasonData?.state === 'completed'}
    >
      <ScheduleViewLighting
        seedSchedule={seedData?.defaultLightingSchedule}
        seasonSchedule={seasonData?.defaultLightingSchedule}
        zoneSchedule={zoneData?.schedule}
        context='zone'
      />
      <ScheduleTableLighting scheduleDataDB={zoneData?.schedule || []} />
    </PaperSection>
  );
};

export default SideBarTraySeasonLightingSchedule;
