import type { TraybotDeviceStateUnflattened } from '@phyllome/common';
import { MQTTPayloadTraybotState } from '@phyllome/common';
import type { FC } from 'react';
import Crop169Icon from '@mui/icons-material/Crop169';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Tooltip } from '@mui/material';
import { useCurrentSidebarDeviceData } from '../../../data/hooks/useCurrentSidebarDeviceData';

type ITraybotBlockProps = {
  row: number;
};

const TraybotBlock: FC<ITraybotBlockProps> = ({ row }) => {

  const { properties } = useCurrentSidebarDeviceData<TraybotDeviceStateUnflattened>();

  const location = Number(properties.location.row);
  const destination = Number(properties.destination.row);
  const moving = properties.state === MQTTPayloadTraybotState.MOVING;

  if (row === location)
    return (
      <Tooltip title='Current Location' arrow sx={{ cursor: 'pointer' }}>
        <Crop169Icon fontSize='large' color='night' />
      </Tooltip>
    );
  else if (row === destination)
    return (
      <Tooltip title='Destination' arrow sx={{ cursor: 'pointer' }}>
        <Crop169Icon fontSize='large' color='budding' />
      </Tooltip>
    );
  else if (moving && location < row && row < destination)
    return (
      <Tooltip title={row} arrow sx={{ cursor: 'pointer' }}>
        <ArrowRightIcon fontSize='large' color='seedling' />
      </Tooltip>
    );
  else if (moving && location > row && row > destination)
    return (
      <Tooltip title={row} arrow sx={{ cursor: 'pointer' }}>
        <ArrowLeftIcon fontSize='large' color='seedling' />
      </Tooltip>
    );
  else if (row === 0)
    return (
      <Tooltip title='Lift' arrow sx={{ cursor: 'pointer' }}>
        <Crop169Icon fontSize='large' color='sunlight' />
      </Tooltip>
    );
  else
    return (
      <Tooltip title={row} arrow sx={{ cursor: 'pointer' }}>
        <Crop169Icon fontSize='large' color='fog' />
      </Tooltip>
    );
};

export default TraybotBlock;