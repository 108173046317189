import type { DatabaseTraySeason, WithId } from '@phyllome/common';
import useFetch from '../useFetch';

export const normaliseTraySeasonData = (data: WithId<DatabaseTraySeason>) => {
  const ret = {
    ...data,
    mostRecentIrrigationTimeStamp: data.mostRecentIrrigationTimeStamp ? new Date(data.mostRecentIrrigationTimeStamp) : undefined,
    timestampSeeded: data.timestampSeeded ? new Date(data.timestampSeeded) : undefined,
    timestampGrowthStart: data.timestampGrowthStart ? new Date(data.timestampGrowthStart) : undefined,
    timestampGrowthEnd: data.timestampGrowthEnd ? new Date(data.timestampGrowthEnd) : undefined,
    timestampHarvested: data.timestampHarvested ? new Date(data.timestampHarvested) : undefined,
    timestampRejected: data.timestampRejected ? new Date(data.timestampRejected) : undefined,
  };

  return ret as WithId<DatabaseTraySeason>;
};

const useTraySeason = (id: string) => {
  const fetch = useFetch<WithId<DatabaseTraySeason>>(`/farm/:brokerId/trayseason/${id}`, {
    default: undefined,
    interval: 10000,
  });

  if (fetch.isPending) return undefined;
  const ret = normaliseTraySeasonData(fetch.data);

  return ret;
};

export default useTraySeason;
