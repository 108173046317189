import type { FC } from 'react';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import {
  Box,
  Card,
  ImageListItem,
  ImageListItemBar,
  Stack,
  styled,
  Typography,
  Modal,
  IconButton,
  Button,
  Link,
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import useSeasonPhotos from './Hooks/useSeasonPhotos';
import { useNavigate, useParams } from 'react-router-dom';
import useSeason from '../Seasons/hooks/useSeason';
import { useState, useCallback, useContext } from 'react';
import { colors } from '@phyllome/common';
import FarmContext from '../../../components/FarmProvider/FarmContext';

const ImageSize = 120;

const Satellite: FC<unknown> = () => {
  const { seasonId } = useParams();
  const season = useSeason(seasonId);
  const photos = useSeasonPhotos({ seasonId: seasonId?.toString() || '', type: 'visible' });
  const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const nav = useNavigate();
  const { brokerId } = useContext(FarmContext);

  const groupedByColumn = photos.reduce((acc, photo) => {
    const column = photo.location.column;

    if (!acc[column]) {
      acc[column] = [];
    }
    if (photo.timestamp) {
      acc[column].push(photo as Photo);
    }
    return acc;
  }, {} as Record<number, Photo[]>);

  const maxRow = Math.max(...photos.map(photo => photo.location.row));

  const processedColumns = Object.entries(groupedByColumn).map(([column, columnPhotos]) => {
    const sortedPhotos = Array(maxRow).fill(null);

    columnPhotos.forEach(photo => {
      sortedPhotos[photo.location.row - 1] = photo;
    });
    return {
      column: parseInt(column),
      photos: sortedPhotos,
    };
  });

  const sortedColumns = processedColumns.sort((a, b) => a.column - b.column);

  // Function to find the next available photo
  const findNextPhoto = useCallback((currentPhoto: Photo): Photo | null => {
    const currentColumn = sortedColumns.find(col => col.column === currentPhoto.location.column);

    if (!currentColumn) return null;

    // Try to find next photo in the same column
    const nextRowIndex = currentPhoto.location.row;

    if (nextRowIndex < currentColumn.photos.length) {
      const nextPhoto = currentColumn.photos[nextRowIndex];

      if (nextPhoto) return nextPhoto;
    }

    // If no photo in next row, try next column
    const nextColumnIndex = sortedColumns.findIndex(col => col.column === currentPhoto.location.column) + 1;

    if (nextColumnIndex < sortedColumns.length) {
      const nextColumn = sortedColumns[nextColumnIndex];

      return nextColumn.photos.find(photo => photo !== null) || null;
    }
    return null;
  }, [sortedColumns]);

  // Function to find the previous photo
  const findPrevPhoto = useCallback((currentPhoto: Photo): Photo | null => {
    const currentColumn = sortedColumns.find(col => col.column === currentPhoto.location.column);

    if (!currentColumn) return null;

    // Try to find previous photo in the same column
    const prevRowIndex = currentPhoto.location.row - 2;

    if (prevRowIndex >= 0) {
      const prevPhoto = currentColumn.photos[prevRowIndex];

      if (prevPhoto) return prevPhoto;
    }

    // If no photo in previous row, try previous column
    const prevColumnIndex = sortedColumns.findIndex(col => col.column === currentPhoto.location.column) - 1;

    if (prevColumnIndex >= 0) {
      const prevColumn = sortedColumns[prevColumnIndex];
      const lastPhoto = [...prevColumn.photos].reverse().find(photo => photo !== null);

      return lastPhoto || null;
    }
    return null;
  }, [sortedColumns]);

  const handleNext = () => {
    if (selectedPhoto) {
      const nextPhoto = findNextPhoto(selectedPhoto);

      if (nextPhoto) {
        setSelectedPhoto(nextPhoto);
      }
    }
  };

  const handlePrev = () => {
    if (selectedPhoto) {
      const prevPhoto = findPrevPhoto(selectedPhoto);

      if (prevPhoto) {
        setSelectedPhoto(prevPhoto);
      }
    }
  };

  const handleClick = (photo: Photo) => {
    setSelectedPhoto(photo);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedPhoto(null);
  };

  return (
    <ContainerPrimary title='Satellite View' icon={<CameraAltIcon />}>
      <Box pb={2}>
        <h2>{season?.name}</h2>
        <Button
          component={Link}
          onClick={() => nav(`/broker/${brokerId}/seasons/#/season/${seasonId}`)}
          variant='outlined'
          color='primary'
          sx={{ mb: 2, mt: 2 }}
        >
          Return to Season
        </Button>
        <Stack spacing={2}>
          {sortedColumns.map(({ column, photos }) => (
            <Box key={column}>
              <Typography variant='subtitle1' sx={{ mb: 1 }}>
                Column {column}
              </Typography>
              <Box>
                <Box sx={{ overflowX: 'scroll', display: 'flex', gap: 1, minWidth: 'min-content' }}>
                  {photos.map((photo, index) => (
                    <Box key={index} sx={{ flexShrink: 0, position: 'relative' }}>
                      {photo ? (
                        <Card
                          sx={{ width: ImageSize, cursor: 'pointer' }}
                          onClick={() => handleClick(photo)}
                        >
                          <ImageListItem>
                            <RotatedImageContainer>
                              <RotatedImage
                                src={photo.url}
                                alt={`Row ${index + 1}, Column ${column}`}
                                loading='lazy'
                              />
                            </RotatedImageContainer>
                            <ImageListItemBar
                              subtitle={`R${index + 1}`}
                              sx={{
                                '& .MuiImageListItemBar-subtitle': {
                                  fontSize: '0.6rem',
                                  textAlign: 'center',
                                  padding: '0px 0',
                                },
                              }}
                            />
                          </ImageListItem>
                        </Card>
                      ) : (
                        <Card sx={{ width: ImageSize }}>
                          <PlaceholderBox>
                            <Typography variant='caption'>
                              Row {index + 1}
                            </Typography>
                          </PlaceholderBox>
                        </Card>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            maxWidth: '90vw',
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            outline: 'none',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          {selectedPhoto && (
            <>
              <Box sx={{ position: 'relative' }}>
                <img
                  src={selectedPhoto.url}
                  alt={`Full size Row ${selectedPhoto.location.row}, Column ${selectedPhoto.location.column}`}
                  style={{
                    maxWidth: '100%',
                    maxHeight: `calc(90vh - ${ImageSize}px)`,
                    objectFit: 'contain',
                  }}
                />
                <IconButton
                  onClick={handlePrev}
                  disabled={!findPrevPhoto(selectedPhoto)}
                  sx={{
                    position: 'absolute',
                    left: -30,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    bgcolor: 'background.paper',
                    '&:hover': { bgcolor: colors.sunlight },
                  }}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton
                  onClick={handleNext}
                  disabled={!findNextPhoto(selectedPhoto)}
                  sx={{
                    position: 'absolute',
                    right: -30,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    bgcolor: 'background.paper',
                    '&:hover': { bgcolor: colors.sunlight },
                  }}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography>Surface Temperature: {selectedPhoto.temperature.surface.toFixed(1)}°C Ambient Temperature: {selectedPhoto.temperature.ambient.toFixed(1)}°C</Typography>
                <Typography>Location: Column {selectedPhoto.location.column}, Row {selectedPhoto.location.row}</Typography>

              </Box>
            </>
          )}
        </Box>
      </Modal>
    </ContainerPrimary>
  );
};

const RotatedImageContainer = styled(Box)({
  width: `${ImageSize}px`,
  height: `${ImageSize}px`,
  position: 'relative',
  overflow: 'hidden',
});

const RotatedImage = styled('img')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transform: 'rotate(90deg)',
  transformOrigin: 'center',
});

const PlaceholderBox = styled(Box)(({ theme }) => ({
  height: ImageSize,
  width: ImageSize,
  backgroundColor: theme.palette.grey[200],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

interface Photo {
  id: string;
  url: string;
  location: {
    row: number;
    column: number;
    shelf: number;
  };
  temperature: {
    ambient: number;
    surface: number;
  };
  timestamp: {
    seconds: number;
    nanoseconds: number;
  };
}

export default Satellite;