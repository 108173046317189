import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, GuardDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionGuardAlarm,
  mqttDefinitionGuardFlash,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Alert, Stack, Box, Typography, Divider } from '@mui/material';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormGuardAlarm = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<GuardDeviceStateUnflattened>;
}) => {

  const deviceId = deviceState.deviceId;

  const controlLifecycleAlarm = useControlSend(
    mqttDefinitionGuardAlarm,
    deviceId,
  );

  const controlLifecycleFlash = useControlSend(
    mqttDefinitionGuardFlash,
    deviceId,
  );

  const defaultValuesAlarm = formCreateDefaultValues(mqttDefinitionGuardAlarm, {
    timeout: 100,
  });
  const defaultValuesFlash = formCreateDefaultValues(mqttDefinitionGuardFlash, {
    timeout: 100,
  });

  return (
    <PaperSection
      heading='Manual Guard alarm and flash control'
      mb={2}
      subheading={`${mqttDefinitionGuardAlarm.topic} and ${mqttDefinitionGuardFlash.topic}`}
    >
      <Stack
        spacing={4}
        direction='row'
        justifyContent='center'
        alignItems='center'
        divider={<Divider orientation='vertical' flexItem />}
      >
        <PhormContainer
          defaultValues={defaultValuesAlarm}
          onSubmit={(data) => {
            controlLifecycleAlarm.run({
              payload: {
                timeout: Number(data.timeout),
                alarm: 1,
              },
              topicParams: {
                deviceId: deviceId,
              },
            });
          }}
          onError={(error) => console.log(error)}
          schema={yup.object({})}
        >
          <Stack spacing={2}>
            <Typography variant='h6' textAlign='center'>
              <NotificationsActiveIcon fontSize='small' />
              Alarm
            </Typography>
            <TextFieldElement label='Timeout (ms)' name='timeout' />
            <SubmitControl controlHook={controlLifecycleAlarm}>
              Send
            </SubmitControl>
          </Stack>
        </PhormContainer>
        <PhormContainer
          defaultValues={defaultValuesFlash}
          onSubmit={(data) => {
            controlLifecycleFlash.run({
              payload: {
                timeout: Number(data.timeout),
              },
              topicParams: {
                deviceId: deviceId,
              },
            });
          }}
          onError={(error) => console.log(error)}
          schema={yup.object({})}
        >
          <Stack spacing={2}>
            <Typography variant='h6' textAlign='center'>
              <WbTwilightIcon fontSize='small' />
              Flash
            </Typography>
            <TextFieldElement label='Timeout (ms)' name='timeout' />
            <SubmitControl controlHook={controlLifecycleFlash}>
              Send
            </SubmitControl>
          </Stack>
        </PhormContainer>
      </Stack>
      <Box mt={2}>
        <Alert severity='info'>Set timeout to 0 to turn off</Alert>
      </Box>
    </PaperSection>
  );
};

export default FormGuardAlarm;
