import { Alert, Box, Button } from '@mui/material';

const NewVersion = () => {

  return (

    <Alert severity='error' variant='filled' style={{ backgroundColor: 'red', width: '100%', marginBottom: '10px' }}>
      <Box>A new version of the console is now available.</Box>
      <Box sx={{ mt: 2 }}>
        <Button
          onClick={() => window.location.reload()}
          variant='contained'
          color='warning'
          style={{ marginLeft: '10px' }}
        >Click here to refresh</Button>
      </Box>
    </Alert>
  );
};

export default NewVersion;