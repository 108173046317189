import { Tooltip } from '@mui/material';
import type { HTMLAttributes, CSSProperties } from 'react';
import { useState, useEffect, useRef } from 'react';
import * as React from 'react';

type IProps = {
  shelf: number;
  row: number;
  shelves: number;
  zIndex?: number;
  children?: React.ReactNode;
  isSelected?: boolean;
  isGroupSelected?: boolean;
  rackBoxStyle?: React.CSSProperties;
  rackBoxStyleHover?: React.CSSProperties;
  rackBoxStyleSelected?: React.CSSProperties;
  rackBoxStyleGroupSelected?: React.CSSProperties;
  toolTip?: React.ReactNode;
  isPulsing?: boolean;
  seed?: string;
} & HTMLAttributes<HTMLDivElement>;

export const rackBoxCSS: CSSProperties = {
  boxSizing: 'border-box',
  width: '55px',
  height: '15px',
  fontSize: '8px',
  lineHeight: '15px',
  textAlign: 'center',
  border: '1px solid transparent',
  fontFamily: 'Azeret Mono, monospace',
};

const RackBox = React.memo(function RackBox({
  shelf,
  row,
  shelves,
  children,
  zIndex = 10,
  style,
  isGroupSelected = false,
  rackBoxStyle = {},
  rackBoxStyleSelected = {},
  rackBoxStyleGroupSelected = {},
  isSelected = false,
  toolTip,
  onClick = () => null,
  isPulsing = false,
  seed = '',
}: IProps) {
  // Only initialize the hover state when we have a tooltip
  const [isHovering, setIsHovering] = useState(false);
  const [isFlashing, setIsFlashing] = useState(false);
  const prevSeedRef = useRef(seed);

  // Effect to detect seed changes and trigger flash
  useEffect(() => {
    if (prevSeedRef.current !== seed) {

      // Seed has changed, trigger flash
      setIsFlashing(true);

      // Remove the flash class after 1000ms
      const timer = setTimeout(() => {
        setIsFlashing(false);
      }, 1000);

      // Update the ref to the current seed value
      prevSeedRef.current = seed;
      // Clean up the timer if component unmounts
      return () => clearTimeout(timer);
    }
  }, [seed]);

  const height = shelves * 16;

  const baseStyles = {
    ...rackBoxCSS,
    zIndex: `${zIndex}`,
    overflow: 'hidden',
    position: 'absolute',
    top: `${height - shelf * 16}px`,
    left: `${row * 56}px`,
    userSelect: 'none',
    ...style,
    ...rackBoxStyle,
    ...(isGroupSelected ? rackBoxStyleGroupSelected : {}),
    ...(isSelected ? rackBoxStyleSelected : {}),
  };

  // Combine all classes
  const className = `rack-box-hover ${isPulsing ? 'pulsing' : ''} ${isFlashing ? 'flashing' : ''}`;

  // Handle hover events only if we have a tooltip
  const handleMouseEnter = () => {
    if (toolTip) {
      setIsHovering(true);
    }
  };

  const handleMouseLeave = () => {
    if (toolTip) {
      setIsHovering(false);
    }
  };

  // Common props for the div
  const divProps = {
    className,
    onClick,
    style: baseStyles as CSSProperties,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };

  // Only wrap with Tooltip when we have a tooltip AND the user is hovering
  if (toolTip && isHovering) {
    return (
      <Tooltip title={toolTip} arrow>
        <div {...divProps}>{children}</div>
      </Tooltip>
    );
  } else {
    return <div {...divProps}>{children}</div>;
  }
}, (prevProps, nextProps) => {
  const styleEqual = JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style);

  return (
    prevProps.shelf === nextProps.shelf &&
    prevProps.row === nextProps.row &&
    prevProps.shelves === nextProps.shelves &&
    prevProps.zIndex === nextProps.zIndex &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.isGroupSelected === nextProps.isGroupSelected &&
    prevProps.rackBoxStyle === nextProps.rackBoxStyle &&
    prevProps.rackBoxStyleSelected === nextProps.rackBoxStyleSelected &&
    prevProps.rackBoxStyleGroupSelected === nextProps.rackBoxStyleGroupSelected &&
    prevProps.toolTip === nextProps.toolTip &&
    prevProps.isPulsing === nextProps.isPulsing &&
    // Important: we must allow the component to re-render when the seed changes
    prevProps.seed === nextProps.seed &&
    styleEqual
  );
});

export default RackBox;