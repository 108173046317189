import { useEffect, useState, useRef, useCallback } from 'react';
import useUserToken from '../hooks/useUserToken';

const getWSEndpoint = (token: string) => {
  return process.env.NODE_ENV === 'production'
    ? `wss://websocket.phyllome.io/ws/?token=${token}`  // Changed to wss://
    : `ws://127.0.0.1:8090?token=${token}`;            // Changed to ws://
};

interface WebSocketOptions {
  onMessage?: (message: any) => void;
  onConnectionChange?: (isConnected: boolean) => void;
}

const useWebSocket = ({ onMessage, onConnectionChange }: WebSocketOptions = {}) => {

  const token = useUserToken();
  const [socketUrl, setSocketUrl] = useState('');
  const wsRef = useRef<WebSocket | null>(null);
  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 5;

  const connect = useCallback(() => {
    if (!socketUrl || !token) return;

    wsRef.current = new WebSocket(getWSEndpoint(token as string));

    wsRef.current.onclose = (event) => {
      console.log('WebSocket Closed!!', event.code, event.reason);

      onConnectionChange?.(false);

      // Implement reconnection with exponential backoff
      if (reconnectAttempts.current < maxReconnectAttempts) {
        const timeout = Math.min(1000 * Math.pow(2, reconnectAttempts.current), 10000);

        console.log(`Reconnecting in ${timeout}ms...`);

        setTimeout(() => {
          reconnectAttempts.current += 1;
          connect();
        }, timeout);
      }
    };

    wsRef.current.onopen = () => {
      console.log('WebSocket Connected');

      onConnectionChange?.(true);

      const subTest2 = {
        type: 'deviceUpdate',
        'data.properties.brokerId': 'mcp-alexandria',
      };

      // wsRef.current?.send(JSON.stringify({ type: 'subscribe', 'key': 'testme', value: subTest }));
      wsRef.current?.send(JSON.stringify({ type: 'subscribe', 'key': 'testme2', value: subTest2 }));
      reconnectAttempts.current = 0; // Reset attempts on successful connection
    };

    wsRef.current.onmessage = (event) => {
      console.log('Received::::', event.data);
      onMessage?.(event.data);
    };

    wsRef.current.onerror = (error) => {
      console.log('Error details:', {
        readyState: wsRef.current?.readyState,
        protocol: wsRef.current?.protocol,
        extensions: wsRef.current?.extensions,
        url: wsRef.current?.url,
        error,
      });
    };
  }, [onConnectionChange, onMessage, socketUrl, token]);

  useEffect(() => {
    if (token) {
      setSocketUrl(getWSEndpoint(token as string));
    }
  }, [token]);

  useEffect(() => {
    connect();
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
    };
  }, [connect]);

  const subscribe = useCallback((key: string, value: any) => {
    if (wsRef.current) {
      wsRef.current.send(JSON.stringify({ type: 'subscribe', key, value }));
    }

  }, []);

  const unsubscribe = useCallback((key: string) => {
    if (wsRef.current) {
      wsRef.current.send(JSON.stringify({ type: 'unsubscribe', key }));
    }
  }, []);

  // Expose a manual reconnect method if needed
  const reconnect = useCallback(() => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    reconnectAttempts.current = 0;
    connect();
  }, [connect]);

  return { reconnect, subscribe, unsubscribe };
};

export default useWebSocket;