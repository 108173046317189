import { Tabs, Tab, Box, Alert, Button } from '@mui/material';
import { colors } from '@phyllome/common';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import useNavSide from '../../data/hooks/useNavSide';
import DeviceTypeToIcon from '../../components/DeviceTypeToIcon/DeviceTypeToIcon';
import useLatestLighting from './hooks/useLatestLighting';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import PhyTabPanel from '../../components/PhyTabPanel/PhyTabPanel';
import LatestLightingInfo from './LatestLightingInfo';
import LatestLighting from './LatestLighting';
import GraphLighting from './GraphLighting';
import ControlLighting from './ControlLighting';
import TaskLighting from './TaskLighting';
import useRoute from '../../data/hooks/useRoute';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import FarmContext from '../../components/FarmProvider/FarmContext';
import PendingDevice from '../../components/PendingDevice/PendingDevice';

const SideBarEntryLighting = ({ deviceId }: { deviceId: string }) => {
  const navSide = useNavSide();
  const router = useRoute();
  const [value, setValue] = useState<any>(Number(router.sidebarQuery.tab) || 0);

  const latestDeviceInfo = useLatestLighting(deviceId);
  const { brokerId } = useContext(FarmContext);

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    router.navSideQuery({ tab: value });
  }, [value, router]);

  if (!latestDeviceInfo?.lastAccessed)
    return (
      <Box p={2}>
        <Alert severity='info'>No Latest Data for lighting/{deviceId}!</Alert>
      </Box>
    );
  return (
    <>
      <AppDrawerTitle title={`lighting/${latestDeviceInfo.deviceId}`} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor='inherit'
        variant='scrollable'
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label='Latest' value={0} />
        <Tab label='Graphs' value={1} />
        <Tab label='Control' value={2} />
        <Tab label='Activity' value={3} />
        <Tab label='Tasks' value={4} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <LatestLightingInfo deviceState={latestDeviceInfo} />
        {latestDeviceInfo.properties.coreId && (
          <Box mb={2} textAlign='center'>
            <Button
              variant='outlined'
              color='primary'
              size='small'
              onClick={() => {
                navSide(`lightingcore/${latestDeviceInfo.properties.coreId}`);
              }}
            >
              <DeviceTypeToIcon deviceType='lightingcore' />
              Lighting Core Controller
            </Button>
          </Box>
        )}
        <PendingDevice deviceState={latestDeviceInfo} />
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType='lighting'
          count={1}
        />
        <LatestLighting deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <GraphLighting deviceState={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <ControlLighting deviceState={latestDeviceInfo}/>
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <ActivityLog
          identifier={`device/lighting/${latestDeviceInfo.deviceId}/notes`}
          heading='Activity Log'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <TaskLighting deviceState={latestDeviceInfo} />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryLighting;
