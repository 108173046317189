import type { FC } from 'react';
import { Box } from '@mui/material';

interface IProps {
  width?: string;
  variant?: 'colored' | 'white';
  onClick?: () => void;
}

const variants = {
  colored: '/images/phyllome-logo-rgb.png',
  white: '/images/phyllome-logo-white-rgb.png',
};

const Logo: FC<IProps> = ({
  width = '100%',
  variant = 'colored',
  onClick = () => {},
}) => {
  return (
    <Box display='inherit'>
      <img
        src={variants[variant]}
        alt='phyllome'
        onClick={onClick}
        style={{ width }}
      />
    </Box>
  );
};

export default Logo;
