import { useEffect, useState } from 'react';
import { useUser } from 'reactfire';

const useUserToken = () => {

  const [idToken, setIdToken] = useState<string | null>(null);

  const user = useUser();

  useEffect(() => {

    const fetchToken = async () => {
      if (!user || !user.data) return;
      const _token = await user.data.getIdToken();

      setIdToken(_token);
    };

    fetchToken();
  }, [user]);
  return idToken;
};

export default useUserToken;