import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import type { AirDeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { mqttDefinitionAirConfigGet, mqttDefinitionAirConfigSet } from '@phyllome/common';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Typography } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import useSetDesiredState from '../../../data/hooks/useSetDesiredState';
import SubmitDesiredState from '../../../forms/SubmitDesiredState';

const FormAirConfigSetTemperatureOffset = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<AirDeviceStateUnflattened>;
}) => {
  const { deviceId } = deviceState;
  const state = deviceState.properties;

  // convert this from useControlSend
  const dsLifeCycle = useSetDesiredState({
    deviceId: deviceId,
    deviceType: 'air',
  });

  const activityLog = useActivityLog(`device/air/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionAirConfigSet, {
    offsets: { temperature: state?.offsets?.temperature || 0 },
  });

  return (
    <PaperSection
      mb={2}
      heading='Temperature Calibration Offset'
      subheading={mqttDefinitionAirConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='air'
        definition={mqttDefinitionAirConfigGet}
        offsets={state.offsets?.temperature}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          // replace controlLifecycle.run
          dsLifeCycle.run({
            offsets: { temperature: data.offsets.temperature },
          }).then(() => {
            activityLog.add(
              `Set data to ${JSON.stringify(data)}`,
              'OPERATION',
            );
          });

          activityLog.add(
            `Set temperature offset config to ${data.offsets.temperature}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          offsets: yup.object({ temperature: yup.number() }),
        })}
      >
        <Stack spacing={2}>
          <Typography variant='body1'>
            Set the temperature offset against a calibrated measured reading. A
            value of -1.2 will reduce each sample by 1.2 degrees.
          </Typography>
          <TextFieldElement
            label='Temperature Offset'
            name='offsets.temperature'
            type='text'
          />
          {/* replace SubmitControl with SubmitDesiredState */}
          <SubmitDesiredState controlHook={dsLifeCycle}>
            Send to device
          </SubmitDesiredState>

        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormAirConfigSetTemperatureOffset;
