import { Stack } from '@mui/system';
import { Divider } from '@mui/material';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import type { Co2DeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { mqttDefinitionCo2ConfigSet } from '@phyllome/common';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import StaleDataAlert from '../../../components/StaleDataAlert/StaleDataAlert';
import { useMemo } from 'react';

const FormCo2ConfigSetDosing = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<Co2DeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;
  const controlLifecycle = useControlSend(mqttDefinitionCo2ConfigSet, deviceId);
  const activityLog = useActivityLog(`device/co2/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionCo2ConfigSet, {
    dosing: {
      a: latest.dosing?.a || 0,
      b: latest.dosing?.b || 0,
      c: latest.dosing?.c || 0,
      flow: latest.dosing?.flow || 0,
      limit: latest.dosing?.limit || 0,
    },

    heaterWarmup: latest.heaterWarmup || 0,
  });

  return (
    <PaperSection
      heading='CO2 Dosing Config'
      mb={2}
      subheading={mqttDefinitionCo2ConfigSet.topic}
    >
      <StaleDataAlert
        deviceId={deviceId}
        deviceType='co2'
        a={latest.dosing?.a}
        b={latest.dosing?.b}
        c={latest.dosing?.c}
        flow={latest.dosing?.flow}
        limit={latest.dosing?.limit}
      />
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              dosing: {
                a: data.dosing.a,
                b: data.dosing.b,
                c: data.dosing.c,
                flow: data.dosing.flow,
                limit: data.dosing.limit,
              },
              heaterWarmup: data.heaterWarmup,
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            `Set CO2 dosing config to ${JSON.stringify(data)}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          dosing: yup
            .object({
              a: yup.number().required(),
              b: yup.number().required(),
              c: yup.number().required(),
              flow: yup.number().required(),
              limit: yup.number().required(),
            })
            .required(),
          heaterWarmup: yup.number().required(),
        })}
      >
        <Stack spacing={2}>
          <h4>Dosing Quadratic</h4>
          <p>ax2 + bx + c</p>
          <Stack direction='row' spacing={2}>
            <TextFieldElement label='Dosing A' name='dosing.a' required />
            <TextFieldElement label='Dosing B' name='dosing.b' required />
            <TextFieldElement label='Dosing C' name='dosing.c' required />
          </Stack>
          <Divider />
          <h4>Flow rates</h4>
          <p>
            Dosing flow (Lpm) is the measured flow rate of the gas entering the
            room.
          </p>
          <TextFieldElement label='Dosing Flow' name='dosing.flow' required />
          <p>
            Dosing limit is the maximum volume of gas (L) to be dosed in one
            minute
          </p>
          <TextFieldElement label='Dosing Limit' name='dosing.limit' required />
          <Divider />
          <h4>Heater Warmup</h4>
          <p>
            Time in minutes for the heater to warmup before dosing commences
          </p>
          <TextFieldElement
            label='Heater Warmup'
            name='heaterWarmup'
            required
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormCo2ConfigSetDosing;
