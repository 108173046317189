import type { DatabaseDevices, NDSDeviceStateUnflattened } from '@phyllome/common';
import useFetch from '../../../data/hooks/useFetch';

const useNDSByLocation = ({
  column,
  shelf,
  row,
}: {
  column: number;
  shelf: number;
  row: number;
}) => {

  const dId = `${column}.${shelf}.${row}`;
  const fetch = useFetch<DatabaseDevices<NDSDeviceStateUnflattened>>(`/farm/:brokerId/device/nds/${dId}`);

  return fetch;
};

export default useNDSByLocation;
