import type { NDSDeviceStateUnflattened } from '@phyllome/common';
import { colors } from '@phyllome/common';
import RackBox from '../../../../components/factoryui/components/RackBox';
import useNDSOverlayData from './useNDSOverlayData';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { useAtomValue } from 'jotai';
import { devicesByPrefixAtom } from '../../../../data/atoms/deviceAtomFamily';

const OverlaySprinklerValves = ({ column }: { column: number }) => {
  const {
    brokerId,

    shelves,

  } = useNDSOverlayData(column);

  const deviceData = useAtomValue(devicesByPrefixAtom(`${brokerId}.nds.${column}`));

  return (
    <>
      {Object.keys(deviceData).map(_deviceRow => {

        const data = deviceData[_deviceRow];

        if (!data) {
          return null;
        }
        const { row, shelf } = (data.properties as NDSDeviceStateUnflattened).location;
        const key = `${column}.${shelf}.${row}`;

        const { valve, enable } = data.properties;
        const color = valve ? colors.river : enable ? colors.ocean : colors.fog;

        return (
          <RackBox
            style={{ textAlign: 'left', cursor: 'pointer' }}
            key={key}
            zIndex={11}

            row={row}
            shelf={shelf}
            shelves={shelves}
          >

            <WaterDropIcon
              sx={{ fontSize: '11px', margin: '1px', color: color }}
            />

          </RackBox>
        );
      })
      }
    </>
  );
};

export default OverlaySprinklerValves;
