import type {
  BoxProps,
  ButtonProps,
} from '@mui/material';
import {
  Badge,
  Box,
  CircularProgress,

  Stack,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { TaskAlt } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import { Button } from '@mui/material';
import type { FC, JSX, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { formHasErrors } from '../components/Form/helpers';
import type { SetDesiredState } from '../data/hooks/useSetDesiredState';

type IProps = {
  onClick?: () => void;
  controlHook: SetDesiredState;
  inline?: boolean;
  height?: string;
  color?: ButtonProps['color'];
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  icon?: JSX.Element;
} & BoxProps;

const SubmitDesiredState: FC<PropsWithChildren<IProps>> = ({
  children,
  controlHook,
  onClick = () => null,
  inline = false,
  height = 'auto',
  color = 'primary',
  variant = 'contained',
  size = 'medium',
  icon = <PlayArrowIcon />,
  ...boxProps
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const hasErrors = formHasErrors(errors);

  const isBusy = controlHook.status === 'PENDING';

  const isDone = controlHook.status === 'COMPLETE';
  const buttonColor = isDone ? color : isBusy ? 'info' : color;

  return (
    <Box sx={{ ...boxProps.sx, display: inline ? 'inline' : 'block' }}>
      <Stack direction='row' spacing={2}>
        <Badge badgeContent='new!' color='secondary'>
          <Button
            sx={{ height }}
            onClick={onClick}
            color={buttonColor}
            variant={variant}
            type='submit'
            size={size}
            disabled={isBusy || hasErrors}
            endIcon={<ControlHookIcon icon={icon} controlHook={controlHook} />}
          >
            {children}
          </Button>
        </Badge>
      </Stack>
    </Box>
  );
};

export default SubmitDesiredState;

type IControlHookIconProps = {
  icon?: JSX.Element;
  controlHook: SetDesiredState;
};

const ControlHookIcon: FC<IControlHookIconProps> = ({ icon, controlHook }) => {
  switch (controlHook.status) {
    case 'PENDING':
      return <CircularProgress size={15} />;
      break;
    case 'COMPLETE':
      return <TaskAlt fontSize='small' />;
      break;
    case 'ERROR':
      return <ErrorIcon fontSize='small' />;
      break;
    default:
      if (icon) return <>{icon}</>;
      break;
  }
  return null;
};

