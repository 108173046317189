import type { DatabaseDevices, FertigatorDeviceStateUnflattened } from '@phyllome/common';
import FormFertigatorSetEc from './forms/FormFertigatorSetEc';
import FormFertigatorSetPeroxide from './forms/FormFertigatorSetPeroxide';
import FormFertigatorSetPump from './forms/FormFertigatorSetPump';
import FormFertigatorSetAcid from './forms/FormFertigatorSetAcid';
import FormFertigatorSetBase from './forms/FormFertigatorSetBase';
import LatestFertigatorInfo from './LatestFertigatorInfo';
import FormFertigatorManual from './forms/FormFertigatorManual';
import GenericPing from '../Generic/control/GenericPing';

const ControlFerigator = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<FertigatorDeviceStateUnflattened> ;
}) => {
  const deviceId = deviceState.deviceId;

  return (
    <>
      <LatestFertigatorInfo deviceState={deviceState} />
      <FormFertigatorSetEc deviceState={deviceState} />
      <FormFertigatorSetAcid deviceState={deviceState} />
      <FormFertigatorSetBase deviceState={deviceState} />
      <FormFertigatorSetPeroxide deviceState={deviceState} />
      <FormFertigatorSetPump deviceState={deviceState} />
      <FormFertigatorManual deviceState={deviceState} />
      <div>
        <GenericPing
          deviceId={deviceId}
          deviceType='fertigator'
        />
      </div>
    </>
  );
};

export default ControlFerigator;
