import type { FC } from 'react';
import { useContext, useState } from 'react';
import AppDrawerTitle from '../../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import { Alert, Tab, Tabs } from '@mui/material';
import useTraySeason from '../../../../data/hooks/collections/useTraySeasonBySeasonId';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import useLatestLighting from '../../../../devicespecific/Lighting/hooks/useLatestLighting';
import SideBarTraySeasonConsumables from './SideBarTraySeasonConsumables';
import SideBarTraySeasonPhoto from './SideBarTraySeasonPhoto';
import SideBarTraySeasonInfo from './SideBarTraySeasonInfo';
import SideBarTraySeasonIrrigationSchedule from './SideBarTraySeasonIrrigationSchedule';
import SideBarTraySeasonEdit from './SideBarTraySeasonEdit';
import SideBarTraySeasonReassign from './SideBarTraySeasonReassign';
import SideBarTraySeasonDebug from './SideBarTraySeasonDebug';
import FormLightingOnOff from '../../../../devicespecific/Lighting/forms/FormLightingOnOff';
import ActivityLog from '../../../../components/ActivityLog/ActivityLog';
import useSeason from '../../Seasons/hooks/useSeason';
import useSeed from '../../../../data/hooks/collections/useSeed';
import SideBarTraySeasonLightingSchedule from './SideBarTraySeasonLightingSchedule';
import PhyTabPanel from '../../../../components/PhyTabPanel/PhyTabPanel';
import useRoute from '../../../../data/hooks/useRoute';
import { colors } from '@phyllome/common';

type IProps = {
  traySeasonId: string;
};

const SideBarEntryTraySeasonEntryPoint: FC<IProps> = ({ traySeasonId }) => {

  const router = useRoute();
  const { brokerId } = useContext(FarmContext);
  const traySeasonData = useTraySeason(traySeasonId);
  const title = `TraySeasonID: ${traySeasonId}`;
  const seasonData = useSeason(traySeasonData?.seasonId);
  const latestZoneInfo = useLatestLighting(
    traySeasonData?.lightingZone?.toString() || '',
  );
  const seedData = useSeed(traySeasonData?.seedId);

  const [value, setValue] = useState<any>(Number(router.sidebarQuery.tab) || 0);
  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {

    setValue(newValue);
    router.navSideQuery({ tab: newValue });
  };

  if (!traySeasonData) return (<Alert severity='error'>Tray Season Data not found</Alert>);
  if (!seasonData) return (<Alert severity='error'>Season Data not found</Alert>);
  if (!seedData) return (<Alert severity='error'>Seed Data not found</Alert>);
  if (!latestZoneInfo) return (<Alert severity='error'>Zone info not found</Alert>);
  return (
    <>
      <AppDrawerTitle title={title} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor='inherit'
        variant='scrollable'
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label='Summary' value={0} />
        <Tab label='Details' value={1} />
        <Tab label='Schedules' value={2} />
        <Tab label='Consumables' value={3} />
        <Tab label='Activity' value={4} />
        <Tab label='Debug' value={5} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <SideBarTraySeasonInfo
          seasonData={seasonData}
          traySeasonData={traySeasonData}
        />
        <SideBarTraySeasonEdit
          traySeasonData={traySeasonData}
          seasonData={seasonData}
        />
        <SideBarTraySeasonPhoto traySeasonData={traySeasonData} />
        {latestZoneInfo && <FormLightingOnOff deviceState={latestZoneInfo} />}
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <SideBarTraySeasonReassign
          traySeasonData={traySeasonData}
          brokerId={brokerId}
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <SideBarTraySeasonIrrigationSchedule
          seasonData={seasonData}
          traySeasonData={traySeasonData}
        />
        <SideBarTraySeasonLightingSchedule
          seasonData={seasonData}
          seedData={seedData}
          zoneData={latestZoneInfo.properties}
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <SideBarTraySeasonConsumables
          seedData={seedData}
          traySeasonData={traySeasonData}
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <ActivityLog
          identifier={`traySeason/${traySeasonData.id}/notes`}
          heading='Activity Log'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <SideBarTraySeasonDebug traySeasonData={traySeasonData} />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryTraySeasonEntryPoint;
