import { atom } from 'jotai';
import { parseDevicePath, type DatabaseDevices, type WithId } from '@phyllome/common';
import requestUsingWS from '../../data/WebSocket/requestDeviceData';
import getAtomValue from '../../data/atoms/getAtomValue';
import websocketAtom from '../../data/atoms/websocketAtom';
import setAtomValue from '../../data/atoms/setAtomValue';
import { websocketFiltersAtom } from '../../data/atoms/websocketFilters';
import { brokerIdAtom } from '../../data/atoms/brokerIdAtom';
import { deviceAtomUpdate } from '../../data/atoms/deviceAtomFamily';
import { setValueByPath } from './helpers';
import { devicesAtom } from '../../data/atoms/devicesAtom';

export const deviceSubscriberAtom = atom(0);

class DeviceWorker {
  static brokerId: string;
  static isFetchingDevices: boolean;
  static isFetchingNDSes: boolean;

  static states = atom({
    devicesLoaded: false,
    devicesNDSLoaded: false,
  });

  static fetchAllDevices = async () => {
    const brokerId = getAtomValue(brokerIdAtom);
    const existingDevices = getAtomValue(devicesAtom);

    const fetchedDevices = await requestUsingWS<WithId<DatabaseDevices>[]>({
      topic: `${brokerId}/devices`,
    });

    // Build a new device map with only the relevant brokerId
    const newDevices: Record<string, WithId<DatabaseDevices>> = {};

    for (const [id, device] of Object.entries(existingDevices)) {
      if (device.brokerId === brokerId) {
        newDevices[id] = device;
      }
    }

    for (const device of fetchedDevices) {
      newDevices[device.id] = device;
    }

    setAtomValue(devicesAtom, newDevices);

    setAtomValue(DeviceWorker.states, (prev) => ({
      ...prev,
      devicesLoaded: true,
    }));
  };

  static fetchAllNDSDevices = async () => {
    const brokerId = getAtomValue(brokerIdAtom);
    const existingDevices = getAtomValue(devicesAtom);

    const fetchedDevices = await requestUsingWS<WithId<DatabaseDevices>[]>({
      topic: `${brokerId}/nds`,
    });

    const newDevices = { ...existingDevices };

    for (const device of fetchedDevices) {
      newDevices[device.id] = device;
    }

    setAtomValue(devicesAtom, newDevices);

    setAtomValue(DeviceWorker.states, (prev) => ({
      ...prev,
      devicesNDSLoaded: true,
    }));
  };

  static stopSyncing = async () => {
    setAtomValue(websocketFiltersAtom, {
      fetchDevices: false,
    });

    const websocket = getAtomValue(websocketAtom);

    websocket?.off('device:update');
  };

  static startFetchingNDS = async (brokerId: string) => {
    await this.fetchAllNDSDevices();

    setAtomValue(websocketFiltersAtom, {
      brokerId,
      fetchNDSes: true,
    });
  };

  static stopFetchingNDS = async () => {
    const brokerId = getAtomValue(brokerIdAtom);

    setAtomValue(websocketFiltersAtom, {
      brokerId,
      fetchNDSes: false,
    });
  };

  static commenceSyncing = async (brokerId: string) => {
    await this.fetchAllDevices();

    const websocket = getAtomValue(websocketAtom);

    setAtomValue(websocketFiltersAtom, {
      brokerId,
      fetchDevices: true,
    });

    websocket?.on('device:update', (data) => {
      const deviceReference = parseDevicePath(data.id);

      if (!deviceReference) return;

      const existingDevice = getAtomValue(devicesAtom)[data.id];
      const updatedDevice = { ...existingDevice };

      for (const key of Object.keys(data.data)) {
        setValueByPath(updatedDevice, key, data.data[key]);
      }

      deviceAtomUpdate({
        brokerId,
        deviceType: deviceReference.deviceType,
        deviceId: deviceReference.deviceId,
        data: updatedDevice,
      });
    });
  };
}

export default DeviceWorker;
