import GenericPing from '../Generic/control/GenericPing';
import type { DatabaseDevices, TransferDeviceStateUnflattened } from '@phyllome/common';
import LatestTransferInfo from './LatestTransferInfo';
import FormTransferManualConveyors from './forms/FormTransferManualConveyors';
import FormTransferManualActuators from './forms/FormTransferManualActuators';

const ControlAir = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<TransferDeviceStateUnflattened>;
}) => {

  const { deviceId } = deviceState;

  return (
    <>
      <LatestTransferInfo deviceState={deviceState} />
      <FormTransferManualConveyors deviceState={deviceState} />
      <FormTransferManualActuators deviceState={deviceState} />
      <GenericPing
        deviceId={deviceId}
        deviceType='transfer'
      />
    </>
  );
};

export default ControlAir;
