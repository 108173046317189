import { useAtomValue } from 'jotai';
import { devicesByPrefixAtom } from '../../../../data/atoms/deviceAtomFamily';
import getUIInfoBasedOnAge from '../helpers/getUIInfoBasedOnAge';
import useNDSOverlayData from './useNDSOverlayData';
import type { DatabaseDevices, NDSDeviceStateUnflattened, WithId } from '@phyllome/common';
import RackBox from '../../../../components/factoryui/components/RackBox';
import useRoute from '../../../../data/hooks/useRoute';
import { useMemo } from 'react';

type OverlayTraySimpleProps = {
  column: number;
  onClick?: (NDSLatestRow: WithId<DatabaseDevices>) => void;
};

const OverlaySprinklerSelect = ({
  column,
  onClick = () => null,
}: OverlayTraySimpleProps) => {

  const {
    brokerId,
    shelves,
  } = useNDSOverlayData(column);

  const { sidebarPath } = useRoute();

  const selectedSprinkler = useMemo(() => sidebarPath.split('/')[2], [sidebarPath]);

  const deviceData = useAtomValue(devicesByPrefixAtom(`${brokerId}.nds.${column}`));

  return (
    <>
      {Object.keys(deviceData).map(_deviceRow => {
        const data = deviceData[_deviceRow];

        if (!data) {
          return null;
        }

        const { row, shelf } = (data.properties as NDSDeviceStateUnflattened).location;
        const key = `${column}.${shelf}.${row}`;
        const seed = String(new Date(data.lastAccessed as any).getSeconds());
        const { timestamp, location } = data.properties;
        const { age } = getUIInfoBasedOnAge(new Date(), new Date(timestamp));

        if (!location) {
          return null;
        }
        return (
          <RackBox
            title={`updated ${age} minutes ago`}
            onClick={() => onClick(data)}
            key={key}
            zIndex={50}
            row={location.row}
            shelf={location.shelf}
            shelves={shelves}
            seed={seed}
            style={{
              border: selectedSprinkler === key ? '2px solid rgb(229, 255, 0)' : 'none',
              cursor: 'pointer',
            }}
          />
        );
      })}
    </>
  );
};

export default OverlaySprinklerSelect;
