import { generateRid } from '@phyllome/common';
import getAtomValue from '../atoms/getAtomValue';
import websocketAtom from '../atoms/websocketAtom';
import { brokerIdAtom } from '../atoms/brokerIdAtom';
import { decode } from '@msgpack/msgpack';

const requestUsingWS = <T = any>(options: {
  topic: string;
  data?: Record<string, any>
}): Promise<T> => {
  return new Promise((resolve, reject) => {
    // Get the websocket instance
    const websocket = getAtomValue(websocketAtom);

    if (!websocket) {
      reject(new Error('Websocket connection not available'));
      return;
    }

    // Get the brokerId from the options or from the atom
    const brokerId =  getAtomValue(brokerIdAtom);

    // Generate a unique request ID
    const rid = generateRid();

    // Replace any :brokerId placeholders in the topic
    const topic = options.topic.replace(':brokerId', brokerId);

    // Create the timeout duration (default 30 seconds)
    const timeout =  30000;

    // Set up one-time response handler
    const responseHandler = (response: { rid: number; data: any }) => {
      if (response.rid !== rid) return;

      try {
        const decoded = decode(response.data) as any;
        const result = decoded.data as T;

        // Clean up the listener once we get our response
        websocket.off('response', responseHandler);
        console.log('[WEBSOCKET] Resolved request', rid);
        resolve(result);
      } catch (error) {
        websocket.off('response', responseHandler);
        reject(error);
      }
    };

    // Add the response listener
    websocket.on('response', responseHandler);

    // Set up timeout to prevent hanging promises
    const timeoutId = setTimeout(() => {
      websocket.off('response', responseHandler);
      reject(new Error(`Websocket request timed out for rid: ${rid}`));
    }, timeout);

    // Send the request
    try {
      console.log('[WEBSOCKET] Sending request', rid);
      websocket.emit('request', {
        data: options.data || {},
        rid,
        topic,
      });
    } catch (error) {
      clearTimeout(timeoutId);
      websocket.off('response', responseHandler);
      reject(error);
    }
  });
};

export default requestUsingWS;