import { type FC, useEffect, useState } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from '@mui/material';
import useNavSide from '../../../data/hooks/useNavSide';
import StyledTableCell from '../../../components/Tables/StyledTableCell';
import { colors, type DatabaseSeason } from '@phyllome/common';
import dateToString, { dateFormatTypes } from '../../../helpers/dateToString';
import SeasonState from '../../../components/puregui/SeasonState/SeasonState';
import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';
import { useAppDrawer } from '../../../sidebar/AppDrawer/useAppDrawer';
import SeasonTimelineMini from '../../../components/TimelineView/TimelineMini';

const DEFAULT_ROWS_PER_PAGE = 50;

type ISeasonsListProps = {
  seasonsList: DatabaseSeason[];
};

const SeasonsTable: FC<ISeasonsListProps> = ({ seasonsList }) => {
  const [selectedRow, setSelectedRow] = useState('');
  const navSide = useNavSide();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const { state } = useAppDrawer();

  useEffect(() => {
    const hashArray = state.hash.split('/');

    setSelectedRow(hashArray[2]);
  }, [state.hash]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='seasons' size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Seeded</StyledTableCell>
            <StyledTableCell>Started</StyledTableCell>
            <StyledTableCell>Timeline</StyledTableCell>
            <StyledTableCell>Ended</StyledTableCell>
            <StyledTableCell>Trays</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? seasonsList.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage,
            )
            : seasonsList
          ).map((season) => (
            <TableRow
              hover
              key={season.id}
              onClick={() => {
                navSide(`season/${season.id}`);
              }}
              sx={{
                cursor: 'pointer',
              }}
              selected={selectedRow === season.id}
            >
              <TableCell
                sx={{
                  cursor: 'pointer',
                  boxSizing: 'border-box',
                  boxShadow:
                    selectedRow === season.id
                      ? `inset 3px 0px 0px 0px ${colors.budding}`
                      : 'inset 3px 0px 0px 0px transparent',
                }}
              >
                <SeasonState
                  totalDevices={season.totalDevices}
                  totalDevicesSatisfied={season.totalDevicesSatisfied}
                  state={season.state}
                  desiredState={season.desiredState}
                />
              </TableCell>
              <TableCell>{season.name}</TableCell>
              <TableCell>
                {dateToString(
                  season.traySeedEarliest,
                  dateFormatTypes.DAYMONTH,
                )}
              </TableCell>
              <TableCell>
                {dateToString(season.growthStarted, dateFormatTypes.DAYMONTH)}
              </TableCell>
              <TableCell>
                <SeasonTimelineMini season={season} />
              </TableCell>
              <TableCell>
                {dateToString(season.growthEnded, dateFormatTypes.DAYMONTH)}
              </TableCell>
              <TableCell>{season.trayNumberActual}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
              colSpan={3}
              count={seasonsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default SeasonsTable;
