import { Alert, Button, Tab, Tabs } from '@mui/material';
import AppDrawerTitle from '../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import useSeason from './hooks/useSeason';
import { useContext, useState } from 'react';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import SideBarSeasonNDSSchedule from './components/SideBarSeasonNDSSchedule';
import SideBarSeasonConsumables from './components/SideBarSeasonConsumables';
import SideBarSeasonProfit from './components/SideBarSeasonProfit';
import SideBarSeasonInfo from './components/SideBarSeasonInfo';
import SideBarSeasonState from './SideBarSeasonState';
import SideBarSeasonDevices from './components/SideBarSeasonDevices';
import SideBarSeasonTasksProgress from './components/SideBarSeasonTasksProgress';
import SideBarSeasonEditGrowthTimeline from './components/SideBarSeasonEditGrowthTimeline';
import SideBarSeasonCompletedSeasonEdit from './components/SideBarSeasonCompletedSeasonEdit';
import SideBarSeasonEditSeasonDetails from './components/SideBarSeasonEditSeasonDetails';
import ActivityLog from '../../../components/ActivityLog/ActivityLog';
import SideBarSeasonLightingSchedule from './components/SideBarSeasonLightingSchedule';
import SideBarSeasonDangerZone from './components/SideBarSeasonDangerZone';
import { colors } from '@phyllome/common';
import PhyTabPanel from '../../../components/PhyTabPanel/PhyTabPanel';
import useRoute from '../../../data/hooks/useRoute';
import { Link, useNavigate } from 'react-router-dom';
import SideBarSeasonPhotoLatest from './SideBarSeasonPhotoLatest';
import SideBarTraySeasonDebug from './components/SideBarTraySeasonDebug';

const SideBarSeasonEntry = ({ seasonId }: { seasonId: string }) => {
  const seasonData = useSeason(seasonId);
  const router = useRoute();
  const { brokerId } = useContext(FarmContext);
  const [value, setValue] = useState<any>(Number(router.sidebarQuery.tab) || 0);
  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {

    setValue(newValue);
    router.navSideQuery({ tab: newValue });
  };

  if (!seasonData) return (<Alert severity='error'>Season not found</Alert>);
  return (
    <>
      <AppDrawerTitle title={seasonData.name} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor='inherit'
        variant='scrollable'
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label='Summary' value={0} />
        <Tab label='Details' value={1} />
        <Tab label='Schedules' value={2} />
        <Tab label='Consumables' value={3} />
        <Tab label='Activity' value={4} />
        <Tab label='Photos' value={5} />
        <Tab label='Debug' value={6} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <SideBarSeasonState seasonData={seasonData} />
        <SideBarSeasonTasksProgress
          seasonData={seasonData}
          brokerId={brokerId}
        />
        <SideBarSeasonInfo
          seasonData={seasonData}
          brokerId={brokerId}
          seasonId={seasonId}
        />
        <SideBarSeasonDevices seasonData={seasonData} />
        <SideBarSeasonProfit seasonData={seasonData} />
        <SatelliteViewButton brokerId={brokerId} seasonId={seasonId} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <SideBarSeasonEditSeasonDetails seasonData={seasonData} />
        <SideBarSeasonEditGrowthTimeline seasonData={seasonData} />
        <SideBarSeasonCompletedSeasonEdit seasonData={seasonData} />
        <SideBarSeasonDangerZone seasonData={seasonData} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <SideBarSeasonDevices seasonData={seasonData} />
        <SideBarSeasonNDSSchedule seasonData={seasonData} />
        <SideBarSeasonLightingSchedule seasonData={seasonData} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <SideBarSeasonConsumables seasonData={seasonData} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <ActivityLog
          identifier={`season/${seasonData.id}/notes`}
          heading='Activity Log'
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <SideBarSeasonPhotoLatest season={seasonData} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={6}>
        <SideBarTraySeasonDebug seasonData={seasonData} />
      </PhyTabPanel>

    </>
  );
};

export default SideBarSeasonEntry;

const SatelliteViewButton = ({ brokerId, seasonId }: { brokerId: string, seasonId: string }) => {
  const nav = useNavigate();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Button
        component={Link}
        to={`/broker/${brokerId}/satellite/${seasonId}`}
        onClick={() => nav(`/broker/${brokerId}/seasons/#/season/${seasonId}`)}
        variant='outlined'
        color='primary'
        sx={{ mb: 2, mt: 2 }}
      >
        Satellite View
      </Button>
    </div>
  );
};
