const generateTabId = () => {
  // Use crypto.randomUUID if available (modern browsers)
  if (window.crypto && 'randomUUID' in window.crypto) {
    return window.crypto.randomUUID();
  }
  // Fallback for older browsers
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

// Store tab ID in sessionStorage to keep it consistent during page refreshes in the same tab
const getTabId = () => {
  const storageKey = 'socket_tab_id';
  let tabId = sessionStorage.getItem(storageKey);

  if (!tabId) {
    tabId = generateTabId();
    sessionStorage.setItem(storageKey, tabId);
  }
  return tabId;
};

export default getTabId;