import type { DatabaseDevices, FertigatorDeviceStateUnflattened } from '@phyllome/common';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import Graph from '../../components/Favourites/Graph';
import { AverageInfo } from '../../components/AverageTimeSeries/AverageInfo';
import Stacker from '../../components/Stacker/Stacker';
import { useMemo } from 'react';

const GraphFertigator = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<FertigatorDeviceStateUnflattened> ;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const brokerId = deviceState.brokerId;
  const deviceId = deviceState.deviceId;

  const averageItems = [
    {
      field: 'sample.ec',
      label: 'EC',
      sup: 'mS/cm',
    },
    {
      field: 'sample.ph',
      label: 'pH',
      sup: ' ', // space required to keep vertical alignment
    },
    {
      field: 'sample.temperature',
      label: 'Temperature',
      sup: '\u00b0C',
    },
    {
      field: 'sample.do',
      label: 'DO',
      sup: '%',
    },
  ];

  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        <AverageInfo
          latest={latest}
          deviceType='fertigator'
          items={averageItems}
        />
        <Graph name='fertSampleEC' brokerId={brokerId} deviceId={deviceId} target={latest.ec?.setpoint}/>
        <Graph name='fertSamplePH' brokerId={brokerId} deviceId={deviceId} target={latest.acid?.setpoint}/>
        <Graph name='fertSampleDO' brokerId={brokerId} deviceId={deviceId} />
        <Graph name='fertSampleORP' brokerId={brokerId} deviceId={deviceId} />
        <Graph
          name='fertSampleTemperature'
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph
          name='fertDispensedNutrientA'
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph
          name='fertDispensedNutrientB'
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph name='fertDispensedAcid' brokerId={brokerId} deviceId={deviceId} />
        <Graph
          name='fertDispensedPeroxide'
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph
          name='fertDispensedPump'
          brokerId={brokerId}
          deviceId={deviceId}
        />
      </Stacker>
    </ChartProvider>
  );
};

export default GraphFertigator;
