import LayoutCenter from '../../../components/puregui/LayoutCenter/LayoutCenter';
import Logo from '../../../components/Logo/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useAuth } from 'reactfire';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Box, Button } from '@mui/material';

const provider = new GoogleAuthProvider();

const Login = () => {
  const authHook = useAuth();

  const signIn = () => {
    signInWithPopup(authHook, provider).then((result) => {
      console.log('successfully signed in', result);
    });
  };

  return (
    <LayoutCenter>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
      >
        <Logo />
        <Box mt={4}>
          <Button
            variant='contained'
            color='primary'
            startIcon={<FontAwesomeIcon icon={faGoogle} />}
            onClick={signIn}
          >
            Sign In With Google
          </Button>
        </Box>
      </Box>
    </LayoutCenter>
  );
};

export default Login;
