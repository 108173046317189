import type { DatabaseDevices, LiftDeviceStateUnflattened } from '@phyllome/common';
import Alert from '@mui/material/Alert';
import { useMemo } from 'react';

//import ReChart from "../../components/Rechart/Rechart";
//import ReChartArea from "../../components/Rechart/RechartArea";

const GraphLiftCore = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LiftDeviceStateUnflattened>;
}) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { deviceId, brokerId } = deviceState;

  return (
    <Alert severity='info' variant='outlined'>
      Not yet implemented
    </Alert>
  );
};

export default GraphLiftCore;
