// AlertDialog.tsx
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from '@mui/material';
import { useState } from 'react';

interface AlertDialogProps {
  trigger: (handleOpen: () => void) => React.ReactNode;
  title?: string;
  message: string;
  okButtonText?: string;
  onConfirm?: () => void;
}

export const AlertDialog = ({
  trigger,
  title = 'Action Required',
  message,
  okButtonText = 'OK',
  onConfirm,
}: AlertDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onConfirm?.();
  };

  return (
    <>
      {trigger(handleOpen)}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {okButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// Example of another component using the AlertDialog
export const AnotherComponent = () => {
  return (
    <AlertDialog
      title='Warning'
      message='Some other warning message'
      okButtonText='Got it'
      onConfirm={() => console.log('Dialog closed')}
      trigger={(handleOpen) => (
        <Button onClick={handleOpen}>
          Show Warning
        </Button>
      )}
    />
  );
};