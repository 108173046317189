import type { DatabaseSeason, WithId } from '@phyllome/common';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import { CircularProgress, Stack } from '@mui/material';
import { useDevicesInSeason } from '../../../../data/hooks/collections/useDevicesInSeason';
import DeviceTaskIconNew from '../../../../components/DeviceTaskIcon/DeviceTaskIconNew';

const SideBarSeasonDevices = ({
  seasonData,
}: {
  seasonData: WithId<DatabaseSeason>;
}) => {
  const devicesInSeason = useDevicesInSeason(seasonData.id);

  if (seasonData?.state === 'completed') return null;

  if (devicesInSeason.status === 'pending') {
    return (
      <PaperSection heading='Attached Devices (loading...)' mb={2}>
        <CircularProgress size='1.2em' />
      </PaperSection>
    );
  }
  const numberOfDissatifiedDevices = devicesInSeason.data.filter((item) =>
    item.updateStatus !== 'COMPLETE',
  ).length;

  const numberOfDevices = devicesInSeason.data.length;

  return (
    <PaperSection
      heading={`Attached Devices (${numberOfDevices - numberOfDissatifiedDevices} / ${numberOfDevices})`}
      mb={2}
    >
      <Stack direction='row' useFlexGap flexWrap='wrap' spacing={0.5}>
        {devicesInSeason.data.map((device) => {
          return <DeviceTaskIconNew data={device} key={device.deviceId} />;
        })}
      </Stack>
    </PaperSection>
  );
};

export default SideBarSeasonDevices;
