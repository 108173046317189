import type { FC } from 'react';
import * as React from 'react';

// CSS styles can be moved to a separate CSS file
const loggedInAsStyles: React.CSSProperties = {
  display: 'none',
  position: 'fixed',
  bottom: '10px',
  left: '80px',
};

interface IProps {
  brokerId: string | undefined;
  visible: boolean;
}

const LoggedInAs: FC<IProps> = ({ brokerId, visible }) => {
  return (
    <div style={loggedInAsStyles}>
      <div className='text'>
        {visible ? `Using ${brokerId}` : `Using ${brokerId}`}
      </div>
    </div>
  );
};

export default LoggedInAs;