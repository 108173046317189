import type { LightingDeviceStateUnflattened } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestLighting = (deviceId: string) => {
  const data = useDeviceState<LightingDeviceStateUnflattened>({
    deviceId: deviceId,
    deviceType: 'lighting',
  });

  if (!data) return undefined;
  return data;
};

export default useLatestLighting;
