import type { DatabaseDevices, LiftDeviceStateUnflattened } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import { formatSeconds } from '../../helpers/formatSeconds';
import FormLiftConfigSetColumn from './forms/FormLiftCoreConfigSetColumn';
import FormLiftConfigSetSensors from './forms/FormLiftCoreConfigSetSensors';
import FormLiftConfigSetSpeeds from './forms/FormLiftCoreConfigSetSpeeds';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import { useMemo } from 'react';

const ConfigLiftCore = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LiftDeviceStateUnflattened>;
}) => {
  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;

  return (
    <>
      <PaperSection heading='Lift Controller Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceId}</LabelValue>
        <LabelValue label='Aliases'>{String(latest.alias)}</LabelValue>
        <LabelValue label='Firmware'>{latest.firmware}</LabelValue>
        <LabelValue label='Build date'>{latest.compiled}</LabelValue>
        <LabelValue label='Network IP'>TBD</LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormLiftConfigSetSensors deviceState={deviceState} />
      <FormLiftConfigSetSpeeds deviceState={deviceState} />
      <FormLiftConfigSetColumn deviceState={deviceState} />
      <GenericConfigGet
        deviceId={deviceId}
        deviceType='liftcore'
      />
    </>
  );
};

export default ConfigLiftCore;
