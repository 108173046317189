import type { LightingCoreDeviceStateUnflattened } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestLightingCore = (deviceId: string) => {
  return useDeviceState<LightingCoreDeviceStateUnflattened>({
    deviceId,
    deviceType: 'lightingcore',
  });
};

export default useLatestLightingCore;
