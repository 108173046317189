import { type FC, useContext } from 'react';
import FarmContext from '../../../FarmProvider/FarmContext';
import RackPosition from '../../components/RackPosition';
import NDSRackElement from './NDSRackElement';
import useNDSLatestByColumnPoller from '../../../../devicespecific/NDS/hooks/useNDSLatestByColumnPoller';

type NDSRackProps = {
  brokerId: string;
  column: number;
};

const NDSRack: FC<NDSRackProps> = ({ brokerId, column }) => {
  const farmConfig = useContext(FarmContext);

  const { rows, shelves } = farmConfig.columnSpec[column - 1];
  const NDSData = useNDSLatestByColumnPoller(brokerId, column);

  if (!NDSData) return null;

  const { location } = NDSData.data[0].properties;

  return (
    <>
      {NDSData.data.map((data) => {
        if (location.shelf > shelves || location.row > rows) {
          return null;
        }
        return (
          <RackPosition
            shelf={location.shelf}
            row={location.row}
            shelves={shelves}
            key={data.deviceId}
          >
            <NDSRackElement data={data} />
          </RackPosition>
        );
      })}
    </>
  );
};

export default NDSRack;
