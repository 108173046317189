import type { SentryDeviceStateUnflattened } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestSentry = (deviceId: string) => {
  return useDeviceState<SentryDeviceStateUnflattened>({
    deviceId,
    deviceType: 'sentry',
  });
};

export default useLatestSentry;
