import { useMutation } from '@tanstack/react-query';
import useUserToken from './useUserToken';
import { useContext } from 'react';
import { createFetchEndpoint } from './useFetch';
import FarmContext from '../../components/FarmProvider/FarmContext';

const usePost = (endpoint: string) => {
  const userToken = useUserToken();
  const { brokerId } = useContext(FarmContext);

  const mutation = useMutation({
    mutationFn: async (params: any) => {
      if (!userToken) return;
      const correctEndPoint = createFetchEndpoint(endpoint, brokerId, userToken);

      const response = await fetch(correctEndPoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    },
  });

  const run = (params: any) => {
    return new Promise((resolve, reject) => {
      mutation.mutate(params, {
        onSuccess: (data) => {
          resolve(data);
        },
        onError: (error) => {
          reject(error);
        },
      });
    });
  };

  return {
    ...mutation,
    run,
  };
};

export default usePost;