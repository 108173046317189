import { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  limit,
} from 'firebase/firestore';
import type { DatabaseSeason, WithId } from '@phyllome/common';

export type SeasonRow = WithId<DatabaseSeason>;

const useFindSeasonWithZone = ({
  zoneId,
  brokerId,
}: {
  zoneId: number;
  brokerId: string;
}): WithId<DatabaseSeason> | undefined => {
  const [seasons, setSeasons] = useState<WithId<DatabaseSeason>>();

  useEffect(() => {
    const db = getFirestore();
    const seasonQuery = query(
      collection(db, 'season'),
      where('brokerId', '==', brokerId),
      where('state', '!=', 'completed'),
      where('lightingZones', 'array-contains', zoneId),
      orderBy('created'),
      limit(1),
    );
    const unsubscribe = onSnapshot(seasonQuery, (snapshot) => {
      const seasonData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as WithId<DatabaseSeason>[];

      setSeasons(seasonData[0]);
    });

    return () => {
      unsubscribe();
    };
  }, [brokerId, zoneId]);
  return seasons;
};

export default useFindSeasonWithZone;
