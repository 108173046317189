import { useMemo } from 'react';
import type { NDSDeviceFromFetch } from '../../../types/types';
import useNDSLatestByColumnPoller from './useNDSLatestByColumnPoller';

export type UseNDSLatestLookupReturn = { [key: string]: NDSDeviceFromFetch };

/*
  Instead of returning an array, it returns an object
*/
const useNDSLatestWithLookup = (
  brokerId: string,
  column: number,
): UseNDSLatestLookupReturn => {
  const data = useNDSLatestByColumnPoller(brokerId, column);

  // Memoize the derived lookup object based on data
  const lookupData = useMemo(() => {
    if (!data?.data) return {};

    const collect: { [key: string]: NDSDeviceFromFetch } = {};

    // biome-ignore lint/complexity/noForEach: <explanation>
    data.data.forEach((value) => {
      const { location } = value.properties;
      const key = `${location.row}${location.shelf}.${location.row}`;

      collect[key] = value;
    });
    return collect;
  }, [data]);

  return lookupData;
};

export default useNDSLatestWithLookup;