import { useContext } from 'react';
import type { AnyObject, DeviceType, WithId } from '@phyllome/common';
import FarmContext from '../../components/FarmProvider/FarmContext';
import useFetch from './useFetch';

const useDevicesUpdating = () => {
  const { brokerId } = useContext(FarmContext);
  const data = useFetch<WithId<{
    lastAccessed: string,
    diff: AnyObject,
    seasonId: null | string[],
    deviceType: DeviceType,
    desiredState: AnyObject,
    properties: AnyObject,
    deviceId: string
  }>[]>(`/farm/${brokerId}/devices/updating`, {
    default: [],
    interval: 5001,
  });

  return data;

};

export default useDevicesUpdating;