import { Stack } from '@mui/system';
import { Controller } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, LiftDeviceStateUnflattened } from '@phyllome/common';
import {   mqttDefinitionLiftMove } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { Alert, AlertTitle, Divider, FormControl, FormControlLabel, FormLabel, Radio } from '@mui/material';
import { useMemo, useState } from 'react';

const FormLiftMove = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<LiftDeviceStateUnflattened>;
}) => {
  const [showShelfZeroAlert, setShowShelfZeroAlert] = useState(false);

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionLiftMove,
    deviceId,
  );

  const activityLog = useActivityLog(`device/lift/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionLiftMove, {
    shelf: latest?.shelf || 1, // shelf 1 as traybot doesn't like shelf 0
  });

  // shelf 12 at the top
  // TODO get shelf from farm config
  const shelfOptions = Array.from({ length: 12 }, (_, i) => ({
    id: (11 - i).toString(),
    label: `Shelf ${11 - i}`,
  }));

  const anyTraybotLoaded = latest?.traybots ? Object.values(latest.traybots).some(value => !!value) : false;
  const isBlocked = latest?.blocked ? Object.values(latest.blocked).some(value => !!value) : false;
  const isBusy = latest?.busy !== false;
  const formSubmitDisabled = isBlocked || isBusy;

  return (
    <PaperSection
      heading='Move lift'
      subheading={mqttDefinitionLiftMove.topic}
      mb={2}
    >
      {(isBlocked || isBusy) && (
        <Alert severity='warning'>
          <AlertTitle>Lift is blocked / busy</AlertTitle>
          Can not move lift while blocked or in busy state.
        </Alert>
      )}
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              shelf: data.shelf,
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            `Moved lift ${deviceId} to shelf ${data.shelf}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          firstColumn: yup.number().min(1).max(100).required(),
        })}
      >
        <Stack spacing={2}>

          <FormControl component='fieldset'>
            <FormLabel component='legend'>Select Shelf</FormLabel>
            <Stack spacing={0.5} sx={{ mt: 1 }}>
              <Controller
                name='shelf'
                render={({ field }) => (
                  <>
                    {shelfOptions.map((option) => (
                      <>
                        {option.id === '0' && <Divider />}
                        <FormControlLabel
                          key={option.id}
                          control={
                            <Radio
                              checked={field.value === option.id}
                              onChange={() => {
                                field.onChange(option.id);
                                setShowShelfZeroAlert(option.id === '0' && (anyTraybotLoaded ));
                              }}
                              disabled={
                                (option.id === '0'  && (anyTraybotLoaded))
                              }
                            />
                          }
                          label={option.label}
                        />
                      </>
                    ))}
                  </>
                )}
              />
            </Stack>
          </FormControl>
          {showShelfZeroAlert && (
            <Alert severity='error'>
              <AlertTitle>Error - Traybot loaded</AlertTitle>
              Can not move to shelf 0 when traybot is loaded.
            </Alert>
          )}
          <SubmitControl controlHook={controlLifecycle} disabled={formSubmitDisabled}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormLiftMove;
