import {    useAtomValue } from 'jotai';
import { devicesByPrefixAtom } from '../../../../data/atoms/deviceAtomFamily';
import { brokerIdAtom } from '../../../../data/atoms/websocketFilters';

const useNDSByColumn = (column: number) => {

  const brokerId = useAtomValue(brokerIdAtom);
  const deviceData = useAtomValue(devicesByPrefixAtom(`${brokerId}.nds.${column}`));

  return deviceData;

};

export default useNDSByColumn;