import { Stack } from '@mui/system';
import { SwitchElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { DatabaseDevices, TransferDeviceStateUnflattened } from '@phyllome/common';
import {
  mqttDefinitionTransferManual,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Typography } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { useMemo } from 'react';

const FormTransferManualActuators = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<TransferDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const { deviceId } = deviceState;

  const controlLifecycle = useControlSend(
    mqttDefinitionTransferManual,
    `${deviceId}`,
  );

  const activityLog = useActivityLog(`device/transfer/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTransferManual,
    {
      actuators: {
        hatch: latest?.actuators?.hatch || 1,
        roller_infeed: latest?.actuators?.roller_infeed || 1,
        roller_outfeed: latest?.actuators?.roller_outfeed  || 1,
        sweeper_infeed: latest?.actuators?.sweeper_infeed  || 1,
        sweeper_outfeed: latest?.actuators?.sweeper_outfeed  || 1,
      },
    },
  );

  return (
    <PaperSection
      mb={2}
      heading='Actuators Manual Control'
      subheading={mqttDefinitionTransferManual.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              actuators: {
                hatch: data?.actuators?.hatch,
                sweeper: {
                  infeed: data?.actuators?.sweeper_infeed,
                  outfeed: data?.actuators?.sweeper_outfeed,
                },
                roller: {
                  infeed: data?.actuators?.roller_infeed,
                  outfeed: data?.actuators?.roller_outfeed,
                },

              },
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            'Manually set actuators',
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          conveyors: yup.object({
          }),
        })}
      >
        <Stack spacing={2}>
          <Typography variant='body1'>
            Open / Close the actuators.
          </Typography>
          <SwitchElement
            label='Sweeper Infeed'
            name='actuators.sweeper_infeed'
          />
          <SwitchElement
            label='Sweeper Outfeed'
            name='actuators.sweeper_outfeed'
          />
          <SwitchElement
            label='Roller Infeed'
            name='actuators.roller_infeed'
          />
          <SwitchElement
            label='Roller Outfeed'
            name='actuators.roller_outfeed'
          />
          <SwitchElement
            label='Door Hatch'
            name='actuators.hatch'
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTransferManualActuators;
