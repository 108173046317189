import { colors, desiredStateCompare, type DatabaseDevices } from '@phyllome/common';
import PaperSection from '../puregui/PaperSection/PaperSection';
import { useEffect, useState } from 'react';
import type { AnyObject } from 'yup';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowForward';
import ObjectToTable from '../ObjectToTable/ObjectToTable';
import {  howLongAgoInEnglish } from '../../helpers/FStimeAgo';

const PendingDevice = ({ deviceState }: { deviceState: DatabaseDevices<any> }) => {
  const [diff, setDiff] = useState<AnyObject>({});

  useEffect(() => {
    if (!deviceState) return;

    const test = desiredStateCompare({
      currentState: deviceState.properties,
      desiredState: deviceState.desiredProperties,
    });

    setDiff(test);
  }, [deviceState]);

  const longAgo = howLongAgoInEnglish(deviceState.desiredPropertiesSentToMCPTimestamp);

  const text = longAgo === 'now' ? 'just now' : `${longAgo} ago`;

  if (!deviceState) return null;
  if (deviceState.updateStatus === 'COMPLETE') return null;
  return (
    <PaperSection
      subheading={`MCP received this request ${text}`}
      heading='Pending Device Update'
      mb={2}
      headerSx={{ background: colors.earth3 }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Property</TableCell>
            <TableCell>From</TableCell>
            <TableCell>{' '}</TableCell>
            <TableCell>To</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(diff).map((property, index) => {
            const from = deviceState.properties[property];
            const to = diff[property];

            return (
              <TableRow key={index}>
                <TableCell>{property}</TableCell>
                <TableCell>
                  {typeof from === 'object'
                    ? <ObjectToTable data={from} />
                    : String(from)}
                </TableCell>
                <TableCell>
                  <Box component='span' sx={{ fontSize: '150%', display: 'flex', justifyContent: 'center' }}>
                    <ArrowRightAltIcon sx={{ fontSize: '150%', color: '#888' }}/>
                  </Box>
                </TableCell>
                <TableCell>
                  {typeof to === 'object'
                    ? <ObjectToTable data={to} />
                    : String(to)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

    </PaperSection>
  );
};

export default PendingDevice;