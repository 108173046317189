import FormPackagerReset from './forms/FormPackagerReset';
import LatestPackagerInfo from './LatestPackagerInfo';
import type { DatabaseDevices, PackagerDeviceStateUnflattened } from '@phyllome/common';
import GenericPing from '../Generic/control/GenericPing';

const ControlPackager = ({

  deviceState,
}: {
  deviceState: DatabaseDevices<PackagerDeviceStateUnflattened>;
}) => {

  const { deviceId } = deviceState;

  return (
    <>
      <LatestPackagerInfo deviceState={deviceState} />
      <FormPackagerReset deviceState={deviceState} />
      <div>
        <GenericPing
          deviceId={deviceId}
          deviceType='packager'
        />
      </div>
    </>
  );
};

export default ControlPackager;
