import type { DatabaseDevices, SeederDeviceStateUnflattened } from '@phyllome/common';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import FormSeederConfigTray from './forms/FormSeederConfigTray';
import FormSeederConfigSpeed from './forms/FormSeederConfigSpeed';
import { useMemo } from 'react';

const ConfigSeeder = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<SeederDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;

  return (
    <>
      <PaperSection heading='Seeder Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceId}</LabelValue>
        <LabelValue label='Firmware'>{latest?.firmware}</LabelValue>
        <LabelValue label='Build Date'>{latest?.compiled}</LabelValue>
        <LabelValue label='Free memory'>TBD</LabelValue>
        <LabelValue label='Network'>TBD</LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormSeederConfigTray deviceState={deviceState} />
      <FormSeederConfigSpeed deviceState={deviceState} />
      <GenericConfigGet
        deviceId={deviceId}
        deviceType='seeder'
      />
    </>
  );
};

export default ConfigSeeder;
