// Import the necessary functions from Jotai
import type { Atom } from 'jotai/vanilla';
import { getDefaultStore } from 'jotai/vanilla';

// Your helper function (not a hook)
function getAtomValue<T>(atom:Atom<T>) {
  // Get the default store
  const store = getDefaultStore();

  // Get the current value of the atom
  return store.get(atom);
}

export default getAtomValue;