/* eslint-disable @typescript-eslint/no-unused-vars */
import { isJson } from '@phyllome/common';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useUser } from 'reactfire';
import useUserToken from '../../../data/hooks/useUserToken';
import useAllDevices from '../../../data/hooks/useAllDevices';
import useWebSocket from '../../../data/WebSocket/useWebsocket';

type DeviceWebsocketRunProps = {
  brokerId: string;
};

const getWSEndpoint = (token:string) => {
  return process.env.NODE_ENV === 'production'
    ? `https://websocket.phyllome.io/ws/?t=${token}`
    : `http://127.0.0.1:8090?token=${token}`;
};

const DeviceWebsocketRun: FC<DeviceWebsocketRunProps> = () => {
  const [socketUrl, setSocketUrl] = useState('');

  const token = useUserToken();

  const ws = useWebSocket();

  // useEffect(() => {

  //   if (token === undefined) return;
  //   async function fetchData() {

  //     const ws = new WebSocket(getWSEndpoint(token as string));

  //     ws.onopen = () => {
  //       ws.send(JSON.stringify({ type: 'authenticate', token }));
  //     };

  //     ws.onopen = () => {
  //       console.log('Connected! Checking handshake details...');
  //     };

  //     ws.onclose = (event) => {
  //       console.log('WebSocket Closed:', event.code, event.reason);
  //     };

  //     ws.onmessage = (event) => {
  //       console.log('Received:', event.data);
  //     };

  //     ws.onerror = (error) => {
  //       // Log the full error details
  //       console.log('Error details:', {
  //         readyState: ws.readyState,
  //         protocol: ws.protocol,
  //         extensions: ws.extensions,
  //         url: ws.url,
  //         error,
  //       });
  //     };

  //   }

  //   fetchData();

  // }, [token]);
  return <div>Wopot</div>;
  //return <DeviceWebsocket socketUrl={socketUrl} token={token}/>;
};

// const DeviceWebsocket = ({ socketUrl, token }: { socketUrl: string, token: string }) => {
//   const [connectionStatus, setConnectionStatus] = useState<string>('Connecting...');

//   const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
//     protocols: token,
//     onOpen: () => {
//       console.log('WebSocket Connected');
//       setConnectionStatus('Connected');

//       // Send an initial message to test the connection
//       // sendMessage(JSON.stringify({
//       //   type: 'hello',
//       //   message: 'Connected to WebSocket',
//       // }));
//     },
//     onClose: (event) => {
//       console.log('WebSocket Closed:', event);
//       setConnectionStatus(`Closed: ${event.reason || 'No reason provided'}`);
//     },
//     onError: (event) => {
//       console.error('WebSocket Error:', event, event.toString());
//       setConnectionStatus('Error occurred');
//     },
//     onMessage: (event) => {

//       if (isJson(event.data)) {
//         console.log('Received message:', JSON.parse(event.data));
//       }
//     },
//     // Add reconnect options
//     shouldReconnect: (closeEvent) => true,
//     reconnectInterval: 3000,
//     reconnectAttempts: 10,

//   });

//   // Add a more detailed status display
//   useEffect(() => {
//     switch (readyState) {
//       case ReadyState.CONNECTING:
//         setConnectionStatus('Connecting...');
//         break;
//       case ReadyState.OPEN:
//         setConnectionStatus('Open');
//         break;
//       case ReadyState.CLOSING:
//         setConnectionStatus('Closing...');
//         break;
//       case ReadyState.CLOSED:
//         setConnectionStatus('Closed');
//         break;
//       default:
//         setConnectionStatus('Unknown');
//         break;
//     }
//   }, [readyState]);
//   return (
//     <div>
//       <div>Connection Status: {connectionStatus}</div>
//       <div>ReadyState: {ReadyState[readyState]}</div>

//     </div>
//   );
// };

export default DeviceWebsocketRun;