import { useMemo, type FC } from 'react';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import { PhyconMUI } from '../../../components/puregui/Phycon/Phycon';
import { CompassCalibration } from '@mui/icons-material';
import useDevicesUpdating from '../../../data/hooks/useDevicesUpdating';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import useSeasons from '../../../data/hooks/collections/useSeasons';
import useNavSide from '../../../data/hooks/useNavSide';

type DevicesUpdatingProps = {
  brokerId: string;
};

const DevicesUpdating: FC<DevicesUpdatingProps> = () => {

  const que = useDevicesUpdating();
  const seasons = useSeasons({
    active: true,
  });

  const navSide = useNavSide();

  const seasonLookup = useMemo<{ [key: string]: typeof seasons.data[0] }>(() => {

    return seasons.data.reduce((acc, season) => ({
      ...acc,
      [season.id]: season,
    }), {});
  }, [seasons]);

  const filteredMap = que.data?.filter(x => Object.keys(x.diff).length > 0);

  return (
    <ContainerPrimary
      title={`Updating Devices (${filteredMap.length})`}
      fullWidth={true}
      icon={<PhyconMUI icon={CompassCalibration} />}
    >
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Device ID</TableCell>
              <TableCell>Desired State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMap.map((device) => (
              <TableRow key={device.id}>
                <TableCell><Button onClick={() => { navSide(`${device.deviceType}/${device.deviceId}`); }}>{device.deviceType}/{device.deviceId}</Button></TableCell>
                <TableCell>
                  <DULabel>DESIRED STATE</DULabel>
                  {JSON.stringify(device.desiredState)}
                  <DULabel>SENDING TO DEVICE</DULabel>
                  {JSON.stringify(device.diff)}</TableCell>
                <TableCell>{device.seasonId && device.seasonId.map(y => {
                  const season = seasonLookup[y];
                  const seasonId = season ? season.id : y;

                  return <Button onClick={() => navSide(`season/${seasonId}`)}>{season ? season.name : y}</Button>;
                })}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ContainerPrimary>
  );
};

const DULabel = ({ children }:{ children: React.ReactNode }) => <div style={{ fontWeight: 'bold', color: '#2294d9' }}>{children}</div>;

export default DevicesUpdating;