import type { CameraDeviceStateUnflattened, DatabaseDevices } from '@phyllome/common';
import { Button } from '@mui/material';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import useNavSide from '../../data/hooks/useNavSide';
import FormCameraConfigCameraSettingsVisible from './forms/FormCameraConfigCameraSettingsVisible';
import FormCameraConfigCameraSettingsIR from './forms/FormCameraConfigCameraSettingsIR';
import GenericConfigGet from '../../components/GenericConfigGet/GenericConfigGet';
import { useMemo } from 'react';

const ConfigCamera = ({
  deviceState,
}: {
  deviceState: DatabaseDevices<CameraDeviceStateUnflattened>;
}) => {

  const latest = useMemo(() => deviceState.properties, [deviceState.properties]);

  const deviceId = deviceState.deviceId;
  const navSide = useNavSide();

  return (
    <>
      <PaperSection heading='Camera Device Configuration' mb={2}>
        <LabelValue label='Device ID'>{deviceId}</LabelValue>
        <LabelValue label='Linked Traybot'>
          {latest.traybot}
          <Button
            variant='text'
            size='small'
            onClick={() => {
              navSide(`traybot/${latest.traybot}`);
            }}
          >
            open
          </Button>
        </LabelValue>
        <LabelValue label='Network IP'>
          {latest.network?.ip} ({latest.network?.rssi}dB)
        </LabelValue>
        <LabelValue label='Uptime'>{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormCameraConfigCameraSettingsVisible deviceState={deviceState} />
      <FormCameraConfigCameraSettingsIR deviceState={deviceState} />
      <GenericConfigGet
        deviceId={deviceId}
        deviceType='camera'
      />
    </>
  );
};

export default ConfigCamera;
