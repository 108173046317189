import type { DatabaseTraySeason, WithId } from '@phyllome/common';
import useFetch from '../useFetch';
import { normaliseTraySeasonData } from './useTraySeasonBySeasonId';
import { useEffect, useState } from 'react';

const useTraySeasonsCurrentByColID = ({
  columnId,
  seasonId,
}: {
  columnId?: number;
  seasonId?: string;
}) => {
  const [endpoint, setEndpoint] = useState('');

  useEffect(() => {
    if (!columnId && !seasonId) {
      setEndpoint('/farm/:brokerId/trayseasons/:brokerId');
    } else if (columnId && !seasonId) {
      setEndpoint(`/farm/:brokerId/trayseasons/column/${columnId}`);
    } else if (seasonId && !columnId) {
      setEndpoint(`/farm/:brokerId/trayseasons/season/${seasonId}`);
    } else if (seasonId && columnId) {
      setEndpoint(`/farm/:brokerId/trayseasons/season/${seasonId}/${columnId}`);
    }
  }, [columnId, seasonId]);

  const fetch = useFetch<WithId<DatabaseTraySeason>[]>(endpoint, {
    default: [],
    interval: 15000,
  });

  return {
    data: fetch.data.map((data) => {
      return normaliseTraySeasonData(data);
    }),
    refetch: fetch.refetch,
  };

};

export default useTraySeasonsCurrentByColID;