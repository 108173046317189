import { Timestamp } from 'firebase/firestore';
import dateToString, { dateFormatTypes } from '../../../helpers/dateToString';
import * as React from 'react';

interface DateProps {
  date?: Timestamp | Date | string | null | undefined;
}

interface UsingDateObjectProps {
  date: Date;
}

// Rename component to avoid conflict with built-in Date constructor
const DateDisplay: React.FC<DateProps> = ({ date }) => {
  let parsedDate: Date | null = null;

  if (typeof date === 'string') {
    try {
      parsedDate = new Date(date);
    } catch (e) {
      console.error('Invalid date string:', date, e);
    }
  } else if (date instanceof Timestamp) {
    parsedDate = date.toDate();
  } else if (date instanceof Date) {
    parsedDate = date;
  }

  if (!parsedDate || isNaN(parsedDate.getTime())) return <span>no date</span>;
  return <UsingDateObject date={parsedDate} />;
};

const UsingDateObject: React.FC<UsingDateObjectProps> = ({ date }) => {
  const string = dateToString(date, dateFormatTypes.DAYMONTHHOURSSECONDS);

  return <span>{string}</span>;
};

export default DateDisplay;