import { Tabs, Tab, Box } from '@mui/material';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  children: React.ReactNode;
  selected: number;
  brokerId: string;
}

const Logs: FC<IProps> = ({ brokerId, children, selected }) => {
  const navigate = useNavigate();

  // const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  //   '& .MuiBadge-badge': {
  //     right: -3,
  //     top: 13,
  //     border: `2px solid ${theme.palette.background.paper}`,
  //     padding: '0 4px',
  //   },
  // }));

  return (
    <>
      <Box mb={2}>
        <Tabs value={selected} aria-label='Seasons Navigation'>
          <Tab
            label='Alerts'
            value={0}
            onClick={() => navigate(`/broker/${brokerId}/logs/alerts`)}
          />
          <Tab
            label='Activity'
            value={1}
            onClick={() => navigate(`/broker/${brokerId}/logs/activity`)}
          />
          <Tab
            label='Updates'
            value={2}
            onClick={() => navigate(`/broker/${brokerId}/logs/updates`)}
          />
        </Tabs>
      </Box>
      <Box>{children}</Box>
    </>
  );
};

export default Logs;
